import {Input, notification, Spin,Rate} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {claimRepository} from "../../repositories/ClaimRepository";
import {ClaimDetailContext} from "../../context/ClaimDetailContext";
import {AxiosError} from "axios";
import lodash from "lodash";


interface RatingFormInterface {
    rating: number|undefined
    comment: string|undefined
}

export const Feedback = () => {
    const {t} = useTranslation()
    const {claim, provider, customerCode, onRatingSuccess} = useContext(ClaimDetailContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [form, setForm] = useState<RatingFormInterface>({rating: undefined, comment: undefined})

    useEffect(() => {
        setForm({
            rating: claim.rating,
            comment: claim.ratingComment
        })
    }, [claim])

    const handleChangeRating = (rating: number) => {
        setForm({
            ...form,
            rating
        })
    }

    const handleChangeComment = (comment: string) => {
        setForm({
            ...form,
            comment
        })
    }

    const handleSubmitRating = () => {
        if (!form.rating) {
            notification.error({
                message: t('rating.validate_rating')
            })
            return
        }

        if (!form.comment) {
            notification.error({
                message: t('rating.validate_comment')
            })
            return
        }

        setLoading(true)
        claimRepository.rating(provider.code, customerCode, claim.code, {
            rating: form.rating,
            comment: form.comment
        })
            .then(() => {
                setForm({
                    rating: undefined,
                    comment: undefined
                })

                notification.success({
                    message: t('rating.create_success')
                })

                document.body.click()

                onRatingSuccess()
            })
            .catch((error: AxiosError) => {
                if (lodash.get(error.response, 'status') === 400) {
                    notification.error({
                        message: t(`rating.error_${lodash.get(error.response, 'data.title')}`)
                    })
                }
                else {
                    notification.error({
                        message: t('rating.create_failed')
                    })
                }

            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleMouseOverRating = (star: number) => {
        setForm({
            ...form,
            rating: star
        })
    }

    return (
        <Spin spinning={loading}>
            <div className="ticket-feedback rate-box">
                <h4 className={'fsz-16 txt-color-black robotomedium'}>
                    {t('tickets.feedback')}
                </h4>
                <div className={'info-group rate-star-box align-items-center'}>
                    <div className="info-group-label robotoregular fsz-12 width182px">{t('tickets.feedback_star')}:</div>
                    <div className={`info-group-value pdl5`}>
                        <Rate
                            style={{fontSize:32}}
                            allowHalf={true}
                            value={lodash.get(form,'rating',0)/2}
                            onChange={(value)=> handleChangeRating(value*2)}
                            // onHoverChange={(value => handleMouseOverRating(value))}
                        />
                    </div>
                </div>
                <div className={'info-group rate-content-box'}>
                    <div className="info-group-label robotoregular fsz-12 width182px">{t('tickets.feedback_content')}:</div>
                    <div className="info-group-value">
                        <Input.TextArea
                            disabled={loading}
                            value={form.comment}
                            onChange={e => handleChangeComment(e.target.value)}
                            className={`rate-content`}
                            placeholder={"Enter để lưu đánh giá"}
                            style={{
                                pointerEvents: loading ? 'none' : 'inherit'
                            }}
                            onPressEnter={handleSubmitRating}
                            rows={5}
                            maxLength={500}
                            showCount
                        />
                    </div>
                </div>
            </div>
        </Spin>
    )
}
