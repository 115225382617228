import { Row, Col } from 'antd';

interface RowItemInterface {
  label?: string | false;
  children?: any;
  isRequire?: boolean;
  className?: string;
  labelSpan?: number;
  contentSpan?: number;
  subLabel?: string;
}

export const RowItem: React.FC<RowItemInterface> = ({
  className = 'mgt10',
  label,
  subLabel,
  isRequire = false,
  labelSpan = 3,
  contentSpan = 21,
  children,
}: RowItemInterface): JSX.Element => {
  return (
    <Row gutter={12} className={className}>
      <Col
        span={labelSpan}
        className={isRequire ? 'label label-require' : 'label'}
      >
        {label && `${label}:`}
        {subLabel && <br />}
        {subLabel}
      </Col>

      <Col span={contentSpan}>{children}</Col>
    </Row>
  );
};
