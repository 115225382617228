import { SKUInterface, StoredProductInterface } from './StoredProductInterface'

export interface AttributeParams {
  attributeId: number
  value: string
}

export interface LogisticParams {
  logisticId: number
  enabled: boolean
  shippingFee: number
}

export enum conditionEnum {
  NEW = 'NEW',
  USED = 'USED'
}

export interface ProductForSellInterface extends StoredProductInterface {
  code: string
  channel: string
  categoryId: number
  attributes: AttributeParams[]
  logistics: LogisticParams[]
  integrationId: string
  integrationName: string
  productVideo: string
  condition: conditionEnum
  daysToShip: number
  isPreOrder: boolean
}

export enum EditProductForSellFormNames {
  code = 'code',
  integrationName = 'integrationName',
  categoryId = 'categoryId',
  productImages = 'productImages',
  images = 'images',
  video = 'video',
  name = 'name',
  category = 'category',
  detail = 'detail',
  attributes = 'attributes',
  logistics = 'logistics',
  weight = 'weight',
  length = 'length',
  width = 'width',
  height = 'height',
  skus = 'skus',
  isPreOrder = 'isPreOrder',
  daysToShip = 'daysToShip',
  condition = 'condition'
}

export interface DetailFieldValueInterface {
  original_value: string // 'NoBrand';
  translate_value: string // 'NoBrand';
}

export enum InputTypeEnum {
  DROP_DOWN = 'DROP_DOWN',
  TEXT_FILED = 'TEXT_FILED',
  COMBO_BOX = 'COMBO_BOX'
}

export interface DetailFieldInterface {
  attributeId: number
  attributeName: string
  isMandatory: boolean
  attributeType: 'STRING_TYPE'
  inputType: InputTypeEnum
  options: string[] // ['NoBrand'];
  values: DetailFieldValueInterface[]
  formatType: null
  unitList: null
}

export interface BodyEditProductForSellInterface {
  name?: string
  code: string
  description?: string
  detail?: string
  productVideo?: string
  productImages?: string[]
  categoryId: number
  featuredImage?: string
  skus?: SKUInterface[]
  attributes?: AttributeParams[]
  logistics: LogisticParams[]
  weight?: string | null
  length?: string | null
  width?: string | null
  height?: string | null
  msrp?: number
  salePrice?: number
  isPreOrder: boolean
  daysToShip: number
  condition: conditionEnum
}
