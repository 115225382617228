import { Col, Grid, Row, Table, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { ClaimInterface } from '../../../Interface/ClaimInterface'
import EmptyDataFilter from '../../Empty/EmptyDataFilter'
import { SkeletonTable, SkeletonTableColumnsType } from '../../SkeletonTable'

const { Text } = Typography
const { useBreakpoint } = Grid

// VNK3BG00JPP: ma nay nhieu khieu nai

interface IProps {
	isLoading: boolean
	claimList: ClaimInterface[]
	total: null | number
	pageSize: null | number
	currentPage: number
	onPageChange: (filter: any) => void
	providerCode: string | undefined
	accountCode: string | undefined
	orderCode: string
}

export const getClaimDetailLink = (
	providerCode: string | undefined,
	accountCode: string | undefined,
	orderCode: string,
	claimCode: string,
	fromUrl?: string
): string => {
	if (providerCode && accountCode) {
		return `/claims/${providerCode}/${accountCode}/${orderCode}/${claimCode}/${fromUrl??''}`
	}
	return ''
}

const Tickets: React.FC<IProps> = (props) => {
	const classes = {
		txtBlue: 'txt-color-blue1',
		tHeadFont: 'robotomedium txt-size-h7 whitespace',
		statusDot: 'status-dot mg-bt-1',
		regularFontSize: 'txt-size-h7 robotoregular',
		paginationSpacing: 'mgt0 mgbt0',
		outlinedBtn: 'btn-outlined-orange txt-capitalize border-radius4',
		txtBlack: 'txt-color-black',
		txtOrange: 'txt-color-orange2',
		createdClaimBtn:
			'txt-size-h7 robotoregular btn-outlined-orange btn-create-claim-by-order txt-capitalize border-radius4 px-16 py-5',
	}

	const screen = useBreakpoint()
	const isGreaterThanSm = screen.md || screen.lg || screen.xl || screen.xxl

	const { t } = useTranslation()
	let { code } = useParams()

	const columns = [
		{
			title: <span className={`mg-r-4 fsz-14 line-h-22 whitespace txt-color-gray4 whitespace`}>{t('orderDetail.claimCode')}</span>,
			dataIndex: 'code',
			key: 'code',
			render: (_: string, record: ClaimInterface) => (
				<Text className={`${classes.regularFontSize} ${classes.txtOrange}`}>#{record.code}</Text>
			),
		},
		{
			title: <span className={`mg-r-4 fsz-14 line-h-22 whitespace txt-color-gray4 whitespace`}>{t('orderDetail.claimName')}</span>,
			dataIndex: 'name',
			key: 'name',
			width: isGreaterThanSm ? `${(180 / 924) * 100}%` : 'auto',
			render: (_: string, record: ClaimInterface) => (
				<Text className={`${classes.txtBlack} ${classes.regularFontSize}`}>{record.name}</Text>
			),
		},
		{
			title: <span className={`mg-r-4 fsz-14 line-h-22 whitespace txt-color-gray4 whitespace`}>{t('orderDetail.claimCreatedAt')}</span>,
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (_: string, record: ClaimInterface) => (
				<Text className={`${classes.txtBlack} ${classes.regularFontSize}`}>
					{formatDateTime(moment(record.createdAt))}
				</Text>
			),
		},
		{
			title:<span className={`mg-r-4 fsz-14 line-h-22 whitespace txt-color-gray4 whitespace`}>{t('orderDetail.claimStatus')}</span>,
			dataIndex: 'status',
			key: 'status',
			render: (_: string, record: ClaimInterface) => (
				<>
					<span className={classes.statusDot} style={{ backgroundColor: record.publicState?.color }} /> &nbsp; &nbsp;
					<Text className={`${classes.txtBlack} ${classes.regularFontSize}`}>
						{record.publicState?.name}
						{record.archived && ` (${t('tickets.archived')})`}
					</Text>
				</>
			),
		},
		{
			title: <span className={`mg-r-4 fsz-14 line-h-22 whitespace txt-color-gray4 whitespace`}>{t('tickets.resolvesSuggestion')}</span>,
			dataIndex: 'solution',
			key: 'solution',
			render: (_: string, record: ClaimInterface) => (
				<Text className={`${classes.txtBlack} ${classes.regularFontSize}`}>{record.solution?.name}</Text>
			),
		},
		{
			title: <span className={`mg-r-4 fsz-14 line-h-22 whitespace txt-color-gray4 whitespace`}>{t('orderDetail.totalRefund')}</span>,
			dataIndex: 'totalRefund',
			key: 'totalRefund',
			width: isGreaterThanSm ? `${(235 / 924) * 100}%` : 'auto',
			render: (_: string, record: ClaimInterface) => (
				<Row justify='space-between' align='middle'>
					<Col>
						<Text className={`${classes.regularFontSize} ${classes.txtBlack}`}>
							{/*{formatMoneyByUnit(record.estimatedRefundValue)}*/}
							{record.state === 'REFUND' ? formatMoneyByUnit(record.estimatedRefundValue) : '---'}
						</Text>
					</Col>
					<Col>
						<Link
							to={getClaimDetailLink(props.providerCode, props.accountCode, props.orderCode, record.code, 'order')}
							className={`${classes.regularFontSize} ${classes.txtBlue} item-link`}>
							{t('orderDetail.claimDetail')}
						</Link>
					</Col>
				</Row>
			),
		},
	]

	const dataSource = props.claimList.map((claim) => ({ ...claim, key: `${code}_${claim.code}` }))

	return (
		<Row gutter={[12, 8]}>
			<Col xs={24}>
				<SkeletonTable columns={columns as SkeletonTableColumnsType[]} rowCount={3} loading={props.isLoading} className='order-claims-list'>
					<Table
						className='order-claims-list'
						locale={{
							emptyText: (
								<EmptyDataFilter description={t('orderDetail.emptyClaims')} />
							),
						}}
						loading={props.isLoading}
						columns={columns}
						dataSource={dataSource}
						pagination={false}
					/>
				</SkeletonTable>
			</Col>

			{/* {props.claimList.length > 0 && (
				<Col xs={24}>
					<M24Pagination
						className={classes.paginationSpacing}
						filter={{
							size: props.pageSize,
							page: props.currentPage,
						}}
						pagination={{
							total: props.total,
							current: props.currentPage,
							pageSize: props.pageSize,
							hideOnSinglePage: true,
						}}
						onPageChange={props.onPageChange}
					/>
				</Col>
			)} */}
			
		</Row>
	)
}
export default Tickets

