import { Avatar, Divider, Row, Select } from 'antd'
import React from 'react'
import lodash from 'lodash'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { useTranslation } from 'react-i18next'
interface ConnectionSelectionProps {
	accountDefault: any
	accountBalance: any
	currentConfigShowBalance?: boolean
	onChangeHandler: (val: string) => void
}
const ConnectionSelection: React.FC<ConnectionSelectionProps> = (props) => {
    const {t} = useTranslation()
	const { accountBalance, accountDefault, currentConfigShowBalance, onChangeHandler } = props

	const formatAmount = (amount: any, unit?: string) => {
		if (!amount && amount !== 0) return ''
		let color = amount < 0 ? 'txt-color-red' : 'txt-color-green'
		return <span className={color}>{`${formatMoneyByUnit(amount, unit)}`}</span>
	}
	return (
		<Select
			placeholder={t('shopping-cart.selectAccount')}
			value={accountDefault?.connection?.id}
			className={'width100'}
			onChange={onChangeHandler}>
			{accountBalance &&
				accountBalance.length > 0 &&
				accountBalance.map((x: any) => {
					let currencyItem = JSON.parse(x.connection.provider.currency)
					return (
						<Select.Option value={x.connection.id}>
							<Row className={'dpl-flex align-items-center'}>
								<Avatar size={20} src={lodash.get(x, 'connection.provider.logo', '')} shape={'circle'} className='bd-none-impt'/>
								<span className={'mgl5 robotoregular txt-size-h7 txt-color-black'}>
									{lodash.get(x, 'connection.domain', '')}
								</span>
								<Divider type={'vertical'} className={'bd-color-black'} />
								<span className={'robotoregular txt-size-h7 txt-color-black'}>
									{lodash.get(x, 'connection.name', '')}
								</span>
								{currentConfigShowBalance && (
									<span className={'mgl10 robotoregular txt-size-h7'}>
										Số dư:{' '}
										<span>
											{formatAmount(lodash.get(x, 'balance', ''), lodash.get(currencyItem, 'code', ''))}
										</span>
									</span>
								)}
							</Row>
						</Select.Option>
					)
				})}
		</Select>
	)
}

export default ConnectionSelection

