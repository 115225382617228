import { PlusOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Empty, Image, Modal, Radio, Row, Spin, Switch, Table } from 'antd'
import lodash from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConnectFromM1DialogConfirm from '../../components/ConnectSupplier/ConnectFromM1Confirm'
import ModalAddSupplier from '../../components/ConnectSupplier/ModalAddSupplier'
import MainLayout from '../../components/Layout'
import ConfirmModal from '../../components/Modal/ConfirmModal'
import { TENANT_KEY } from '../../core/config'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatMoneyBySuffix } from '../../core/helpers/string'
import { categoryRepository } from '../../repositories/CategoryRepository'
import { supplierRepository } from '../../repositories/SupplierRepository'
import empty from '../../resources/images/empty-blue.png'
import { localStorageRead } from '../../utils/LocalStorageUtils'
import M24ErrorUtils from '../../utils/M24ErrorUtils'
import M24Notification from '../../utils/M24Notification'
import { newLineToBr } from '../../utils/StringUtils'
import ConnectionItem from './ConnectionItem'
import './styles.scss'

function ConnectSupplier() {
	const [showProgressBar, setShowProgressBar] = useState<boolean>(false)
	const [isLoading, setLoading] = useState<boolean>(false)
	const [isShowModalAdd, setShowModalAdd] = useState<boolean>(false)
	const [domain, setDomain] = useState<any>(null)
	const { t } = useTranslation()
	const [accounts, setAccounts] = useState<any[]>([])
	const [statues, setStatues] = useState<any[]>([])
	const [showConfirm, setShowConfirm] = useState<boolean>(false)
	const [typeConfirm, setTypeConfirm] = useState<string>('')
	const [itemSelected, setSelected] = useState<any>({})
	const [isReconnect, setIsReconnect] = useState<any>(false)

	const [isConnectingFromM1, setIsConnectingFromM1] = useState<boolean>(false)
	const [connectionParams, setConnectionParams] = useState<any>({})
	const [currentConfigShowBalance] = useState(lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', false))
	const [showFaq, setShowFaq] = useState(false)
	const [faqs, setFaqs] = useState('')
	const [usernameUseInFaqs, setUsernameUseInFaqs] = useState('')
	const [providerCode, setProviderCode] = useState('')
	const [loadingFaqs, setLoadingFaqs] = useState(false)
	const [providerName, setProviderName] = useState('')

	useEffect(() => {
		getStatuses()
		getData()

		const hash = window.location.hash
		let params = new URLSearchParams(hash.substring(hash.indexOf('?')))
		let data = {
			token: params.get('token'),
			username: params.get('username'),
			tenant: params.get('tenant'),
		}

		if (data.token) {
			setConnectionParams(data)
			setIsConnectingFromM1(true)
		}
	}, [])

	const getData = async () => {
		setShowProgressBar(true)
		const providers = await categoryRepository.getProvider()
		supplierRepository
			.getListAccount()
			.then((res) => {
				let items = lodash
					.chain(res)
					// Group the elements of Array based on `color` property
					.groupBy('provider.domain')
					// `key` is group's name (color), `value` is the array of objects
					.map((value, key) => {
						let group = value[0].provider
						return { ...group, accounts: value }
					})
					.value()
				const providerCode = lodash.map(providers, 'code')
				const providerCodeFromItems = lodash.map(items, 'code')
				const providerAccountsEmpty = lodash.difference(providerCode, providerCodeFromItems)
				for (let i = 0; i < providerAccountsEmpty.length; i++) {
					const group = providers.find((x: any) => x.code === providerAccountsEmpty[i])
					items = [...items, { ...group, accounts: [] }]
				}
				setAccounts(items)
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => setTimeout(() => {setShowProgressBar(false)}, 2000) )
	}
	const getStatuses = () => {
		categoryRepository
			.getConnectionsStatus()
			.then((res) => {
				setStatues(res)
			})
			.catch((err) => {})
	}
	const onShowModalAdd = () => {
		setShowModalAdd(!isShowModalAdd)
		// if (isShowModalAdd) setDomain('');
	}
	const handleAddAccount = (body: any) => {
		setLoading(true)
		supplierRepository
			.addAccount(body)
			.then((res) => {
				setShowModalAdd(false)
				M24Notification.messageSuccess(t('message.success'))
				getData()
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => setLoading(false))
	}

	const onCancelConfirm = () => {
		setShowModalAdd(false)
		if (isShowModalAdd) setDomain('')
		setSelected({})
		setTypeConfirm('')
		setShowConfirm(false)
		setIsReconnect(false)
	}

	const onCancelConnectFromM1 = () => {
		setIsConnectingFromM1(false)
	}
	const onSubmitConnectFromM1 = () => {
		setLoading(true)
		let body: any = { tokenFromM1: connectionParams.token }
		supplierRepository
			.verifyConnectionFromM1(connectionParams.username, connectionParams.tenant, body)
			.then((response) => {
				M24Notification.messageSuccess(t('message.success'))
				getData()
			})
			.catch((error) => {
				M24ErrorUtils.showError(t, error)
			})
			.finally(() => {
				setLoading(false)
				onCancelConnectFromM1()
			})
	}

	const getBalance = (domain: string, connectionId: any) => {
		supplierRepository
			.getBalance(connectionId)
			.then((res) => {
				let items = lodash.cloneDeep(accounts)
				items.forEach((xx) => {
					if (xx.domain === domain) {
						xx.accounts.forEach((x: any) => {
							if (x.id === connectionId) x.balance = res.balance
						})
					}
				})
				setAccounts(items)
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
	}

	const onSubmitConfirm = () => {
		setLoading(true)
		let body: any = {}
		if (typeConfirm === 'DEFAULT') {
			body.defaultConnection = true
		} else if (typeConfirm === 'CONNECT') {
			body.status = 'CONNECTED'
		} else if (typeConfirm === 'DISCONNECT') {
			body.status = 'DISCONNECTED'
		} else if (typeConfirm === 'DELETE') {
		}
		supplierRepository
			.updateAccount(itemSelected.id, body)
			.then((res) => {
				M24Notification.messageSuccess(t('message.success'))
				getData()
				onCancelConfirm()
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => setLoading(false))
	}

	const onReconnectSubmit = (body: any) => {
		setLoading(true)
		let obj: any = { password: body.password, secret: body.secret }
		supplierRepository
			.reconnectSupplier(itemSelected.id, obj)
			.then((res) => {
				M24Notification.messageSuccess(t('message.success'))
				onCancelConfirm()
				getData()
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => {
				onCancelConfirm()
				setLoading(false)
			})
	}

	const handleShowFaqsChargeCash = (provider: string, username: string) => {
		setShowFaq(true)
		setUsernameUseInFaqs(username)
		getFaqs(provider)
	}

	const getFaqs = async (provider: string) => {
		setLoadingFaqs(true)
		try {
			const res = await categoryRepository.getFaqs(provider)
			setFaqs(lodash.get(res, 'content', ''))
			setLoadingFaqs(false)
		} catch (err) {
			M24ErrorUtils.showError(t, err)
			setLoadingFaqs(false)
		}
	}

	const onSubmitAccountNotExist = (body: any, provider: string) => {
		setLoading(true)
		supplierRepository
			.registerAccount(body, provider)
			.then((res) => {
				setShowModalAdd(false)
				M24Notification.messageSuccess(t('message.success'))
				getData()
			})
			.catch((err) =>
				M24Notification.notifyError(
					t('message.titleFailed'),
					t(`message.${lodash.get(err, 'response.data.title')}`),
					'_notify-error',
					5
				)
			)
			.finally(() => {
				setLoading(false)
			})
	}

	const renderItem = (item: any) => {
		let currencyItem = JSON.parse(item.currency)
		const columns = [
			{
				title: t('supplier.username'),
				dataIndex: 'name',
				key: 'name',
				width: 200,
				render: (text: any, record: any) => <span className={'txt-size-h7 txt-color-black robotoregular'}>{text}</span>,
			},
			{
				title: t('supplier.createdAt'),
				dataIndex: 'createdAt',
				key: 'createdAt',
				width: 200,
				render: (text: any, record: any) => (
					<span className={'txt-size-h7 txt-color-black robotoregular'}>{formatDateTime(text)}</span>
				),
			},
			{
				title: t('supplier.balance'),
				dataIndex: 'balance',
				className: 'txt-right',
				key: 'balance',
				width: 200,
				render: (value: any, record: any) => (
					<Row className={'align-items-center justify-content-end'}>
						<span className={'txt-size-h7 txt-color-black robotoregular mgr5'}>
							{record.balance != null && record.balance !== undefined
								? formatMoneyBySuffix(
										record.balance,
										lodash.get(currencyItem, 'prefix', ''),
										lodash.get(currencyItem, 'suffix', '')
								  )
								: '********'}
						</span>
						<span
							onClick={() => {
								if (record.status === 'CONNECTED' && (record.balance == null || record.balance === undefined))
									getBalance(item.domain, record.id)
							}}
							className={`txt-size-h3 ${
								record.status === 'CONNECTED' && (record.balance == null || record.balance === undefined)
									? 'txt-color-orange cursor-pointer'
									: 'txt-color-gray cursor-nodrop'
							}`}>
							<i className='fas fa-eye'></i>
						</span>
					</Row>
				),
			},
			{
				title: t('supplier.status'),
				dataIndex: 'status',
				key: 'status',
				width: 250,
				render: (text: any, record: any) => {
					let status = statues.find((x) => x.code === record.status)
					const isManual = lodash.get(record, 'isManual')
					if (record.status !== 'INVALID')
						return (
							<Row className={'align-items-center'}>
								<Switch
									onChange={(checked) => {
										setSelected(record)
										setTypeConfirm(checked ? 'CONNECT' : 'DISCONNECT')
										setShowConfirm(true)
									}}
									checked={record.status === 'CONNECTED'}
								/>
								<span className={'txt-size-h7 txt-color-black robotoregular mgl10'}>
									{lodash.get(status, 'name', text)}
								</span>
							</Row>
						)
					else
						return (
							<Row className={''}>
								<span className={'txt-size-h7 txt-color-black robotoregular'}>
									<i className='fal fa-exclamation-triangle mgr5 txt-color-red' />
									{t('supplier.invalid-connection-error')}
								</span>
								{isManual && (
									<a
										className={'txt-size-h7 txt-color-black robotoregular mgl10 align-item-center'}
										title={`${t('supplier.reconnect')}`}
										onClick={() => {
											setSelected(record)
											setIsReconnect(true)
											setShowModalAdd(true)
										}}>
										<i className='fad fa-sync-alt mgr5' />
									</a>
								)}
							</Row>
						)
				},
			},
			{
				title: t('supplier.actionDefault'),
				dataIndex: 'defaultConnection',
				key: 'defaultConnection',
				render: (text: any, record: any) => (
					<Row className={'align-items-center'}>
						<Radio
							onChange={(e: any) => {
								setSelected(record)
								setTypeConfirm('DEFAULT')
								setShowConfirm(true)
							}}
							disabled={record.status !== 'CONNECTED'}
							checked={record.defaultConnection}>
							{t('supplier.accountDefault')}
						</Radio>
					</Row>
				),
			},
			{
				title: t('supplier.action'),
				dataIndex: 'action',
				key: 'action',
				render: (text: any, record: any) => (
					<Row className={'align-items-center'}>
						<Row className={'cursor-pointer'}>
							<span
								className={'txt-size-h7 txt-color-black robotoregular mgl10 btn-remove'}
								onClick={() => {
									setSelected(record)
									setTypeConfirm('DELETE')
									setShowConfirm(true)
								}}>
								<i className='fal fa-trash mgr5' />
								{t('supplier.actionDelete')}
							</span>
						</Row>
					</Row>
				),
			},
		]
		return (
			<ConnectionItem
				currentConfigShowBalance={currentConfigShowBalance}
				getBalance={getBalance}
				data={item}
				statues={statues}
				setDomain={setDomain}
				setIsReconnect={setIsReconnect}
				setProviderCode={setProviderCode}
				setTypeConfirm={setTypeConfirm}
				setProviderName={setProviderName}
				setSelected={setSelected}
				setShowConfirm={setShowConfirm}
				onShowModalAdd={onShowModalAdd}
				handleShowFaqsChargeCash={handleShowFaqsChargeCash}
				setShowModalAdd={setShowModalAdd}
				loading={showProgressBar}
			/>
		)
	}

	const formatTemplateFaqs = () => {
		const re = /“(.*?)”/g
		const reStep = /\d\.(.*?)\r\n/gm
		let result = []
		let resultForStep = []
		let current
		let txtRendered = ''
		while ((current = re.exec(faqs))) {
			result.push(current.pop())
		}
		while ((current = reStep.exec(faqs))) {
			resultForStep.push(current.shift())
		}
		const boldTextFind: any = result.length > 0 ? result : []
		const stepNeedBold: any = resultForStep.length > 0 ? resultForStep : []
		txtRendered = faqs.split(boldTextFind[0]).join(`<span class='robotomedium'>${boldTextFind[0]}</span>`)
		for (let i = 0; i < stepNeedBold.length; i++) {
			if (i % 2 === 0) {
				txtRendered = txtRendered
					.split(stepNeedBold[i])
					.join(`<span class='robotomedium fsz-16'>${stepNeedBold[i]}</span><div class='px-16'>`)
			} else {
				txtRendered = txtRendered
					.split(stepNeedBold[i])
					.join(`</div><span class='robotomedium fsz-16'>${stepNeedBold[i]}</span><div class='px-16'>`)
			}
		}
		return `<span class='robotoregular txt-color-black fsz-14 line-h-22'>${newLineToBr(
			txtRendered.split('{username}').join(usernameUseInFaqs)
		)}</span>`
	}

	return (
		<MainLayout title={t('menu.connectSupplier')} showProgressBar={showProgressBar}>
			<Card className={'border-card-header supplier-card-head'}>
				<span className={'title-body txt-color-black robotomedium fsz-16 line-h-22'}>{t('supplier.list')}</span>
				<Row>
					<span className={'txt-size-h7 txt-color-gray4'}>{t('dashboard.welcome')}</span>
				</Row>
				{/* <Button onClick={onShowModalAdd} icon={<PlusOutlined style={{fontSize: 15}}/>} className={'mgt20 pdt5'}
                    type={'primary'}>{t('supplier.btAddAccount')}</Button> */}
			</Card>
			{accounts?.length > 0 ? (
				<div>
					{accounts.map((x: any) => {
						return renderItem(x)
					})}
				</div>
			) : !showProgressBar ? (
				<div style={{ marginTop: 200 }}>
					<Row className={'justify-content-center'}>
						<Image preview={false} className={'icon-empty'} src={empty} />
					</Row>
					<Row className={'mgt10 justify-content-center'}>
						<span>{t('supplier.empty')}</span>
					</Row>
					<Row className={'justify-content-center'}>
						<Button
							onClick={onShowModalAdd}
							icon={<PlusOutlined style={{ fontSize: 15 }} />}
							className={'mgt20'}
							type={'primary'}
							ghost>
							{t('supplier.btAddAccount')}
						</Button>
					</Row>
				</div>
			) : null}

			{isShowModalAdd && (
				<ModalAddSupplier
					onSubmitAccountNotExist={onSubmitAccountNotExist}
					domain={domain}
					isLoading={isLoading}
					onSubmit={handleAddAccount}
					isVisible={isShowModalAdd}
					selectedSupplier={itemSelected}
					isReconnect={isReconnect}
					onReconnectSubmit={onReconnectSubmit}
					onCancel={onCancelConfirm}
					provider={providerCode}
					providerName={providerName}
				/>
			)}
			{showConfirm && (
				<ConfirmModal
					visible={showConfirm}
					loading={isLoading}
					content={
						typeConfirm === 'DELETE'
							? t('supplier.confirmDelete')
							: typeConfirm === 'DEFAULT'
							? t('supplier.confirmDefault')
							: typeConfirm === 'CONNECT'
							? t('supplier.confirmConnect')
							: t('supplier.confirmDisConnect')
					}
					onSubmit={onSubmitConfirm}
					onCancel={onCancelConfirm}
				/>
			)}

			{isConnectingFromM1 && (
				<ConnectFromM1DialogConfirm
					isVisible={isConnectingFromM1}
					isLoading={isLoading}
					onCancel={onCancelConnectFromM1}
					onSubmit={onSubmitConnectFromM1}
					connectionParams={connectionParams}
				/>
			)}
			<Modal
				width={572}
				title={<span className='robotoregular txt-color-black fsz-16 line-h-24'>{t('supplier.faqsCashCharged')}</span>}
				onCancel={() => setShowFaq(false)}
				open={showFaq}
				className='pd-12 faq-modal'
				closeIcon={<i className='far fa-times' onClick={() => setShowFaq(false)} />}
				bodyStyle={{ overflowY: 'scroll' }}
				footer={
					!loadingFaqs && [
						<Button
							key='submit'
							type='primary'
							onClick={() => setShowFaq(false)}
							className='btn-df btn-df-secondary-bg-white px-12 py-5 robotoregular  fsz-14 line-h-22'>
							{t('supplier.closeFaqs')}
						</Button>,
					]
				}>
				<Spin spinning={loadingFaqs}>
					{loadingFaqs ? (
						<div className='empty-faqs'></div>
					) : (
						<span
							className='white-space-pre-ln'
							dangerouslySetInnerHTML={{
								__html: formatTemplateFaqs(),
							}}
						/>
					)}
				</Spin>
			</Modal>
		</MainLayout>
	)
}

export default ConnectSupplier

