import React from 'react'
import { useMemo } from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, FormInstance, Input, InputNumber, Row, Typography } from 'antd'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import UploadCommon from '../../UploadCommon'
import { SKUInterface } from '../../../Interface/StoredProductInterface'
import { uploadRepository } from '../../../repositories/UploadRepository'
import M24Notification from '../../../utils/M24Notification'
import { InputNumberWithBeforeAddon } from '../../base/InputNumberWithBeforeAddon'
import { onPressOnlyNumber, onPressOnlyNumberAndAllowDecimals } from '../helpers'
import { RowItem } from '../RowItem'
import { formatterNumberForInputNumber } from '../../../core/helpers/string'

import './style.scss'

const { Paragraph } = Typography

interface ColumnInterface {
	salePrice: string
	salePricePlaceholder: string
}

interface SellInfoSectionProps {
	form: FormInstance<Record<any, any>>
	variantProperties?: string[]
	columnKeys?: ColumnInterface
	type?: 'STORED_PRODUCT' | 'PRODUCT_FOR_SELL'
	setVariantProperties: (value: string[]) => void
	setIsDisableSubmitButton: (value: boolean) => void
	getCurrencySymbol?: (index: number) => string
}

let SellInfoSection: any = ({
	form,
	variantProperties = [],
	columnKeys = {
		salePrice: 'productForSell.price-sell',
		salePricePlaceholder: 'productForSell.sale-price-placeholder',
	},
	type = 'STORED_PRODUCT',
	setIsDisableSubmitButton,
	getCurrencySymbol = () => '¥',
	setVariantProperties,
}: SellInfoSectionProps): JSX.Element => {
	const { t } = useTranslation()

	const isProductForSellPage: boolean = useMemo(() => type === 'PRODUCT_FOR_SELL', [type])

	const getValueFromEvent = ({ file, fileList }: any, index: number) => {
		if (fileList?.length === 0) {
			const skus: SKUInterface[] = form.getFieldValue('skus')
			skus[index].image = ''
			form.setFieldsValue({
				skus,
			})
		} else {
			uploadRepository
				.create(file)
				.then((res: AxiosResponse<string>): void => {
					const skus: SKUInterface[] = form.getFieldValue('skus')
					skus[index].image = res.data
					form.setFieldsValue({
						skus,
					})
				})
				.catch(() => {
					M24Notification.notifyError(t('message.titleFailed'), t(`message.try_again`), '', 2)
				})
		}
		setIsDisableSubmitButton(false)
	}

	const setEditableStr = (value: string, index: number) => {
		const skus: SKUInterface[] | any = form.getFieldValue('skus')
		skus.forEach((sku: SKUInterface, skuIndex: number) => {
			if (skus[skuIndex].variantProperties !== null) {
				skus[skuIndex].variantProperties[index].name = value
			}
		})
		form.setFieldsValue({
			skus,
		})
		const temp = [...variantProperties]
		temp[index] = value
		setVariantProperties(temp)
		setIsDisableSubmitButton(false)
	}

	return (
		<section id='sell-info'>
			<h2 className='section-header'>
				<span>{t('productForSell.selling-detail-box')}</span>
			</h2>

			<RowItem label={`${t('productForSell.classify-list')}`}>
				<div>
					<Row gutter={26} className='table-header'>
						{variantProperties.map((p: string, index: number) => (
							<div key={`p-head-${index}`} className='table-cell'>
								<Paragraph
									editable={{
										onChange: (value: string) => setEditableStr(value, index),
										maxLength: 14,
									}}>
									{p}
								</Paragraph>
							</div>
						))}
						<div className='img-col table-cell'>{t('productForSell.image')}</div>
						<div className='table-cell'>{t('productForSell.sku')}</div>
						<div className='table-cell'>{t('productForSell.stock')}</div>
						<div className='table-cell'>{t('productForSell.origin-price')}</div>
						<div className='table-cell'>{t(columnKeys.salePrice)}</div>
						{isProductForSellPage && <div className='table-cell action' />}
					</Row>

					<Form.List name='skus'>
						{(skus, { remove }) =>
							skus.map(({ key, name, ...restField }) => {
								let url: string = form.getFieldValue(['skus', key, 'image']) || ''

								if (typeof url === 'object') {
									url = ''
								}

								return (
									<Row key={key} gutter={26} className='table-row'>
										<Form.List name={[name, 'variantProperties']}>
											{(properties) =>
												properties.map(({ key, name, ...restField }) => {
													return (
														<div className='table-cell' key={`p-row-${key}`}>
															<Form.Item hidden name={[name, 'name']}>
																<Input />
															</Form.Item>
															<Form.Item {...restField} name={[name, 'value']}>
																<Input
																	placeholder={`${t('productForSell.please-enter')}`}
																	className='m24-input'
																/>
															</Form.Item>
														</div>
													)
												})
											}
										</Form.List>

										<div className='img-col table-cell'>
											<Form.Item
												{...restField}
												shouldUpdate
												name={[name, 'image']}
												rules={[
													{
														required: isProductForSellPage,
														message: '',
													},
												]}
												getValueFromEvent={(e) => getValueFromEvent(e, name)}>
												<UploadCommon
													listType='picture-card'
													fileList={
														url
															? [
																	{
																		uid: String(name),
																		name: 'img',
																		status: 'done',
																		url,
																	},
															  ]
															: []
													}
													className='upload-classify-img'
													customRequest={() => {}}
													beforeUpload={() => false}
													accept='image/*'
													require={isProductForSellPage}
													requireMessage={`${t('productForSell.validate-sku')}`}>
													{url ? null : <PlusOutlined className='plus-icon' />}
												</UploadCommon>
											</Form.Item>
										</div>

										<div className='table-cell'>
											<Form.Item {...restField} name={[name, 'name']}>
												<Input
													placeholder={`${t('productForSell.enter-sku')}`}
													className='m24-input'
												/>
											</Form.Item>
										</div>

										<div className='table-cell'>
											<Form.Item
												{...restField}
												name={[name, 'stock']}
												rules={
													isProductForSellPage
														? [
																{
																	required: true,
																	message: `${t('productForSell.stock-validate')}`,
																},
														  ]
														: undefined
												}>
												<InputNumber
													min='0'
													onKeyDown={() => false}
													className='width100pc'
													onKeyPress={onPressOnlyNumber}
													placeholder={`${t('productForSell.stock-placeholder')}`}
													formatter={formatterNumberForInputNumber}
												/>
											</Form.Item>
										</div>

										<div className='table-cell'>
											<Form.Item {...restField} name={[name, 'msrp']}>
												<InputNumberWithBeforeAddon
													addonAfter={getCurrencySymbol(key)}
													min={0}
													onKeyPress={onPressOnlyNumberAndAllowDecimals}
													placeholder={`${t('productForSell.origin-price-placeholder')}`}
													className='width100pc'
													disabled={type === 'PRODUCT_FOR_SELL'}
													formatter={formatterNumberForInputNumber}
												/>
											</Form.Item>
										</div>

										<div className='table-cell'>
											<Form.Item
												{...restField}
												name={[name, 'salePrice']}
												rules={
													isProductForSellPage
														? [
																{
																	required: true,
																	message: `${t(
																		'productForSell.sale-price-validate'
																	)}`,
																},
														  ]
														: undefined
												}>
												{type === 'PRODUCT_FOR_SELL' ? (
													<InputNumber
														min={1}
														onKeyDown={() => false}
														onKeyPress={onPressOnlyNumberAndAllowDecimals}
														placeholder={`${t('productForSell.sale-price-placeholder')}`}
														className='width100pc'
														formatter={formatterNumberForInputNumber}
													/>
												) : (
													<InputNumberWithBeforeAddon
														addonAfter={getCurrencySymbol(key)}
														min={0}
														onKeyPress={onPressOnlyNumberAndAllowDecimals}
														placeholder={`${t(columnKeys?.salePricePlaceholder)}`}
														formatter={formatterNumberForInputNumber}
														className='width100pc'
													/>
												)}
											</Form.Item>
										</div>

										{isProductForSellPage && (
											<div className='table-cell action'>
												<MinusCircleOutlined
													className='dynamic-delete-button'
													style={{ color: '#ff4d4f' }}
													onClick={() => remove(name)}
												/>
											</div>
										)}
									</Row>
								)
							})
						}
					</Form.List>
				</div>
			</RowItem>
		</section>
	)
}

SellInfoSection = React.memo(SellInfoSection)

export { SellInfoSection }