import { RedoOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, DatePicker, Divider, Form, Image, Input, Row, Select } from 'antd'
import lodash, { isArray, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { FORM_KEY_PROVIDER, FORM_KEY_PROVIDER_USER_NAME, FORM_KEY_SUPPLIER } from '../../../core/config'
import { getCleanFormFilter } from '../../../utils/StringUtils'

const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker
interface FilterProps {
	accounts: any
	idAcc: string | undefined
	filter: any
	loading: boolean
	handleFilter: (filter: any) => void
	handleProgressBar: (val: boolean) => void
	handleAcc: (val: string | undefined) => void
	filterChangeHandler: (obj: any) => void
}

const Filter: React.FC<FilterProps> = (props) => {
	const { t } = useTranslation()
	const [form] = Form.useForm()

	const [searchParams, setSearchParams] = useSearchParams()
	const [showFilter, setShowFilter] = useState(true)
	const [showCleanFilter, setShowCleanFilter] = useState(false)

	const { idAcc, accounts, handleFilter, handleAcc, handleProgressBar, filter, filterChangeHandler } = props
	const labelClasses = 'txt-color-black robotoregular fsz-14 line-h-22'

	useEffect(() => {
		setShowCleanFilter(
			!lodash.isEmpty(
				lodash.pickBy(filter, (val: any, key: string) => {
					if (key !== FORM_KEY_PROVIDER && key !== FORM_KEY_PROVIDER_USER_NAME && key !== FORM_KEY_SUPPLIER) {
						if (isArray(val)) {
							return val.every((e) => e) && !isEmpty(val)
						}
						return !isEmpty(val)
					}
				})
			)
		)
	}, [filter])

	useEffect(() => {
		if (searchParams.get('customer') && searchParams.get('provider')) {
			const indexOfAccount = accounts.findIndex(
				(acc: any) =>
					acc.name === searchParams.get('customer') && acc.provider.code === searchParams.get('provider')
			)
			if (indexOfAccount > -1) {
				handleAcc(lodash.get(accounts[indexOfAccount], 'id'))
			}
		} else {
			const index = accounts.findIndex((acc: any) => acc.id === idAcc)
			if (index > -1) {
				setSearchParams({
					customer: lodash.get(accounts[index], 'name'),
					provider: lodash.get(accounts[index], 'provider.code'),
				})
			}
		}
		form.setFieldsValue({
			supplier: idAcc,
			query: searchParams.get('query'),
			exportedAt: [
				searchParams.get('exportedAtFrom') ? moment(searchParams.get('exportedAtFrom')) : null,
				searchParams.get('exportedAtTo') ? moment(searchParams.get('exportedAtTo')) : null,
			],
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts, form, idAcc, searchParams, setSearchParams])

	const getFormState = () => {
		const supplier = form.getFieldValue('supplier')
		const account = accounts.find((x: any) => x.id === supplier)
		const customer = lodash.get(account, 'name')
		const provider = lodash.get(account, 'provider.code')
		const exportedAt = form.getFieldValue('exportedAt')
		const exportedAtFrom = exportedAt && exportedAt[0] && moment(exportedAt[0].startOf('day')).toISOString()
		const exportedAtTo = exportedAt && exportedAt[1] && moment(exportedAt[1].endOf('day')).toISOString()

		const filter = getCleanFormFilter(
			{
				...form.getFieldsValue(true),
				customer,
				provider,
				exportedAtFrom,
				exportedAtTo,
			},
			[],
			['supplier', 'exportedAt']
		)
		return filter
	}

	const handleChangeAcc = (val: string) => {
		handleAcc(val)
		handleProgressBar(true)
		onFinish()
	}

	const onFinish = () => {
		handleFilter(getFormState())
	}

	const clearFilter = () => {
		form.resetFields()
		handleFilter({ ...getFormState() })
	}

	return (
		<Row
			className={`white-box mg-bt-12 pd-imp-12 ${!showFilter ? 'cursor-pointer' : ''}`}
			onClick={!showFilter ? () => setShowFilter(!showFilter) : undefined}>
			<Row
				justify='space-between'
				className='width100pc cursor-pointer'
				onClick={() => setShowFilter(!showFilter)}>
				<Col span={12}>
					<h3 className={`robotomedium fsz-16 line-h-24 txt-color-black ${!showFilter ? 'mg-0' : ''}`}>
						{t('deliveryNote.find')}
					</h3>
				</Col>
				<Col span={12} className='dpl-flex justify-content-end'>
					<i
						className={`fas fa-angle-${showFilter ? 'up' : 'down'} txt-color-black fsz-14 cursor-pointer`}
						onClick={() => setShowFilter(!showFilter)}></i>
				</Col>
			</Row>
			{showFilter && (
				<>
					<Col span={24}>
						<Form
							name='note-delivery'
							onFinish={onFinish}
							className='form-filter'
							form={form}
							initialValues={{
								query: '',
								supplier: idAcc,
								exportedAt: [],
							}}
							onValuesChange={(changedValues, allValues) => {
								filterChangeHandler(allValues)
							}}
						>
							<Row align='middle' gutter={[16, 8]} className='mg-bt-12'>
								<Col span={6} className='mg-bt-0 flex align-items-center'>
									<Item
										label={<span className={labelClasses}>{t('deliveryNote.code')}</span>}
										labelAlign='left'
										className='query'
										name={'query'}
									>
										<Input placeholder={`${t('deliveryNote.queryPlaceholder')}`} allowClear />
									</Item>
								</Col>

								<Col span={10}>
									<Item
										label={<span className={labelClasses}>{t('deliveryNote.exportedTime')}</span>}
										name='exportedAt'
									>
										<RangePicker
											placeholder={[t('deliveryNote.fromDate'), t('deliveryNote.toDate')]}
											className='width100pc h-32'
											format={'DD-MM-YYYY'}
											suffixIcon={<i className='far fa-calendar-alt' />}
											//@ts-ignore
											placement={'bottomRight'}
										/>
									</Item>
								</Col>
								<Col span={8}>
									<Item
										label={<span className={labelClasses}>{t('deliveryNote.supplier')}</span>}
										className='width100pc supplier'
										name='supplier'
										valuePropName='option'
									>
										<Select
											showSearch={false}
											placeholder={t('packages.placeHolderChooseAcc')}
											className='h-32'
											value={idAcc}
											onChange={(val: string) => handleChangeAcc(val)}
										>
											{accounts.map((acc: any) => (
												<Option key={acc.id} value={acc.id}>
													<div className='dpl-flex align-items-center'>
														<Avatar
															size={16}
															src={lodash.get(acc, 'provider.logo', '')}
															className='bd-none-impt w-12 h-12 dpl-flex align-items-center mg-r-4'
														/>
														<span className='robotoregular fsz-14 line-h-22 mg-r-4 mg-l-4'>
															{lodash.get(acc, 'provider.code', '')}
														</span>
														<Divider
															type={'vertical'}
															className={'bd-color-black line-h-22 mg-0 mg-r-4'}
														/>
														<span className='robotoregular fsz-14 line-h-22'>
															{lodash.get(acc, 'name', '')}
														</span>
													</div>
												</Option>
											))}
										</Select>
									</Item>
								</Col>
							</Row>
							<Row className='dpl-flex justify-content-end aling-items-center'>
								{showCleanFilter && (
									<Button
										className='clean-filter robotoregular txt-color-gray4 fsz-12 line-h-20 dpl-flex align-items-center
													flex-basis-btn-filter cursor-pointer bd-none mg-r-12'
										onClick={clearFilter}
										icon={<RedoOutlined className='mg-r-4' />}
									>
										<span className='mg-0'>{t('packages.refreshFilter')}</span>
									</Button>
								)}
								<Button
									className='btn-df btn-df-secondary-bg-lemon fsz-14 line-h-22 bd-none border-radius4
													px-12 py-3 flex-basis-btn-filter'
									htmlType='submit'
									form={'note-delivery'}
								>
									{t('packages.search')}
								</Button>
							</Row>
						</Form>
					</Col>
				</>
			)}
		</Row>
	)
}

export default Filter

