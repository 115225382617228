import { Button, Form, Input } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormValues } from '.'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import DraftShipmentInterface, { FeeInterfaceInDraftShipment } from '../../../Interface/DraftShipmentInterface'
import ServiceInterface from '../../../Interface/ServiceInterface'
import lodash from 'lodash'
const { Item } = Form
interface ShipmentFeesAndFormProps {
	services: ServiceInterface[]
	draftShipment?: DraftShipmentInterface
	loading: boolean
	calculating: boolean
	allowCreate: boolean
	currency?: string
	currentServiceHasError: any
	checkedList: any
	submitHandler: (only1688: boolean, formValues?: FormValues) => void
	currentStep?: number
	preview1688?: any
	isShipmentAndPeerPayment?: boolean
	feePeerPayment?: any
	purchasingAccount?: any
}
const ShipmentFeesAndForm: React.FC<ShipmentFeesAndFormProps> = (props) => {
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const {
		submitHandler,
		draftShipment,
		loading,
		calculating,
		allowCreate,
		currency,
		currentStep,
		preview1688,
		isShipmentAndPeerPayment,
		feePeerPayment,
		purchasingAccount
	} = props
	const [expectedPackages, setExpectedPackages] = useState('')
	const [totalFeePeerPayment, setTotalFeePeerPayment] = useState(0)

	const onFinish = () => {
		submitHandler(false, { ...form.getFieldsValue(true), expectedPackages: Number(expectedPackages) })
	}

	useEffect(() => {
		if (feePeerPayment && feePeerPayment.listFees) {
			setTotalFeePeerPayment(lodash.sumBy(feePeerPayment.listFees, 'provisionalAmount'))
		} else {
			setTotalFeePeerPayment(0)
		}
	}, [feePeerPayment])

	return (
		<div className='box box-fees'>
			<div className='flex flex-col'>
				<div className='flex flex-col bg-color-orange5 pd-12 mg-bt-12'>
					<div className='flex align-items-center'>
						{/* <span className='robotomedium txt-color-black fsz-16 line-h-24 whitespace mg-bt-4 mg-r-12'>
							{t('shipment.calculateFeesTemp')}
						</span> */}
						<span className='box-divider box-divider-orange'></span>
					</div>
					{draftShipment?.fees && (
						<div className='box-fees__details'>
							{draftShipment?.fees?.map((fee: FeeInterfaceInDraftShipment) => (
								<div key={fee.id} className='flex align-items-center justify-content-between mg-bt-12'>
									<span className='robotoregular fsz-14 line-h-22 txt-color-gray7'>
										{t(`shipments.${fee.feeType}`)}
									</span>
									<span className='value'>
										{fee.provisionalAmount ? formatMoneyByUnit(fee.provisionalAmount, '') : '---'}
									</span>
								</div>
							))}
						</div>
					)}
					

					<div className='flex align-items-center justify-content-between'>
						<span className='robotomedium fsz-16 line-h-24 txt-color-black'>
							{t(`shipments.totalFeesTemp`)}
						</span>
						<span className='robotobold fsz-14 line-h-22 txt-color-blue6'>
							{draftShipment?.totalFee
								? formatMoneyByUnit(draftShipment?.totalFee, '')
								: '---'}
						</span>
					</div>
				</div>

				<div className='flex align-items-center px-8 mg-bt-12'>
					<span className='robotomedium txt-color-black fsz-16 line-h-24 whitespace mg-r-12'>
						{t('shipment.shipmentInfo')}
					</span>
					<span className='box-divider box-divider-orange'></span>
				</div>
				<div className='box-fees__shipment-info px-8'>
					<Form
						name='form-shipment'
						initialValues={{ refCustomerCode: '', refShipmentCode: '', expectedPackages: '' }}
						onFinish={onFinish}
						autoComplete='off'
						className='form-shipment'
						form={form}>
						<Item
							label={`${t('shipment.packages')}`}
							// name='expectedPackages'
							labelAlign='left'
							colon={false}>
							<Input
								placeholder={`${t('shipment.packagesPlaceholder')}`}
								pattern={'^[0-9\b]+$'}
								value={expectedPackages}
								onChange={(e) =>
									setExpectedPackages((v) => (e.target.validity.valid ? e.target.value : v))
								}
							/>
						</Item>
						<Item label={`${t('shipment.orderCode')}`} labelAlign='left' name='refShipmentCode' colon={false}>
							<Input placeholder={`${t('shipment.orderCodePlaceholder')}`} />
						</Item>

						<Item label={`${t('shipment.customerCode')}`} labelAlign='left' name='refCustomerCode' colon={false}>
							<Input placeholder={`${t('shipments.customerCodePlaceholder')}`} />
						</Item>
					</Form>
				</div>
			</div>
			<Button
				className='mg-t-12 width100pc btn-df btn-df-secondary-bg-lemon robotomedium flex align-items-center flex-nowrap justify-content-center fsz-14 line-h-22'
				htmlType='submit'
				form='form-shipment'
				icon={<i className={`mg-r-10 fa-solid fa-${ isShipmentAndPeerPayment && currentStep !== 2 ? 'right-long' : 'plus'}`}></i>}
				loading={loading}
				disabled={isShipmentAndPeerPayment ? !allowCreate || calculating || isEmpty(preview1688) || isEmpty(purchasingAccount) : !allowCreate || calculating}>
				{currentStep === 1 && isShipmentAndPeerPayment ? 'Tiếp Theo' : t('shipment.createShipment')}
			</Button>
		</div>
	)
}

export default ShipmentFeesAndForm