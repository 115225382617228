import { Layout } from 'antd'
import lodash from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import DocumentTitle from 'react-document-title'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import {
	ACC_GSA_PROFILE,
	ID_GO_BIZ_EXTENSION,
	PAGE_DESCRIPTIONS,
	PROFILE_KEY,
	TENANT_KEY,
	TOKEN_KEY,
} from '../../core/config'
import useWindowDimensions from '../../hooks'
import DescriptionsInterface from '../../Interface/DescriptionsInterface'
import { TenantUpdateConfigCommand } from '../../Interface/TenantUpdateConfigCommand'
import { cartRepository } from '../../repositories/CartRepository'
import { noticeRepository } from '../../repositories/NoticeRepository'
import { tenantRepository } from '../../repositories/TenantRepository'
import { userRepository } from '../../repositories/UserRepository'
import { localStorageRead, localStorageRemoveItem, localStorageSave } from '../../utils/LocalStorageUtils'
import Descriptions from '../Descriptions'
import ExtensionCommon from '../ExtensionCommon'
import ConfirmModal from '../Modal/ConfirmModal'
import ProgressBar from '../Spinner/ProgressBar'
import Navigation from './Navigation'
import Sidebar from './Sidebar'
import './styles.scss'

const { Content } = Layout
const PERCENT_COMPLETE = 100

export interface MainLayoutProps {
	children: any
	showProgressBar?: boolean
	descriptions?: DescriptionsInterface | null
	title?: any
	hasExtensionCommon?: boolean
	breadcrumbs?: any
	totalCart?: number
}

function MainLayout(props: MainLayoutProps) {
	const { children, showProgressBar, title, descriptions, hasExtensionCommon, breadcrumbs } = props
	const [collapsed, setCollapsed] = useState(true)
	const [percent, setPercent] = useState<number>(-1)
	const [autoIncrement, setAutoIncrement] = useState<boolean>(false)
	const [isShowMenuDropDown, setShowMenuDropDown] = useState<boolean>(false)
	const [intervalTime, setIntervalTime] = useState<number>(200)
	const [activeKey, setActiveKey] = useState<string>('')
	const [profile, setProfile] = useState(localStorageRead(PROFILE_KEY))
	const location = useLocation()
	const { t } = useTranslation()
	const { height } = useWindowDimensions()
	const [isShowNotice, setIsShowNotice] = useState(false)
	const [totalUnreadNotice, setTotalUnreadNotice] = useState(0)
	const [showDescriptions, setShowDescriptions] = useState(false)
	const [showExtension, setShowExtension] = useState(true)
	const [showDescriptionsFromSession, setShowDescriptionsFromSession] = useState(true)

	const [confirmLogOut, setConfirmLogOut] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const [installed, setInstalled] = useState(false)
	const [currentConfigServices, setCurrentConfigServices] = useState<TenantUpdateConfigCommand>()
	const navigate = useNavigate()

	const [totalCart, setTotalCart] = useState(0)

	useEffect(() => {
		if (localStorageRead(PAGE_DESCRIPTIONS)?.includes(descriptions?.pageCode)) {
			setShowDescriptionsFromSession(false)
		} else {
			setShowDescriptionsFromSession(true)
		}
	}, [descriptions?.pageCode])

	useEffect(() => {
		const readLocalStorageProfile = () => {
			setProfile(localStorageRead(PROFILE_KEY))
		}
		window.addEventListener('storage', readLocalStorageProfile)
		return () => {
			window.removeEventListener('storage', readLocalStorageProfile)
		}
	}, [])

	useEffect(() => {
		if (profile?.introductions?.includes(descriptions?.pageCode)) {
			setShowDescriptions(false)
		} else {
			setShowDescriptions(true)
		}
	}, [descriptions?.pageCode, profile?.introductions])

	useEffect(() => {
		if (showProgressBar) {
			startWithAutoIncrement()
		} else {
			setPercentValue(PERCENT_COMPLETE)
		}
	}, [showProgressBar])

	useEffect(() => {
		if (location.pathname && location.pathname !== '/') {
			let pathname: string[] = location.pathname.split('/')
			if (pathname.includes('selling-products')) {
				pathname.push('selling-products/shopee')
			}
			if (pathname.includes('pending-products')) {
				pathname.push('pending-products/shopee')
			}
			setActiveKey(pathname[pathname.length - 1])
		}
	}, [location.pathname])

	// useEffect(() => {
	// 	onMessage(firebaseMessaging, (payload) => {
	// 		console.log('Message received. ', payload);
	// 	});
	// }, [])

	const checkExtension = (id: string, src: string, cb: any) => {
		let script = document.createElement('script')
		script.type = 'text/javascript'
		const source = `chrome-extension://${id}/${src}`
		script.setAttribute('src', source)
		document.body.appendChild(script)
		script.onload = () => {
			cb(1)
		}
		script.onerror = () => {
			cb(0)
		}
	}

	useEffect(() => {
		if (hasExtensionCommon) {
			checkExtension(ID_GO_BIZ_EXTENSION, 'injector.js', (ok: any) => {
				setInstalled(!!ok)
			})
		}
	}, [hasExtensionCommon])

	useEffect(() => {
		getTotalUnreadNotice()
	}, [])

	const getTotalUnreadNotice = () => {
		noticeRepository.getUnreadCountNotices().then((response) => {
			setTotalUnreadNotice(response.data)
		})
	}

	const onConfirmLogOut = () => {
		setLoading(true)
		localStorageSave(PROFILE_KEY, null)
		localStorageSave(TOKEN_KEY, null)
		localStorageSave(TENANT_KEY, null)
		localStorageSave(PAGE_DESCRIPTIONS, null)
		localStorageSave(ACC_GSA_PROFILE, null)
		window.location.href = process.env.REACT_APP_LOGOUT_URL!
		// navigate('/login')

		setLoading(false)
	}

	const onCancelLogOut = () => {
		setConfirmLogOut(false)
		setLoading(false)
	}

	/**
	 * hiển thị progressbar
	 **/
	const startWithAutoIncrement = () => {
		setPercent(0)
		setAutoIncrement(true)
		setIntervalTime(Math.random() * 150)
	}

	/**
	 * Dừng progressbar
	 **/
	const stopWithAutoIncrement = () => {
		setPercent(-1)
		setAutoIncrement(false)
		setIntervalTime(150)
	}

	const setPercentValue = (percent: number) => {
		setPercent(percent)
		if (percent === PERCENT_COMPLETE) {
			setTimeout(() => {
				stopWithAutoIncrement()
			}, 500)
		}
	}

	const visibleDropdownUserActionsChangeHandler = (visible: boolean) => {
		setShowMenuDropDown(visible)
	}

	const handleClickHideDescriptionsForever = async () => {
		setShowDescriptions(false)
		try {
			await userRepository.updateIntroductions(descriptions?.pageCode!)
			const profile = await userRepository.getProfile()
			localStorageRemoveItem(PROFILE_KEY)
			localStorageSave(PROFILE_KEY, profile)
		} catch (err: any) {}
	}

	const getProfile = useCallback(async () => {
		localStorageRemoveItem(PROFILE_KEY)
		try {
			const res = await userRepository.getProfile()
			localStorageSave(PROFILE_KEY, res)
			setProfile({ ...res })
		} catch (err) {}
	}, [])

	useEffect(() => {
		if (descriptions) {
			getProfile()
		}
	}, [])

	const closeHandler = () => {
		setShowExtension(false)
	}

	const handleShowDescription = () => {
		setShowDescriptionsFromSession(false)
		const pageDescriptions = localStorageRead(PAGE_DESCRIPTIONS)
		localStorageRemoveItem(PAGE_DESCRIPTIONS)
		localStorageSave(PAGE_DESCRIPTIONS, [...pageDescriptions, descriptions?.pageCode])
	}

	useEffect(() => {
		const favicon = document.querySelector('[rel="icon"]') as HTMLAnchorElement | null
		const tenantInfo = localStorageRead(TENANT_KEY)
		if (favicon && tenantInfo) {
			favicon.href = tenantInfo.config.favicon
		}
	}, [])

	useEffect(() => {
		const fetchCurrent = async () => {
			const response = await tenantRepository.getCurrent()
			if (localStorageRead(TENANT_KEY)) {
				localStorageRemoveItem(TENANT_KEY)
			}
			localStorageSave(TENANT_KEY, response)
			const { generalConfig, peerPaymentConfig, shipmentConfig, gsaConfig, orderConfig } = response?.config
			setCurrentConfigServices((prevState: any) => ({
				...prevState,
				enableShipment: shipmentConfig?.enable,
				enableOrder: orderConfig?.enable,
				enableGeneral: generalConfig?.enable,
				enablePeerPayment: peerPaymentConfig?.enable,
				enableGsa: gsaConfig?.enable,
			}))
		}
		fetchCurrent()
	}, [])

	const getData = (filter?: any) => {
		cartRepository
			.getProductCart({})
			.then((res) => {
				let total = lodash.get(res, 'headers.x-total-count')
				setTotalCart(total)
			})
			.catch((err) => {})
	}

	useEffect(() => {
		getData()
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<DocumentTitle title={`${title ? title : 'M24'}`}>
				<Layout className={'main-layout'}>
					<Sidebar
						// logoutHandler={() => setConfirmLogOut(true)}
						// toggleHandler={() => setCollapsed(!collapsed)}
						// specificToggle={(val: boolean) => setCollapsed(val)}
						activeKey={activeKey}
						// collapsed={collapsed}
						currentConfigServices={currentConfigServices}
					/>
					<Layout onClick={!collapsed ? () => setCollapsed(true) : undefined}>
						<Navigation
							title={title}
							logoutHandler={() => setConfirmLogOut(true)}
							totalUnreadNotice={totalUnreadNotice}
							isShowNotice={isShowNotice}
							profile={profile}
							breadcrumbs={breadcrumbs}
							getTotalUnreadNotice={getTotalUnreadNotice}
							onVisibleChangeNoticesHandler={(visible: boolean) => setIsShowNotice(visible)}
							visibleDropdownUserActions={isShowMenuDropDown}
							visibleDropdownUserActionsChangeHandler={visibleDropdownUserActionsChangeHandler}
							totalCart={totalCart}
						/>
						<ProgressBar
							percent={percent}
							autoIncrement={autoIncrement}
							intervalTime={intervalTime}
							spinner={false}
						/>
						<Content style={{ minHeight: height - 64 }} className='content'>
							{children}
						</Content>
						<ConfirmModal
							className={'itto_logout'}
							title={`${t('dashboard.confirmLogoutTitle')}`}
							content={t('dashboard.confirmLogoutContent')}
							visible={confirmLogOut}
							loading={isLoading}
							onCancel={onCancelLogOut}
							onSubmit={onConfirmLogOut}
						/>
					</Layout>
				</Layout>
			</DocumentTitle>
			<div className='box-description'>
				{showDescriptions && showDescriptionsFromSession && (
					<Descriptions
						{...{
							...descriptions,
							className: `${!(hasExtensionCommon && showExtension && !installed) ? 'mg-bt-12' : ''} ${
								descriptions?.className
							}`,
						}}
						profile={profile}
						notShowAgain={handleClickHideDescriptionsForever}
						showLater={handleShowDescription}
					/>
				)}
				{hasExtensionCommon && showExtension && !installed && <ExtensionCommon handleClose={closeHandler} />}
			</div>
		</>
	)
}

export default MainLayout

