import { Divider, Image } from 'antd'
import React from 'react'
import lodash from 'lodash'

interface SupplierProps {
  acc: any
  className?: string
}

const Supplier:React.FC<SupplierProps> = ({acc, className}) => {
	return (
		<span className={`dpl-flex align-items-center txt-color-black fsz-14 robotoregular ${className ? className : ''}`}>
			<Image
				preview={false}
				src={lodash.get(acc, 'provider.logo', '')}
				className='bd-none w-24 h-24 dpl-flex align-items-center mg-r-4'
			/>
			<span className='robotomedium fsz-14 line-h-22 mg-r-4'>{lodash.get(acc, 'provider.code', '')}</span>
			<Divider type={'vertical'} className={'bd-color-black line-h-22 mg-0 mg-r-4'} />
			<span className='robotomedium fsz-14 line-h-22'>{lodash.get(acc, 'name', '')}</span>
		</span>
	)
}

export default Supplier
