import { Button, Col, Image, Modal, Row, Spin, Upload } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import { RcFile } from "antd/es/upload";
import lodash from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { MAX_UPLOAD_SIZE, PROFILE_KEY } from "../../../core/config";
import { formatNumber } from "../../../core/helpers/string";
import { UserProfileInterface } from "../../../Interface/UserProfileInterface";
import { ServicePackageInterface } from "../../../Interface/UserServiceInterface";
import { VendorServiceInterface } from "../../../Interface/VendorServiceInterface";
import { userServiceRepository } from "../../../repositories/UserServiceRepository";
import UploadImage01 from '../../../resources/images/upload-image-1.png';
import { localStorageRead } from "../../../utils/LocalStorageUtils";
import M24Notification from "../../../utils/M24Notification";
import BenefitSharing from "./BenefitSharing";


const {confirm} = Modal

type BankTransferProps = {
    orderInformation: any;
    selectedVendor: VendorServiceInterface;
    selectedPackage: ServicePackageInterface;
    formData: any;
    handleCreateOrder: () => void;
    isCreateOrder: boolean;
    step: number;
    handlePrevStep: () => void;
    handleNextStep: () => void;
    setProviderAccount: Dispatch<SetStateAction<string>>;
    setProvider: Dispatch<SetStateAction<string>>;
    provider: string,
    providerAccount: string,
    setSelectedValue: Dispatch<SetStateAction<any>>,
    selectedVal: string,
    showRequestBillModal: () => void
}

function BankTransferStep(props: BankTransferProps) {
    const {t} = useTranslation();
    const {
        orderInformation, selectedVendor, formData, step,
        selectedPackage, handleCreateOrder, showRequestBillModal
    } = props;
    const profile = localStorageRead(PROFILE_KEY);
    const [transferGuide, setTransferGuide] = React.useState<any>({});
    const [previewFile, setPreviewFile] = useState<any>()
    const [isShowPreviewFile, setIsShowPreviewFile] = useState(false);
    const [uploaded, setUploaded] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [previewImg, setPreviewImg] = useState('')
    const [fileList, setFileList] = useState<UploadFile[]>([])


    React.useEffect(() => {
        // setIsLoading(true);
        userServiceRepository.getTransferGuide(selectedVendor.code, formData?.paymentMethod)
            .then((response: any) => {
                setTransferGuide(response);
            }).catch((err) => {
            console.log(err)
        })
        // .finally(()=>setIsLoading(false))
    }, [selectedVendor, formData])

    const handleNextPurchaseStep = () => {
        props.handleNextStep();
    }

    const handlePrevPurchaseStep = () => {
        // setTransferStep(prevState => prevState - 1)
        props.handlePrevStep();
        setUploaded(false)
        setFileList([])
    }

    const handleProcessingPurchase = () => {
        if (step < 3) {
            handleNextPurchaseStep();
        } else {
            handleCreateOrder();
        }
    }

    const beforeUpload = (file: RcFile) => {
        let isPDF = file.type === 'application/pdf'
        if (!file.type.startsWith("image") && !isPDF) {
            M24Notification.messageError(t('profile.wrong-type-bill-img'), '', 2)
            return Upload.LIST_IGNORE;
        }
        if (file.size > MAX_UPLOAD_SIZE * 1024 * 1024) {
            M24Notification.messageError(t('ticket-create.error_max_upload_file_size'), '', 2);
            return Upload.LIST_IGNORE;
        }
        return false
    }
    const handleUploadBillOrderImg = (fileObject: any) => {
        setUploading(true);
        userServiceRepository.updateOrdImage(
            lodash.get(orderInformation, 'code'), fileObject.file
        ).then((response) => {
            setPreviewImg(lodash.get(response, 'billOrder', ''))
            M24Notification.messageSuccess(t('profile.bill-uploaded'), '', 2)
            setFileList(fileObject.fileList);
        }).catch((err) => {
            setUploaded(false);
            M24Notification.messageError(t('profile.bill-upload-failed'), '', 2)
        }).finally(() => setUploading(false))
    }

    const handlePreviewImage = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }
        setPreviewFile(file)
        setIsShowPreviewFile(true);

    }

    const getBase64 = (file: RcFile): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    }


    const cancelPreviewImage = () => {
        setIsShowPreviewFile(false)
        setPreviewFile(null)
    }

    const handleImageChange = (newFileList: any) => {
        switch (newFileList.file.type) {
            case 'application/pdf' :
        }
        if (newFileList && newFileList.fileList.length >= 1) {
            handleUploadBillOrderImg(newFileList);
        } else {
            setFileList([])
        }
    }

    // const handleForceInput = () => {
    //     if(props.provider && !props.providerAccount) return true;
    //     else if (props.providerAccount && !props.provider) return true;
    //     else if (!props.provider || !props.providerAccount) return false;
    //     else return false
    //
    // }

    const renderBankTransferStep = () => {
        switch (step) {
            case 2:
                return (<div>
                    <GuideTransfer
                        transferGuide={transferGuide}
                        orderInfo={orderInformation}
                        step={step}
                    />
                    <BenefitSharing
                        setProviderAccount={props.setProviderAccount}
                        setProvider={props.setProvider}
                        provider={props.provider}
                        providerAccount={props.providerAccount}
                        setSelectedValue={props.setSelectedValue}
                        selectedVal={props.selectedVal}
                    />
                </div>);
            case 3:
                return <UploadTransferBill
                    previewFile={previewFile}
                    isShowPreviewFile={isShowPreviewFile}
                    handlePreviewImage={handlePreviewImage}
                    cancelPreviewImage={cancelPreviewImage}
                    beforeUpload={beforeUpload}
                    uploaded={uploaded}
                    handleImageChange={handleImageChange}
                    uploading={uploading}
                    previewImg={previewImg}
                    fileList={fileList}
                />
        }
    }

    return (
        <div className={'package-list'}>
            <Row className={'mgt10'}>
                <Col span={16} flex={'auto'} className={'pdr10'}>
                    {renderBankTransferStep()}
                </Col>
                <Col span={8} flex={'auto'}>
                    <PaymentInformation
                        orderInformation={orderInformation}
                        selectedPackage={selectedPackage}
                        profile={profile}
                        showRequestBillModal={showRequestBillModal}
                    />
                </Col>
            </Row>
            <div className={'package-list-footer'}>
                <Button className={'cancel mgr10'}
                        onClick={handlePrevPurchaseStep}
                >
                    <i className="fa-solid fa-arrow-left-long mgl5 mgr10"/>
                    {t('profile.prev-button')}
                </Button>
                <Button className={'purchase txt-capitalize'}
                        onClick={handleProcessingPurchase}
                        loading={props.isCreateOrder}
                        disabled={(!props.provider || !props.providerAccount) && !props.selectedVal}
                        // disabled={handleForceInput()}
                >
                    {step < 3 ?
                        <span>
                                <i className="fa-solid fa-arrow-right-long mgl5 mgr10"/>{t('profile.next-button')}
                            </span>
                        : <span>
                                <i className='fa-solid fa-check mgl5 mgr10'/> {t('button.submit')}
                            </span>
                    }
                </Button>
            </div>
        </div>

    )
}

export default BankTransferStep;

type PaymentInformationProps = {
    orderInformation: any;
    selectedPackage: ServicePackageInterface;
    profile: UserProfileInterface;
    showRequestBillModal: () => void
}
const PaymentInformation = (props: PaymentInformationProps) => {
    const {t} = useTranslation();
    const {
        orderInformation, selectedPackage, profile, showRequestBillModal
    } = props;

    return (
        <div className={'width100pc orderInfo-box'}>
            <div className={'orderInfo-box-topContent'}>
                <span className={'txt-size-h5 txt-color-black robotomedium'}>{t('profile.package-payment-title')}</span>
                <span className={'txt-size-h7 txt-color-black robotoregular'}>
                    {t('profile.fullName')}:
                    <span className={'robotomedium mgl5'}>{lodash.get(profile, 'name', '')}</span>
                </span>
                <span className={'txt-size-h7 txt-color-black robotoregular'}>
                     {t('profile.username')}:
                    <span className={'robotomedium mgl5'}>{lodash.get(profile, 'username', '')}</span>
                </span>
                <span className={'txt-size-h7 txt-color-black robotoregular'}>
                    {t('profile.email')}:
                    <span className={'robotomedium mgl5'}>{lodash.get(profile, 'email', '')}</span>
                </span>

            </div>
            <span className={'linear'}/>
            <div className={'orderInfo-box-bottomContent'}>
                <span className={'txt-size-h7 robotoregular txt-color-black'}>{t('profile.service-package-name')}:
                    <span className={'robotomedium mgl5'}>{lodash.get(orderInformation, 'packageName', '')}</span>
                </span>
                <span className={'txt-size-h7 robotoregular txt-color-black'}>{t('profile.service-package-totalValue')}:
                    <span className={'robotomedium mgl5'}>
                         {formatNumber(lodash.get(orderInformation, 'totalValue', ''))}
                        <span> {lodash.get(orderInformation, 'currency', null)}</span>
                    </span>
                </span>
                <span
                    className={'txt-size-h7 robotoregular txt-color-black'}>{t('profile.service-package-expiration-date')}:
                    <span className={'robotomedium mgl5'}>{lodash.get(selectedPackage, 'expirationTime', '')}
                        <span className={'mgl5'}>{t('profile.service-package-expiration-unit')}</span>
                    </span>
                </span>
                <span className={'txt-size-h7 robotoregular txt-color-blue6'}>{t('profile.service-package-order-code')}:
                    <span className={'robotomedium mgl5'}>{lodash.get(orderInformation, 'code', '')}</span>
                </span>
                <span
                    className={'txt-size-h7 robotoregular txt-color-red3 txt-underline cursor-pointer'}
                    onClick={showRequestBillModal}>
                        {t('profile.payment-request-bill')}
                </span>
            </div>


        </div>
    )
}


const GuideTransfer = ({transferGuide, orderInfo}: any) => {
    const {t} = useTranslation();

    const copyButtonRender = ReactDOMServer.renderToString(<span id={'copy-btn'} className={'txt-color-primary'}><i className={'fa-regular cursor-pointer fa-copy mgl5 mgr5 _btCopy'}/></span>)
    const compile = lodash.template(transferGuide?.description);
    const transferGuideContent = compile({'code': `${orderInfo.code}${copyButtonRender}`})

    useEffect(()=> {
        const copyBtn = document.getElementById('copy-btn');
        if (copyBtn) {
            copyBtn.addEventListener('click', copyOrderCode)
        }

    },[transferGuideContent,orderInfo,transferGuide])

    async function copyOrderCode() {
        try {
            await navigator.clipboard.writeText(orderInfo.code);
            M24Notification.messageSuccess('Copied', '',1);
        } catch (err) {
            M24Notification.messageError(`Failed to copy: ${err}`,'',2);
        }
    }

    return (
        <div className={'dlp-flex'}>
            <div
                className={'dpl-bl bg-color-gray5 txt-size-h5 robotomedium pdt5 pdbt5 pdl12 border-radius4'}>{t('profile.bank-transfer-guide-title')}</div>
            <div className={'mgt15 mgbt15 dpl-fl flex-row'}>
                <span className={'height100pc'}>
                    <i className="fa-solid fa-pipe mgr5 txt-size-h7" style={{color: '#339DFF'}}/>
                </span>
                <span>
                    <i className="fa-solid fa-circle-exclamation mgr5 txt-size-h7" style={{color: '#339DFF'}}/>
                    <span
                        className={'txt-size-h7 robotoregular txt-color-black'}>{t('profile.guide-transfer-tip')}</span>
                </span>
            </div>
            <div className={'transfer-guide-box mgt15 pdl6'} style={{textAlign: 'justify'}}
                 dangerouslySetInnerHTML={{__html: transferGuideContent}}
            />
        </div>
    )
}


type UploadTransferBillProps = {
    beforeUpload: (img: RcFile) => void;
    handlePreviewImage: (img: any) => void;
    cancelPreviewImage: () => void;
    isShowPreviewFile: any;
    previewFile: any;
    uploaded: boolean;
    handleImageChange: (newFileList: any) => void;
    uploading: boolean;
    previewImg: string;
    fileList: any
}
const UploadTransferBill = (props: UploadTransferBillProps) => {
    const {t} = useTranslation();

    return (
        <div>
            <div className={'mgt15'}>
                <span className={'height100pc'}>
                    <i className="fa-solid fa-pipe mgr5 txt-size-h7" style={{color: '#339DFF'}}/>
                </span>
                <span>
                    <i className="fa-solid fa-circle-exclamation pdr5 txt-size-h7" style={{color: '#339DFF'}}/>
                    <span
                        className={'txt-size-h7 robotoregular txt-color-black'}>{t('profile.guide-transfer-bill-required')}</span>
                </span>
            </div>
            <Row className={'width100pc mgt20'} justify={'center'}>
                <Col offset={5} className={'align-items-center width100pc'}>
                    <div className={'width100pc'}>
                        <Upload
                            className="upload-bill-image "
                            listType={"picture-card"}
                            accept="image/*,application/pdf"
                            beforeUpload={props.beforeUpload}
                            onChange={props.handleImageChange}
                            showUploadList={true}
                            onPreview={props.handlePreviewImage}
                            maxCount={1}
                            onRemove={(file) => {
                                return new Promise((resolve: any, reject: any) => {
                                    confirm({
                                        style: {top: 200},
                                        icon: null,
                                        title: <span
                                            className={'robotomedium'}>{t('profile.confirm-delete-title')}</span>,
                                        content: <span
                                            className={'robotoregular'}>{t('profile.confirm-delete-modal')}</span>,
                                        cancelText: <span>
                                            <i className="fa-solid fa-xmark mgl5 mgr10"/>
                                            {t('profile.confirm-cancelText')}
                                        </span>,
                                        cancelButtonProps: {className: 'btn_cancel'},
                                        okText: <span>
                                            <i className="fa-solid fa-trash mgl5 mgr10"/>
                                            {t('profile.confirm-okText')}</span>,
                                        okType: 'danger',
                                        okButtonProps: {className: 'btn-remove-order'},
                                        onOk: () => {
                                            resolve(true)
                                        },
                                    })
                                })
                            }}
                            fileList={props.fileList}
                        >
                            {props.fileList?.length < 1 ?
                                <Spin spinning={props.uploading}>
                                    <div className={'dpl-flex align-items-center flex-col'}>
                                        <div className={'mgt15 mgbt10'}>
                                            <Image
                                                preview={false}
                                                src={UploadImage01} sizes={'40'}
                                            />
                                        </div>
                                        <div className={'align-items-center txt-color-blue6 txt-size-h5 robotomedium'}>
                                            {t('profile.upload-transfer-bill')}
                                        </div>
                                        <div
                                            className={'txt-center mgbt15 mgt5 txt-color-gray robotoregular txt-size-h7'}>
                                            {t('profile.upload-transfer-bill-notification')}
                                        </div>
                                    </div>
                                </Spin>
                                : null}
                        </Upload>
                    </div>
                </Col>
            </Row>
            {props.previewFile?.type.startsWith('image') ?
                <Modal
                    className={'bill-preview-img-popup'}
                    title={<div className={'dpl-flex flex-col align-items-center'}>
                        <span className={'robotomedium txt-size-h5'}>{t('profile.bill-image')}</span>
                        <span className={'robotoregular txt-size-h7'}>{t('profile.bill-image-preview-content')}</span>
                    </div>}
                    open={props.isShowPreviewFile}
                    footer={false}
                    onCancel={props.cancelPreviewImage}
                    bodyStyle={{
                        maxHeight: 650,
                    }}
                    centered
                >
                    <div className={'dpl-flex justify-content-center width100'}>
                        <div>
                            <img src={props.previewImg || props.previewFile.thumbUrl} alt={'preview-img'}
                                 style={{width: '100%', maxHeight: 600}}
                            />
                        </div>
                    </div>
                </Modal> : null
            }

        </div>
    )
}


