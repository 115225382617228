import { RedoOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, DatePicker, Divider, Form, Image, Input, Row, Select } from 'antd'
import lodash, { isArray, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { FORM_KEY_PROVIDER, FORM_KEY_PROVIDER_USER_NAME, FORM_KEY_SUPPLIER } from '../../../core/config'
import { getCleanFormFilter, handleInputChangeMany } from '../../../utils/StringUtils'
import './style.scss'

const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker
interface FilterProps {
	statuses: any
	accounts: any
	idAcc: string | undefined
	page: number
	size: number
	filter: any
	loading: boolean
	handleFilter: (filter: any) => void
	handleProgressBar: (val: boolean) => void
	handleAcc: (val: string) => void
	filterChangeHandler: (obj: any) => void
	showProgressBar: boolean
}

const Filter: React.FC<FilterProps> = (props) => {
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const {
		statuses,
		idAcc,
		accounts,
		handleFilter,
		handleAcc,
		handleProgressBar,
		filter,
		filterChangeHandler,
		loading,
		showProgressBar,
	} = props
	const [searchParams, setSearchParams] = useSearchParams()
	const [showFilter, setShowFilter] = useState(true)
	const [selectedStatuses, setSelectedStatuses] = useState<{ statuses: any }>({
		statuses: searchParams.get('statuses') || '',
	})
	const [showCleanFilter, setShowCleanFilter] = useState(false)
	const [expand, setExpand] = useState(false)

	useEffect(() => {
		setShowCleanFilter(
			!lodash.isEmpty(
				lodash.pickBy(filter, (val: any, key: string) => {
					if (key !== FORM_KEY_PROVIDER && key !== FORM_KEY_PROVIDER_USER_NAME && key !== FORM_KEY_SUPPLIER) {
						if (isArray(val)) {
							return val.every((e) => e) && !isEmpty(val)
						}
						return !isEmpty(val)
					}
				})
			) || selectedStatuses.statuses
		)
	}, [filter, selectedStatuses.statuses])

	useEffect(() => {
		if (searchParams.get('customer') && searchParams.get('provider')) {
			const indexOfAccount = accounts.findIndex(
				(acc: any) =>
					acc.name === searchParams.get('customer') && acc.provider.code === searchParams.get('provider')
			)
			if (indexOfAccount > -1) {
				handleAcc(lodash.get(accounts[indexOfAccount], 'id'))
			}
		} else {
			const index = accounts.findIndex((acc: any) => acc.id === idAcc)
			if (index > -1) {
				setSearchParams({
					customer: lodash.get(accounts[index], 'name'),
					provider: lodash.get(accounts[index], 'provider.code'),
				})
			}
		}
		form.setFieldsValue({
			supplier: idAcc,
			query: searchParams.get('query'),
			createdAt: [
				searchParams.get('createdAtFrom') ? moment(searchParams.get('createdAtFrom')) : null,
				searchParams.get('createdAtTo') ? moment(searchParams.get('createdAtTo')) : null,
			],
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts, form, idAcc, searchParams, setSearchParams])

	const getFormState = () => {
		const supplier = form.getFieldValue('supplier')
		const account = accounts.find((x: any) => x.id === supplier)
		const customer = lodash.get(account, 'name')
		const provider = lodash.get(account, 'provider.code')
		const createdAt = form.getFieldValue('createdAt')
		const createdAtFrom = createdAt && createdAt[0] && moment(createdAt[0].startOf('day')).toISOString()
		const createdAtTo = createdAt && createdAt[1] && moment(createdAt[1].endOf('day')).toISOString()

		const filter = getCleanFormFilter(
			{
				...form.getFieldsValue(true),
				statuses: selectedStatuses?.statuses,
				customer,
				provider,
				createdAtFrom,
				createdAtTo,
			},
			[],
			['supplier', 'createdAt']
		)
		return filter
	}

	const onChangeFilterMany = (key: string, isCheck: boolean, code: string) => {
		const temp = handleInputChangeMany(key, code, selectedStatuses)
		setSelectedStatuses({ ...temp })
	}

	const handleChangeAcc = (val: string) => {
		handleAcc(val)
		handleProgressBar(true)
		onFinish()
	}

	const onFinish = () => {
		handleFilter(getFormState())
	}

	const clearFilter = () => {
		form.resetFields()
		setSelectedStatuses({ statuses: '' })
		handleFilter({ ...getFormState(), statuses: '' })
	}

	return (
		<Row
			className={`white-box mg-bt-12 ${!showFilter ? 'pd-bt-0 cursor-pointer' : 'pd-imp-12'}`}
			onClick={!showFilter ? () => setShowFilter(!showFilter) : undefined}
			align='middle'>
			<Row
				justify='space-between'
				className='width100pc cursor-pointer'
				onClick={() => setShowFilter(!showFilter)}>
				<Col span={12}>
					<h3 className={`robotomedium fsz-16 line-h-24 txt-color-black ${!showFilter ? 'mg-0' : ''}`}>
						{t('requestDelivery.find')}
					</h3>
				</Col>
				<Col span={12} className='dpl-flex justify-content-end'>
					<i
						className={`fas fa-angle-${showFilter ? 'up' : 'down'} txt-color-black fsz-14 cursor-pointer`}
						onClick={() => setShowFilter(!showFilter)}
					></i>
				</Col>
			</Row>
			{showFilter && (
				<>
					<Col span={24} className='dpl-flex align-items-center mg-bt-9'>
						<span className='dpl-flex align-items-baseline flex-wrap'>
							<span className='robotoregular fsz-14 line-h-22 whitespace txt-color-black mg-r-6'>
								{t('requestDelivery.statuses')}
							</span>
							{statuses.map(({ code, name }: any) => {
								let isSelect = false
								if (selectedStatuses) {
									let temp = selectedStatuses?.statuses?.split(',')
									if (temp && temp.includes(code)) isSelect = true
								}
								return (
									<span
										className={`${isSelect ? 'status-package active' : 'status-package'} mg-r-6`}
										key={code}
										onClick={() => {
											onChangeFilterMany('statuses', !isSelect, code)
										}}
									>
										{name}
									</span>
								)
							})}
						</span>
					</Col>
					<Col span={24}>
						<Form
							name='request-delivery'
							onFinish={onFinish}
							className='form-filter'
							form={form}
							initialValues={{
								query: '',
								createdAt: [],
							}}
							onValuesChange={(changedValues, allValues) => {
								filterChangeHandler(allValues)
							}}>
							{expand && (
								<Row align='middle' gutter={[16, 8]} className='mg-bt-12'>
									<Col span={8} className='mg-bt-0'>
										<Item
											label={
												<span className='robotoregular fsz-14 line-h-22'>
													{t('requestDelivery.queryLabel')}
												</span>
											}
											labelAlign='left'
											className='query'
											name={'query'}>
											<Input
												placeholder={`${t('requestDelivery.queryPlaceholder')}`}
												allowClear
											/>
										</Item>
									</Col>
									<Col span={8}>
										<Item
											label={
												<span className='robotoregular fsz-14 line-h-22'>
													{t('packages.providerAccount')}
												</span>
											}
											className='width100pc'
											name='supplier'
											valuePropName='option'
										>
											<Select
												showSearch={false}
												placeholder={t('packages.placeHolderChooseAcc')}
												value={idAcc}
												onChange={(val: string) => handleChangeAcc(val)}
											>
												{accounts.map((acc: any) => (
													<Option key={acc.id} value={acc.id}>
														<div className='dpl-flex align-items-center'>
															<Avatar
																size={16}
																src={lodash.get(acc, 'provider.logo', '')}
																className='bd-none-impt w-12 h-12 dpl-flex align-items-center mg-r-4'
															/>
															<span className='robotoregular fsz-14 line-h-22 mg-r-4 mg-l-4'>
																{lodash.get(acc, 'provider.code', '')}
															</span>
															<Divider
																type={'vertical'}
																className={'bd-color-black line-h-22 mg-0 mg-r-4'}
															/>
															<span className='robotoregular fsz-14 line-h-22'>
																{lodash.get(acc, 'name', '')}
															</span>
														</div>
													</Option>
												))}
											</Select>
										</Item>
									</Col>
									<Col span={8}>
										<Item
											label={
												<span className='robotoregular fsz-14 line-h-22'>
													{t('requestDelivery.createdAt')}
												</span>
											}
											name='createdAt'
										>
											<RangePicker
												placeholder={[
													t('requestDelivery.fromDate'),
													t('requestDelivery.toDate'),
												]}
												className='width100pc'
												format={'DD-MM-YYYY'}
												suffixIcon={<i className='far fa-calendar-alt' />}
												//@ts-ignore
												placement='bottomRight'
											/>
										</Item>
									</Col>
								</Row>
							)}
						</Form>

						<Row className='dpl-flex justify-content-between align-items-center'>
							<span className='cursor-pointer flex align-items-center' onClick={() => setExpand(!expand)}>
								<i className={`fa-solid fa-angle-${expand ? 'up' : 'down'} mg-r-6 fsz-12`}></i>
								<span className='line-h-20 txt-color-black fsz-12'>
									{t(`shipments.${expand ? 'collapseFilter' : 'expandFilter'}`)}
								</span>
							</span>
							<div className='dpl-flex justify-content-end align-items-center flex-nowrap'>
								{showCleanFilter && (
									<Button
										className='clean-filter robotoregular txt-color-gray4 fsz-12 line-h-20 dpl-flex align-items-center
													flex-basis-btn-filter cursor-pointer bd-none mg-r-12'
										onClick={clearFilter}
										icon={<RedoOutlined className='mg-r-4' />}
									>
										<span className='mg-0'>{t('packages.refreshFilter')}</span>
									</Button>
								)}
								<Button
									className='btn-df btn-df-secondary-bg-lemon fsz-14 line-h-22 bd-none border-radius4
													px-14 flex-basis-btn-filter txt-capitalize'
									htmlType='submit'
									form='request-delivery'>
									{t('packages.search')}
								</Button>
							</div>
						</Row>
					</Col>
				</>
			)}
		</Row>
	)
}

export default Filter

