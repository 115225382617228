import { Image, Steps } from 'antd'
import lodash from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import { HistoryOrder } from '../../../containers/OrderDetail'
import { formatDateTime } from '../../../core/helpers/date-time'
import StepIconFinishedImg from '../../../resources/images/finish-step.png'
import StepIconImg from '../../../resources/images/StepIcon.png'
import './style.scss'

const {Step} = Steps
interface TrackOrderProps {
    statuses: any
    orderDetail: any
    orderHistory: any
    loading: boolean
    handleTrackOrder: () => void
}

const TrackOrder:React.FC<TrackOrderProps> = (props) => {
    const {statuses, loading, orderHistory, handleTrackOrder} = props
  	const {t} = useTranslation()
    useEffect(() => {
        handleTrackOrder()
    }, [])

    const customDot = (dot: any, { status, index }: any) => {
        return  !loading ? <Image preview={false} src={status !== 'process' ? StepIconFinishedImg : StepIconImg} /> : <Skeleton circle width={10} height={10} />
    }

      const CustomDescription = ({timestamp, handlingTime}: {timestamp: any, handlingTime: any}) => (
        <span className="dpl-flex flex-col align-items-start justify-content-start fsz-12 line-h-20">
            <span className="robotoregular  txt-color-gray4">{!loading ?timestamp : <Skeleton width={120} height={20} />}</span>
            <span className="robotoregular txt-color-gray4">{!loading ? `(${handlingTime})` : <Skeleton width={50} height={20}/>}</span>
        </span>
      )

  return (
        <Steps className="transport" current={0} progressDot={customDot} direction="vertical">
            {orderHistory && orderHistory.map(({status, timestamp, handlingTime, id} : HistoryOrder) =>{
                const nameOfStatus = lodash.get(statuses.find(({code}: any) => code === status), 'name', '---')
                const timestampRendered = lodash.isNull(timestamp) ? t('orderDetail.undefined') : formatDateTime(timestamp)
                const handlingTimeRendered = lodash.isNull(handlingTime) ? t('orderDetail.undefined') : `${handlingTime} ngày`
                return (
                    <Step key={id}  title={<span className='robotomedium txt-color-black fsz-12 line-h-20'>{!loading ? nameOfStatus : <Skeleton width={100} height={20} />}</span>}
                    description={<CustomDescription timestamp={timestampRendered} handlingTime={handlingTimeRendered}/>} />)}
                )
            }
        </Steps>
  )
}

export default TrackOrder
