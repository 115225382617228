import {Repository} from '../core/repositories/Repository';
import {AxiosResponse} from 'axios';
import {url} from '../core/helpers/string';
import {httpConfig} from "../core/config/http";
import {API_BASE_URL, DEFAULT_PAGE_SIZE} from "../core/config";

export class SupplierRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.setBaseURL(url(API_BASE_URL, ''));
    }

    public getProviders = (): Promise<any> => {
        return this.http
            .get(`provider`, {})
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    };
    public getExchangeRateByAccount = (currency: string, connectionId: string): Promise<any> => {
        return this.http
            .get(`connections/exchange-rates?currency=${currency}&connectionId=${connectionId}`)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    };
    public getListAccount = (): Promise<any> => {
        return this.http
            .get(`connections`, {params:{sort:'createdAt:desc'}})
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    };
    public addAccount = (body: any): Promise<any> => {
        return this.http
            .post(`connections`, body)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    };
    public updateAccount = (id: number,body: any): Promise<any> => {
        return this.http
            .patch(`connections/${id}`, body)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    };
    public getTransactionTypes = (connectionId: any): Promise<any> => {
        return this.http
            .get(`connections/${connectionId}/transaction_types`, {})
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    };
    public getBalance = (connectionId: any): Promise<any> => {
        return this.http
            .get(`connections/${connectionId}/balance`, {})
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    };
    public getAccountBalance = (): Promise<any> => {
        return this.http
            .get(`connections/account-balance`, {})
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    };
    public getTransactionsByAccount = (connectionId: number, filter: any): Promise<any> => {
        return this.http
            .get(`connections/${connectionId}/transactions`, {params: {...filter, page: filter&&filter.page?filter.page:0, size: filter&&filter.size?filter.size:DEFAULT_PAGE_SIZE}})
            .then((response: AxiosResponse<any>) => {
                return response;
            });
    };

    public reconnectSupplier = (id:number,body:any): Promise<any> => {
        return this.http
            .post(`connections/${id}/reconnect`, body)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }

    public verifyConnectionFromM1 = (username:string,tenant:string,body:any) : Promise<any> => {
        return this.http
            .post(`connections/verify-one-time-token?customer=${username}&provider=${tenant}`,body)
            .then((res:AxiosResponse<any>) => {
                return res;
            })
    }
    public registerAccount = (body: any, provider: string): Promise<any> => {
        return this.http
            .post(`connections/${provider}/register`, body)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    };

    public getListAccountsByProvider = (provider: string): Promise<any> => {
        return this.http
            .get(`connections`, {params: {provider}})
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    };


}

export const supplierRepository: SupplierRepository = new SupplierRepository();
