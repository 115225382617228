import { Form, Input } from 'antd';
import { FormItemProps } from 'antd/es/form/FormItem';
import { t } from 'i18next';
import { FC, ChangeEvent } from 'react';

interface PriceInputProps extends FormItemProps {
  value?: string;
  rowId?: string;
  index?: number;
  autoFocus?: boolean;
  disableInput?: boolean;
  handleConfig?: (
    priceFomula: string,
    integrationIds: string[],
    isConfigAll?: boolean
  ) => void;
  onChangePrice?: (value: string, index: number) => void;
}

const PriceInput: FC<PriceInputProps> = ({
  value,
  index = 0,
  rowId = '',
  autoFocus = false,
  disableInput = false,
  handleConfig = () => {},
  onChangePrice = () => {},
  ...itemProps
}: PriceInputProps) => {
  return (
    <>
      <Form.Item
        className={'txt-color-black txt-size-h7 wordbreakword mgbt0'}
        help={
          <span className="mgt4">
            <i className="fa-regular fa-circle-info mgr4 mgl4"></i>
            {t('price-config.help1')}{' '}
            <span className="robotomedium">{t('price-config.help2')}</span>
            {' = '}
            {t('price-config.help3')}
          </span>
        }
        {...itemProps}
      >
        <Input
          value={value}
          className="m24-input"
          onKeyDown={(e: any) => {
            if (e.code === 'Enter') {
              handleConfig(e.target.value, [rowId]);
            }
          }}
          onBlur={(e) => handleConfig(e.target.value, [rowId])}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangePrice(e.target.value, index)
          }
          onClick={(e) => {
            e.stopPropagation();
          }}
          placeholder={`${t('price-config.input-placeholder')}`}
          autoFocus={autoFocus}
          disabled={disableInput}
        />
      </Form.Item>
    </>
  );
};

export { PriceInput };
