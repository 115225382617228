import { Button, Col, Tabs } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { TAB_PRODUCT, TAB_TICKET, TAB_TRANSACTION, TAB_TRANSPORT } from '../../../core/config'
import { orderRepository } from '../../../repositories/OrderRepository'
import { getUrlPathname } from '../../../utils/util'
import Packages from './Packages'
import Products from './Products'
import Tickets from './Tickets'
import Transactions from './Transactions'

const { TabPane } = Tabs
interface TabProps {
	items: any
	exchangeRate: number
	currency: string
	loading: boolean
	orderDetail: any
	finalcials: any
	confirmProductChange: (code: string, id: string, confirm: boolean, successInfo: string) => void
	loadingNotConfirm: { [id: string]: boolean } | undefined
	loadingConfirm: { [id: string]: boolean } | undefined
	handleConfirm: (val: boolean) => void
	claim: any
	onClaimPageChange: (filter: any) => void
	providerCode: string | undefined
	accountCode: string | undefined
	loadingTransaction: boolean
}
interface PropsProductTab {
	label: string
	total?: number
	isActive: boolean
	loading: boolean
}
export const CustomerTabTitle: React.FC<PropsProductTab> = ({ label, total, isActive, loading }) => {
	return (
		<span className='px-12 pd-bt-6 dpl-flex align-items-center'>
			<span className={`mgr4 fsz-16 line-h-24 ${isActive ? 'robotomedium' : 'robotoregular'}`}>
				{!loading ? label : <Skeleton width={100} height={22} />}
			</span>
			{!loading ? (
				<span
					className={`dpl-flex align-items-center justify-content-center robotoregular line-h-20  px-8 pd-t-1 w-23 mg-bt-2 border-radius20 ${
						isActive ? 'bg-color-orange3 txt-color-white' : 'bg-color-gray-tab txt-color-black'
					}`}>
					{total}
				</span>
			) : (
				<Skeleton width={30} borderRadius={20} height={24} />
			)}
		</span>
	)
}

const TabLayout: React.FC<TabProps> = (props) => {
	const [packages, setPackages] = useState([])
	const [loadingPackages, setLoadingPackages] = useState(false)
	const { t } = useTranslation()
	const [searchParams, setSearchParams] = useSearchParams()
	const navigate = useNavigate()

	let { code } = useParams()
	const {
		orderDetail,
		items,
		exchangeRate,
		currency,
		loading,
		finalcials,
		confirmProductChange,
		loadingNotConfirm,
		loadingConfirm,
		loadingTransaction,
	} = props
	const { handleConfirm, claim, onClaimPageChange, providerCode, accountCode } = props
	const [currentTabKey, setCurrentTabKey] = useState('TAB_PRODUCT')
	const handleChangeTab = (activeKey: string) => {
		setCurrentTabKey(activeKey)
		setSearchParams({tab: activeKey})
	}

	useEffect(() => {
		if(searchParams.get('tab')) {
			setCurrentTabKey(searchParams.get('tab')!)
		}
	},[searchParams])

	const getPackages = useCallback(() => {
		setLoadingPackages(true)
		orderRepository
			.getPackages(code)
			.then((res) => {
				setPackages(res)
			})
			.catch((err) => {})
			.finally(() =>
				setTimeout(() => {
					setLoadingPackages(false)
				}, 2000)
			)
	}, [code])

	useEffect(() => {
		getPackages()
	}, [getPackages])

	return (
		<Col span={24} className='pd-12 bg-color-white box-order mgt12 txt-color-gray fsz-16 robotoregular border-radius4'>
			<Tabs defaultActiveKey={currentTabKey} onChange={handleChangeTab} tabBarExtraContent={{
						right:
							currentTabKey === TAB_TICKET ? (
								<Button
									className='btn-df btn-df-secondary-bg-lemon mg-bt-10 fsz-14 line-h-22 py-5'
									icon={<i className='mg-r-10 fa-solid fa-plus'></i>}
									onClick={() =>
										navigate(
											`/claims/create?orderCode=${orderDetail?.code}&providerCode=${providerCode}&customerCode=${
												accountCode
											}&back=${getUrlPathname(window.location)}`
										)
									}>
									{t('tickets.createTickets')}
								</Button>
							)  : null,
					}}>
				<TabPane
					tab={
						<CustomerTabTitle
							label={t('orderDetail.tabProduct')}
							total={items.length}
							isActive={currentTabKey === 'TAB_PRODUCT'}
							loading={loading}
						/>
					}
					key={TAB_PRODUCT}>
					<Products
						loadingNotConfirm={loadingNotConfirm}
						loadingConfirm={loadingConfirm}
						confirmProductChange={confirmProductChange}
						handleConfirm={handleConfirm}
						items={items}
						exchangeRate={exchangeRate}
						currency={currency}
						loading={loading}
					/>
				</TabPane>
				<TabPane
					tab={
						<CustomerTabTitle
							label={t('orderDetail.tabPackages')}
							total={packages.length}
							isActive={currentTabKey === 'TAB_TRANSPORT'}
							loading={loading}
						/>
					}
					key={TAB_TRANSPORT}>
					<Packages packages={packages} loading={loadingPackages} orderDetail={orderDetail} />
				</TabPane>
				<TabPane
					tab={
						<CustomerTabTitle
							label={t('orderDetail.tabTicket')}
							total={claim.total || 0}
							isActive={currentTabKey === 'TAB_TICKET'}
							loading={loading}
						/>
					}
					key={TAB_TICKET}>
					<Tickets
						{...claim}
						onPageChange={onClaimPageChange}
						providerCode={providerCode}
						accountCode={accountCode}
						orderCode={orderDetail?.code}
					/>
				</TabPane>
				<TabPane
					tab={
						<CustomerTabTitle
							label={t('orderDetail.tabTransaction')}
							total={finalcials.length}
							isActive={currentTabKey === 'TAB_TRANSACTION'}
							loading={loading}
						/>
					}
					key={TAB_TRANSACTION}>
					<Transactions dataSource={finalcials} loading={loadingTransaction} />
				</TabPane>
			</Tabs>
		</Col>
	)
}

export default TabLayout

