import { Avatar, Badge, Popover } from 'antd'
import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { tabItems } from '../../../core/config'
import { NoticeCategoryInterface } from '../../../Interface/NoticeCategoryInterface'
import { noticeRepository } from '../../../repositories/NoticeRepository'
import TabNotification from '../Notices'
interface PopoverNoticeProps {
	onVisibleChangeHandler: (visible: boolean) => void
	totalUnreadNotice: number
	getTotalUnreadNotice: () => void 
	isShowNotice: boolean
}
const PopoverNotices: React.FC<PopoverNoticeProps> = (props) => {
	const { totalUnreadNotice, onVisibleChangeHandler, getTotalUnreadNotice, isShowNotice } = props

	return (
		<Popover
			placement='bottomRight'
			trigger={['click']}
			overlayClassName={'header-notification-popup'}
			onVisibleChange={onVisibleChangeHandler}
			// @ts-ignore
			getPopupContainer={() => document.getElementById('header-right-content')}
			content={<TabNotification totalUnread={totalUnreadNotice} refetchUnread={getTotalUnreadNotice} />}>
			{/* <i className={`${isShowNotice ? 'is-show-notice' : ''} bell-icon fas fa-bell cursor-pointer mgr10`}>
			<span
				className={`notice-badge ${
					totalUnreadNotice >= 10 && totalUnreadNotice <= 99 ? 'notice-badge-notice-2num' : totalUnreadNotice > 99 ? 'notice-badge-notice-99' : ''
				}`}>
				{totalUnreadNotice}
			</span>

			</i> */}

			<Badge count={totalUnreadNotice} offset={[totalUnreadNotice > 99 ? 6 : 8,12]} size='small' className='noti-count cursor-pointer link-hover pd-8 fsz-16' overflowCount={99}>
				<i className='fa-solid fa-bell fsz-14'></i>
			</Badge>
		</Popover>
	)
}

export default PopoverNotices

