import {Repository} from '../core/repositories/Repository';
import {url} from '../core/helpers/string';
import {httpConfig} from "../core/config/http";
import {API_BASE_URL} from "../core/config";
import {ThreadFilterInterface} from "../Interface/ThreadFilterInterface";
import {ThreadCommentFilterInterface} from "../Interface/ThreadCommentFilterInterface";
import {ThreadCommentCreateInterface} from "../Interface/ThreadCommentCreateInterface";
import {ThreadCreateInterface} from "../Interface/ThreadCreateInterface";


export class ThreadRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.setBaseURL(url(API_BASE_URL, ''));
    }

    public getThreads = (criteria: ThreadFilterInterface) => {
        return this.http
            .get(`/threads`, {params: {...criteria}})
    }

    public createThread = (data: ThreadCreateInterface) => {
        return this.http
            .post(`/threads`, data)
    }

    public getThreadComments = (id: string, criteria: ThreadCommentFilterInterface) => {
        return this.http
            .get(`/threads/${id}/comments`, {params: {...criteria}})
    }

    public createComment = (id: string, data: ThreadCommentCreateInterface) => {
        const formData = new FormData()

        if (Array.isArray(data.attachments)) {
            for (let file of data.attachments) {
                formData.append('attachments', new Blob([file]), file.name)
            }
        }
        if (data.comment) {
            formData.set('comment', new Blob([JSON.stringify(data.comment)], {type: 'application/json'}))
        }
        return this.http
            .post(`/threads/${id}/comments`, formData)
    }
}
export const threadRepository: ThreadRepository = new ThreadRepository();
