import { Empty } from 'antd'
import React, { ReactNode } from 'react'
import EmptyImg from '../../resources/images/empty/no-data/empty_filter.png'
interface EmptyDataFilterProps {
	description: string | ReactNode
	minHeight?: string | number
	className?: string
}
const EmptyDataFilter: React.FC<EmptyDataFilterProps> = ({ description, minHeight, className }) => {
	return (
		<div className={className ? className : ''} style={{ minHeight: `calc(100vh - ${minHeight}px)` }} >
			<Empty description={<span className='robotoregular fsz-14 line-h-22 txt-color-gray'>{description}</span>} image={EmptyImg} />
		</div>
	)
}

export default EmptyDataFilter
