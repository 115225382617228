import { RedoOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, DatePicker, Divider, Form, Image, Input, Row, Select } from 'antd'
import lodash, { isArray, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { FORM_KEY_CUSTOMER, FORM_KEY_PROVIDER } from '../../core/config'
import { handleInputChangeMany } from '../../utils/StringUtils'
import './style.scss'

const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker
interface FilterProps {
	statuses: any
	accounts: any
	idAcc: string
	filter: any
	showProgressBar: boolean
	handleFilter: (filter: any) => void
	handleProgressBar: (val: boolean) => void
	handleAcc: (id: string) => void
	filterChangeHandler: (obj: any) => void
}

const Filter: React.FC<FilterProps> = (props) => {
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const [searchParams, setSearchParams] = useSearchParams()
	const [expand, setExpand] = useState(false)
	const [showCleanFilter, setShowCleanFilter] = useState(false)

	const [showFilter, setShowFilter] = useState(true)
	const { statuses, accounts, idAcc, handleFilter, handleAcc, filterChangeHandler, filter, handleProgressBar } = props
	const [typeSearch, setTypeSearch] = useState<string | null>(searchParams.get('typeSearch') || 'range')
	const [selectedStatuses, setSelectedStatuses] = useState<{ statuses: any }>({
		statuses: searchParams.get('statuses') || '',
	})

	useEffect(() => {
		if (searchParams.get('customer') && searchParams.get('provider')) {
			const indexOfAccount = accounts.findIndex(
				(acc: any) =>
					acc.name === searchParams.get('customer') && acc.provider.code === searchParams.get('provider')
			)
			if (indexOfAccount > -1) {
				handleAcc(lodash.get(accounts[indexOfAccount], 'id'))
			}
		} else {
			const index = accounts.findIndex((acc: any) => acc.id === idAcc)
			if (index > -1) {
				setSearchParams({
					customer: lodash.get(accounts[index], 'name'),
					provider: lodash.get(accounts[index], 'provider.code'),
				})
			}
		}

		form.setFieldsValue({
			typeSearch: searchParams.get('typeSearch') || 'range',
			supplier: idAcc,
			query: searchParams.get('query'),
			timeInEqual: searchParams.get('typeSearch') === 'equal' ? searchParams.get('handlingTimeTo') : '',
			fromInRange: searchParams.get('typeSearch') === 'range' ? searchParams.get('handlingTimeFrom') : '',
			toInRange: searchParams.get('typeSearch') === 'range' ? searchParams.get('handlingTimeTo') : '',
			timeOnlyFrom: searchParams.get('typeSearch') === 'from' ? searchParams.get('handlingTimeFrom') : '',
			timeOnlyTo: searchParams.get('typeSearch') === 'to' ? searchParams.get('handlingTimeTo') : '',
			createdAt: [
				searchParams.get('createdFrom') ? moment(searchParams.get('createdFrom')) : null,
				searchParams.get('createdTo') ? moment(searchParams.get('createdTo')) : null,
			],
			cutOffStatus: searchParams.get('cutOffStatus'),
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts, form, idAcc, searchParams, setSearchParams])

	const onChangeFilterMany = (key: string, isCheck: boolean, code: string) => {
		const temp = handleInputChangeMany(key, code, selectedStatuses)
		setSelectedStatuses({ ...temp })
		filterChangeHandler(temp)
	}

	const getFormState = () => {
		const supplier = form.getFieldValue('supplier')
		const account = accounts.find((x: any) => x.id === supplier)
		const customer = lodash.get(account, 'name')
		const provider = lodash.get(account, 'provider.code')
		const createdAt = form.getFieldValue('createdAt')
		const createdFrom = createdAt && createdAt[0] && moment(createdAt[0].startOf('day')).toISOString()
		const createdTo = createdAt && createdAt[1] && moment(createdAt[1].endOf('day')).toISOString()
		const handlingTimeFrom =
			typeSearch === 'equal'
				? form.getFieldValue('timeInEqual')
				: typeSearch === 'range'
				? form.getFieldValue('fromInRange')
				: typeSearch === 'from'
				? form.getFieldValue('timeOnlyFrom')
				: ''
		const handlingTimeTo =
			typeSearch === 'equal'
				? form.getFieldValue('timeInEqual')
				: typeSearch === 'range'
				? form.getFieldValue('toInRange')
				: typeSearch === 'to'
				? form.getFieldValue('timeOnlyTo')
				: ''
		const filter = lodash.omitBy(
			{
				...form.getFieldsValue(true),
				statuses: selectedStatuses?.statuses,
				customer,
				provider,
				createdFrom,
				createdTo,
				handlingTimeFrom,
				handlingTimeTo,
			},
			(o: any, key: string) => {
				return (
					isEmpty(o) ||
					[
						'supplier',
						'createdAt',
						'timeInEqual',
						'fromInRange',
						'toInRange',
						'timeOnlyFrom',
						'timeOnlyTo',
					].includes(key)
				)
			}
		)
		return filter
	}
	const onFinish = () => {
		handleFilter(getFormState())
		handleProgressBar(true)
	}

	const clearFilter = () => {
		form.resetFields()
		setSelectedStatuses({ statuses: '' })
		setTypeSearch('range')
		handleFilter({ ...lodash.omit(getFormState(), 'statuses') })
	}

	const handleChangeTypeSearch = (value: string) => {
		form.setFieldsValue({
			...form.getFieldsValue(),
			timeInEqual: '',
			fromInRange: '',
			toInRange: '',
			timeOnlyFrom: '',
			timeOnlyTo: '',
		})
		setTypeSearch(value)
	}

	const handleChangeAcc = (val: string) => {
		handleAcc(val)
		onFinish()
	}

	useEffect(() => {
		setShowCleanFilter(
			!lodash.isEmpty(
				lodash.pickBy(filter, (val: any, key: string) => {
					if (key !== FORM_KEY_CUSTOMER && key !== FORM_KEY_PROVIDER && !['supplier'].includes(key)) {
						if (isArray(val)) {
							return val.every((e) => e) && !isEmpty(val)
						}
						return !isEmpty(val)
					}
				})
			) || selectedStatuses?.statuses
		)
	}, [filter, selectedStatuses?.statuses])

	const labelClasses = 'txt-color-black robotoregular fsz-14 line-h-22'

	return (
		<Row
			className={`white-box mg-bt-12 pd-imp-12 ${!showFilter ? 'cursor-pointer' : ''}`}
			onClick={!showFilter ? () => setShowFilter(!showFilter) : undefined}>
			<Row
				onClick={() => setShowFilter(!showFilter)}
				justify='space-between'
				className='width100pc cursor-pointer'>
				<Col span={12}>
					<h3
						className={`robotomedium fsz-16 line-h-24 txt-color-black txt-capitalize ${
							!showFilter ? 'mg-bt-0' : ''
						}`}>
						{t('packages.searchHead')}
					</h3>
				</Col>
				<Col span={12} className='dpl-flex justify-content-end align-items-center'>
					<i
						className={`fas fa-angle-${showFilter ? 'up' : 'down'} txt-color-black fsz-14 cursor-pointer`}
						onClick={() => setShowFilter(!showFilter)}></i>
				</Col>
			</Row>
			{showFilter && (
				<>
					<Col span={24} className='dpl-flex align-items-center mg-bt-9'>
						<span className={`dpl-flex align-items-baseline flex-wrap`}>
							<span className='robotoregular fsz-14 line-h-22 whitespace txt-color-black mg-r-6'>
								{t('packages.statuses')}
							</span>
							{statuses.map(({ code, name }: any) => {
								let isSelect = false
								if (selectedStatuses) {
									let temp = selectedStatuses?.statuses?.split(',')
									if (temp && temp.includes(code)) isSelect = true
								}
								return (
									<span
										className={`txt-capitalize mg-r-6 ${
											isSelect ? 'status-package active' : 'status-package'
										}`}
										key={code}
										onClick={() => {
											onChangeFilterMany('statuses', !isSelect, code)
										}}>
										{name}
									</span>
								)
							})}
						</span>
					</Col>
					<Col span={24}>
						{expand && (
							<Form
								name='package-filter'
								onFinish={onFinish}
								className='package-filter'
								form={form}
								initialValues={{
									query: '',
									supplier: idAcc,
									createdAt: [],
									cutOffStatus: undefined,
									handlingTimeFrom: '',
									handlingTimeTo: '',
									handlingTime: '',
									fromInRange: '',
									toInRange: '',
									timeInEqual: '',
									fromOnlyFrom: '',
									timeOnlyTo: '',
									typeSearch: 'range',
								}}
								onValuesChange={(changedValues, allValues) => {
									filterChangeHandler(allValues)
								}}>
								<Row align='middle' gutter={[24, 8]} className='mg-bt-12'>
									<Col span={8} className='mg-bt-0'>
										<Item
											label={<span className={labelClasses}>{t('packages.findByQuery')}</span>}
											labelAlign='left'
											className='query'
											name={'query'}>
											<Input placeholder={`${t('packages.placeHolderQuery')}`} allowClear />
										</Item>
									</Col>
									<Col span={8}>
										<Item
											label={
												<span className={labelClasses}>{t('packages.providerAccount')}</span>
											}
											className='width100pc'
											name='supplier'
											valuePropName='option'>
											<Select
												showSearch={false}
												placeholder={t('packages.placeHolderChooseAcc')}
												value={idAcc}
												onChange={(val: string) => handleChangeAcc(val)}>
												{accounts.map((acc: any) => (
													<Option key={acc.id} value={acc.id}>
														<div className='dpl-flex align-items-center h-30'>
															<Avatar
																size={16}
																src={lodash.get(acc, 'provider.logo', '')}
																className='bd-none-impt w-12 h-12 dpl-flex align-items-center mg-r-4'
															/>
															<span className='robotoregular fsz-14 line-h-22 mg-r-4 mg-l-4'>
																{lodash.get(acc, 'provider.code', '')}
															</span>
															<Divider
																type={'vertical'}
																className={'bd-color-black line-h-22 mg-0 mg-r-4'}
															/>
															<span className='robotoregular fsz-14 line-h-22'>
																{lodash.get(acc, 'name', '')}
															</span>
														</div>
													</Option>
												))}
											</Select>
										</Item>
									</Col>
									<Col span={8}>
										<Item
											label={<span className={labelClasses}>{t('packages.createdAt')}</span>}
											name='createdAt'>
											<RangePicker
												placeholder={[t('packages.fromDate'), t('packages.toDate')]}
												className='width100pc'
												format={'DD-MM-YYYY'}
												suffixIcon={<i className='far fa-calendar-alt' />}
												//@ts-ignore
												placement='topRight'
											/>
										</Item>
									</Col>
									<Col span={8} className='mg-bt-0'>
										<Item
											label={<span className={labelClasses}>{t('packages.statusStopped')}</span>}
											labelAlign='left'
											className='status-cutoff'
											name='cutOffStatus'>
											<Select placeholder={t('packages.stoppedStatusPlaceHolder')} allowClear>
												{statuses.map((stt: any) => (
													<Option key={stt.code} value={stt.code} className={''}>
														<span className={'robotoregular fsz-14 line-h-22'}>
															{lodash.get(stt, 'name', '')}
														</span>
													</Option>
												))}
											</Select>
										</Item>
									</Col>
									<Col span={6}>
										<Item name='typeSearch' className='mg-r-4' valuePropName='option'>
											<Select
												showSearch={false}
												value={typeSearch}
												onChange={(val) => handleChangeTypeSearch(val)}>
												<Option value='range'>
													<span className={labelClasses}>
														{t('packages.rangeHandlingTime')}
													</span>
												</Option>
												<Option value='equal'>
													<span className={labelClasses}>
														{t('packages.equalHandlingTime')}
													</span>
												</Option>
												<Option value='from'>
													<span className={labelClasses}>
														{t('packages.fromHandlingTime')}
													</span>
												</Option>
												<Option value='to'>
													<span className={labelClasses}>{t('packages.toHandlingTime')}</span>
												</Option>
											</Select>
										</Item>
									</Col>
									<Col span={10} className='type-search'>
										{typeSearch === 'range' ? (
											<div className='flex align-items-center width100pc'>
												<Item colon={false} name='fromInRange' className='width100pc mg-r-4'>
													<Input
														placeholder={`${t('packages.placeHolderHandlingTimeFrom')}`}
														allowClear
													/>
												</Item>
												<Item name='toInRange' className='width100pc mg-r-4'>
													<Input
														placeholder={`${t('packages.placeHolderHandlingTimeTo')}`}
														allowClear
													/>
												</Item>
												<span className='robotoregular fsz-12 line-h-20 txt-color-gray4'>
													{t('packages.date')}
												</span>
											</div>
										) : typeSearch === 'equal' ? (
											<Item name={'timeInEqual'}>
												<Input
													placeholder={`${t('packages.placeHolderForHandlingTimeNotRange')}`}
													allowClear
												/>
											</Item>
										) : typeSearch === 'from' ? (
											<Item name={'timeOnlyFrom'}>
												<Input
													placeholder={`${t('packages.placeHolderForHandlingTimeNotRange')}`}
													allowClear
												/>
											</Item>
										) : (
											<Item name={'timeOnlyTo'}>
												<Input
													placeholder={`${t('packages.placeHolderForHandlingTimeNotRange')}`}
													allowClear
												/>
											</Item>
										)}
									</Col>
								</Row>
							</Form>
						)}
						<div className='flex justify-content-between align-items-center'>
							<span className='cursor-pointer flex align-items-center' onClick={() => setExpand(!expand)}>
								<i className={`fa-solid fa-angle-${expand ? 'up' : 'down'} mg-r-6 fsz-12`}></i>
								<span className='line-h-20 txt-color-black fsz-12'>{t('shipments.expandFilter')}</span>
							</span>
							<div className='dpl-flex justify-content-end align-items-center flex-nowrap'>
								{showCleanFilter && (
									<Button
										className='clean-filter robotoregular txt-color-gray4 fsz-12 line-h-20 dpl-flex align-items-center
													flex-basis-btn-filter cursor-pointer bd-none mg-r-12'
										onClick={clearFilter}
										icon={<RedoOutlined className='mg-r-4' />}>
										<span className='mg-0'>{t('packages.refreshFilter')}</span>
									</Button>
								)}

								<Button
									className='btn-df btn-df-secondary-bg-lemon fsz-14 line-h-22 bd-none border-radius4
														px-24 py-3 flex-basis-btn-filter txt-capitalize'
									htmlType='submit'
									form='package-filter'
									onClick={onFinish}>
									{t('packages.search')}
								</Button>
							</div>
						</div>
					</Col>
				</>
			)}
		</Row>
	)
}

export default Filter

