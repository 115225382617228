import { InputNumber, InputNumberProps } from 'antd'
import { FC, useMemo, useState } from 'react'

const InputNumberWithBeforeAddon: FC<InputNumberProps> = ({
  className = '',
  ...props
}: InputNumberProps) => {
  const [isFocus, setIsFocus] = useState<boolean>(false)

  const classes: string = useMemo((): string => {
    let c: string = className
    if (isFocus) {
      c += ' _focused'
    }
    if (props.disabled) {
      c += ' _disabled'
    }
    return c
  }, [className, isFocus, props.disabled])

  return (
    <InputNumber
      {...props}
      className={classes}
      onBlur={() => setIsFocus(false)}
      onFocus={() => setIsFocus(true)}
    />
  )
}

export { InputNumberWithBeforeAddon }
