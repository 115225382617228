import React, {useState} from "react";
import {Divider, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {UserServiceInterface} from "../../../Interface/UserServiceInterface";
import UserServices from "./UserServices";
import {VendorServiceInterface} from "../../../Interface/VendorServiceInterface";
import {userServiceRepository} from "../../../repositories/UserServiceRepository";
import ListVendor from "./ListVendor";
import {VendorServiceModel} from "../../../model/VendorServiceModel";
import M24Notification from "../../../utils/M24Notification";
import PurchasePopUp from "./PurchasePopUp";
import { URLSearchParamsInit } from "react-router-dom";
import './style.scss'

export interface PackageOrderDomain {
    packageCode: string,
    vendor: string,
    policyAccepted: boolean,
    paymentMethod: string,
}

interface ServiceProps {
    searchParams: URLSearchParams
    setSearchParams: (nextInit: URLSearchParamsInit, navigateOptions?: {}) => void
}

function Service({searchParams, setSearchParams}: ServiceProps) {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [userServices, setUserServices] = React.useState<UserServiceInterface[]>([]);
    const [vendors, setVendors] = React.useState<VendorServiceInterface[]>([]);
    const [selectedVendor, setSelectedVendor] = React.useState<VendorServiceInterface>(VendorServiceModel);
    const [vendorPackages, setVendorPackages] = React.useState<any>([]);
    const [checked, setChecked] = useState(true);
    

    const vendorId: any = searchParams.get('vendorId')

    React.useEffect(() => {
        getServiceVendor();
        getUserPackages();
    }, [])

    const getServiceVendor = () => {
        setIsLoading(true);
        userServiceRepository.getVendor().then((response) => {
            setVendors(response);
            if (vendorId) {
                const vender: VendorServiceInterface = response.find((v: any) => v.id === vendorId)

                if (vender) {
                    handleVendorSelect(vender)
                } else {
                    M24Notification.notifyError(
                        t('message.titleFailed'),
                        'Không tìm thấy nhà cung ứng. Vui lòng kiểm tra lại',
                        '',
                        5
                      );
                }
            }
        })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setIsLoading(false));
    }

    const getUserPackages = () => {
        setIsLoading(true);
        userServiceRepository.getUserPackage().then((response) => {
            setUserServices(response.data)
        }).catch((err) => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const getVendorPackages = (vendor: VendorServiceInterface) => {
        setIsLoading(true);
        userServiceRepository.getVendorPackages(vendor.code.toLowerCase()).then((response) => {
            setVendorPackages(response.data);
        }).then((()=> {
            showPurchaseModal();
        })).catch(() => {
            setVendorPackages([])
            M24Notification.messageError(t('profile.error-message'));
        }).finally(()=> setIsLoading(false));
    }

    const handleVendorSelect = (vendor: VendorServiceInterface) => {
        setSelectedVendor(vendor);
        getVendorPackages(vendor);

        const tab: any = searchParams.get('tab')
        const packageId: any = searchParams.get('packageId')
        const query: any = {
            tab,
            packageId: packageId,
            vendorId: vendor.id,
        }

        if (!packageId) {
            delete query.packageId
        }
        setSearchParams(query)
        setChecked(true)
    }

    const [showPurchasePopUp,setShowPurchasePopUp] = useState<boolean>(false);

    const showPurchaseModal = () => {
        setShowPurchasePopUp(true)
    }

    const cancelPurchasePopUp = () => {
        setShowPurchasePopUp(false);
        setSelectedVendor(VendorServiceModel);
    }

    return (
        <div className={'service-container'}>
            <Spin spinning={isLoading}>
            <div>
                <Divider orientation="left" plain className={'profile-title'}>
                    <span
                        className={'txt-size-h3 txt-color-black robotomedium txt-capitalize'}>{t('profile.user-own-service')}</span>
                </Divider>
                <UserServices
                    handlePurchase={handleVendorSelect}
                    isLoading={isLoading}
                    userServices={userServices}
                />
            </div>
            <div>
                <Divider orientation="left" plain className={'profile-title'}>
                    <span
                        className={'txt-size-h3 txt-color-black robotomedium txt-capitalize'}>{t('profile.user-vendor-list')}</span>
                </Divider>
                <ListVendor
                    isLoading={isLoading}
                    vendors={vendors}
                    handlePurchase={handleVendorSelect}
                />
            </div>
                <PurchasePopUp isLoading={isLoading}
                               visible={showPurchasePopUp}
                               setShowPurchasePopUp={setShowPurchasePopUp}
                               onCancel={cancelPurchasePopUp}
                               selectedVendor={selectedVendor}
                               vendorPackages={vendorPackages}
                               searchParams={searchParams}
                               setSearchParams={setSearchParams}
                               checked={checked}
                               handleChecked={(val: boolean) => setChecked(val)}
                />


            {/*{showSuccessPopup && <SuccessPopup*/}
            {/*    showSuccessPopup={showSuccessPopup}*/}
            {/*    selectedVendor={selectedVendor}*/}
            {/*    selectedPackage={selectedPackage}*/}
            {/*    confirmSuccessModal={confirmSuccessModal}*/}
            {/*/>}*/}
            </Spin>
        </div>
    )
}

export default Service;
