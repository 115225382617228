import { Modal, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { URLSearchParamsInit } from "react-router-dom";
import { BillTypeEnum, CreatePaymentInvoiceBody, RequestBillFormNames } from "../../../Interface/PaymentInterface";
import { ServicePackageInterface } from "../../../Interface/UserServiceInterface";
import { VendorServiceInterface } from "../../../Interface/VendorServiceInterface";
import { ServicePackageModel } from "../../../model/UserServiceModel";
import { userServiceRepository } from "../../../repositories/UserServiceRepository";
import M24Notification from "../../../utils/M24Notification";
import BankTransferStep from "./BankTransferStep";
import { PackageOrderDomain } from "./index";
import { ModalRequestInvoice } from "./ModalRequestInvoice";
import PaymentMethods from "./PaymentMethods";
import SuccessPopup from "./SuccessPopup";
import VendorPackages from "./VendorPackages";

type PurchasePopUpProps = {
    isLoading: boolean;
    visible:boolean;
    setShowPurchasePopUp: (value: boolean) => void
    onCancel:()=>void;
    selectedVendor:VendorServiceInterface;
    vendorPackages:ServicePackageInterface[];
    searchParams: URLSearchParams
    setSearchParams: (nextInit: URLSearchParamsInit, navigateOptions?: {}) => void
    checked: boolean
    handleChecked: (val: boolean) => void
}

function PurchasePopUp(props:PurchasePopUpProps) {
    const {t} = useTranslation();
    const {
        visible,selectedVendor,isLoading,vendorPackages,
        onCancel,searchParams, setSearchParams, setShowPurchasePopUp, checked, handleChecked
    } = props;
    const [step,setStep] = useState<number>(0);
    const [selectedPackage, setSelectedPackage] = React.useState<ServicePackageInterface>(ServicePackageModel);
    const [formData, setFormData] = React.useState<PackageOrderDomain>();
    const [orderInformation, setOrderInformation] = React.useState<any>({});
    const [paymentMethod,setPaymentMethod] = React.useState<string>('BANK_TRANSFER');
    const [isCreateDraft,setIsCreateDraft] = React.useState(false);
    const [isCreateOrder,setIsCreateOrder] = React.useState(false);

    const [invoiceForm, setInvoiceForm] = useState<any>()
    const [isShowRequestBillModal, setIsShowRequestBillModal] = useState<boolean>(false)

    const tab: any = searchParams.get('tab')
    const packageId: any = searchParams.get('packageId')

    useEffect(() => {
        if (visible && packageId) {
            const packageFromUri: ServicePackageInterface | undefined = vendorPackages.find((v: any) => v.id === packageId)

            if (packageFromUri) {
                setSelectedPackage(packageFromUri)
                document.getElementById(packageId)?.scrollIntoView()
                return
            }

            M24Notification.notifyError(
                t('message.titleFailed'),
                'Không tìm thấy gói. Vui lòng kiểm tra lại.',
                '',
                5
              );
        }
    }, [visible])

    React.useEffect(() => {
        setFormData({
            ...formData,
            packageCode: selectedPackage.code,
            vendor: selectedVendor.code,
            policyAccepted: true,
            paymentMethod: paymentMethod,
        });

        if (!visible) {
            return
        }
        
        const query: any = {
            tab,
            packageId: selectedPackage.id,
            vendorId: selectedVendor.id,
        }

        if (!selectedVendor.id) {
            delete query.vendorId
        }
        if(!selectedPackage.id) {
            delete query.packageId
        }
        setSearchParams(query)
    }, [selectedVendor, selectedPackage]);

    const handleNextStep = () => {
        setStep(prevState => prevState + 1);
    }
    const handlePrevStep = () => {
        setStep(prevState =>  prevState - 1);
    }

    const handleSelectPackage = (pk: ServicePackageInterface) => {
        setSelectedPackage(pk);
    }

    const handleSelectPayment = (value:any) => {
        setPaymentMethod(value)
    }

    const handleCreatDraftOrder = () => {
        setIsCreateDraft(true);
        userServiceRepository.creatDraftOrd(formData!).then((response)=> {
            handleNextStep();
            setOrderInformation(response);
        }).catch((error)=> {
            M24Notification.notifyError(t('message.titleFailed'),t('message.Internal Server Error'),'',2000)
        }).finally(()=>setIsCreateDraft(false))
    }

    const [provider,setProvider] = useState<string>('');
    const [providerAccount,setProviderAccount] = useState<string>('');
    const [selectedVal,setSelectedValue] = React.useState<string>('');

    const handleCreatePackageOrder = () => {
        let paymentInvoice: CreatePaymentInvoiceBody | undefined = undefined

        if (invoiceForm) {
            paymentInvoice = invoiceForm[RequestBillFormNames.billType] === BillTypeEnum.COMPANY
            ? {
                type: invoiceForm[RequestBillFormNames.billType],
                tax: invoiceForm[RequestBillFormNames.taxCode],
                name: invoiceForm[RequestBillFormNames.companyName],
                address: invoiceForm[RequestBillFormNames.companyAddress],
                email: invoiceForm[RequestBillFormNames.companyEmail],
            }
            : {
                type: invoiceForm[RequestBillFormNames.billType],
                name: invoiceForm[RequestBillFormNames.fullName],
                address: invoiceForm[RequestBillFormNames.address],
                email: invoiceForm[RequestBillFormNames.email],
                tax: '',
            };
        }

        let body = {
            drawOrder:orderInformation.code,
            remark:'',
            provider:'',
            providerAccount:'',
            paymentInvoice: paymentInvoice && {
                ...paymentInvoice,
                order: orderInformation.code,
                owner: orderInformation.customer,
                draft: true
            },
        }
        if(selectedVal && selectedVal.length >=1) {
            body = {
                ...body,
                provider: selectedVal.split(',')[0],
                providerAccount: selectedVal.split(',')[1],
            }
        } else if(provider || providerAccount) {
            body = {
                ...body,
                provider: provider,
                providerAccount: providerAccount
            }
        }
        setIsCreateOrder(true);
        userServiceRepository.createPackageOrd(body).then(() => {
            handleNextStep();
        }).catch((error)=> {
            M24Notification.notifyError(t('message.titleFailed'),t('purchasePopup.creat-order-failed'),'',2000)
        }).then(()=> setIsCreateOrder(false));
    }

    const handleCancelPurchase = () => {
        setSelectedPackage(ServicePackageModel);
        setStep(0);
        setProvider('');
        setProviderAccount('');
        setSelectedValue('')
        onCancel();
        setSearchParams({tab})
    }

    const renderPopUpTitle = () => {
        if (step < 1)
           return (<span
                className={'width100pc dpl-il txt-capitalize robotomedium txt-color-black'}>{t('profile.vendor-packages', {vendor: selectedVendor.name})}</span>);
        else
            return (<span className={'txt-color-black robotomedium txt-capitalize'}>{t('profile.vendor-packages-purchase', {
                    vendor: selectedVendor!.name,
                    package: selectedPackage!.name,
                })}</span>
            );

    }

    const renderStepsComponent = (step:number) => {
        if(step === 0)
                return(
                    <VendorPackages
                        vendorPackages={vendorPackages}
                        selectedVendor={selectedVendor}
                        selectedPackage={selectedPackage}
                        handleSelectPackage={handleSelectPackage}
                        isLoading={isLoading}
                        handleNextStep={handleNextStep}
                        onCancel={handleCancelPurchase}
                        checked={checked}
                        handleChecked={(val) => handleChecked(val)}
                    />
                    );
        else if(step === 1)
                return (
                    <PaymentMethods
                        handleCreatDraft={handleCreatDraftOrder}
                        isCreateDraft={isCreateDraft}
                        paymentSelected={paymentMethod}
                        handleSelectedPayment={handleSelectPayment}
                        handlePrevStep={handlePrevStep}/>
                );
        else if (step === 2 || step === 3)
                return (
                    <BankTransferStep
                        orderInformation={orderInformation}
                        selectedVendor={selectedVendor}
                        selectedPackage={selectedPackage}
                        formData={formData}
                        handleCreateOrder={handleCreatePackageOrder}
                        step={step}
                        handlePrevStep={handlePrevStep}
                        handleNextStep={handleNextStep}
                        isCreateOrder={isCreateOrder}
                        setProvider={setProvider}
                        setProviderAccount={setProviderAccount}
                        provider={provider}
                        providerAccount={providerAccount}
                        setSelectedValue={setSelectedValue}
                        selectedVal={selectedVal}
                        showRequestBillModal={() => {
                            setIsShowRequestBillModal(true)
                        }}
                    />
                );
        else {
            return (
                <SuccessPopup
                    selectedVendor={selectedVendor}
                    selectedPackage={selectedPackage}
                    handleCancel={handleCancelPurchase}
                />
            )
        }
    }

    const handleClickStep = (value:number) => {
        if(step >= value && step <= 3) {
            setStep(value)
        }
    }

    useEffect(() => {
        if (step !== 1 && step !== 2 && step !== 3) {
            setInvoiceForm(undefined)
        }
    }, [step])


    useEffect(() => {
       
 
     },[])

 
    return(
        <>
      
            <Modal
                title={renderPopUpTitle()}
                className={`package-modal ${isShowRequestBillModal ? 'd-none' : ''}`}
                width={1100}
                open={visible}
                onCancel={handleCancelPurchase}
                closeIcon={<i className="fa-solid fa-xmark txt-color-black"/>}
                footer={null}
                centered
            >
                <div className={'purchase-step-header'}>
                    <Steps current={step} onChange={handleClickStep}>
                        <Steps.Step title={t('purchasePopup.select-package-step')}/>
                        <Steps.Step title={t('purchasePopup.select-payment-step')} />
                        <Steps.Step title={t('purchasePopup.payment-info-step')} />
                        <Steps.Step title={t('purchasePopup.payment-auth-step')} />
                        <Steps.Step title={t('purchasePopup.purchase-finish-step')}/>
                    </Steps>
                </div>
                <div>
                    {renderStepsComponent(step)}
                </div>
            </Modal>

            <ModalRequestInvoice
                isShowRequestBillModal={isShowRequestBillModal}
                closeModal={() => setIsShowRequestBillModal(false)}
                invoiceForm={invoiceForm}
                setInvoiceForm={setInvoiceForm}
            />
    </>
    );
}

export default PurchasePopUp;
