import { Avatar, Button, Divider, Modal, Tooltip } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { formatMoneyByUnit } from '../../../../core/helpers/string'
import { ProviderInterface } from '../../../../Interface/AccountBalanceInterface'
import MarketPalaceInterface from '../../../../Interface/MarketPalaceInterface'
import ShipmentInterface from '../../../../Interface/ShipmentInterface'
import ShipmentStatusInterface from '../../../../Interface/ShipmentStatusInterface'
import ShipmentImageDefault from '../../../../resources/images/shipment-ubox-default.png'
import Logs from './Logs'

interface HeadingProps {
	shipment?: ShipmentInterface
	statuses: ShipmentStatusInterface[]
	marketPalaces: MarketPalaceInterface[]
	providers: ProviderInterface[]
	canceling: boolean
	currencyMarketPalace?: string
	cancelable: boolean
	cancelShipment: () => void
	loading: boolean
}
const Heading: React.FC<HeadingProps> = (props) => {
	const { shipment, statuses, marketPalaces, providers, cancelShipment, canceling, currencyMarketPalace, cancelable, loading } =
		props
	const [currency, setCurrency] = useState('')
	const [statusName, setStatusName] = useState<string | undefined>('')
	const [marketPalace, setMarketPalace] = useState<MarketPalaceInterface>()
	const [provider, setProvider] = useState<ProviderInterface>()
	const [statusColor, setStatusColor] = useState('')
	const [visibleLogModal, setVisibleLogModal] = useState(false)
	const [metadata, setMetadata] = useState<any>()

	useEffect(() => {
		const status = statuses.find((stt: ShipmentStatusInterface) => stt.code === shipment?.status)
		setStatusName(status?.name)
		setStatusColor(status?.color ? status.color : '#0082e6')
		const marketPalace = marketPalaces.find(
			(marketPalace: MarketPalaceInterface) => marketPalace.code === shipment?.marketplace
		)
		setMarketPalace(marketPalace)
		const provider = providers.find((prod: ProviderInterface) => prod.code === shipment?.provider)
		setProvider(provider)
		const currency = provider?.currency
		if (currency) {
			setCurrency(JSON.parse(currency).code)
		}
	}, [marketPalaces, providers, shipment?.marketplace, shipment?.provider, shipment?.status, statuses])

	const cancelShipmentHandler = () => {
			Modal.confirm({
				title: t('shipment.confirmTitleCancel'),
				closeIcon: <i className='fa-solid fa-xmark'></i>,
				icon: null,
				centered: true,
				maskClosable: true,
				content: t('shipment.confirmCancelContent'),
				cancelButtonProps: {
					className: 'btn-df btn-df-tertiary-bg-white flex align-items-center',
					icon: <i className='fa-solid fa-xmark mg-r-6'></i>,
				},
				okButtonProps: { className: 'btn-df btn-df-quaternary-bg-red' },
				className: 'shipment-confirm-delete',
				autoFocusButton: null,
				onOk: cancelShipment,
				cancelText: t('shipment.cancelText'),
				okText: <span>{t('shipment.okText')}</span>,
				closable: true,
			})
	}

	return (
		<>
			<div className='shipment-heading'>
				<div className='shipment-heading__left'>
					<div className='info'>
						{!loading && (
							<Avatar
								className='bd-none-impt'
								size={48}
								shape='square'
								src={shipment?.image ? shipment?.image : ShipmentImageDefault}></Avatar>
						)}
						{loading && <Skeleton width={48} height={48} className='mg-r-6' />}
						<div className='info-text'>
							<div className='info-text__code-status'>
								{!loading ? (
									<span className='status-shipment mg-bt-2' style={{ backgroundColor: statusColor }}>
										{statusName}
									</span>
								) : (
									<Skeleton className='status-shipment mg-bt-2' width={70} height={22} />
								)}
								{!loading ? (
									<Paragraph
										className='robotomedium mg-0 txt-color-primary fsz-16 robotoregular line-h-24 order-heading'
										copyable={{
											text: shipment?.code,
											icon: [
												<Tooltip
													title={
														<span className='robotoregular fsz-12 txt-color-black line-h-20'>
															Copy
														</span>
													}
													color='#FBB739'>
													<i className='far fa-copy line-h-14 w-12' />
												</Tooltip>,
												<Tooltip
													title={
														<span className='robotoregular fsz-12 txt-color-black line-h-20'>
															Copied
														</span>
													}
													color='#FBB739'>
													<i className='far fa-check fsz-12 line-h-14 w-12' />
												</Tooltip>,
											],
											tooltips: [false, false],
										}}>
										{`#${shipment?.code}`}
									</Paragraph>
								) : (
									<Skeleton width={100} height={22} />
								)}
							</div>
						</div>
					</div>

					<div className='finance'>
						<div className='finance-item'>
							{!loading ? (
								<>
									<span className='finance-item__label'>{`${t('shipments.financeTotalFees')}:`}</span>
									<span className='finance-item__value'>{formatMoneyByUnit(shipment?.totalFee, currency)}</span>
								</>
							) : (
								<>
									<Skeleton width={70} height={22} className='mg-r-4' />
									<Skeleton width={80} height={22} />
								</>
							)}
						</div>
						<div className='finance-item'>
							{!loading ? (
								<>
									<span className='finance-item__label'>{`${t('shipment.totalCashOrder')}:`}</span>
									<span className='finance-item__value'>
										{formatMoneyByUnit(shipment?.totalValue, currencyMarketPalace)}
									</span>
								</>
							) : (
								<>
									<Skeleton width={80} height={22} className='mg-r-4' />
									<Skeleton width={80} height={22} />
								</>
							)}
						</div>
					</div>
					<div className='robotoregular txt-color-black fsz-14 line-h-22 finance'>
						{!loading ? (
							<div className='finance-item'>
								<Avatar
									className='bd-none-impt mg-r-4'
									size={12}
									shape='circle'
									src={provider?.logo ? provider.logo : ''}
								/>
								<span className='mg-r-4'>{provider?.code}</span>
								<Divider type='vertical' className='border-divider-vertical' />
								<span>{shipment?.providerUsername}</span>
							</div>
						) : (
							<div className='flex align-items-center'>
								<Skeleton width={12} height={12} circle className='mg-r-4' />
								<Skeleton width={50} height={22} className='mg-r-4' />
								<Skeleton width={1} height={22} className='mg-r-4' />
								<Skeleton width={50} height={22} className='mg-r-4' />
							</div>
						)}

						<div
							className='item-link finance-item cursor-pointer'
							onClick={() => {
								setVisibleLogModal(true)
								setMetadata({ page: 0, size: 15 })
							}}>
							{!loading ? (
								<i className='far fa-file-alt fsz-11 line-h-12 item-link mg-r-4' />
							) : (
								<Skeleton width={14} height={14} className='mg-r-4' />
							)}
							<span>{!loading ? t('orderDetail.log') : <Skeleton width={113} height={20} />}</span>
						</div>
					</div>
				</div>

				<div className='shipment-heading__right'>
					{cancelable &&
						(!loading ? (
							<Button
								className='btn-df btn-df-quaternary-bg-red'
								loading={canceling}
								onClick={cancelShipmentHandler}>
								{t('shipment.cancelOrder')}
							</Button>
						) : (
							<Skeleton width={120} />
						))}
				</div>
			</div>
			<Logs visible={visibleLogModal} visibleHandler={(val: boolean) => setVisibleLogModal(val)} currency={currency} metadata={metadata} handleMetadata={(obj: any) => setMetadata(obj)}/>
		</>
	)
}

export default Heading

