import React, { useEffect, useState } from "react";
import MainLayout from "../../components/Layout";
import {useTranslation} from "react-i18next";
import {Col, Result, Row} from "antd";
import UserInformation from "../../components/Profile/UserInformation";
import Service from "../../components/Profile/Service/index";
import {Link, useSearchParams} from "react-router-dom";
import PaymentList from "../../components/Profile/PaymentList";
import lodash from "lodash";
import {localStorageRead} from "../../utils/LocalStorageUtils";
import {TENANT_KEY} from "../../core/config";
import { TenantUpdateConfigCommand } from "../../Interface/TenantUpdateConfigCommand";
import { tenantRepository } from "../../repositories/TenantRepository";


const PACKAGE_MENU_ITEMS = [
    {code: 'services', name: 'profile.services', display: true},
    {code: 'payments', name: 'profile.payments', display: true},
]


function Profile() {
    const {t} = useTranslation();
    const [showProgressBar, setShowProgressBar] = React.useState<boolean>(false);
    const [currentMenu, setCurrentMenu] = React.useState<string>('info');
    const [searchParams, setSearchParams] = useSearchParams();

    const packagePurchaseConfig = lodash.get(localStorageRead(TENANT_KEY), 'config.enablePackageSell')
    const [currentConfigServices, setCurrentConfigServices] = useState<TenantUpdateConfigCommand>()


    React.useEffect(() => {
        setShowProgressBar(true);
        let tab = searchParams.get('tab');
        PACKAGE_MENU_ITEMS.forEach((item) => {
            if (tab?.localeCompare(item.code) === 0) {
                setCurrentMenu(tab);
            }
        })
        setShowProgressBar(false)
    }, [searchParams,])

    const renderBody = () => {
        if (currentMenu === 'info' && currentConfigServices?.enableGeneral) {
            return <UserInformation/>
        } else if (currentMenu === 'services' && packagePurchaseConfig && currentConfigServices?.enableGsa) {
            return <Service searchParams={searchParams} setSearchParams={setSearchParams} />
        } else if (currentMenu === 'payments' && packagePurchaseConfig && currentConfigServices?.enableGsa) {
            return <PaymentList/>
        } else
            return <Result
                status="404"
                title="404"
                subTitle={t('page.notFound')}
                extra={<Link to={'/'}>{t('page.backToHomePage')}</Link>}
            />
    }


	useEffect(() => {
			const tenantInfo = localStorageRead(TENANT_KEY)
            if(tenantInfo){
                const { generalConfig, peerPaymentConfig, shipmentConfig, gsaConfig, orderConfig } = tenantInfo.config
                setCurrentConfigServices((prevState: any) => ({
                    ...prevState,
                    enableShipment: shipmentConfig?.enable,
                    enableOrder: orderConfig?.enable,
                    enableGeneral: generalConfig?.enable,
                    enablePeerPayment: peerPaymentConfig?.enable,
                    enableGsa: gsaConfig?.enable,
                }))
            }
			
	}, [])


    return (
        <MainLayout title={t('menu.profile')} showProgressBar={showProgressBar}>
            <Row className={'profile-container'}>
                <Col span={4} className={'menu-item'}>
                    {currentConfigServices?.enableGeneral && <div className={`item ${currentMenu === 'info' ? `__current` : ``}`} key={'info'}
                         onClick={() => {
                             setCurrentMenu('info');
                             setSearchParams({tab: 'info'})
                         }}
                    >
                        <span className={'txt-size-h5 robotomedium txt-capitalize'}>{t('profile.user-info')}</span>
                    </div>}
                    {PACKAGE_MENU_ITEMS.map((m) => {
                        const  displayFromTenantConfig = m.code === 'services' || m.code ==='payments' ? currentConfigServices?.enableGsa :  currentConfigServices?.enableGeneral
                        if(m.display && m.display === packagePurchaseConfig && displayFromTenantConfig) {
                            return <div className={`item ${currentMenu === m.code ? `__current` : ``}`} key={m.code}
                                        onClick={() => {
                                            setCurrentMenu(m.code);
                                            setSearchParams({tab: m.code})
                                        }}
                            >
                                <span className={'txt-size-h5 robotomedium txt-capitalize'}>{t(m.name)}</span>
                            </div>
                        }
                        else return null;
                    })}
                </Col>
                <Col span={20} className={'content-container'}>
                    {renderBody()}
                </Col>
            </Row>
        </MainLayout>
    )
}

export default Profile;
