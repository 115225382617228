import { PlusOutlined } from '@ant-design/icons'
import React from 'react'
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
interface HeadingProps {
  className: string
  loading: boolean
}

const Heading:React.FC<HeadingProps> = ({className, loading}) => {
  const {t} = useTranslation();
  return (
    <div className={`dpl-flex align-items-center justify-content-between ${className ? className : ''}`}>
        <h5 className="robotomedium txt-size-h5 mg-0 line-h-24">{!loading ? t('orderDetail.finance') : <Skeleton width={150} height={22} />}</h5>
    </div>
  )
}

export default Heading
