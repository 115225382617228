import { Table } from 'antd'
import lodash, { isArray } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import { formatNumber } from '../../../core/helpers/string'

interface NestedData {
	loading: any
	items: any
	record: any
}

const NestedTable: React.FC<NestedData> = ({ loading, items, record }) => {
	const { t } = useTranslation()

	const classes = 'robotoregular fsz-14 line-h-22 txt-color-black'
	const columns = [
		{
			title: <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('requestDeliveryPackage.orderCode')}</span>,
			key: 'orderCode',
			width: '200px',
			render: (record: any) => <span className={`${classes} txt-color-orange2`}>{lodash.get(record, 'order.code')}</span>,
		},
		{
			title: <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('requestDeliveryPackage.code')}</span>,
			key: 'code',
			width: '30%',
			render: (record: any) => {
				const codes = record.package.code
				return isArray(codes) && codes.length > 0 ? (
					<div className='flex flex-col'>
						{codes.map((code: string) => (
							<span className={`${classes}`}>{code}</span>
						))}
					</div>
				) : (
					<span className={classes}>{codes}</span>
				)
			},
		},

		{
			title: <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('requestDeliveryPackage.weight')}</span>,
			key: 'weight',
			width: '30%',
			align: 'right' as 'right',
			render: (record: any) => (
				<span className={classes}>{`${formatNumber(lodash.get(record, 'package.weight_net'))}kg`}</span>
			),
		},
	]
	const data = items && items.length > 0 ? items : []
	return (
		<Table
			locale={{ emptyText: <EmptyDataFilter description={t('packages.noData')} /> }}
			loading={loading[record.code]}
			rowKey={(record: any) => record.package.id}
			dataSource={data}
			columns={columns}
			pagination={false}
			className='main-table__nested nested main-table__nested-pink'
		/>
	)
}

export default NestedTable
