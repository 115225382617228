import { Avatar, Divider, Image, Row, Steps, Table, Tooltip } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../components/Empty/EmptyDataFilter'
import M24Pagination from '../../components/Pagination/M24Pagination'
import { SkeletonTable, SkeletonTableColumnsType } from '../../components/SkeletonTable'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatNumber } from '../../core/helpers/string'
import { packageRepository } from '../../repositories/PackagesRepository'
import { getCleanFormFilter } from '../../utils/StringUtils'
const { Step } = Steps
interface ListProps {
	filter: any
	packages: any
	statuses: any
	accounts: any
	total: number
	page: number
	size: number
	idAcc: string
	showProgressBar: boolean
	handleFilter: (filter: any) => void
}
const List: React.FC<ListProps> = (props) => {
	const [searchParams] = useSearchParams()
	const { t } = useTranslation()
	const { packages, statuses, handleFilter, accounts, total, page, size, showProgressBar, idAcc, filter } = props
	const [expandedRows, setExpandedRows] = useState<Array<any>>([])
	const [history, setHistory] = useState<{ [id: string]: any }>({})
	const [loading, setLoading] = useState<{ [id: string]: any }>({})
	const [logo, setLogo] = useState('')
	const [provider, setProvider] = useState('')
	const [customer, setCustomer] = useState('')
	const [loadingState, setLoadingState] = useState(true)

	useEffect(() => {
		setTimeout(() => setLoadingState(false), 2000)
	})
	const emptyText = '---'
	useEffect(() => {
		const indexAcc = accounts.findIndex((acc: any) => acc.id === idAcc)
		if (indexAcc > -1 && !showProgressBar) {
			const logo = lodash.get(accounts[indexAcc], 'provider.logo', '')
			const provider = lodash.get(accounts[indexAcc], 'provider.code', '')
			const customer = lodash.get(accounts[indexAcc], 'name', '')
			setLogo(logo)
			setProvider(provider)
			setCustomer(customer)
		}
	}, [accounts, idAcc, showProgressBar])
	const columns = [
		{
			title:t('packages.packageCode'),
			key: 'code',
			dataIndex: 'code',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			width: 200,
			render: (code: string) => (
				<div className='dpl-flex align-items-center'>
					{!showProgressBar ? (
						<i
							className={`far fa-angle-${
								lodash.includes(expandedRows, code) ? 'down' : 'right'
							} txt-color-orange2 w-8 line-h-22 cursor-pointer mg-r-6`}></i>
					) : (
						<Skeleton width={12} height={22} />
					)}
					<Paragraph
						className='robotomedium mg-0 txt-color-orange2 fsz-14 line-h-22 order-heading whitespace'
						copyable={{
							text: code,
							icon: [
								<Tooltip
									title={<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copy</span>}
									color='#FBB739'>
									<i className='far fa-copy line-h-14 w-12 txt-color-orange2'></i>
								</Tooltip>,
								<Tooltip
									title={<span className='robotoregular fsz-12 txt-color-black line-h-20 txt-color-orange2'>Copied</span>}
									color='#FBB739'>
									<i className='far fa-check fsz-12 line-h-14 w-12 txt-color-orange2'></i>
								</Tooltip>,
							],
							tooltips: [false, false],
						}}>
						{code}
					</Paragraph>
				</div>
			),
		},
		{
			title:  <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('packages.orderCode')}</span>,
			key: 'orderCode',
			dataIndex: 'orderCode',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			width: 180,
			render: (orderCode: any) => (
				<Paragraph
					className='robotomedium mg-0 txt-color-black fsz-14 line-h-24 order-heading whitespace'
					copyable={{
						text: orderCode,
						icon: [
							<Tooltip
								title={<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copy</span>}
								color='#FBB739'>
								<i className='far fa-copy line-h-14 w-12 txt-color-orange2'></i>
							</Tooltip>,
							<Tooltip
								title={<span className='robotoregular fsz-12 txt-color-black line-h-20 '>Copied</span>}
								color='#FBB739'>
								<i className='far fa-check fsz-12 line-h-14 w-12 txt-color-orange2'></i>
							</Tooltip>,
						],
						tooltips: [false, false],
					}}>
					{orderCode}
				</Paragraph>
			),
		},
		{
			title:  <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('packages.supplierAcc')}</span>,
			key: 'supplierAcc',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			width: 180,
			render: (record: any) => {
				return (
					<div className='dpl-flex align-items-center whitespace'>
						<Avatar size={16} src={logo} className='bd-none-impt w-12 h-12 dpl-flex align-items-center mg-r-4' />
						<span className={'robotoregular fsz-14 line-h-22 txt-color-black mg-r-4'}>{provider}</span>
						<Divider type={'vertical'} className={'bd-color-black line-h-22 mg-0 mg-r-4'} />
						<span className={'robotoregular fsz-14 line-h-22 txt-color-black'}>{customer}</span>
					</div>
				)
			},
		},
		{
			title:  <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('packages.volume')}</span>,
			key: 'volumetric',
			dataIndex: 'volumetric',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			width: 180,
			align: 'right' as 'right',
			render: (volumetric: any) => (
				<span className='robotoregular fsz-14 line-h-22 whitespace'>
					{volumetric ? `${formatNumber(volumetric)}cm3` : t('orderDetail.undefined')}
				</span>
			),
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('packages.details')}</span>,
			key: 'details',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			width: 180,
			align: 'right' as 'right',
			render: (record: any) => {
				const length = lodash.isNull(lodash.get(record, 'length')) ? '---' : lodash.get(record, 'length', emptyText)
				const width = lodash.isNull(lodash.get(record, 'width')) ? emptyText : lodash.get(record, 'width', emptyText)
				const height = lodash.isNull(lodash.get(record, 'height')) ? emptyText : lodash.get(record, 'height', emptyText)
				return (
					<span className='robotoregular txt-color-black fsz-14 line-h-22 whitespace'>
						{`${formatNumber(length)} x ${formatNumber(width)} x ${formatNumber(height)} (cm)`}
					</span>
				)
			},
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('packages.weight')}</span>,
			key: 'weight',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			dataIndex: 'actualWeight',
			width: 180,
			align: 'right' as 'right',
			render: (actualWeight: string) => (
				<span className='robotoregular txt-color-black fsz-14 line-h-22 whitespace'>
					{`${formatNumber(actualWeight)}kg`}
				</span>
			),
		},

		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('packages.status')}</span>,
			key: 'status',
			dataIndex: 'status',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			width: 180,
			render: (status: any, record: any) => {
				const statusObj = statuses.find(({ code }: any) => code === status)
				const statusName = lodash.isNull(lodash.get(statusObj, 'name'))
					? emptyText
					: lodash.get(statusObj, 'name', emptyText)
				return (
					<span
						className='robotoregular fsz-12 line-h-20 txt-color-white border-radius25 py-3 px-12 whitespace txt-capitalize'
						style={{
							backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
								? '#09B2AA'
								: lodash.get(statusObj, 'color'),
						}}>
						{statusName}
					</span>
				)
			},
		},
	]

	const handleChangePage = (filter: any) => {
		const { page, size } = filter
		handleFilter(
			getCleanFormFilter(
				{
					...filter,
					page: (page - 1).toString(),
					size: size.toString(),
				},
				[],
				['pageSize']
			)
		)
	}

	const CustomDescription = ({ code, idxHistory }: { code: string; idxHistory: string }) => {
		const historyTrackByPosition = history[idxHistory].filter(({ status }: any) => status === code)
		const classes = 'description robotoregular dpl-flex flex-col align-items-start justify-content-start fsz-12 line-h-20'
		return (
			<span className={`${classes}`}>
				{historyTrackByPosition.length > 0 ? (
					historyTrackByPosition.map(({ timestamp, handlingTime }: any, idx: any) => {
						const timestampRendered = lodash.isNull(timestamp)
							? t('orderDetail.undefined')
							: formatDateTime(timestamp)
						const handlingTimeRendered = lodash.isNull(handlingTime)
							? t('orderDetail.undefined')
							: `${handlingTime} ngày`
						return (
							<span className={`${classes}  mg-bt-4`} key={idx}>
								<span className='txt-color-gray4'>{timestampRendered}</span>
								<span className='txt-color-gray4'>{`(${handlingTimeRendered})`}</span>
							</span>
						)
					})
				) : (
					<span className='txt-color-gray4'>{`${t('orderDetail.undefined')}`}</span>
				)}
			</span>
		)
	}

	const customDot = (dot: any, { status, index }: any) => {
		return status !== 'finish' ? <i className='far fa-dot-circle fsz-10'></i> : <i className='far fa-dot-circle fsz-10'></i>
	}

	return (
		<div className='white-box dpl-flex flex-col pd-imp-12'>
			<div className='dpl-flex align-items-center mg-bt-13'>
				<h3 className='robotomeidum mg-0 fsz-14 line-h-22 txt-color-black mg-r-4'>{t('packages.list')}</h3>
				<span
					className={`${
						total > 0 ? 'bg-color-primary txt-color-white' : 'bg-color-gray-badge txt-color-gray4'
					} border-radius20  fsz-12 line-h-20 px-8`}>
					{total}
				</span>
			</div>
			<SkeletonTable
				loading={loadingState || showProgressBar}
				rowCount={packages?.length}
				columns={columns as SkeletonTableColumnsType[]}
				className='main-table table-expandable selected-table mg-bt-13'>
				<Table
					rowKey={(record: any) => record.code}
					columns={columns}
					dataSource={packages}
					locale={{
						emptyText: (
							<EmptyDataFilter
								description={
									!searchParams.get('query') &&
									!searchParams.get('query') &&
									!searchParams.get('cutOffStatus') &&
									!searchParams.get('handlingTimeFrom') &&
									!searchParams.get('handlingTimeTo') &&
									!searchParams.get('typeSearch') &&
									!searchParams.get('statuses')
										? t('packages.noData')
										: t('packages.filterEmpty')
								}
							/>
						),
					}}
					expandRowByClick={true}
					pagination={false}
					scroll={{ x: 'max-content' }}
					className='main-table table-expandable selected-table mg-bt-13'
					loading={showProgressBar}
					expandable={{
						expandedRowRender: (record) => {
							const codes = lodash.uniq(lodash.map(history[record.code], 'status'))
							let positions: Number[] = []
							for (let index = 0; index < codes.length; index++) {
								const status = statuses.find(({ code }: any) => code === codes[index])
								positions.push(status.position)
							}
							const positionMax = lodash.max(positions)
							return (
								<div key={record.code} className='package-timeline'>
									<Steps progressDot={customDot}>
										{history[record.code] &&
											history[record.code].length > 0 &&
											statuses.map(({ name, code, id, position }: any) => {
												const indexOfStatus = history[record.code].findIndex(
													({ status }: any) => code === status
												)
												const status = indexOfStatus > -1 ? 'finish' : 'wait'
												const txtClasses = status === 'finish' ? 'txt-color-black' : 'txt-color-gray2'
												return (
													<Step
														status={status}
														key={id}
														className={
															position > positionMax!
																? 'tail-none'
																: position === positionMax
																? 'tail-none last-tail'
																: 'tail'
														}
														title={
															<span
																className={`txt-left robotomedium fsz-12 line-h-20 ${txtClasses}`}>
																{name}
															</span>
														}
														description={<CustomDescription code={code} idxHistory={record.code} />}
													/>
												)
											})}
									</Steps>
								</div>
							)
						},
						expandIcon: ({ expanded, onExpand, record }) => null,
						columnWidth: 0,
						onExpand: (expanded, record) => {
							if (expanded && !history[record.code]) {
								if (record.isShipment) {
									setLoading({ ...loading, [record.code]: true })
									packageRepository
										.getPackageHistoryOfShipment(
											lodash.get(record, 'code'),
											searchParams.get('customer'),
											searchParams.get('provider')
										)
										.then((res) => setHistory({ ...history, [record.code]: res }))
										.catch((err) => {})
										.finally(() => setLoading({ ...loading, [record.code]: false }))
								} else {
									setLoading({ ...loading, [record.code]: true })
									packageRepository
										.getPackageHistory(
											lodash.get(record, 'code'),
											searchParams.get('customer'),
											searchParams.get('provider')
										)
										.then((res) => setHistory({ ...history, [record.code]: res }))
										.catch((err) => {})
										.finally(() => setLoading({ ...loading, [record.code]: false }))
								}
							}
						},
						onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
					}}
					rowClassName={(record) =>
						lodash.includes(expandedRows, record.code) ? 'active-row cursor-pointer' : 'data-row cursor-pointer'
					}
				/>
			</SkeletonTable>
			<Row justify={'end'} className={''}>
				{packages && packages.length > 0 && (
					<M24Pagination
						onPageChange={handleChangePage}
						filter={{ ...filter, page, size }}
						pagination={{
							current: page,
							pageSize: size,
							hideOnSinglePage: true,
							total,
						}}
						className='mg-0'
					/>
				)}
			</Row>
		</div>
	)
}

export default List

