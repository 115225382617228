import { isEmpty } from 'lodash';
import React, {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import { REDIRECT_AFTER_LOGIN_URL, TENANT_KEY } from '../../core/config';
import { localStorageRead, localStorageSave } from '../../utils/LocalStorageUtils';
import SecurityService from "../../utils/SecurityService";

export const AuthenticateRoute = (props: any) => {

    const navigate = useNavigate()
    const appContext = useContext(AppContext)
    useEffect(() => {
        if (!SecurityService.isLogged()) {
            if (!window.location.href.includes('/login') && isEmpty(appContext.tenant)) {
                localStorageSave(REDIRECT_AFTER_LOGIN_URL, window.location.href)
                navigate('/login')
            }
        }
    }, [appContext.tenant, navigate])

    return props.children
}
