import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Result} from "antd";
import {useSearchParams} from "react-router-dom";
import { connectMarketContext } from "../../context/ConnectMarketContext";


function ConnectMarketRedirectPage() {
    const {t} = useTranslation();
    const [authInfo, setAuthInfo] = useState<any>({});
    const [searchParams,] = useSearchParams();
    const {w} = useContext(connectMarketContext);

    useEffect(()=> {
        const autoClosePopup = setTimeout(()=> {
            w.close();
        },1500)
        return(()=> {
            clearTimeout(autoClosePopup);
        })
    })

    useEffect(() => {
        let authStatus = searchParams.get('status');
        let errorCode = searchParams.get('errorCode');
        let errorMessage = searchParams.get('errorMessage');
        let channel = searchParams.get('channel');
        let shopName = searchParams.get('shopName');

        if (authStatus) {
            setAuthInfo({
                status: authStatus,
                errorCode: errorCode,
                errorMessage: errorMessage,
                channel:channel,
                shopName:shopName,
            });
        }
    }, []);

    return(
        <div>

            {
                authInfo.status === '400' || authInfo.status === '404' ?
                    <Result
                        status="error"
                        title={t('connectMarket.auth-failed-title')}
                        subTitle={<span className={'robotoregular txt-size-h7'}>
                            { authInfo.errorCode ? t(`connectMarket.${authInfo.errorCode}`)
                            : t('connectMarket.auth-failed-subTitle')}</span>
                        }>
                    </Result>
                    :
                    <Result
                        status="success"
                        title={t('connectMarket.auth-success-title')}
                        subTitle={<span className={'robotoregular txt-size-h7'}>{t('connectMarket.auth-success-subTitle',{shopName:authInfo.shopName,channel:authInfo.channel})}</span>}
                    />
            }
        </div>
    );
}

export default ConnectMarketRedirectPage;
