import React, {useState} from 'react';
import MainLayout from "../../components/Layout";
import {Button, Card, Col, Row} from 'antd';
import {useTranslation} from "react-i18next";
import {ChromeOutlined, LinkOutlined, PlusOutlined} from '@ant-design/icons';
import './styles.scss';
import {useNavigate} from "react-router-dom";

function Dashboard() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [currentStep, setStep] = useState(0);
    const renderHeaderCard = () => {
        return <Row>
            <Col>
                <Row><span>{t('dashboard.intro1')}</span></Row>
                <span className={'txt-size-h7 txt-color-gray'}>{t('dashboard.intro2')}</span>
            </Col>
        </Row>
    }
    const renderContent=()=>{
        if(currentStep===0)
            return <div>
                <span className={`mgl5 txt-size-h5 robotobold`}>{t('dashboard.connect')}</span>
                <Row><span className={'txt-color-gray txt-size-h7'}>{t('dashboard.connectTenant')}</span></Row>
                <Button icon={ <LinkOutlined style={{fontSize: 15}}/>} className={'mgt20'} type={'primary'} ghost
                        onClick={()=> {
                            navigate(`/connect-supplier`)
                        }}
                >{t('dashboard.btConnect')}</Button>
            </div>
        else if(currentStep===1)
            return <div>
                <span className={`mgl5 txt-size-h5 robotobold`}>{t('dashboard.install')}</span>
                <ul>
                    <li><span>{t('dashboard.extension1')}</span></li>
                    <li><span>{t('dashboard.extension2')}</span></li>
                </ul>
                <Button onClick={()=> window.open(process.env.REACT_APP_CHROME_EXTENSION_URL,'_blank')} icon={ <ChromeOutlined style={{fontSize: 15}}/>} className={'mgt20'} type={'primary'} ghost>{t('dashboard.installNow')}</Button>
            </div>
        else
            return <div></div>
    }

    return (<MainLayout>
        {/* <span className={'txt-size-32'}>{t('dashboard.title')}</span>
        <Row> <span className={'txt-size-h7 txt-color-gray mgbt20'}>{t('dashboard.welcome')}</span></Row> */}
        <Card  bodyStyle={{paddingTop:0}} className={'mgt12 border-card-header'} title={renderHeaderCard()} bordered={false}>
            <Row>
                <Col lg={5} className={'border-right-1x bd-color-gray pdr30'}>
                    <Row onClick={()=>setStep(0)} className={`cursor-pointer dpl-flex align-items-center mgt10 pd10 ${currentStep===0?'item-selected':''}`}>
                        <div className={`bd1px ${currentStep===0?'bd-color-primary':'bd-color-gray'} border-radius4 pd5 align-items-center dpl-flex`}>
                            <LinkOutlined style={{fontSize: 15, color: currentStep===0?'#F59F32':'gray'}}/>
                        </div>
                        <span className={`mgl5 txt-size-h7 ${currentStep===0?'robotomedium txt-color-orange':'robotoregular'}`}>{t('dashboard.connect')}</span>
                    </Row>
                    <Row onClick={()=>setStep(1)}className={`cursor-pointer dpl-flex align-items-center mgt10 pd10 ${currentStep===1?'item-selected':''}`}>
                        <div className={`bd1px ${currentStep===1?'bd-color-primary':'bd-color-gray'} border-radius4 pd5 align-items-center dpl-flex`}>
                            <ChromeOutlined  style={{fontSize: 15, color: currentStep===1?'#F59F32':'gray'}}/>
                        </div>
                        <span className={`mgl5 txt-size-h7 ${currentStep===1?'robotomedium txt-color-orange':'robotoregular'}`}>{t('dashboard.install')}</span>
                    </Row>
                    <Row onClick={()=>setStep(2)} className={`cursor-pointer dpl-flex align-items-center mgt10 pd10 ${currentStep===2?'item-selected':''}`}>
                        <div className={`bd1px ${currentStep===2?'bd-color-primary':'bd-color-gray'} border-radius4 pd5 align-items-center dpl-flex`}>
                            <PlusOutlined  style={{fontSize: 15, color: currentStep===2?'#F59F32':'gray'}}/>
                        </div>
                        <span  className={`mgl5 txt-size-h7 ${currentStep===2?'robotomedium txt-color-orange':'robotoregular'}`}>{t('dashboard.addProduct')}</span>
                    </Row>
                </Col>
                <Col lg={19} className={'pdl20 pdt20'}>
                    {renderContent()}
                </Col>
            </Row>
        </Card>
    </MainLayout>);
}

export default Dashboard;
