import { GetComponentProps } from 'rc-table/lib/interface'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Services from '../../../components/Services'

interface RowExtendedProps extends GetComponentProps<any> {
	services: any
    index: number
    className: string
	rowClassesByIdx: string
	children: any
}
const RowExtended: React.FC<RowExtendedProps> = (record) => {
	const { t } = useTranslation()
    // const rowClassesByIdx = record.index % 2 === 0 ? 'row-dark' : 'row-light'
	return (
		<>
			<tr className={`${record.rowClassesByIdx}  data-row cursor-pointer ant-table-row`}>{record.children}</tr>
			{record?.services?.length && <tr className={`${record.rowClassesByIdx}  data-row cursor-pointer ant-table-row data-row_extended`}>
				<td colSpan={6} className='pd-12 px-16 row-extended' >
					<div className='flex align-items-center'>
						<span className={'fsz-14 line-h-22 txt-color-secondary mgr3 whitespace'}>{t('orders.services')}:</span>
						<Services
							data={record.services}
							className='fsz-14 line-h-22'
							dividerClass='service-divider-black'
							wrapperClass='flex-nowrap'
						/>
					</div>
				</td>
			</tr>}
			<tr className='h-12'></tr>
		</>
	)
}

export default RowExtended

