import { TOKEN_KEY } from './../core/config/consts'
/**
 * save object to local storage
 * @param key
 * @param data
 */
export function localStorageSave(key: string, data: any) {
	try {
		localStorage.setItem(key, data ? JSON.stringify(data) : data)
		if (key === TOKEN_KEY) {
			// @ts-ignore
			localStorage.setItem(key, data ? JSON.stringify(data) : data, { sameSite: 'strict', secure: true })
		}
	} catch (e) {
		console.log('Cannot save data to local storage', e)
	}
}

/**
 * read localStorage
 * @param key
 * @returns {any}
 */
export function localStorageRead(key: string) {
	if (localStorage == null || typeof key !== 'string') {
		throw new Error('fail to read object to localStorage')
	}

	let result = localStorage.getItem(key)
	if (result) return JSON.parse(result)
	else return ''
}

/**
 * delete localStorage
 * @param key
 */
export function localStorageRemoveItem(key: string) {
	try {
		localStorage.removeItem(key)
	} catch (err) {
		console.log('cannot remove localStorage item', err)
	}
}

