import { Spin } from 'antd'
import lodash, { isEmpty } from 'lodash'
import { useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'
import { TENANT_KEY } from '../../core/config'
import { tenantRepository } from '../../repositories/TenantRepository'
import { localStorageRead, localStorageRemoveItem, localStorageSave } from '../../utils/LocalStorageUtils'

export const Login = () => {
	const appContext = useContext(AppContext)
	useEffect(() => {
		const tenant = appContext.tenant
		if (!isEmpty(tenant)) {
			const timeoutObj = setTimeout(() => {
                let authUrl = process.env.REACT_APP_LOGIN_URL + '/' + tenant?.code + '?redirect_uri=' + window.location.origin + '/authentication';
                if (lodash.get(tenant, 'config.oauthConfig.redirectLogInEndpoint')) {
                    authUrl = tenant?.config.oauthConfig.redirectLogInEndpoint!
                }
                // @ts-ignore
                window.location.href = authUrl;
            }, 1000);
            return () => {
                clearTimeout(timeoutObj)
            }
		} else {
			tenantRepository.getCurrent().then((response) => {
				if (localStorageRead(TENANT_KEY)) {
					localStorageRemoveItem(TENANT_KEY)
				}
				localStorageSave(TENANT_KEY, response)
				let authUrl = process.env.REACT_APP_LOGIN_URL + '/' + response.code + '?redirect_uri=' + window.location.origin + '/authentication'
				if (lodash.get(response, 'config.oauthConfig.redirectLogInEndpoint')) {
					authUrl = lodash.get(response, 'config.oauthConfig.redirectLogInEndpoint')
				}
				window.location.href = authUrl
				appContext.tenant = response
			})
		}
	}, [appContext, appContext.tenant])

	return (
		<div className={'pd20'}>
			Đang chuyển trang, vui lòng đợi một chút.... <Spin />
		</div>
	)
}

