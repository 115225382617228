import { Button, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { NoticeItemInterface } from '../../../Interface/NoticeItemInterface'
import { EmptyNotice } from './EmptyNotice'
import { NoticeItem } from './NoticeItem'

interface Props {
	notices: NoticeItemInterface[]
	loading: boolean
	currentButton: string
	onFilter: (type: string) => void
	onMarkReadOne: (notice: NoticeItemInterface) => void
	onMarkReadAll: () => void
}

const TabContent = (props: Props) => {
	const { t } = useTranslation()

	const { notices, currentButton, onMarkReadAll, onMarkReadOne, loading } = props
	const handleFilter = (type: string) => {
		props.onFilter(type)
	}

	return (
		<div className='dpl-flex flex-col notification-content-box'>
			<div className='dpl-flex align-items-center justify-content-between mg-bt-12'>
				<div className='notice-context-buttons'>
					<Button
						onClick={() => handleFilter('all')}
						className={`btn-notification-filter ${currentButton === 'all' ? 'active' : ''}`}
						size='small'>
						{t('notification.all')}
					</Button>
					<Button
						onClick={() => handleFilter('unread')}
						className={`btn-notification-filter filter-unread ${currentButton === 'unread' ? 'active' : ''}`}
						size='small'>
						{t('notification.unread')}
					</Button>
				</div>
			</div>
			{loading ? (
				<div className='spin-container'>
					<Spin />
				</div>
			) : notices.length > 0 ? (
				<div className='notification-list'>
					{notices.map((item) => (
						<NoticeItem key={item.id} item={item} onMarkRead={onMarkReadOne} />
					))}
				</div>
			) : (
				<EmptyNotice />
			)}
		</div>
	)
}

export default TabContent

