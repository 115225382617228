import React, { useState } from "react";
import {Button, ConfigProvider, Divider, Empty, Spin, Table, Tooltip} from "antd";
import Empty4 from "../../resources/images/empty-4.png";
import {useTranslation} from "react-i18next";
import M24Pagination from "../Pagination/M24Pagination";
import {useSearchParams} from "react-router-dom";
import {userServiceRepository} from "../../repositories/UserServiceRepository";
import lodash, {parseInt} from "lodash";
import M24Notification from "../../utils/M24Notification";
import {formatMoneyByUnit} from "../../core/helpers/string";
import moment from "moment";
import { PackageStatusEnum, PackageOrderInterface, RequestBillStatus, InvoiceData, PaymentInvoiceInterface } from "../../Interface/PaymentInterface";
import { ModalRequestBill } from "./ModalRequestBill";


const PACKAGE_ORDER_STATUS = [
    {code: 'PENDING', name: 'profile.package-order-pending', color: '#FBB739'},
    {code: 'PAID', name: 'profile.package-order-paid', color: '#09B2AA'},
    {code: 'REJECTED', name: 'profile.package-order-rejected', color: '#FF4559'},
    {code: 'WAITING', name: 'profile.package-order-waiting', color: '#707070'},
]

const PromotedStatusMapping: any = {
    'PROMOTED': 'profile.payment-list-promoted-status',
    'UNPROMOTED': 'profile.payment-list-unpromoted-status',
    'NOTIFIED': 'profile.payment-list-notified-status',
}

function PaymentList() {
    const {t} = useTranslation();
    const [packageOrders, setPackageOrders] = React.useState<any>([])
    const [packageOrderPagination, setPagination] = React.useState<any>()
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [filter, setFilter] = React.useState<any>({})
    const [codeItemSelected, setCodeItemSelected] = useState<InvoiceData>()

    React.useEffect(() => {
        let page = searchParams.get('page');
        let size = searchParams.get('size');
        let filterTemp: any = {};
        if (page)
            filterTemp.page = parseInt(page);
        if (size)
            filterTemp.size = parseInt(size);
        setFilter(filterTemp)
    }, [])

    const getPackageOrders = (filter?:any) => {
        setIsLoading(true);
        userServiceRepository.getOrderPaymentList(filter).then((response)=> {
            let currentPage = lodash.get(response, 'headers.x-page-number');
            let total = lodash.get(response, 'headers.x-total-count');
            setPackageOrders(response.data);
            setPagination({
                current:parseInt(currentPage) + 1,
                hideOnSinglePage:true,
                total:parseInt(total),
                pageSize:parseInt(lodash.get(response,'headers.x-page-size'))
            });
        }).catch(()=> {
            M24Notification.messageError(t('profile.get-payment-list-error'));
            setPackageOrders([]);
            setPagination({});
        }).finally(()=> {
            setIsLoading(false);
        })
    };

    React.useEffect(()=> {
        getPackageOrders(Object.fromEntries(searchParams.entries()));
    }, [searchParams, filter])

    const handleOnPageChange = (filter: any) => {
        let filterTemp = {
            ...filter,
            offset: (filter.page - 1) * filter.pageSize,
            limit: filter.pageSize
        };
        setFilter(filterTemp);
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            ...filterTemp
        });
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const onClickInvoice = (record: PackageOrderInterface) => {
        if (record.paymentInvoice?.status === RequestBillStatus.SUCCESS) {
            userServiceRepository.exportPaymentInvoice({invoiceId: record.paymentInvoice?.id}).then((res: any) => {
                var link: any = document.createElement("a");
                link.download = true;
                link.href = res.data.invoice;
                link.target = '_blank'
                link.click();
              }).catch(() => {
                M24Notification.messageError(t('message.unknown-error'));
              })
            return
        }

        setCodeItemSelected({
            code: record.code,
            invoiceId: record.paymentInvoice?.id,
            owner: record.customer
        })
    }

    function renderBill(status: PackageStatusEnum | string, record: PackageOrderInterface) {
        const buttonTextMapping: any = {
            [RequestBillStatus.SUCCESS]: 'profile.payment-download-bill',
            [RequestBillStatus.WAITING]: 'profile.payment-waiting-bill',
        }

        if (status === PackageStatusEnum.PAID) {
            return <Button
                    className="request-bill-btn"
                    type="link"
                    onClick={() => onClickInvoice(record)}>
                        {t(record.paymentInvoice?.status ? buttonTextMapping[record.paymentInvoice?.status] : 'profile.payment-request-bill')}
                    </Button>
        }
    }

    const column = [
        {
            title: t('profile.payment-order-time'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '10%',
            render: (createdAt: any) => {
                return <span
                    className={'txt-size-h7 robotoregular txt-color-black'}>{createdAt ? moment(createdAt).format('DD/MM/YYYY') : null}</span>
            }
        },
        {
            title: t('profile.payment-package-name'),
            dataIndex: 'packageName',
            key: 'packageName',
            width: '20%',
            render: (packageName: any) => {
                return <span className={'txt-size-h7 robotoregular txt-color-black'}>{packageName}</span>
            }
        },
        {
            title: t('profile.payment-order-code'),
            dataIndex: 'code',
            key: 'code',
            width: '15%',
            render: (code: any) => {
                return <span className={'txt-size-h7 robotoregular txt-color-black'}>{code}</span>
            }
        },
        {
            title: <span className={'flr'}>{t('profile.payment-order-paid')}</span>,
            // dataIndex: 'totalValue',
            key: 'totalValue',
            width: '13%',
            render: (record: any) => {
                return <span
                    className={'txt-size-h7 robotoregular txt-color-black flr'}>{formatMoneyByUnit(record.totalValue, record.currency)}</span>
            },
        },
        {
            title: <div className={'width100pc txt-center'}>{t('profile.payment-order-providerAccount')}</div>,
            dataIndex: 'providerAccount',
            key: 'providerAccount',
            width: '13%',
            render: (providerAccount: any, record: any) => {

                return <div
                    className={'width100pc txt-size-h7 robotoregular txt-color-black txt-center'}>
                        <span>{providerAccount ? providerAccount : '---'}</span>

                        <Tooltip
                            color="#FBB739"
                            overlayClassName="tooltip-custom-yellow"
                            title={t(PromotedStatusMapping[record.promoteStatus])}
                            placement="top"
                        >
                            <i className={`fa-solid fa-circle-check mg-l-4 promoted-check check-${record.promoteStatus}`} />
                        </Tooltip>
                </div>
            }
        },
        {
            title: <span className={'flr pdr5'}>{t('profile.payment-order-status')}</span>,
            dataIndex: 'status',
            key: 'status',
            width: '15%',
            render: (status: any, record: PackageOrderInterface) => {
                return <span className={'txt-size-h7 robotoregular txt-color-black flr'}>
                    {PACKAGE_ORDER_STATUS.map((x: any) => {
                        if (x.code === status)
                            return <span style={{backgroundColor: x.color}}
                                         className={'package-order-status-tag'}>{t(x.name)}</span>
                        else return null;
                    })}
                    {renderBill(status, record)}
                </span>
            }
        },

    ]

    return (
        <ConfigProvider renderEmpty={() => <Empty
            image={Empty4}
            imageStyle={{height: 56, color: '#C7C7C7'}}
            description={<span className={'txt-size-h7 robotoregular'}>{t('profile.empty-payment-list')}</span>}/>}
        >
            <div className={'payment-container'}>
                <Divider orientation="left" plain className={'profile-title'}>
                    <span
                        className={'txt-size-h3 txt-color-black robotomedium txt-capitalize'}>{t('profile.payments')}</span>
                </Divider>
                <Spin spinning={isLoading}>
                    <Table
                        dataSource={packageOrders}
                        columns={column}
                        className={'package-order-list'}
                        pagination={false}
                    />
                    <M24Pagination
                        filter={filter}
                        pagination={packageOrderPagination}
                        onPageChange={handleOnPageChange}
                        className={'mgt10 mgbt5 '}
                    />
                </Spin>
            </div>
            <ModalRequestBill
                isShowRequestBillModal={!!codeItemSelected}
                closeModal={() => setCodeItemSelected(undefined)}
                invoiceData={codeItemSelected}
                finishedCallback={getPackageOrders}
            />
        </ConfigProvider>

    );
}

export default PaymentList;
