import React from 'react';
import { FC, useCallback, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { SectionIds } from '..';
import { uploadNavActiveClass, scrollWithOffset } from '../helpers';
import './style.scss';

let timeout: any;

interface ItemInterface {
  id: string;
  title: string;
}

export interface NavLeftBarProps {
  items: ItemInterface[];
}

const NavLeftBar: FC<NavLeftBarProps> = React.memo(({ items = [] }: NavLeftBarProps) => {
  const handleActiveLeftNav = useCallback(() => {
    const common: HTMLElement | null = document.getElementById(
      SectionIds.COMMON_INFO.substring(1)
    );
    const sell: HTMLElement | null = document.getElementById(
      SectionIds.SELL_INFO.substring(1)
    );

    const details: any = document.getElementById(
      SectionIds.DETAILS_INFO.substring(1)
    );

    const transport: any = document.getElementById(
      SectionIds.TRANSPORT_INFO.substring(1)
    );

    const other: any = document.getElementById(SectionIds.OTHER.substring(1));

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (!common || !sell) return;
    uploadNavActiveClass(scrollTop, common, sell, details, transport, other);
  }, []);

  const addEventScroll = () => {
    window.addEventListener('scroll', handleActiveLeftNav, true);
  };

  const removeEventScroll = () => {
    window.removeEventListener('scroll', handleActiveLeftNav, true);
  };

  useEffect(() => {
    addEventScroll();
    return removeEventScroll;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToSection = (el: any) => {
    clearTimeout(timeout);
    removeEventScroll();
    scrollWithOffset(el);

    timeout = setTimeout(() => {
      addEventScroll();
    }, 1000);
  };

  return (
    <div className="left-nav-bar">
      {items.map(
        (item: ItemInterface, index: number): JSX.Element => (
          <HashLink
            key={`left-item-${index}`}
            to={item.id}
            className={index === 0 ? 'active' : ''}
            scroll={scrollToSection}
          >
            {item.title}
          </HashLink>
        )
      )}
    </div>
  );
});

export { NavLeftBar };
