import React from "react";
import {useTranslation} from "react-i18next";
import uboxLogo from "../../../resources/images/ubox-logo.png";

export const EmptyNotice = () => {
    const {t} = useTranslation()
    return (
        <div className="notification-empty-items">
            <div className={'notification-empty-items-img'}><img src={uboxLogo} alt={'No items'} /></div>
            <div className={'notification-empty-items-txt'}>{t('headerNotification.emptyItemsMessage')}</div>
        </div>
    )
}
