import React from "react";
import {VendorServiceInterface} from "../../../Interface/VendorServiceInterface";
import {useTranslation} from "react-i18next";
import {Avatar, Button, Col, ConfigProvider, Empty, List, Row} from "antd";
import Empty4 from '../../../resources/images/empty-4.png'
import UboxLogo3 from '../../../resources/images/UboxLogo3.png'
import Paragraph from "antd/es/typography/Paragraph";

type ListVendorProps = {
    vendors: VendorServiceInterface[];
    isLoading: boolean;
    handlePurchase:(vendor:VendorServiceInterface)=> void;
}

function ListVendor(props: ListVendorProps) {
    const {t} = useTranslation();
    const {vendors, handlePurchase,
    } = props;

    return (
        <ConfigProvider renderEmpty={() => <Empty
            image={Empty4}
            imageStyle={{height: 56, color: '#DCE0E6'}}
            description={<span className={'txt-size-h7 robotoregular'}>{t('profile.empty-vendor-list')}</span>}/>}
        >
                <List
                    className={'vendor-list'}
                    dataSource={vendors}
                    renderItem={(vendor: VendorServiceInterface) => {
                        if(vendor.status !== 'INACTIVE') return(
                            <List.Item className={'vendor-item'} key={vendor.id}>
                                <Row className={'width100pc'}>
                                    <Col span={2} flex={'auto'} className={'align-items-center'}>
                                        <Avatar src={vendor.avatar || UboxLogo3}
                                                size={96}
                                                shape={'square'}
                                                className={'bd-none-impt'}
                                                style={{width: "auto", height: "auto"}}
                                        />
                                    </Col>
                                    <Col span={18} flex={'auto'} className={'pdr10'}>
                                        <div className={'mgr15 vendor-description'}>
                                            <span className={'width100pc txt-size-h1 robotomedium txt-color-black'}>{vendor.name}</span>
                                            <Paragraph
                                                className={'width100pc robotoregular txt-size-h7 txt-color-gray4'}
                                                ellipsis={{rows:3,expandable:true,
                                                    symbol:<span className={'txt-color-blue'}>{t('profile.show-more-description')}</span>}}>
                                                {vendor.description}
                                            </Paragraph>
                                        </div>
                                    </Col>
                                    <Col span={4} flex={'auto'} className={'align-items-center dpl-flex'}>
                                        <Button className={'purchase-btn'}
                                                onClick={() => {
                                                    handlePurchase(vendor)
                                                }}
                                        >
                                            {t('profile.service-purchase')}
                                        </Button>
                                    </Col>
                                </Row>
                            </List.Item>
                        )
                    }}
                />
        </ConfigProvider>

    );
}

export default ListVendor;
