import { Avatar, Button, Col, DatePicker, Divider, Form, Input, Row, Select } from 'antd'
import { t } from 'i18next'
import lodash, { isArray, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ProviderInterface } from '../../Interface/AccountBalanceInterface'
import ShipmentsFilterInterface from '../../Interface/ShipmentFilterInterface'
import ShipmentStatusInterface from '../../Interface/ShipmentStatusInterface'
const { Item } = Form
const { RangePicker } = DatePicker
interface FilterProps {
	filter: any
	statuses: any
	accounts: any
	providers: ProviderInterface[]
	filterChangeHandler: (obj: any) => void
	handleFilter: (params: ShipmentsFilterInterface | {}) => void
}
const Filter: React.FC<FilterProps> = (props) => {
	const { statuses, accounts, handleFilter, filter, filterChangeHandler } = props
	const [form] = Form.useForm()
	const [showFilter, setShowFilter] = useState(true)
	const [searchParams, setSearchParams] = useSearchParams()
	const [checkedStatuses, setCheckedStatuses] = useState<string[]>(searchParams.get('statuses')?.split(',') || [])
	const [expand, setExpand] = useState(false)
	const [idConnectionSelected, setIdConnectionSelected] = useState()
	const [showCleanFilter, setShowCleanFilter] = useState(false)
	
	useEffect(() => {
		if (searchParams.get('providerUsername') && searchParams.get('provider')) {
			const indexOfAccount = accounts.findIndex(
				(acc: any) =>
					acc.name === searchParams.get('providerUsername') &&
					acc.provider.code === searchParams.get('provider')
			)
			if (indexOfAccount > -1) {
				setIdConnectionSelected(lodash.get(accounts[indexOfAccount], 'id'))
			}
		} else {
			const index = accounts.findIndex((acc: any) => acc.id === idConnectionSelected)
			if (index > -1) {
				setSearchParams({
					customer: lodash.get(accounts[index], 'name'),
					provider: lodash.get(accounts[index], 'provider.code'),
				})
			}
		}
		form.setFieldsValue({
			supplier: idConnectionSelected,
			code: searchParams.get('code'),
			originalReceipt: searchParams.get('originalReceipt'),
			merchantName: searchParams.get('merchantName'),
			providerShipmentCode: searchParams.get('providerShipmentCode'),
			waybill: searchParams.get('waybill'),
			createdAt: [
				searchParams.get('createdFrom') ? moment(searchParams.get('createdFrom')) : null,
				searchParams.get('createdTo') ? moment(searchParams.get('createdTo')) : null,
			],
		})
	}, [accounts, idConnectionSelected, searchParams, setSearchParams, form])

	const handleChangeStatuses = (key: string, selected: boolean) => {
		if (selected) {
			setCheckedStatuses([...checkedStatuses, key])
			filterChangeHandler({ statuses: [...checkedStatuses, key] })
		} else {
			setCheckedStatuses(checkedStatuses.filter((stt: string) => stt !== key))
			filterChangeHandler({ statuses: checkedStatuses.filter((stt: string) => stt !== key) })
		}
	}

	const cleanFilter = () => {
		form.resetFields()
		setCheckedStatuses([])
		setIdConnectionSelected(undefined)
		handleFilter({})
	}

	const getFormState = () => {
		const supplier = form.getFieldValue('supplier')
		const account = accounts.find((x: any) => x.id === supplier)
		const providerUsername = lodash.get(account, 'name')
		const provider = lodash.get(account, 'provider.code')
		const createdAt = form.getFieldValue('createdAt')
		const createdFrom = createdAt && createdAt[0] && moment(createdAt[0].startOf('day')).toISOString()
		const createdTo = createdAt && createdAt[1] && moment(createdAt[1].endOf('day')).toISOString()
		const filter = lodash.omitBy(
			{
				...form.getFieldsValue(true),
				statuses: checkedStatuses.join(','),
				providerUsername,
				provider,
				createdFrom,
				createdTo,
			},
			(o: any, key: string) => {
				return isEmpty(o) || key === 'createdAt'
			}
		)

		return filter
	}

	const onFinish = () => {
		handleFilter(getFormState())
	}

	useEffect(() => {
		setShowCleanFilter(
			!lodash.isEmpty(
				lodash.pickBy(filter, (val: any) => {
					if (isArray(val)) {
						return val.every((e) => e) && !isEmpty(val)
					}
					return !isEmpty(val)
				})
			)
		)
	}, [filter])

	return (
		<div className='shipments-filter pd-16'>
			<div
				className='mg-bt-16 flex align-items-center justify-content-between cursor-pointer'
				onClick={() => setShowFilter(!showFilter)}
			>
				<span className='robotomedium fsz-16 line-h-24 txt-color-black'>{t('shipments.filterTitle')} </span>
				<i
					className={`fa-solid fa-angle-${showFilter ? 'up' : 'down'} cursor-pointer`}
					onClick={() => setShowFilter(!showFilter)}
				></i>
			</div>
			{showFilter && (
				<>
					<div className={`dpl-flex align-items-baseline flex-wrap mg-bt-12 ${expand ? 'mg-bt-12' : ''}`}>
						<span className={'txt-size-h7 robotoregular txt-color-black mgr10'}>
							{t('orders.order_status')}:{' '}
						</span>
						{statuses?.map(({ code, name }: ShipmentStatusInterface) => {
							const selected = checkedStatuses && checkedStatuses.includes(code) ? true : false
							return (
								<span
									className={`txt-capitalize ${
										selected ? 'status-package active' : 'status-package'
									}`}
									key={code}
									onClick={() => {
										handleChangeStatuses(code, !selected)
									}}
								>
									{name}
								</span>
							)
						})}
					</div>
					{expand && (
						<Form
							name='shipments-form'
							className={`shipments-form ${expand ? 'mg-bt-12' : ''}`}
							form={form}
							onFinish={onFinish}
							initialValues={{
								code: '',
								originalReceipt: '',
								merchantName: '',
								providerShipmentCode: '',
								waybill: '',
								createdAt: [],
								supplier: idConnectionSelected,
							}}
							onValuesChange={(changedValues, allValues) => {
								filterChangeHandler(allValues)
							}}
						>
							<Row gutter={[32, 10]}>
								<Col span={8}>
									<Item label={`${t('shipments.orderCode')}:`} labelAlign='left' colon={false} name='code'>
										<Input placeholder={`${t('shipments.orderCodePlaceholder')}`} allowClear />
									</Item>
								</Col>
								<Col span={8}>
									<Item
										label={`${t('shipments.originalInvoice')}:`}
										labelAlign='left'
										colon={false}
										name='originalId'>
										<Input placeholder={`${t('shipments.originalCodePlaceholder')}`} allowClear />
									</Item>
								</Col>
								<Col span={8}>
									<Item
										label={`${t('shipments.accountProvider')}:`}
										colon={false}
										labelAlign='left'
										name='supplier'
										className='supplier'
									>
										<Select
											value={idConnectionSelected}
											placeholder={t('shipments.connectionsSelectionsPlaceholder')}
										>
											{accounts.map((x: any) => {
												return (
													<Select.Option key={x.id} value={x.id} className={''}>
														<Row className={'dpl-flex align-items-center'}>
															<Avatar
																size={20}
																src={lodash.get(x, 'provider.logo', '')}
																shape={'circle'}
																className='bd-none-impt'
															></Avatar>
															<span className={'mgl5 robotoregular txt-size-h8'}>
																{lodash.get(x, 'provider.code', '')}
															</span>
															<Divider type={'vertical'} className={'bd-color-black'} />
															<span className={'robotoregular txt-size-h8'}>
																{lodash.get(x, 'name', '')}
															</span>
														</Row>
													</Select.Option>
												)
											})}
										</Select>
									</Item>
								</Col>
								<Col span={8}>
									<Item label={`${t('shipments.wayBill')}:`} labelAlign='left' colon={false} name='waybill'>
										<Input allowClear placeholder={`${t('shipments.wayBillPlaceholder')}`} />
									</Item>
								</Col>
								<Col span={8}>
									<Item label={`${t('shipments.shop')}:`} labelAlign='left' colon={false} name='merchantName'>
										<Input allowClear placeholder={`${t('shipments.merchantNamePlaceholder')}`} />
									</Item>
								</Col>
								<Col span={8}>
									<Item label={`${t('shipments.createdAt')}:`} name='createdAt' labelAlign='left'>
										<RangePicker
											placeholder={[t('packages.fromDate'), t('packages.toDate')]}
											className='width100pc'
											format={'DD-MM-YYYY'}
											suffixIcon={<i className='far fa-calendar-alt' />}
											//@ts-ignore
											placement='topRight'
										/>
									</Item>
								</Col>
							</Row>
						</Form>
					)}
					<div className='shipments-form__actions'>
						<span className='cursor-pointer fsz-12' onClick={() => setExpand(!expand)}>
							<i className={`mg-r-6 fa-solid fa-angle-${expand ? 'up' : 'down'}`}></i>
							<span className='fsz-12 line-h-20 txt-color-black robotoregular'>
								{t('shipments.expandFilter')}
							</span>
						</span>
						<div className='buttons'>
							{showCleanFilter && (
								<span
									className='buttons-clear fsz-14 line-h-22 mg-r-12 robotoregular'
									onClick={cleanFilter}
								>
									<i className='fa-solid fa-arrow-rotate-right fsz-12 mg-r-6'></i>
									<span>{t('shipments.cleanFilter')}</span>
								</span>
							)}
							<Button
								className='btn-df btn-df-secondary-bg-lemon fsz-14 line-h-22 px-24 py-4'
								htmlType='submit'
								form='shipments-form'
								onClick={onFinish}
							>
								{t('shipments.submitFilter')}
							</Button>
						</div>
					</div>
				</>
			)}
		</div>
	)
}

export default Filter

