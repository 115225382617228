import type { UploadFile } from 'antd/es/upload/interface';
import {
  EditStoredProductFormNames,
  SKUInterface,
} from '../../Interface/StoredProductInterface';
import { RichTextFields } from './CommonInfoSection';

export const getBodyEditProduct = (
  productImages: UploadFile<unknown>[],
  richTextFields: RichTextFields,
  values: Record<EditStoredProductFormNames, any>
) => {
  const images: string[] = productImages.map(
    (imgObj: UploadFile<unknown>) => imgObj.url || ''
  );
  let minMsrp: any = null;
  let minSalePrice: any = null;
  values.skus.forEach((sku: SKUInterface | any) => {
    if (sku.image?.file) {
      sku.image = '';
    }

    if (minMsrp === null) {
      minMsrp = sku.msrp;
    }

    if (minSalePrice === null) {
      minSalePrice = sku.salePrice;
    }

    if (sku.msrp !== null && sku.msrp < minMsrp) {
      minMsrp = sku.msrp;
    }

    if (sku.salePrice !== null && sku.salePrice < minSalePrice) {
      minSalePrice = sku.salePrice;
    }
  });

  return {
    productImages: images,
    name: values.name?.trim() || '',
    ...richTextFields,
    skus: values.skus,
    width: values.width,
    height: values.height,
    length: values.length,
    weight: values.weight,
    msrp: minMsrp,
    salePrice: minSalePrice,
    featuredImage: images[0] || '',
  };
};

export const uploadNavActiveClass = (
  scrollTop: number,
  common: HTMLElement,
  sell: HTMLElement
) => {
  const navCommon = document.querySelectorAll('.left-nav-bar a');
  if (!navCommon[0]) return;

  if (scrollTop === common.offsetTop) {
    navCommon[0].classList.add('active');
    navCommon[1].classList.remove('active');
    navCommon[2].classList.remove('active');
  }

  if (scrollTop > sell?.offsetTop - 50) {
    navCommon[0].classList.remove('active');
    navCommon[1].classList.add('active');
    navCommon[2].classList.remove('active');
  }

  if (
    scrollTop + 10 >
    document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  ) {
    navCommon[0].classList.remove('active');
    navCommon[1].classList.remove('active');
    navCommon[2].classList.add('active');
  }
};

export const onPressOnlyNumber = (event: any) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const onPressOnlyNumberAndAllowDecimals = (event: any) => {
  if (!/[0-9.]/.test(event.key)) {
    event.preventDefault();
  }
};
