import {Repository} from "../core/repositories/Repository";
import {httpConfig} from "../core/config/http";
import {API_BASE_URL,} from "../core/config";
import {AxiosResponse} from "axios";
import {PackageOrderDomain} from "../components/Profile/Service";
import { CreatePaymentInvoiceBody, PaymentInvoiceInterface } from "../Interface/PaymentInterface";

export class UserServiceRepository extends Repository{
    constructor() {
        super(httpConfig);
        this.setBaseURL(API_BASE_URL);
    }

    public getVendor = ():Promise<any> => {
        return this.http.get('vendors').then((response:AxiosResponse) => {
            return response.data
        })
    }

    public getUserPackage = ():Promise<any> => {
        return this.http.get('customer-packages', {params: {sort:'createdAt:desc'}}).then((response:AxiosResponse) => {
            return response
        })
    }

    public getVendorPackages = (vendorName:string):Promise<any> => {
        return this.http.get(`vendor-packages`,{params:{vendor:vendorName}}).then((response:AxiosResponse)=> {
            return response;
        })
    }

    public getOrderPaymentList = (filter:any):Promise<any> => {
        // let limit = filter && filter.size? filter.size:DEFAULT_PAGE_SIZE;
        // let offset=filter && filter.page? filter.page*limit:0;
        return this.http.get(`package-orders`,{params: {...filter,sort:'createdAt:desc'}})
            .then((response:AxiosResponse)=> {
            return response;
        })
    }
    public getTransferGuide = (code:any,method:any):Promise<any> => {
        return this.http.get(`vendors/${code}/payment-methods/${method}/guide`)
            .then((response:AxiosResponse)=> {
                return response.data;
            })
    }

    public creatDraftOrd = (body:PackageOrderDomain):Promise<any> => {
        return this.http.post(`package-draw-orders`,body).then((response:AxiosResponse) => {
            return response.data
        })
    }
    public updateOrdImage = (code:string,attachment:any) => {
        let formData = new FormData();
        formData.append("bill-order", attachment)
        return this.http.patch(`package-draw-orders/${code}`,formData).then((response:AxiosResponse) => response.data)
    }

    public createPackageOrd = (body:any) => {
        return this.http.post (`package-orders`,body).then((response:AxiosResponse) => response)
    }

    public createPaymentInvoice = (body: CreatePaymentInvoiceBody): Promise<AxiosResponse<PaymentInvoiceInterface>> => {
        return this.http.post (`payment-invoices`, body)
    }

    public getPaymentInvoice = (id: string): Promise<AxiosResponse<PaymentInvoiceInterface>> => {
        return this.http.get(`payment-invoices/${id}`)
    }
    
    public updatePaymentInvoice = (id: string, body: CreatePaymentInvoiceBody): Promise<AxiosResponse<PaymentInvoiceInterface>> => {
        return this.http.patch(`payment-invoices/${id}`, body)
    }
    
    public exportPaymentInvoice = ({invoiceId}: {invoiceId: string}): Promise<AxiosResponse<any>> => {
        return this.http.post(`payment-invoices/${invoiceId}/export`)
    }
}
export const userServiceRepository:UserServiceRepository = new UserServiceRepository();

