import { Col, Row } from 'antd'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
interface SkeletonOrdersProps {
	loading: boolean
	data?: any
}
const SkeletonOrders: React.FC<SkeletonOrdersProps> = ({ data, loading }) => {
	return (
		<>
			{data?.map((item: any, idx: number) => (
				<div className={'_order-item'} key={idx}>
					<Col span={24}>
						<Row className={' pd12 mg-t-2 item-shadow'}>
							<Skeleton width={48} height={48} />
							<Col flex={'auto'} className={'mgl8 mg-bt-12'}>
								<Row className={''} align={'middle'}>
									<Col span={20}>
										<Row align={'middle'}>
											<Skeleton width={125} height={22} className='mg-r-10' />
											<Skeleton width={125} height={22} className='mg-r-10' />
											<Skeleton width={50} height={22} className='mg-r-10' />
											<Skeleton width={120} height={22} />
										</Row>
									</Col>
									<Col span={4}>
										<Row className={'justify-content-end'}>
											<Skeleton width={100} height={22} borderRadius={20} />
										</Row>
									</Col>
								</Row>
								<Row className={'mgt5'} align={'middle'}>
									<Row className={''} align={'middle'}>
										<Skeleton width={125} height={22} className='mg-r-10'/>
										<Row className={'dpl-flex align-items-center'}>
											<Skeleton width={50} height={22} className='mg-r-10' />
											<Skeleton width={65} height={22} className='mg-r-10' />
										</Row>
									</Row>
									<span className={'flex align-items-center txt-size-h8 txt-color-secondary robotoregular'}>
                                        <Skeleton width={50} height={22} className='mg-r-10' />
											<Skeleton width={65} height={22} className='mg-r-10' />
									</span>

                                    <Skeleton width={60} height={22} className='mg-r-10'/>
									<Row className={'flex flex-nowrap'}>
										{item?.services.map((service: any) => (
											<Skeleton width={80} height={20} className='mg-r-8' key={service.id}/>
										))}
									</Row>
								</Row>
							</Col>
						</Row>
					</Col>
				</div>
			))}
		</>
	)
}

export default SkeletonOrders

