import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {ConnectShopInterface} from "../../Interface/ConnectShopInterface";
import {IMarketplace} from "../../Interface/MarketplaceInterface";
import {connectMarketRepository} from "../../repositories/ConnectMarketRepository";
import {categoryRepository} from "../../repositories/CategoryRepository";
import {DEFAULT_PAGE_SIZE} from "../../core/config";
import {warehouseRepository} from "../../repositories/ProductWarehouseRepository";
import {Col, Row} from "antd";
import PendingProductFilter from "../../components/PendingProduct/Filter";
import PendingProductList from "../../components/PendingProduct/List";
import MainLayout from "../../components/Layout";
import SellingProductList from "../../components/SellingProduct/List";
import {AxiosResponse} from "axios";
import SellingProductFilter from "../../components/SellingProduct/Filter";


type SellingProductProps = {
    channel?:string;
}

interface SellingProductDataInterface {
    total: number | null,
    productList: any[],
}


function SellingProduct(props:SellingProductProps) {
    const {t} = useTranslation();
    const [loading,setLoading] = useState(false);
    const [searchParams,setSearchParams] = useSearchParams();
    const [shopList, setShopList] = useState<ConnectShopInterface[]|any>([]);
    const [selectedItems, setSelectItems] = useState<string[]>([]);
    const [productData, setProductData] = useState<SellingProductDataInterface>({
        total: null,
        productList: [],
    });
    const [marketplaces,setMarketplaces] = useState<IMarketplace[]>([]);
    const fetchGetProducts = useRef<null | ReturnType<typeof setTimeout>>(null);
    const [statuses,setStatuses] = useState<Object[]>([])

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000);

    useEffect(() => {
        getShopeeIntegrations();
        getMarketplaces();
        getSellingProductStatuses();
    }, []);

    const getShopeeIntegrations = () => {
        setLoading(true)
        connectMarketRepository.getChannelShops({
            offset:0,
            limit:100,
        },'shopee')
            .then((response)=> {
                setShopList(response.data)
            }).catch((err)=>{
            console.log(err.response)})
            .finally(()=> setLoading(false))
    }

    const getMarketplaces = () => {
        categoryRepository.getMarketPlaces().then((res)=> {
            setMarketplaces(res)
        }).catch((err) => {
            console.log(err.response.data)})
    }

    const getSellingProductStatuses = () => {
        warehouseRepository.getSellingProductStatus('shopee').then((response)=> {
            setStatuses(response);
        }).catch((reason => {}))
    }

    useEffect(() => {
        return () => {
            if(fetchGetProducts.current) {
                return clearTimeout(fetchGetProducts.current)
            }
        }
    }, [])

    useEffect(() => {
        if(fetchGetProducts.current) {
            clearTimeout(fetchGetProducts.current)
        }
        fetchGetProducts.current = setTimeout(() => {
            const currentPage = parseInt(searchParams.get('page') || '1')
            const pageSize = parseInt(searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`)

            getProductList({ ...Object.fromEntries(searchParams), currentPage, pageSize })
        }, 1000)
    }, [searchParams])


    const getProductList = (filter: any) => {
        const { page, pageSize, currentPage, sort, order, ...rest } = filter
        const offset = (pageSize || 0) * (currentPage - 1 || page - 1 || 0)
        const limit = pageSize || DEFAULT_PAGE_SIZE
        setLoading(true);
        warehouseRepository.getSellingProducts({
            offset,limit,
            channel:props.channel,
            ...rest
        },controller.signal)
            .then(res => {
                setProductData(prevState => ({
                    ...prevState,
                    total: parseInt(res.headers['x-total-count'] || '') || null,
                    productList: res.data
                }))
            })
            .catch(err => {
                setProductData(prevState => ({
                    ...prevState,
                }))
            }).finally(()=> setLoading(false));
        clearTimeout(timeoutId);
    }




    return(
        <MainLayout title={t('sellingProduct.header-title')}>
            <Row
                gutter={[0, 12]}
                className="stored-products-container"
            >
                <Col xs={ 24 }>
                    <SellingProductFilter searchParams={searchParams} setSearchParams={setSearchParams} integrationList={shopList} statuses={statuses}/>
                </Col>
                <Col xs={ 24 }>
                    <SellingProductList
                        statuses={statuses}
                        loading={loading}
                        total={productData.total}
                        productList={productData.productList}
                        selectedItems={selectedItems}
                        setSelectItems={setSelectItems}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        marketplaces={marketplaces}
                    />
                </Col>
            </Row>
        </MainLayout>
    )
}

export default SellingProduct;
