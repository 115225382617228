import { AxiosResponse } from 'axios'
import { API_BASE_URL, DEFAULT_PAGE_SIZE } from '../core/config'
import { httpConfig } from '../core/config/http'
import { url } from '../core/helpers/string'
import { Repository } from '../core/repositories/Repository'
import { SHIPMENT_PEER_PAYMENT } from '../core/config/consts'

export class PeerpaymentAndShipmentRepository extends Repository {
	constructor() {
		super(httpConfig)
		this.setBaseURL(url(API_BASE_URL, ''))
	}

	public getAccountProvider = (type: string, linkProvider: string): Promise<any> => {
		return this.http
			.get(`/connections/account-balance/providers`, { params: { type, linkProvider } })
			.then((response: AxiosResponse<any>) => {
				return response
			})
	
		}

	public getShipmentAndPeerPayment = (filter:any) => {
		let limit = filter && filter.size? filter.size:DEFAULT_PAGE_SIZE;
        let offset=filter && filter.page? filter.page*limit:0;
        return this.http
            .get(`shipments`, {params:{...filter, offset, limit, sort:'createdAt:desc', type:SHIPMENT_PEER_PAYMENT}})
            .then((response: AxiosResponse<any>) => {
                return response;
                
            });
	}
}
export const peerPaymentAndShipmentRepository: PeerpaymentAndShipmentRepository = new PeerpaymentAndShipmentRepository()

