import { Form, InputNumber, Radio } from 'antd'
import { FormInstance, useWatch } from 'antd/es/form/Form'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EditProductForSellFormNames } from '../../Interface/ProductInterface'
import { onPressOnlyNumber } from './helpers'
import { RowItem } from './RowItem'

interface OtherSectionProps {
	form: FormInstance<Record<EditProductForSellFormNames, any>>
}

const OtherSection: FC<OtherSectionProps> = ({ form }: OtherSectionProps) => {
	const { t } = useTranslation()

	const isPreOrder = useWatch('isPreOrder', form)

	return (
		<section id='other-section'>
			<h2 className='section-header'>
				<span>{t('productForSell.other')}</span>
			</h2>

			<RowItem label={`${t('productForSell.before-order')}`}>
				<Form.Item name='isPreOrder' initialValue={true}>
					<Radio.Group>
						<Radio value={false} defaultChecked>
							{t('productForSell.no')}
						</Radio>
						<Radio value={true}>{t('productForSell.yes')}</Radio>
					</Radio.Group>
				</Form.Item>
				{isPreOrder === true && (
					<div className='time-order'>
						<Form.Item
							label={`${t('productForSell.time-order')}`}
							rules={[
								{
									required: true,
									min: 7,
									max: 15,
									message: `${t('productForSell.time-order-validate')}`,
									type: 'number',
								},
							]}
							className='txt-size-h7'
							name='daysToShip'
							initialValue={7}>
							<InputNumber
								placeholder={`${t('productForSell.time-order-placeholder')}`}
								min={7}
								max={15}
								onKeyPress={onPressOnlyNumber}
								className='width250px'
							/>
						</Form.Item>
					</div>
				)}
			</RowItem>

			<RowItem label={`${t('productForSell.status')}`}>
				<Form.Item name='condition'>
					<Radio.Group>
						<Radio value={'NEW'} defaultChecked>
							{t('productForSell.new')}
						</Radio>
						<Radio value={'USED'}>{t('productForSell.old')}</Radio>
					</Radio.Group>
				</Form.Item>
			</RowItem>
		</section>
	)
}

export { OtherSection }

