import { Image } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DescriptionsInterface from '../../Interface/DescriptionsInterface'
import UboxImgInDesc from '../../resources/images/gobiz-logo-extension.png'

const Descriptions: React.FC<DescriptionsInterface> = ({
	title,
	descriptions,
	className,
	hasDescription,
	pageCode,
	profile,
	notShowAgain,
	showLater,
}) => {
	const { t } = useTranslation()

	return hasDescription ? (
		<div className={`flex rad-4 mg-bt-12 ${className ? className : ''}`}>
			<Image
				src={UboxImgInDesc}
				alt='ubo-image'
				preview={false}
				rootClassName='mg-r-8 flex-basis-48 flex-shrink-0'
				width={48}
				height={48}
			/>
			<div className='flex flex-col'>
				<div className='flex flex-col mg-bt-12'>
					<span className='robotomedium fsz-16 line-h-24 txt-color-blue6 mg-bt-2'>{title}</span>
					<span className='robotoregular fsz-14 line-h-22 txt-color-black'>{descriptions}</span>
				</div>
				<div className='flex'>
					<span
						className='robotoregular fsz-12 line-h-20 txt-color-gray7 cursor-pointer whitespace mg-r-18'
						onClick={notShowAgain}>
						{t('shoppingCart.descriptionsHide')}
					</span>
					<span
						className='robotoregular fsz-12 line-h-20 txt-color-green1 cursor-pointer whitespace'
						onClick={showLater}>
						<i className='fa-solid fa-check mg-r-4'></i>
						{t('shoppingCart.descriptionsUnderstand')}
					</span>
				</div>
			</div>
		</div>
	) : null
}

export default Descriptions

