import { Checkbox, Col, Image, Radio, Row } from 'antd'
import lodash from 'lodash'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import empty from '../../../resources/images/empty-2.png'

export interface ServicesProps {
	services: any
	serviceGroups: any
	checkedList: any
	setCheckedList: (items: any) => void
	setIsAllow: (val: boolean) => void
	handleCurServiceHasError?: (obj: any) => void
}

function Services(props: ServicesProps) {
	const { t } = useTranslation()
	const { services, serviceGroups, checkedList, setCheckedList, setIsAllow } = props

	const checkDisable = (item: any) => {
		let disable = false
		// if (item.requires.length > 0) {
		//     let hasOne = false;
		//     item.requires.map((x: any) => {
		//         if (checkedList.find((y: any) => y.code === x)) {
		//             hasOne = true;
		//         }
		//     });
		//
		//     disable = !hasOne;
		// }
		// nếu item là dependencies của 1 phần tử trong checkedList
		if (checkedList.length > 0 && checkedList.find((x: any) => x.dependencies.find((y: any) => y === item.code))) {
			disable = true
		}
		// nếu trong list check có phần tử excludes item thì disable item
		if (checkedList.length > 0 && checkedList.find((x: any) => x.excludes && x.excludes.indexOf(item.code) > -1)) {
			disable = true
		}
		return disable
	}
	const onItemCheck = useCallback(
		(item: any) => {
			let items = lodash.cloneDeep(checkedList)
			if (items.find((x: any) => x.code === item.code)) {
				items = items.filter((x: any) => x.code !== item.code)
				// loại bỏ các phân tử đã được check nhưng có requires của các phần sắp bị loại bỏ do cùng group với item
				// và ko còn chứa bất kỳ 1 required nào khác
				//loại bỏ các phần tử trong cùng group ra khỏi checkedList
				items = items.filter((x: any) => item.requires.indexOf(x.code) === -1)

				// loại bỏ các phân tử dependencies của item
				if (Array.isArray(item.dependencies) && item.dependencies.length > 0) {
					item.dependencies.map((x: any) => {
						items = items.filter((y: any) => y.code !== x)
					})
				}
			} else {
				//loại bỏ các phần tử trong mảng checkedList nếu item có excludes phần tử đó
				let temp: any = []
				items.map((x: any) => {
					if (item.excludes.indexOf(x.code) === -1) {
						temp.push(x)
					} else {
						// if (x.serviceGroup && x.serviceGroup.required) {
						//     let services = orderServices.filter(service => {
						//         if (item.code !== service.code && service.serviceGroup && service.serviceGroup.code === x.serviceGroup.code && item.excludes.indexOf(service.code) === -1)
						//             return service;
						//     });
						//     temp.push(services[0])
						// }
					}
				})
				items = temp
				if (item.excludeGroups)
					items = items.filter(
						(x: any) => !x.serviceGroup || (x.serviceGroup && item.excludeGroups.indexOf(x.serviceGroup.code) === -1)
					)
				// //loại bỏ các phần từ trong mảng checkedList nếu các phần tử đó cùng group với item và group có single === true
				if (item.serviceGroup && item.serviceGroup.single) {
					items = items.filter((x: any) => !(x.serviceGroup && x.serviceGroup.code === item.serviceGroup.code))
				}

				items.push(item)
				// tự động thêm các phần tử thuộc dependencies của item
				if (Array.isArray(item.dependencies) && item.dependencies.length > 0) {
					item.dependencies.map((x: any) => {
						let depend = services.find((y: any) => y.code === x)
						if (depend) {
							items.push(depend)
							// 	const serviceGroup = serviceGroups.find((serviceGroup: any) => lodash.map(serviceGroup.services, 'code').includes(depend.code) )
							// 	console.log('serviceGroup', serviceGroup)
							// const anotherItemInSameGroupWithDepend = items.find((item: any) => serviceGroup.services.includes(depend.code) && item.code !== depend.code)
							const sameGroupWithDependencies = items.filter((item: any) => {
								if (item.serviceGroup) {
									return item.serviceGroup.code === depend.serviceGroup.code && item.code !== depend.code
								}
							})
							items = items.filter(
								(item: any) => !lodash.map(sameGroupWithDependencies, 'code').includes(item.code)
							)
						}
					})
				}
			}
			setCheckedList(items)
		},
		[checkedList, serviceGroups, services, setCheckedList]
	)
	const renderCheckbox = (item: any, disabled = false) => {
		if (item.needApprove && item.approved === null) {
			item.colorService = 'service-normal'
		} else {
			item.colorService = 'service-normal'
		}
		return (
			<Col key={item.code} span={6}>
				<Checkbox
					disabled={disabled || checkDisable(item)}
					key={item.code}
					onChange={() => onItemCheck(item)}
					id={`_input-checkbox_service_${item.id}`}
					className={`_service-checkbox serviceoption__checkbox  mgr10`}
					checked={checkedList.find((x: any) => x.code === item.code) !== undefined}>
					<span className={`whitespace txt-size-h7 ${item.colorService} robotoregular`}>{item.name}</span>
				</Checkbox>
			</Col>
		)
	}
	const renderCheckboxGroup = (group: any) => {
		const items = checkedList.filter(
			(x: any) => (x.serviceGroup && x.serviceGroup.code === group.code) || (group.code === 'other' && !x.serviceGroup)
		)
		const disabled =
			group.code !== 'other' && checkedList.find((x: any) => x.excludeGroups && x.excludeGroups.indexOf(group.code) > -1)
		return (
			<Col className={'mgt0 mgbt5 pd-l-0'}>
				<Row className={``} key={group.code}>
					<Col span={4}>
						<span
							className={
								'robotoregular txt-size-h7 txt-color-gray4 robotoregular line-h-22'
							}>{`${group.name}:`}</span>
					</Col>
					<Col span={20}>
						<Row>{group.services.map((x: any) => renderCheckbox(x, disabled))}</Row>
					</Col>
				</Row>

				<Row className={``}>
					<Col span={4}></Col>
					<Col span={20}>
						{items.map((service: any) => {
							if (service.needApprove)
								return (
									<Row className={'align-items-center'}>
										<i className='txt-color-orange fas fa-exclamation-triangle mgr5'></i>
										<span
											dangerouslySetInnerHTML={{
												__html: t('message.serviceNeedApprove', { name: service.name }),
											}}></span>
									</Row>
								)
							if (service.requires && service.requires.length > 0) {
								let hasError = false
								let name: string[] = []
								service.requires.map((x: any) => {
									let temp = services.find((xx: any) => xx.code === x)
									if(temp) {
										name.push(temp.name)
									}
									if (!checkedList.find((y: any) => y.code === x)) {
										hasError = true
										setIsAllow(false)
									}
								})

								if (hasError) {
									return (
										<Row className={'align-items-center mgbt5 mgt5'}>
											<i className='txt-color-red fas fa-exclamation-triangle mgr5'></i>
											<span
												dangerouslySetInnerHTML={{
													__html: t('message.serviceRequire', {
														service: service.name,
														serviceRequire: name.toString(),
													}),
												}}></span>
										</Row>
									)
								}
							}
							if (service.requireGroups && service.requireGroups.length > 0) {
								let hasError = false
								let name: string[] = []
								service.requireGroups.map((x: any) => {
									let temp = serviceGroups.find((xx: any) => xx.code === x)
									name.push(temp.name)
									if (!checkedList.find((y: any) => y.serviceGroup && y.serviceGroup.code === x)) {
										hasError = true
										setIsAllow(false)
									}
								})

								if (hasError) {
									return (
										<Row className={'align-items-center mgbt5 mgt5'}>
											<i className='txt-color-red fas fa-exclamation-triangle mgr5'></i>
											<span
												dangerouslySetInnerHTML={{
													__html: t('message.serviceGroupRequire2', {
														service: service.name,
														serviceGroup: name.toString(),
													}),
												}}></span>
										</Row>
									)
								}
							}
						})}
					</Col>
				</Row>
			</Col>
		)
	}
	const checkDisableRadio = useCallback(
		(item: any, group: any) => {
			let disable = false
			if (item.requires.length > 0) {
				let hasOne = false
				item.requires.map((x: any) => {
					if (checkedList.find((y: any) => y.code === x)) {
						hasOne = true
					}
				})

				disable = !hasOne
			}
			// nếu trong list check có phần tử excludes item thì disable item
			if (checkedList.length > 0 && checkedList.find((x: any) => x.excludes && x.excludes.indexOf(item.code) > -1)) {
				disable = true
			}

			if (item.excludeGroups) {
				item.excludeGroups.map((x: any) => {
					let temp = checkedList.find((xx: any) => xx.serviceGroup && xx.serviceGroup.code === x)
					if (temp && temp.needApprove && temp.approved === null) {
						disable = true
					}
				})
			}
			return disable
		},
		[checkedList]
	)
	const onChangeRadio = useCallback(
		(e: any) => {
			// lấy ra item được chọn
			let item = services.find((x: any) => x.code === e.target.value)
			if (!item) return

			let items = lodash.cloneDeep(checkedList)

			//loại bỏ các phần tử trong mảng checkedList nếu item có excludes phần tử đó
			items = items.filter((x: any) => item.excludes.indexOf(x.code) === -1)
			if (item.excludeGroups)
				items = items.filter(
					(x: any) => !x.serviceGroup || (x.serviceGroup && item.excludeGroups.indexOf(x.serviceGroup.code) === -1)
				)

			//loại bỏ các phần từ trong mảng checkedList nếu các phần tử đó cùng group với item và group có single === true
			if (item.serviceGroup && item.serviceGroup.single) {
				let temp = items.find((x: any) => x.serviceGroup && x.serviceGroup.code === item.serviceGroup.code)
				// loại bỏ các phân tử đã được check nhưng có requires của các phần sắp bị loại bỏ do cùng group với item
				// và ko còn chứa bất kỳ 1 required nào khác
				//loại bỏ các phần tử trong cùng group ra khỏi checkedList
				if (temp)
					items = items.filter((x: any) => temp.requires && temp.requires.indexOf(x.code) == -1 && x.code !== temp.code)
			}

			items.push(item)
			// tự động thêm các phần tử thuộc dependencies của item
			if (Array.isArray(item.dependencies) && item.dependencies.length > 0) {
				item.dependencies.map((x: any) => {
					let depend = services.find((y: any) => y.code === x)
					if (depend) {
						items.push(depend)
					}
				})
			}
			setCheckedList(items)
		},
		[checkedList, services, setCheckedList]
	)
	const renderRadioGroup = (group: any) => {
		const items = checkedList.filter((x: any) => x.serviceGroup && x.serviceGroup.code === group.code)
		const currentItem = checkedList.find((x: any) => x.serviceGroup && x.serviceGroup.code === group.code)
		let disabled = checkedList.find((x: any) => x.excludeGroups && x.excludeGroups.indexOf(group.code) > -1)
		if (!disabled) {
			checkedList.find((x: any) => {
				if (x.dependencies) {
					x.dependencies.map((y: any) => {
						if (items.find((z: any) => z.code === y)) disabled = true
					})
				}
			})
		}
		return (
			<Col className={'mgt5 pd-l-0'}>
				<Row key={group.code}>
					<Col span={4}>
						<span className={'robotoregular txt-size-h7 txt-color-gray4 whitespace'}>{`${group.name}:`}</span>
					</Col>
					<Col span={20}>
						<Radio.Group
							key={group.id}
							onChange={onChangeRadio}
							disabled={disabled}
							className={'width100'}
							value={currentItem ? currentItem.code : null}>
							<Row>
								{group.services.map((x: any) => {
									x.colorService = 'service-normal'
									return (
										<Col span={6}>
											<Radio
												key={x.code}
												className={`_service-checkbox horizontal-radio serviceoption__checkbox`}
												value={x.code}
												disabled={checkDisableRadio(x, group)}>
												<span className={`txt-size-h7 ${x.colorService} robotoregular _service-name whitespace`}>
													{x.name}
												</span>
											</Radio>
										</Col>
									)
								})}
							</Row>
						</Radio.Group>
					</Col>
				</Row>
				<Row className={`mgt5`}>
					<Col span={4}></Col>
					<Col span={20}>
						{group.required && !currentItem ? (
							<Row className={'align-items-center mgbt5'}>
								<i className='txt-color-red mgr5 far fa-exclamation-circle'></i>
								<span className={'txt-color-h7 robotoregular'}>{t('message.serviceGroupRequire')}</span>
							</Row>
						) : null}
						{items.map((service: any) => {
							if (service.needApprove)
								return (
									<Row className={'align-items-center mgbt5'}>
										<i className='txt-color-orange fas fa-exclamation-triangle mgr5'></i>
										<span
											dangerouslySetInnerHTML={{
												__html: t('message.serviceNeedApprove', { name: service.name }),
											}}></span>
									</Row>
								)
							if (service.requires && service.requires.length > 0) {
								let hasError = false
								let name: string[] = []
								service.requires.map((x: any) => {
									let temp = services.find((xx: any) => xx.code === x)
									if(temp) {
										name.push(temp.name)
									}
									if (!checkedList.find((y: any) => y.code === x)) {
										hasError = true
										setIsAllow(false)
									}
								})
								if (hasError) {
									return (
										<Row className={'align-items-center mgbt5'}>
											<i className='txt-color-red fas fa-exclamation-triangle mgr5'></i>
											<span
												dangerouslySetInnerHTML={{
													__html: t('message.serviceRequire', {
														service: service.name,
														serviceRequire: name.toString(),
													}),
												}}></span>
										</Row>
									)
								}
							}
							if (service.requireGroups && service.requireGroups.length > 0) {
								let hasError = false
								let name: string[] = []
								service.requireGroups.map((x: any) => {
									let temp = serviceGroups.find((xx: any) => xx.code === x)
									name.push(temp.name)
									if (!checkedList.find((y: any) => y.serviceGroup && y.serviceGroup.code === x)) {
										hasError = true
										setIsAllow(false)
									}
								})
								if (hasError) {
									return (
										<Row className={'align-items-center mgbt5'}>
											<i className='txt-color-red fas fa-exclamation-triangle mgr5'></i>
											<span
												dangerouslySetInnerHTML={{
													__html: t('message.serviceGroupRequire2', {
														service: service.name,
														serviceGroup: name.toString(),
													}),
												}}></span>
										</Row>
									)
								}
							}
						})}
					</Col>
				</Row>
			</Col>
		)
	}
	const renderGroup = (group: any) => {
		if (!group.single) {
			return renderCheckboxGroup(group)
		} else {
			return renderRadioGroup(group)
		}
	}
	return (
		<div>
			{/* // single: true =>  chi chon 1
			required: bat buoc phai chon */}
			{/* defaultApplied : mac dinh duoc chon 
			dependencies: muon tich dich vu nay thi phai chon dich vu co trong dependencies[]
			excludeGroups: chon dich vu nay thi khong duoc chon nhung dich vu trong nhom
			excludes: chon dich vu nay thi khong duoc chon dich vu kia trong excludes[]
			needApprove: dich vu phai duoc duyet
			onlyStaff: chi nhan vien duoc chon tren gd admin 
			shipping: day co phai la dich vu van chuyen hay khong */}
			{serviceGroups && serviceGroups.length > 0 ? (
				serviceGroups.map((group: any, index: number) => {
					return renderGroup(group)
				})
			) : (
				<div>
					<Row className={'justify-content-center'}>
						<Image preview={false} className={'icon-empty'} src={empty}></Image>
					</Row>
					<Row className={'mgt10 justify-content-center'}>
						<span className={'txt-color-gray'}>{t('shopping-cart.emptyService')}</span>
					</Row>
				</div>
			)}
		</div>
	)
}

export default Services

