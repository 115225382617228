import { Repository } from '../core/repositories/Repository';
import { httpConfig } from '../core/config/http';
import { API_BASE_URL } from '../core/config';
import { url } from '../core/helpers/string';
import { AxiosResponse } from 'axios';
import {
  IntegrationCreateCommand,
  PriceConfigureBody,
  ShopCriteria,
} from '../Interface/ConnectShopInterface';

export class ConnectMarketRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.setBaseURL(url(API_BASE_URL, ''));
  }

  getMarketChannel = (): Promise<any> => {
    return this.http.get(`channels`).then((response: AxiosResponse) => {
      return response.data;
    });
  };

  getChannelShops = (filter: ShopCriteria, channel: string): Promise<any> => {
    return this.http
      .get(`integrations`, {
        params: { ...filter, sort: 'timestamp:desc', channel },
      })
      .then((response: AxiosResponse) => {
        return response;
      });
  };

  connectShop = (body: IntegrationCreateCommand) => {
    return this.http
      .post(`integrations`, body)
      .then((response: AxiosResponse) => response.data);
  };

  priceConfigure = (body: PriceConfigureBody) => {
    return this.http
      .patch(`integrations/price-configure`, body)
      .then((response: AxiosResponse) => response.data);
  };

  getCategories = (body: any) => {
    return this.http.get(
      `/integrations/item-categories?channel=${body.chanel}&integrationId=${body.integrationId}`
    );
  };

  getAllAttributesOfCategory = (
    channel?: string,
    integrationId?: string,
    categoryId?: number
  ) => {
    return this.http.get(
      `/integrations/item-attributes?channel=${channel}&integrationId=${integrationId}&categoryId=${categoryId}`
    );

    //   return Promise.resolve( {
    //     data: [{
    //       "attributeId": 1212,
    //       "attributeName": "Brand",
    //       "isMandatory": true,
    //       "attributeType": "STRING_TYPE",
    //       "inputType": "DROP_DOWN",
    //       "options": [
    //           "NoBrand"
    //       ],
    //       "values": [
    //           {
    //               "original_value": "NoBrand",
    //               "translate_value": "NoBrand"
    //           }
    //       ],
    //       "formatType": null,
    //       "unitList": null
    //   }, {
    //     "attributeId": 1234,
    //     "attributeName": "Brand",
    //     "isMandatory": true,
    //     "attributeType": "STRING_TYPE",
    //     "inputType": "DROP_DOWN",
    //     "options": [
    //         "NoBrand"
    //     ],
    //     "values": [
    //         {
    //             "original_value": "NoBrand",
    //             "translate_value": "NoBrand"
    //         }
    //     ],
    //     "formatType": null,
    //     "unitList": null
    // }]
    // })
  };
}
export const connectMarketRepository = new ConnectMarketRepository();
