import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { formatMoneyByUnit } from '../../../../core/helpers/string'
import { ProviderInterface } from '../../../../Interface/AccountBalanceInterface'
import ShipmentFeeInterface from '../../../../Interface/ShipmentFeeInterface'
import ShipmentInterface from '../../../../Interface/ShipmentInterface'
import Item from './Item'
interface FinanceProps {
	shipment?: ShipmentInterface
	fees: ShipmentFeeInterface[]
	providers: ProviderInterface[]
	expand: boolean
	loading: boolean
	loadingFees: boolean
}
const Finance: React.FC<FinanceProps> = (props) => {
	const { t } = useTranslation()
	const { shipment, fees, providers, expand, loading, loadingFees } = props
	const [currency, setCurrency] = useState('')
	const [currencyMarketplace, setCurrencyMarketplace] = useState('')
	const [totalFees, setTotalFees] = useState(0)
	const [feeSubLabel, setFeeSubLabel] = useState('')
	const [totalUnpaid, setTotalUnpaid] = useState(0)
	const [totalRefund, setTotalRefund] = useState('')
	const [totalPaid, setTotalPaid] = useState(0)

	useEffect(() => {
		const provider = providers.find((prod: ProviderInterface) => prod.code === shipment?.provider)
		const currency = provider?.currency
		if (currency) {
			setCurrency(JSON.parse(currency).code)
		}
		setCurrencyMarketplace(shipment?.currency!)
		let totalFees = 0
		let feeSubLabel = '(2) = '

		fees.forEach((fee: ShipmentFeeInterface, idx: number) => {
			if (!fee.free) {
				if (fee.manual && fee.actualAmount) {
					totalFees += fee.actualAmount
				} else if (!fee.manual && fee.provisionalAmount) {
					totalFees += fee.provisionalAmount
				}
			}
			feeSubLabel += `2.${idx + 1} ${idx !== fees.length - 1 ? '+' : ''} `
		})
		setTotalFees(totalFees)
		setFeeSubLabel(feeSubLabel)
		const totalPaid = shipment?.totalPaid ? shipment?.totalPaid : 0
		setTotalPaid(shipment?.totalPaid)
		const totalRefund = shipment?.totalRefund ? shipment?.totalRefund : 0
		setTotalRefund(shipment?.totalRefund)
		setTotalUnpaid(totalFees - totalPaid + totalRefund)
	}, [
		fees,
		providers,
		shipment?.currency,
		shipment?.provider,
		shipment?.totalPaid,
		shipment?.totalRefund,
		shipment?.totalValue,
	])

	return (
		<div className={`finance ${expand ? '' : 'finance-collapse'}`}>
			<span className='finance-title'>{!loading ? t('shipment.finance') :<Skeleton width={150}/>}</span>
			{expand && (
				<div className='finance-details'>
					<div className='finance-details-fees'>
						<Item
							label={t('shipment.cash')}
							position={'(1)'}
							currency={currency}
							currencyMarketplace={currencyMarketplace}
							value={shipment?.declareValue!}
							loading={loading}
						/>
						<Item
							label={t('shipment.totalFees')}
							currency={currency}
							currencyMarketplace={currencyMarketplace}
							position={feeSubLabel}
							value={totalFees}
							loading={loading}
						/>
						{fees.map((fee: ShipmentFeeInterface, idx: number) => (
							<div className='fee-items' key={idx}>
								<span className='label flex align-items-center'>
									{!loadingFees ? fee.type?.name ? fee.type?.name : '---' : <Skeleton width={60}/>}
									{!loadingFees && <span className='label-notice'>{`(2.${idx + 1})`}</span>}
								</span>
								{fee.manual || fee.free ? (
									<div className='flex justify-content-end'>
										{fee.provisionalAmount && (
											<span className={'txt-size-h8 txt-color-secondary txt-linethrough mgr5'}>
												{!loadingFees ? formatMoneyByUnit(fee.provisionalAmount, currency) : <Skeleton width={80}/>}
											</span>
										)}
										<span className={'txt-size-h8 txt-color-black'}>
											{!loadingFees ? fee.free ? t('shipment.feeFree') : formatMoneyByUnit(fee.actualAmount, currency) : <Skeleton width={80}/>}
										</span>
									</div>
								) : (
									<span>{!loadingFees ? formatMoneyByUnit(fee.provisionalAmount, currency) : <Skeleton width={80}/>}</span>
								)}
							</div>
						))}
					</div>
					<div className='summary'>
						<Item
							label={t('shipment.paid')}
							currency={currency}
							value={totalPaid}
							// valueExchanged={grandTotal}
							position={`(3)`}
							loading={loading}
						/>
						<Item
							position={'(4)'}
							label={t('shipment.totalRefund')}
							value={totalRefund}
							currency={currency}
							loading={loading}
						/>
						{shipment && shipment.status !== 'CANCELLED' ? (
							<Item
								position={'(5)'}
								label={t(`shipment.${totalUnpaid >= 0 ? 'totalUnpaid' : 'totalCash'}`)}
								value={totalUnpaid ? Math.abs(totalUnpaid) : totalUnpaid}
								valueClass='txt-color-red robotomedium'
								labelClass='robotomedium'
								currency={currency}
								loading={loading}
							/>
						) : null}
						{shipment?.totalClaim && (
							<Item
								position={'(7)'}
								label={t('shipment.totalClaim')}
								value={lodash.get(shipment, 'totalClaim', 0)}
								currency={currency}
								loading={loading}
							/>
						)}
						{shipment?.totalCollect && (
							<Item
								position={'(8)'}
								label={t('shipment.totalCollect')}
								value={Math.abs(lodash.get(shipment, 'totalCollect', 0))}
								currency={currency}
								loading={loading}
							
							/>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default Finance
