import { Avatar, Button, Card, Checkbox, Col, Divider, List, Row } from 'antd'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import EmptyCart from '../../Empty/EmptyCart'
import ConfirmModal from '../../Modal/ConfirmModal'
import M24Pagination from '../../Pagination/M24Pagination'
import ProductItem from '../ProductItem'
import './styles.scss'

type Props = {
	handleClickOrderBtn: () => void
	cartItem: any
	filter: any
	pagination: any
	getCartData: (temp: any) => void
	onPageChange: (filter: any) => void
	handleDeleteSku: (merchantId: any, productId: any, skuId: any, callback: () => void) => void
	handleDeleteMerchant: (merchantId: any, callback: () => void) => void
	handleUpdateQuantitySku: (
		merchantId: any,
		quantify: number,
		productId: any,
		skuId: any,
		callback: () => void
	) => void
	isDeletingMerchant: boolean
	isDeletingSKU: boolean
	handleUpdateEmployeeNote: (skuId: any, value: string, callback: () => void) => void
	handleUpdatePersonalNote: (skuId: any, value: string, callback: () => void) => void
	selectMerchant: (merchant: any, isSelect: boolean) => void
	selectSku: (merchant: any, product: any, sku: any, isSelect: boolean) => void
	merchantSelected: any
	handleShowProgressBar: (val: boolean) => void
	onCreateShipmentAndOrder: () => void
}

const MerchantList: React.FC<Props> = (props) => {
	const { t } = useTranslation()
	const [showConfirm, setShowConfirm] = useState<boolean>(false)
	const [merchantIdSelected, setMerchantId] = useState<string>()
	const {
		handleDeleteSku,
		cartItem,
		isDeletingMerchant,
		isDeletingSKU,
		handleDeleteMerchant,
		handleUpdateQuantitySku,
		handleUpdateEmployeeNote,
		handleUpdatePersonalNote,
		pagination,
		filter,
		merchantSelected,
		selectMerchant,
		selectSku,
		handleShowProgressBar,
		onCreateShipmentAndOrder,
	} = props

	const [totalSKU, setTotalSKU] = useState<number>(0)
	const [totalLink, setTotalLink] = useState<number>(0)
	useEffect(() => {
		if (merchantSelected && merchantSelected.products && merchantSelected.products.length > 0) {
			let total = 0
			let totalLink = 0
			merchantSelected.products.map((x: any) => {
				totalLink = totalLink + x.skus.length
				x.skus.map((sku: any) => {
					total = total + sku.quantity
				})
			})
			setTotalSKU(total)
			setTotalLink(totalLink)
		} else {
			setTotalSKU(0)
			setTotalLink(0)
		}
	}, [merchantSelected])
	const onSubmitConfirm = () => {
		handleDeleteMerchant(merchantIdSelected, () => {
			setShowConfirm(false)
			setMerchantId('')
		})
	}
	const onCancelConfirm = () => {
		setShowConfirm(false)
	}
	const renderMerchants = () => {
		return (
			<>
				<List
					pagination={false}
					dataSource={cartItem}
					rowKey={(record: any) => record.id}
					renderItem={(item: any) => {
						console.log('item', item.id)
						if (item.skus && item.skus.length == 0) return
						let totalQuantity: number = 0
						let totalPrice: number = 0
						let merchantId = lodash.get(item, 'id', '')
						let totalSku = 0
						let totalLink = 0
						item.products.map((product: any) => {
							let total: number = 0
							let price: any = 0
							totalSku = totalSku + product.skus.length
							totalLink = totalLink + product.skus.length
							product.skus.map((xx: any) => {
								total = total + xx.quantity
							})
							if (product.pricePolicies && product.pricePolicies.length > 0) {
								let pricePolicies = lodash.sortBy(product.pricePolicies, ['minQuantity'])
								if (total < pricePolicies[0].minQuantity) {
									price = pricePolicies[0].salePrice
								} else if (total > pricePolicies[pricePolicies.length - 1].maxQuantity) {
									price = pricePolicies[pricePolicies.length - 1].salePrice
								} else {
									pricePolicies.map((x: any) => {
										if (total >= x.minQuantity && total <= x.maxQuantity) {
											price = x.salePrice
										}
									})
								}
								totalPrice += total * price
							} else {
								product.skus.map((xx: any) => {
									totalPrice += xx.salePrice * xx.quantity
								})
							}
							totalQuantity = totalQuantity + total
						})
						let currency = lodash.get(item, 'marketplace.defaultCurrency', '')
						let isSelected = false
						console.log(
							'merchantSelected.merchant.id ',
							merchantSelected.merchant.id 
						)
						console.log('merchantId', merchantId)
						if (merchantSelected.merchant.id && merchantId === merchantSelected.merchant.id) {
							isSelected = true
						}
						let indeterminate = false
						if (isSelected) {
							indeterminate = true
							let totalSkuSelected = 0
							merchantSelected.products.map((x: any) => {
								totalSkuSelected = totalSkuSelected + x.skus.length
								// x.skus.map((xx: any)=>{
								//     totalSkuSelected=totalSkuSelected+xx.quantity;
								// });
							})
							if (totalSkuSelected === totalSku) indeterminate = false
						}
						let merchant = {
							id: merchantId,
							name: lodash.get(item, 'name', ''),
							icon: lodash.get(item, 'marketplace.icon', ''),
						}

						return (
									<Card
										bodyStyle={{ paddingTop: 5, paddingBottom: 5 }}
										className={`${isSelected ? 'active' : ''} merchant-card-custom`}
										title={
											<Row align={'middle'} onClick={() =>selectMerchant(merchant, !isSelected)}>
												<Col span={20}>
													<Checkbox
														indeterminate={indeterminate}
														onChange={(e) => selectMerchant(merchant, e.target.checked)}
														checked={isSelected}
														className='mg-r-16'
													/>
													<Avatar
														className={'bd-none-impt'}
														shape={'square'}
														size={20}
														src={lodash.get(item, 'marketplace.icon')}
													/>
													<span
														className={
															'mgl10 mgr5 fsz-12 robotoregular txt-color-gray4 line-h-20'
														}>
														{t('shopping-cart.seller')}:{' '}
													</span>
													<a
														href={item.url}
														target={'_blank'}
														className={
															'robotoregular txt-color-black fsz-12 line-h-20 txt-color-black line-h-20'
														}
														rel='noreferrer'>
														{lodash.get(item, 'name', '')}
													</a>
													<Divider type={'vertical'} />
													<span className={'robotoregular fsz-12 txt-color-black line-h-20'}>
														{`${totalQuantity} ${t(
															'shopping-cart.products'
														)}/${totalLink} link`}
													</span>
													<Divider type={'vertical'} />
													<span className={'robotoregular fsz-12 txt-color-black line-h-20'}>
														{t('shopping-cart.totalPaid')}:
														<span className={'mgl5'}>
															{formatMoneyByUnit(totalPrice, currency)}
														</span>
													</span>
												</Col>
												<Col span={4} className='flex justify-content-end'>
													<Button
														disabled={!merchantId}
														style={{ paddingRight: 0 }}
														type='text'
														onClick={() => {
															setShowConfirm(true)
															setMerchantId(merchantId)
														}}
														className={
															'txt-size-h8 txt-color-black robotoregular line-h-20 pd-0 h-20 button-delete-all txt-right'
														}
														icon={<i className='fad fa-trash mg-r-5' />}>
														{t('shopping-cart.deleteMerchant')}
													</Button>
												</Col>
											</Row>
										}>
										{item.products.map((product: any, index: number) => {
											console.log('product', product)
											if (product.skus && product.skus.length > 0) {
												let total: number = 0
												let price: any = null
												product.skus.map((xx: any) => {
													total = total + xx.quantity
												})
												let productsSelected = merchantSelected.products.find(
													(x: any) => x.id === product.id
												)
												if (product.pricePolicies && product.pricePolicies.length > 0) {
													let pricePolicies = lodash.sortBy(product.pricePolicies, [
														'minQuantity',
													])
													if (total < pricePolicies[0].minQuantity) {
														price = pricePolicies[0].salePrice
													} else if (
														total > pricePolicies[pricePolicies.length - 1].maxQuantity
													) {
														price = pricePolicies[pricePolicies.length - 1].salePrice
													} else {
														pricePolicies.map((x: any) => {
															if (total >= x.minQuantity && total <= x.maxQuantity) {
																price = x.salePrice
															}
														})
													}
												}
												return (
													<List
														key={product.id}
														itemLayout='horizontal'
														dataSource={product.skus}
														rowKey={(record => record.id)}
														renderItem={(sku: any) => {
															console.log('sku id', sku.id)
															let isSkuSelected = false
															if (productsSelected) {
																const curSku = productsSelected.skus.find(
																	(x: any) => x.id === sku.id
																)
																if(curSku) {
																	isSkuSelected = true
																}else {
																	isSkuSelected = false
																}
															}
															console.log('isSkuSelected', isSkuSelected)
															return (
																<List.Item>
																	<ProductItem
																		handleUpdateEmployeeNote={
																			handleUpdateEmployeeNote
																		}
																		handleUpdatePersonalNote={
																			handleUpdatePersonalNote
																		}
																		handleDeleteSku={(productId, skuId, callback) =>
																			handleDeleteSku(
																				item.id,
																				productId,
																				skuId,
																				callback
																			)
																		}
																		handleUpdateQuantitySku={(
																			quantity,
																			productId,
																			skuId,
																			callback
																		) =>
																			handleUpdateQuantitySku(
																				item.id,
																				quantity,
																				productId,
																				skuId,
																				callback
																			)
																		}
																		product={sku}
																		salePrice={price}
																		isDeleting={isDeletingSKU}
																		currency={currency}
																		isSelected={isSkuSelected}
																		selectSku={(isSelect) => {
																			console.log('isSelect in List', isSelect)
																			selectSku(merchant, product, sku, isSelect)
																		}}
																		handleShowProgressBar={(val: boolean) =>
																			handleShowProgressBar(val)
																		}
																	/>
																</List.Item>
															)
														}}
													/>
												)
											}
										})}
									</Card>
						)
					}}></List>
				<M24Pagination
					filter={filter}
					pagination={pagination}
					onPageChange={props.onPageChange}
					className='my-12 mx-0'
				/>
			</>
		)
	}
	return (
		<>
			<div className='cart-list'>
				{cartItem.length > 0 ? (
					renderMerchants()
				) : (
					<EmptyCart className='min-h-100vh-248' emptyText={t('shopping-cart.emptyText')} />
				)}
			</div>
			<div className={'merchant-card-bottom'}>
				<div className={'align-items-center'}>
					<Row className={'txt-size-h8 txt-color-secondary'}>{t('shopping-cart.orderInformation')}</Row>
					<Row className={'mgt5 txt-size-h6 robotobold'}>{`${
						merchantSelected?.merchant?.id ? '1' : '---'
					} Shop /  ${totalSKU ? totalSKU : '---'} ${t('shopping-cart.products')} / ${
						totalLink ? totalLink : '---'
					} Link`}</Row>
				</div>

				<div className={'align-item-center'}>
					<Button
						disabled={!merchantSelected.merchant?.id}
						className='btn-df btn-df-secondary-bg-white robotomedium txt-capitalize mg-r-12'
						size={'large'}
						icon={<i className='fa-solid fa-plus mg-r-10'></i>}
						onClick={onCreateShipmentAndOrder}>
						{/* {t('shopping-cart.createShipmenAndOrders')} */}
						Tạo Đơn TTH + Ký Gửi
					</Button>
					<Button
						className='btn-df btn-df-secondary-bg-lemon txt-capitalize'
						disabled={!merchantSelected.merchant?.id}
						size={'large'}
						onClick={props.handleClickOrderBtn}
						icon={<i className='fal fa-check mg-r-10' />}>
						{t('shopping-cart.orderAction')}
					</Button>
				</div>
			</div>

			{showConfirm && (
				<ConfirmModal
					title={`${t('cart.deleteMerchant')}`}
					visible={showConfirm}
					loading={isDeletingMerchant}
					content={t('shopping-cart.confirmDeleteMerchant')}
					onSubmit={onSubmitConfirm}
					onCancel={onCancelConfirm}
					iconBtn={<i className='mg-r-10 fa-solid fa-trash' />}
					classButton='btn-df btn-df-warning-bg-white'
				/>
			)}
		</>
	)
}

export default MerchantList