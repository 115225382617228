import { Avatar, Button, Divider, Image, Row, Table, Tooltip } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import M24Pagination from '../../../components/Pagination/M24Pagination'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../components/SkeletonTable'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatNumber } from '../../../core/helpers/string'
import { deliveryRepository } from '../../../repositories/DeliveryRepository'
import { getCleanFormFilter } from '../../../utils/StringUtils'
import NestedTable from './NestedTable'

interface ListProps {
	items: any
	statuses: any
	accounts: any
	total: number
	page: number
	size: number
	filter: any
	idAcc: string | undefined
	showProgressBar: boolean
	handleFilter: (filter: any) => void
}
const List: React.FC<ListProps> = (props) => {
	const [searchParams] = useSearchParams()
	const { t } = useTranslation()
	const { items, statuses, handleFilter, accounts, total, page, size, showProgressBar, filter, idAcc } = props
	const [expandedRows, setExpandedRows] = useState<Array<any>>([])
	const [packages, setPackages] = useState<{ [id: string]: [] }>({})
	const [loading, setLoading] = useState<{ [id: string]: any }>({})
	const [logo, setLogo] = useState('')
	const [provider, setProvider] = useState('')
	const [customer, setCustomer] = useState('')
	const navigate = useNavigate()

	const [loadingState, setLoadingState] = useState(true)

	useEffect(() => {
		setTimeout(() => setLoadingState(false), 3000)
	}, [])

	useEffect(() => {
		const indexAcc = accounts.findIndex((acc: any) => acc.id === idAcc)
		if (indexAcc > -1 && !showProgressBar) {
			const logo = lodash.get(accounts[indexAcc], 'provider.logo', '')
			const provider = lodash.get(accounts[indexAcc], 'provider.code', '')
			const customer = lodash.get(accounts[indexAcc], 'name', '')
			setLogo(logo)
			setProvider(provider)
			setCustomer(customer)
		}
	}, [accounts, idAcc, showProgressBar])

	const emptyText = '---'
	const classes = 'robotoregular fsz-14 line-h-22 txt-color-black whitespace'
	const columns = [
		{
			title: <span className='fsz-14 line-h-22 robotomedium'>{ t('requestDelivery.code')}</span>,
			key: 'code',
			dataIndex: 'code',
			className: 'fsz-14 line-h-22 robotomedium txt-color-black',
			width: 120,
			render: (code: string) => (
				<div className='dpl-flex align-items-center'>
					<i
						className={`far fa-angle-${
							lodash.includes(expandedRows, code) ? 'down' : 'right'
						} txt-color-orange2 w-8 line-h-22 cursor-pointer mg-r-6`}></i>
					<Paragraph
						className='robotomedium mg-0 txt-color-orange1 fsz-14 line-h-22 order-heading whitespace'
						copyable={{
							text: code,
							icon: [
								<Tooltip
									title={<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copy</span>}
									color='#FBB739'>
									<i className='far fa-copy line-h-14 w-12 txt-color-orange2'></i>
								</Tooltip>,
								<Tooltip
									title={<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copied</span>}
									color='#FBB739'>
									<i className='far fa-check fsz-12 line-h-14 w-12 txt-color-orange2'></i>
								</Tooltip>,
							],
							tooltips: [false, false],
						}}>
						{code}
					</Paragraph>
				</div>
			),
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium'>{t('requestDelivery.account')}</span> ,
			key: 'account',
			className: 'fsz-14 line-h-22 robotomedium txt-color-black',
			width: 250,
			ellipsis: true,
			render: (record: any) => (
				<div className='dpl-flex align-items-center whitespace'>
					<Avatar size={16} src={logo} className='bd-none-impt w-12 h-12 dpl-flex align-items-center mg-r-4' />
					<span className={`${classes} mg-r-4`}>{provider}</span>
					<Divider type={'vertical'} className={'bd-color-black line-h-22 mg-0 mg-r-4'} />
					<span className={classes}>{customer}</span>
				</div>
			),
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium'>{t('requestDelivery.createdAt')}</span>,
			key: 'createdAt',
			dataIndex: 'createdAt',
			className: 'fsz-14 line-h-22 robotomedium txt-color-black',
			width: 160,
			align: 'right' as 'right',
			render: (createdAt: any) => <span className={classes}>{formatDateTime(createdAt)}</span>,
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium'>{t('requestDelivery.status')}</span>,
			key: 'status',
			dataIndex: 'status',
			className: 'fsz-14 line-h-22 robotomedium txt-color-black',
			width: 160,
			render: (status: any) => {
				const statusObj = statuses.find(({ code }: any) => code === status)
				const statusName = lodash.isNull(lodash.get(statusObj, 'name'))
					? emptyText
					: lodash.get(statusObj, 'name', emptyText)
				return (
					<span
						className='robotoregular fsz-12 line-h-20 txt-color-white border-radius25 py-3 px-12 whitespace txt-capitalize'
						style={{
							backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
								? '#09B2AA'
								: lodash.get(statusObj, 'color'),
						}}>
						{statusName}
					</span>
				)
			},
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium'>{t('requestDelivery.weight')}</span>,
			key: 'totalWeight',
			dataIndex: 'totalWeight',
			className: 'fsz-14 line-h-22 robotomedium txt-color-black',
			width: 120,
			align: 'right' as 'right',
			render: (totalWeight: any) => {
				return (
					<span className='robotoregular txt-color-black fsz-14 line-h-22 whitespace'>
						{`${formatNumber(totalWeight)} kg`}
					</span>
				)
			},
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium'>{t('requestDelivery.address')}</span>,
			key: 'address',
			width: '40%',
			ellipsis: true,
			render: (record: string) => (
				<span className='robotoregular txt-color-black fsz-14 line-h-22 whitespace'>
					{`${lodash.get(record, 'address.fullName')}, ${lodash.get(record, 'address.address')},${lodash.get(
						record,
						'address.location.display'
					)}`}
				</span>
			),
		},
	]

	const handleChangePage = (filter: any) => {
		const { page, size, providerUsername } = filter
		handleFilter(
			getCleanFormFilter({
				...filter,
				customer: providerUsername,
				page: (page - 1).toString(),
				size: size.toString(),
			})
		)
	}

	return (
		<div className='white-box dpl-flex flex-col pd-imp-12'>
			<div className='dpl-flex align-items-center justify-content-between mg-bt-13'>
				<div className='dpl-flex align-items-center mg-r-4'>
					<h3 className='robotomedium mg-0 fsz-16 line-h-24 txt-color-black mg-r-4'>{t('requestDelivery.list')}</h3>
					<span
						className={`${
							total > 0 ? 'bg-color-primary txt-color-white' : 'bg-color-gray-badge txt-color-gray4'
						} border-radius20  fsz-12 line-h-20 px-8`}>
						{total}
					</span>
				</div>
				<Button
					className='btn-df btn-df-secondary-bg-lemon fsz-14 line-h-22 bd-none border-radius4
													px-12 py-3'
					icon={<i className='far fa-plus txt-color-black mg-r-10'></i>}
					onClick={() => navigate('/delivery/create')}>
					{t('requestDelivery.createRequestDelivery')}
				</Button>
			</div>
			<SkeletonTable
				loading={showProgressBar || loadingState}
				columns={columns as SkeletonTableColumnsType[]}
				className='main-table table-expandable selected-table mg-bt-13'
				rowCount={items?.length > 0 ? items?.length  : 0}>
				<Table
					rowKey={(record: any) => record.code}
					columns={columns}
					dataSource={items}
					locale={{
						emptyText: (
							<EmptyDataFilter
								className={showProgressBar || loadingState ? 'dpl-none' : ''}
								description={
									!searchParams.get('query') &&
									!searchParams.get('statuses') &&
									!searchParams.get('createdAtFrom') &&
									!searchParams.get('createdAtTo')
									&&!searchParams.get('customer') && !searchParams.get('provider')
										? t('deliveryRequest.noData')
										: t('deliveryRequest.filterEmpty')
								}
							/>
						),
					}}
					expandRowByClick={true}
					className='main-table table-expandable selected-table mg-bt-13'
					pagination={false}
					expandable={{
						expandedRowRender: (record) => <NestedTable items={packages} loading={loading} record={record} />,
						expandIcon: ({ expanded, onExpand, record }) => null,
						columnWidth: 0,
						onExpand: (expanded, record) => {
							if (expanded && !packages[record.code]) {
								setLoading({ ...loading, [record.code]: true })
								deliveryRepository
									.getPackages(
										lodash.get(record, 'code'),
										searchParams.get('customer'),
										searchParams.get('provider')
									)
									.then((res) => {
										setPackages({ ...packages, [record.code]: [...res] })
									})
									.catch((err) => {})
									.finally(() => setLoading({ ...loading, [record.code]: false }))
							}
						},
						onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
					}}
					rowClassName={(record) =>
						lodash.includes(expandedRows, record.code) ? 'active-row cursor-pointer' : 'data-row cursor-pointer'
					}
				/>
			</SkeletonTable>
			<Row justify={'end'} className={''}>
				{items && items.length > 0 && (
					<M24Pagination
						onPageChange={handleChangePage}
						filter={{ ...getCleanFormFilter(filter), page, size }}
						pagination={{
							current: page,
							pageSize: size,
							hideOnSinglePage: true,
							total,
						}}
						className='mg-0'
					/>
				)}
			</Row>
		</div>
	)
}

export default List

