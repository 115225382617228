import { Avatar, Badge, Button, Col, Image, Modal, notification, Progress, Row, Table, Tooltip } from 'antd'
import lodash from 'lodash'
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DEFAULT_PAGE_SIZE } from '../../core/config'
import { formatNumber } from '../../core/helpers/string'
import { IMarketplace } from '../../Interface/MarketplaceInterface'
import { warehouseRepository } from '../../repositories/WarehouseRepository'
import M24ErrorUtils from '../../utils/M24ErrorUtils'
import M24Notification from '../../utils/M24Notification'
import EmptyDataFilter from '../Empty/EmptyDataFilter'
import M24Pagination from '../Pagination/M24Pagination'

interface PendingProductListProps {
	loading: boolean
	total: number | null
	productList: any[]
	selectedItems: string[]
	setSelectItems: Dispatch<SetStateAction<string[]>>
	searchParams: any
	setSearchParams: any
	onClickDelete: (record?: any | null) => void
	marketplaces: IMarketplace[]
}

const ActionIcon = (props: { classes: string }) => {
	const [isHovered, setHover] = useState<boolean>(false)
	return (
		<i
			className={`${isHovered ? 'fa-solid' : 'fa-light'} ${props.classes}`}
			onMouseOver={() => setHover(true)}
			onMouseLeave={() => setHover(false)}></i>
	)
}

function PendingProductList(props: PendingProductListProps) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const [productList, setProductList] = useState<any[]>([])
	const [hoveredButtonAtRowKey, setHoverButtonAtRowKey] = useState<null | string>(null)
	const [loadingUpProductToShopee, setLoadingUpProductToShopee] = useState(false)
	const [resShopee, setResShopee] = useState([])
	const [shopeeProgress, setShopeeProgress] = useState<ReactNode>()
	const [openUpShopee, setOpenUpShopee] = useState(false)
	const [loadingUpShopeeSingle, setLoadingUpShopeeSingle] = useState(false)

	useEffect(() => {
		setProductList(
			props.productList.map((item) => ({
				...item,
				startSkuIndex: 0,
				endSkuIndex: 3,
			}))
		)
	}, [props.productList])

	const onChangePage = (filter: any) => {
		props.setSearchParams({
			...Object.fromEntries(props.searchParams),
			page: filter.page,
			pageSize: filter.pageSize,
		})
	}

	const onClickRow = (record: any, rowIndex: number | undefined) => {
		if (Number.isInteger(rowIndex) && hoveredButtonAtRowKey !== `${record.id}`) {
			props.setSelectItems((prevSelectedItems: string[]) => {
				const selectedRowIndex = prevSelectedItems.indexOf(`${record.id}`)
				const clonedSelectedItems = [...prevSelectedItems]
				selectedRowIndex >= 0
					? clonedSelectedItems.splice(selectedRowIndex, 1)
					: clonedSelectedItems.push(`${record.id}`)
				return clonedSelectedItems
			})
		}
	}

	const onShowMoreSkus = (id: string, skusLength: number, type: string) => {
		setProductList((prevProductList) => {
			const matchProductIndex = prevProductList.map((product) => product.id).indexOf(id)
			if (matchProductIndex >= 0) {
				const clonedProductList = [...prevProductList]
				if (type === 'show-more') {
					clonedProductList[matchProductIndex].endSkuIndex += skusLength
				} else if (type === 'show-less') {
					clonedProductList[matchProductIndex].endSkuIndex = 3
				}
				return clonedProductList
			}
			return prevProductList
		})
	}

	const localeCustom = {
		emptyText: (
			<EmptyDataFilter
				description={
					!searchParams.get('name') ? t('pendingProduct.empty-list') : t('pendingProducts.filterEmpty')
				}
			/>
		),
	}

	const navigateToEditPage = (productId: string) => {
		navigate(`/pending-products/shopee/${productId}/edit`)
	}

	const upShopeeHandler = useCallback(
		async (id: string[]) => {
			setLoadingUpProductToShopee(true)
			try {
				const res = await warehouseRepository.upProductToShopeeMarketplace(id)
				if (res[0]?.error) {
					notification.error({ message: t('pendingProducts.shopee_error_param') })
				} else {
					M24Notification.notifySuccess(t('productForSell.post-to-sell-success'), '', '', 4)
					navigate('/pending-products/shopee')
				}
				setLoadingUpProductToShopee(false)
			} catch (error) {
				M24ErrorUtils.showError(t, error)
				setLoadingUpProductToShopee(false)
			}
		},
		[navigate, t]
	)

	useEffect(() => {
		let percent = 0
		const interval = setInterval(() => {
			percent += 10
			if (percent === 100) clearInterval(interval)
		}, 500)
		const curProgress = <Progress percent={percent} steps={100} size='small' strokeColor={'#3340B6'} />
		setShopeeProgress(curProgress)
	}, [])

	const columns: any = [
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium whitespace txt-color-gray4'>
					{t('orderDetail.productName')}
				</span>
			),
			dataIndex: 'name',
			key: 'name',
			width: '25%',
			render: (_: string, record: any) => (
				<Row gutter={[8, 0]} wrap={false}>
					<Col className={'dpl-flex flex-col align-self-center'}>
						<Image
							src={record.productImages[0]}
							preview={false}
							width={64}
							height={64}
							className='border-radius4 bd-none'
						/>
					</Col>
					<Row gutter={[0, 3]}>
						<Col xs={20} className={'pdl5'}>
							<span className='txt-size-h7 robotomedium line-h-22 txt-color-black'>{record.name}</span>
						</Col>
						<Col xs={24} className={'pdl5'}>
							<span className='shop-name txt-size-h8 robotoregular txt-color-blue6 dpl-il-block px-10 py-1 border-radius4 line-h-20 bd-color-blue6 bd1px'>
								{record.integrationName}
							</span>
						</Col>
					</Row>
				</Row>
			),
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium whitespace txt-color-gray4'>Marketplace</span>,
			dataIndex: 'marketplace',
			key: 'marketplace',
			width: '10%',
			render: (_: string, record: any) => {
				let marketplace = lodash.find(props.marketplaces, (m) => m.code === record.marketplace)
				return (
					<div className='flex flex-nowrap marketplace-row align-items-center'>
						<Avatar
							shape='square'
							src={lodash.get(marketplace, 'icon', '')}
							size={16}
							className='rad-2 bd-none-impt mg-r-4'
						/>
						<span className='txt-size-h7 robotoregular line-h-22 txt-color-black txt-capitalize '>
							{record.marketplace}
						</span>
					</div>
				)
			},
		},
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium whitespace txt-color-gray4'>
					{t('warehouse.skuProductTitle')}
				</span>
			),
			dataIndex: '',
			width: '10%',
			render: (_: string, record: any) => (
				<span className='product-code txt-size-h7 robotoregular line-h-22 txt-color-black'>
					{record.code ? record.code : '----'}
				</span>
			),
		},
		{
			title: (
				<Row align='middle'>
					<Col xs={11}>
						<span className='fsz-14 line-h-22 robotomedium whitespace txt-color-gray4'>
							{t('warehouse.skuProductSplitTitle')}
						</span>
					</Col>
					<Col xs={8} className={'txt-right'}>
						<span className={'pdr5 fsz-14 line-h-22 robotomedium whitespace txt-color-gray4'}>
							{t('warehouse.salePrice')}
						</span>
					</Col>
					<Col xs={5} className={'txt-right'}>
						<span className={'pdr5 fsz-14 line-h-22 robotomedium whitespace txt-color-gray4'}>
							{t('warehouse.stock')}
						</span>
					</Col>
				</Row>
			),
			// dataIndex: <span className='txt-color-gray4 fsz-14 line-h-22 robotomedium whitespace'>{t('warehouse.skuProductTitle')}</span>,
			width: '40%',
			render: (_: string, record: any) => (
				<Row gutter={[0, 2]}>
					{record.skus &&
						record.skus.slice(record.startSkuIndex, record.endSkuIndex).map((sku: any) => (
							<Col xs={24} key={`${sku.oid}`}>
								<Row align='middle' className='px-12 py-8 bg-color-gray-badge'>
									<Col xs={11}>
										<span className='txt-size-h7 line-h-22 txt-color-black roboteregular '>
											{sku.name}
										</span>
									</Col>
									<Col xs={8} className={'txt-right'}>
										<span className='txt-size-h7 line-h-22 txt-color-black robotomedium align-self-center'>
											{formatNumber(sku.salePrice)}
										</span>
									</Col>
									<Col xs={5} className={'txt-right'}>
										<span className='txt-size-h7 line-h-22 txt-color-orange2 robotomedium'>
											{formatNumber(sku.stock)}
										</span>
									</Col>
								</Row>
							</Col>
						))}
					{record.endSkuIndex < record.skus?.length && (
						<Col xs={24}>
							<Row justify='center' className={'mgt5'}>
								<span
									className='btn-show-more txt-size-h8 robotomedium txt-color-blue7'
									onMouseOver={() => setHoverButtonAtRowKey(`${record.id}`)}
									onMouseLeave={() => setHoverButtonAtRowKey(null)}
									onClick={() => {
										onShowMoreSkus(record.id, record.skus.length, 'show-more')
									}}>
									<i className='fa-regular fa-plus mgl5 mgr5' />
									{t('pendingProduct.display-more-sku')}
								</span>
							</Row>
						</Col>
					)}
					{record.endSkuIndex > record.skus?.length && record.skus?.length > 2 && (
						<Col xs={24}>
							<Row justify='center' className={'mgt5'}>
								<span
									className='btn-show-more txt-size-h8 robotomedium txt-color-blue7'
									onMouseOver={() => setHoverButtonAtRowKey(`${record.id}`)}
									onMouseLeave={() => setHoverButtonAtRowKey(null)}
									onClick={() => onShowMoreSkus(record.id, record.skus.length, 'show-less')}>
									<i className='fa-regular fa-hyphen mgl5 mgr5' />
									{t('pendingProduct.display-less-sku')}
								</span>
							</Row>
						</Col>
					)}
				</Row>
			),
		},
		{
			title: (
				<div className={'width100pc txt-center fsz-14 line-h-22 robotomedium whitespace txt-color-gray4'}>
					{t('connectMarket.action')}
				</div>
			),
			dataIndex: 'action',
			key: 'action',
			width: '10%',
			render: (_: string, record: any) => (
				<Row
					gutter={12}
					justify={'center'}
					className='action-row'
					onMouseOver={() => setHoverButtonAtRowKey(`${record.id}`)}
					onMouseLeave={() => setHoverButtonAtRowKey(null)}>
					<Col>
						<Tooltip
							placement='top'
							title={t('address.edit-action')}
							color='#3340B6'
							overlayClassName='action-tooltip'
							overlayInnerStyle={{
								borderRadius: '3px',
							}}>
							<Button
								type='text'
								icon={<ActionIcon classes='fa-sliders txt-size-h8 txt-color-blue6' />}
								onClick={() => navigateToEditPage(record.id)}></Button>
						</Tooltip>
					</Col>
					<Col>
						<Tooltip
							placement='top'
							title={t('warehouse.saleChoice')}
							color='#3340B6'
							overlayClassName='action-tooltip'
							overlayInnerStyle={{
								borderRadius: '3px',
							}}>
							<Button
								loading={loadingUpShopeeSingle}
								onClick={
									async () => {
										setLoadingUpShopeeSingle(true)
										try {
											const res = await warehouseRepository.upProductToShopeeMarketplace([
												record.id,
											])
											if (res[0]?.error) {
												notification.error({ message: t('pendingProducts.shopee_error_param') })
											} else {
												M24Notification.notifySuccess(
													t('productForSell.post-to-sell-success'),
													'',
													'',
													4
												)
												navigate('/pending-products/shopee')
											}
											setLoadingUpShopeeSingle(false)
										} catch (error) {
											M24ErrorUtils.showError(t, error)
											setLoadingUpShopeeSingle(false)
										}
									}
									// Modal.confirm({
									// 	title: t('pendingProducts.postedForSale'),
									// 	cancelText: t('button.exit'),
									// 	okText: t('button.submit'),
									// 	okButtonProps: {
									// 		className: 'btn-df btn-df-quaternary-bg-white fsz-14 line-h-22 py-4 px-12',
									// 		icon: <i className='fas fa-trash mg-r-10'></i>,
									// 		loading: loadingUpProductToShopee,
									// 	},
									// 	cancelButtonProps: {
									// 		className: 'btn-df btn-df-tertiary-bg-white fsz-14 py-4 line-h-22 px-12',
									// 		icon: <i className='fa-solid fa-xmark mg-r-10'></i>,
									// 	},
									// 	content: shopeeProgress,
									// 	centered: true,
									// 	width: 500,
									// 	visible: openUpShopee,
									// 	onOk: async () => {
									// 		setLoadingUpProductToShopee(true)
									// 		try {
									// 			const res = await warehouseRepository.upProductToShopeeMarketplace([record.id])
									// 			setResShopee(res)
									// 			setLoadingUpProductToShopee(false)
									// 			if(res[0].error) {
									// 				setOpenUpShopee(true)
									// 			}
									// 		} catch (error) {
									// 			M24ErrorUtils.showError(t, error)
									// 			setLoadingUpProductToShopee(false)
									// 		}
									// 	},
									// })
								}
								type='text'
								icon={<ActionIcon classes='fa-cart-plus txt-size-h8 txt-color-blue6' />}
							/>
						</Tooltip>
					</Col>
					<Col>
						<Tooltip
							placement='top'
							title={t('address.delete-action')}
							color='#FF4559'
							overlayClassName='action-tooltip'
							overlayInnerStyle={{
								borderRadius: '3px',
							}}>
							<Button
								type='text'
								icon={<ActionIcon classes='fa-trash txt-size-h8 txt-color-red2' />}
								onClick={() => {
									props.onClickDelete(record)
								}}></Button>
						</Tooltip>
					</Col>
				</Row>
			),
		},
	]

	return (
		<div className='product-list-container'>
			<Table
				size='small'
				className={'pendingProducts-data'}
				title={() => (
					<Row align='middle' justify='space-between'>
						<Col>
							<Row align='middle' gutter={[3, 0]}>
								<Col>
									<h3 className='mgbt0'>{t('warehouse.waitingProductList')}</h3>
								</Col>
								<Col>
									<Badge
										overflowCount={9999}
										showZero
										count={props.total || 0}
										className={`${!props.total ? 'badge-zero' : 'badge-not-zero'}`}
									/>
								</Col>
							</Row>
						</Col>
						<Col>
							<Row gutter={8}>
								<Col>
									<Button
										disabled={!props.selectedItems.length}
										loading={loadingUpProductToShopee}
										onClick={() => upShopeeHandler(props.selectedItems)}
										className='btn-df-secondary-bg-lemon btn-sale txt-size-h7 robotomedium border-radius4 txt-color-white'
										icon={<i className='fa-regular fa-cart-plus' />}>
										{t('warehouse.saleChoice')}
									</Button>
								</Col>
								{props.selectedItems && props.selectedItems.length > 0 && (
									<Col>
										<Button
											icon={<i className='fa-solid fa-trash' />}
											className='btn-remove-product txt-size-h7 border-radius4 robotomedium txt-color-white'
											onClick={() => props.onClickDelete(null)}>
											{t('address.delete-action')}
										</Button>
									</Col>
								)}
							</Row>
						</Col>
					</Row>
				)}
				rowSelection={{
					selectedRowKeys: props.selectedItems,
					onChange: (selectedRowKeys: any) => {
						props.setSelectItems([...selectedRowKeys])
					},
				}}
				onRow={(record, rowIndex: number | undefined) => {
					return {
						onClick: () => onClickRow(record, rowIndex),
					}
				}}
				loading={props.loading}
				locale={localeCustom}
				columns={columns}
				dataSource={productList.map((item) => ({ ...item, key: `${item.id}` }))}
				pagination={false}
			/>
			{productList.map((item) => ({ ...item, key: `${item.id}` })).length > 0 && (
				<Col xs={24}>
					<Row justify='end'>
						<M24Pagination
							filter={{
								size: parseInt(props.searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`),
								page: parseInt(props.searchParams.get('page') || '1'),
							}}
							pagination={{
								total: props.total,
								current: parseInt(props.searchParams.get('page') || '1'),
								pageSize: parseInt(props.searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`),
								hideOnSinglePage: true,
							}}
							onPageChange={onChangePage}
						/>
					</Row>
				</Col>
			)}
		</div>
	)
}

export default PendingProductList

