import { Avatar, Badge, Button, Card, Col, Grid, Image, Row, Table, Tooltip } from 'antd'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { formatMoneyByUnit } from '../../core/helpers/string'
import { IMarketplace } from '../../Interface/MarketplaceInterface'
import { IProductDetails } from '../../Interface/ProductDetailsInterface'
import EmptyCart from '../Empty/EmptyCart'
import EmptyDataFilter from '../Empty/EmptyDataFilter'
import M24Pagination from '../Pagination/M24Pagination'
import { SkeletonTable, SkeletonTableColumnsType } from '../SkeletonTable'

const { useBreakpoint } = Grid

interface IProps {
	isLoading: boolean
	isSkeleton: boolean
	showEmptyCart: boolean
	productList: IProductDetails[]
	currentPage: number
	pageSize: number | null
	total: number | null
	marketplaces: IMarketplace[]
	searchParams: URLSearchParams
	setSearchParams: Dispatch<SetStateAction<any>>
	selectedItems: string[]
	setSelectItems: Dispatch<SetStateAction<string[]>>
	onClickDelete: (record?: IProductDetails) => void
	onClickMoveToPendingList: (records?: IProductDetails) => void
}

const ActionIcon = (props: { classes: string }) => {
	const [isHoveredIcon, setHoverIcon] = useState<boolean>(false)

	return (
		<i
			className={`${isHoveredIcon ? 'fa-solid' : 'fa-light'} ${props.classes}`}
			onMouseOver={() => setHoverIcon(true)}
			onMouseLeave={() => setHoverIcon(false)}></i>
	)
}

const ProductList: React.FC<IProps> = (props) => {
	const { lg, xl } = useBreakpoint()
	const { t } = useTranslation()
	const navigate = useNavigate()

	const priceColumnWidth: number | null = xl ? 176 : lg ? 140 : null

	const actionColumnWidth = 105

	const [hoveredButtonAtRowKey, setHoverButtonAtRowKey] = useState<null | string>(null)

	const onChangePage = (filter: any) => {
		props.setSearchParams({
			...Object.fromEntries(props.searchParams),
			page: filter.page,
			pageSize: filter.pageSize,
		})
	}

	const onClickRow = (record: any, rowIndex: number | undefined) => {
		if (Number.isInteger(rowIndex) && hoveredButtonAtRowKey !== `${record.id}`) {
			props.setSelectItems((prevSelectedItems: string[]) => {
				const selectedRowIndex = prevSelectedItems.indexOf(`${record.id}`)
				const clonedSelectedItems = [...prevSelectedItems]
				selectedRowIndex >= 0
					? clonedSelectedItems.splice(selectedRowIndex, 1)
					: clonedSelectedItems.push(`${record.id}`)
				return clonedSelectedItems
			})
		}
	}

	const localeCustom = {
		emptyText: props.showEmptyCart ? (
			<EmptyCart className='min-h-100vh-248' emptyText={t('storedProduct.empty-list')} />
		) : (
			<EmptyDataFilter description={t('storedProducts.filterEmpty')} />
		),
	}

	const columns: any = [
		{
			title: t('orderDetail.productName'),
			dataIndex: 'name',
			key: 'name',
			className: 'fsz-14 line-h-22 robotomedium whitespace',
			render: (_: string, record: any) => (
				<Row gutter={[8, 0]} wrap={false} align='middle'>
					<Col>
						{record.featuredImage.includes('.mp4') ? (
							<video width={40} height={40} className='border-radius4'>
								<source src={record.featuredImage} type='video/mp4' />
								Your browser does not support the video tag.
							</video>
						) : (
							<Image
								src={record.featuredImage}
								preview={false}
								width={40}
								height={40}
								className='border-radius4'
							/>
						)}
					</Col>
					<Col>
						<span className='txt-size-h7 robotomedium line-h-22 txt-color-black wordbreakall'>
							{record.name}
						</span>
					</Col>
				</Row>
			),
		},
		{
			title: (
				<Row justify='end'>
					<span className='fsz-14 line-h-22 robotomedium whitespace'>{t('warehouse.productMsrp')}</span>
				</Row>
			),
			dataIndex: 'msrp',
			className: 'fsz-14 line-h-22 robotomedium whitespace',
			key: 'msrp',
			width: priceColumnWidth ? `${(priceColumnWidth / 1176) * 100}%` : 'auto',
			render: (_: string, record: any) => (
				<Row justify='end'>
					<span className='txt-size-h7 robotoregular line-h-22 txt-color-black'>
						{record.msrp && formatMoneyByUnit(record.msrp, record.currency)}
					</span>
				</Row>
			),
		},
		{
			title: (
				<Row justify='end'>
					<span className='fsz-14 line-h-22 robotomedium whitespace'>{t('warehouse.productSalePrice')}</span>
				</Row>
			),
			dataIndex: 'salePrice',
			className: 'fsz-14 line-h-22 robotomedium whitespace',
			key: 'salePrice',
			width: priceColumnWidth ? `${(priceColumnWidth / 1176) * 100}%` : 'auto',
			render: (_: string, record: any) => (
				<Row justify='end'>
					<span className='txt-size-h7 robotoregular line-h-22 txt-color-pink4'>
						{record.salePrice && formatMoneyByUnit(record.salePrice, record.currency)}
					</span>
				</Row>
			),
		},
		{
			title: 'Marketplace',
			dataIndex: 'marketplace',
			className: 'fsz-14 line-h-22 robotomedium whitespace',
			key: 'marketplace',
			render: (_: string, record: any) => (
				<div className='flex align-items-center'>
					<Avatar
						shape='square'
						size={16}
						src={props.marketplaces.find((m) => m.code === record.marketplace)?.icon}
						className='mg-r-3 rad-2 bd-none-impt'
					/>
					<span className='txt-size-h7 robotoregular line-h-22 txt-color-black txt-capitalize'>
						{record.marketplace}
					</span>
				</div>
			),
		},
		{
			title: t('connectMarket.action'),
			dataIndex: 'action',
			key: 'action',
			width: actionColumnWidth,
			className: 'fsz-14 line-h-22 robotomedium whitespace',
			render: (_: string, record: any, index: number) => (
				<Row
					gutter={12}
					onMouseOver={() => setHoverButtonAtRowKey(`${record.id}`)}
					onMouseLeave={() => setHoverButtonAtRowKey(null)}>
					<Col>
						<Tooltip
							placement='top'
							title={t('address.edit-action')}
							color='#3340B6'
							overlayClassName='action-tooltip'
							overlayInnerStyle={{
								borderRadius: '3px',
							}}>
							<Button
								type='text'
								icon={<ActionIcon classes='fa-sliders txt-size-h8 txt-color-blue6' />}
								onClick={(): void => navigate(`/warehouse/stored-product/${record.id}/edit`)}></Button>
						</Tooltip>
					</Col>
					<Col>
						<Tooltip
							placement='top'
							title={t('warehouse.saleChoice')}
							color='#3340B6'
							overlayClassName='action-tooltip'
							overlayInnerStyle={{
								borderRadius: '3px',
							}}>
							<Button
								type='text'
								icon={<ActionIcon classes='fa-cart-plus txt-size-h8 txt-color-blue6' />}
								onClick={() => props.onClickMoveToPendingList(record)}
							/>
						</Tooltip>
					</Col>
					<Col>
						<Tooltip
							placement='top'
							title={t('address.delete-action')}
							color='#FF4559'
							overlayClassName='action-tooltip'
							overlayInnerStyle={{
								borderRadius: '3px',
							}}>
							<Button
								type='text'
								icon={<ActionIcon classes='fa-trash txt-size-h8 txt-color-red2' />}
								onClick={() => props.onClickDelete(record)}></Button>
						</Tooltip>
					</Col>
				</Row>
			),
		},
	]

	return (
		<Card
			title={
				<Row align='middle' justify='space-between'>
					<Col>
						<Row align='middle' gutter={[4, 0]}>
							<Col>
								<span className='mgbt0 txt-capitalize'>{t('warehouse.storedProductList')}</span>
							</Col>
							<Col>
								<Row align='middle' justify='center'>
									<Badge
										overflowCount={999999}
										showZero
										count={props.total || 0}
										className={`${!props.total ? 'badge-zero' : 'badge-not-zero'}`}
									/>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col>
						{props.selectedItems.length >= 1 ? (
							<Row gutter={8} align='middle'>
								<Col>
									<Button
										className='btn-df-secondary-bg-lemon btn-sale txt-size-h7 border-radius4 robotomedium txt-color-white txt-capitalize'
										icon={
											props.selectedItems.length ? (
												<i className='fa-regular fa-cart-plus' />
											) : (
												<i className='fa-regular fa-plus' />
											)
										}
										// disabled={ !props.selectedItems.length }
										onClick={() => props.onClickMoveToPendingList()}>
										{t('warehouse.saleChoice')}
									</Button>
								</Col>
								<Col>
									<Button
										icon={<i className='fa-solid fa-trash' />}
										className='btn-remove-product txt-size-h7 border-radius4 robotomedium txt-color-white'
										// disabled={!props.selectedItems.length}
										onClick={() => props.onClickDelete()}>
										{t('address.delete-action')}
									</Button>
								</Col>
							</Row>
						) : null}
					</Col>
				</Row>
			}
			className='product-list-container'>
			<Row gutter={[0, 12]}>
				<Col xs={24}>
					<SkeletonTable
						loading={props.isLoading || props.isSkeleton}
						rowCount={
							!props.isLoading
								? props.productList.map((item, i) => ({ ...item, key: `${item.id}` })).length
								: 0
						}
						columns={columns as SkeletonTableColumnsType[]}
						className='main-table table-expandable selected-table mg-bt-13'>
						<Table
							rowKey={(record: IProductDetails) => record.id}
							rowSelection={{
								selectedRowKeys: props.selectedItems,
								onChange: (selectedRowKeys: any) => {
									props.setSelectItems([...selectedRowKeys])
								},
							}}
							onRow={(record, rowIndex: number | undefined) => {
								return {
									onClick: () => onClickRow(record, rowIndex),
								}
							}}
							locale={localeCustom}
							columns={columns}
							dataSource={props.productList.map((item, i) => ({ ...item, key: `${item.id}` }))}
							pagination={false}
						/>
					</SkeletonTable>
				</Col>
				<Col xs={24}>
					<Row justify='end' className='pagination-container'>
						<M24Pagination
							filter={{
								size: props.pageSize,
								page: props.currentPage,
							}}
							pagination={{
								total: props.total,
								current: props.currentPage,
								pageSize: props.pageSize,
								hideOnSinglePage: true,
							}}
							onPageChange={onChangePage}
						/>
					</Row>
				</Col>
			</Row>
		</Card>
	)
}

export default ProductList

