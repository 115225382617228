import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {Avatar, Col, List, Radio, Row} from "antd";
import UboxLogo3 from "../../../resources/images/UboxLogo3.png";
import {formatNumber} from "../../../core/helpers/string";
import lodash from "lodash";
import {ServicePackageInterface} from "../../../Interface/UserServiceInterface";
import {VendorServiceInterface} from "../../../Interface/VendorServiceInterface";


type PackageItemProps = {
    pk:ServicePackageInterface
    handleSelectPackage:(pk:ServicePackageInterface) => void;
    selectedPk:ServicePackageInterface;
    selectedVendor:VendorServiceInterface;
}

function PackageItem(props:PackageItemProps) {
    const {t} = useTranslation();
    const {
        pk,handleSelectPackage,selectedPk,selectedVendor
    } = props;
    const [ellipsis,setEllipsis] = React.useState(true);
    const divRef = useRef<any>();
    const [showMore,displayShowMore] = React.useState(true);

    React.useEffect(()=> {
        if(divRef.current.clientHeight >= 111)
            displayShowMore(false);
    },[])

    return(
        <List.Item
            key={pk.id}
            id={pk.id}
            className={`vendor-package-item `}
            onClick={() => {
                handleSelectPackage(pk);
            } }
        >
            <Row className={'width100pc'}>
                <Col span={1} className={'justify-content-start pdl0 pdr5'}>
                    <Radio
                        className={'pdl5'}
                        disabled={pk.status === 'INACTIVE'}
                        checked={pk.id === selectedPk.id}
                        onChange={(event) => {
                            handleSelectPackage(pk);
                        }}/>
                </Col>
                <Col span={23} flex={'auto'} className={`vendor-package-item-content ${pk.id === selectedPk.id ? 'vendor-package-item-content-selected' : ''}`}>
                    <Row>
                        <Col span={2} flex={'auto'} className={'align-items-center'} >
                            <Avatar src={pk.logo ? pk.logo : selectedVendor.avatar || UboxLogo3}
                                    size={64}
                                    shape={'square'}
                                    className={'bd-none-impt width100pc'}
                                    style={{width:'auto', height: "auto"}}
                            />
                        </Col>
                        <Col span={16} flex={'auto'} className={'mgl5'}>
                            <div className={'item-title'}>
                                <span className={'txt-size-h1 robotomedium txt-color-black txt-capitalize'}>{pk.name}</span>
                            </div>
                            <div className={'item-description'}>
                                    <div
                                        ref={divRef}
                                        id={'p-description'}
                                        className={'width100pc robotoregular txt-size-h7'}
                                        dangerouslySetInnerHTML={{__html:pk.description}}
                                        style={ellipsis ? {maxHeight:111,overflowY:'hidden'} : {height:'auto'}}
                                    />
                                    <div className={'txt-size-h7 pdbt3'}
                                        onClick={()=> {setEllipsis(!ellipsis);}}>
                                            {!showMore ? <span className={'txt-color-blue'}>
                                            {!ellipsis ? t('profile.show-off-description') : t('profile.show-more-description')}
                                        </span> :null}
                                    </div>
                            </div>
                        </Col>
                        <Col span={6} flex={'auto'} className={'justify-content-center'}>
                            <div className={'item-price'}>
                                                        <span className={'txt-color-black robotomedium txt-size-h1 align-items-center whitespace-pre-wrap'}>
                                                            {formatNumber(pk.price)} {lodash.get(pk.currency, 'code', null)}</span>
                                <span className={'txt-color-black robotoregular txt-size-h5 align-items-center'}>
                                                            /{pk.expirationTime} {t('profile.service-package-expiration-unit')}</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </List.Item>
    )
}

export default PackageItem;
