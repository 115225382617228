import { Col, Modal, Row } from 'antd'
import lodash from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import MainLayout from '../../components/Layout'
import FilterList from '../../components/StoredProducts/FilterList'
import ProductList from '../../components/StoredProducts/ProductList'
import { DEFAULT_PAGE_SIZE, STORED_DESCRIPTIONS } from '../../core/config/index'
import { IMarketplace } from '../../Interface/MarketplaceInterface'
import { IProductDetails } from '../../Interface/ProductDetailsInterface'
import { categoryRepository } from '../../repositories/CategoryRepository'
import { warehouseRepository } from '../../repositories/ProductWarehouseRepository'
import M24Notification from '../../utils/M24Notification'
import PostToSale from './PostToSale'

const { confirm } = Modal

interface IProductData {
	isLoading: boolean
	productList: IProductDetails[]
	pageSize: number | null
	total: number | null
	currentPage: number
}

const StoredProducts = () => {
	const { t } = useTranslation()

	const [marketplaces, setMarketplaces] = useState<IMarketplace[]>([])
	const [searchParams, setSearchParams] = useSearchParams()
	const [selectedItems, setSelectItems] = useState<string[]>([])
	const [productData, setProductData] = useState<IProductData>({
		isLoading: true,
		currentPage: parseInt(searchParams.get('page') || '1'),
		total: 0,
		productList: [],
		pageSize: parseInt(searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`),
	})

	const fetchGetProductList = useRef<ReturnType<typeof setTimeout> | null>(null)
	const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
	const [moveToPendingList, setMoveToPendingList] = useState<boolean>(false)
	const [isMoving, setIsMoving] = useState<boolean>(false)
	const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false)
	const [errorContent, setErrorContent] = useState<string>('')
	const [movingResult, setMovingResult] = useState<boolean>(false)
	const [successCount, setSuccessCount] = useState<number>(0)
	const [failedCount, setFailedCount] = useState<number>(0)
	const [isCheckedList, setIsCheckedList] = useState<string[]>([])
	const [checkedAll, setCheckAll] = useState(false)
	const [isSkeleton, setIsSkeleton] = useState(true)
	const [showEmptyCart, setShowEmptyCart] = useState(false)

	useEffect(() => {
		getMarketplaces()
		return () => {
			if (fetchGetProductList.current !== null) {
				clearTimeout(fetchGetProductList.current)
			}
		}
	}, [])

	useEffect(() => {
		setShowEmptyCart(
			!searchParams.get('name') &&
				!searchParams.get('marketplaces') &&
				productData.productList.length === 0 &&
				!productData.isLoading && !isSkeleton
		)
	}, [isSkeleton, productData.isLoading, productData.productList.length, searchParams])

	useEffect(() => {
		if (fetchGetProductList.current !== null) {
			clearTimeout(fetchGetProductList.current)
		}
		fetchGetProductList.current = setTimeout(() => {
			const pageSize = parseInt(searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`)
			const currentPage = parseInt(searchParams.get('page') || '1')
			getProductList({ pageSize, currentPage, ...Object.fromEntries(searchParams) })
			// setSelectItems([])
		}, 1000)
	}, [searchParams, confirmDelete])

	const getProductList = (filter: any): void => {
		setProductData((prevState) => ({
			...prevState,
			isLoading: true,
		}))
		const { page, pageSize, currentPage, sort, order, ...rest } = filter
		const offset = (pageSize || 0) * (currentPage - 1 || page - 1 || 0)
		const limit = pageSize || DEFAULT_PAGE_SIZE
		if (rest.hasOwnProperty('name')) {
			rest.name = rest.name.trim()
		}
		warehouseRepository
			.getStoredProducts({ ...rest, offset, limit, sort: sort || 'timestamp:DESC' })
			.then((res) => {
				setProductData((prevState) => ({
					...prevState,
					isLoading: false,
					productList: res.data,
					currentPage: (parseInt(res.headers['x-page-number'] || '') || 0) + 1,
					total: parseInt(res.headers['x-total-count'] || '') || null,
					pageSize: parseInt(res.headers['x-page-size'] || '') || DEFAULT_PAGE_SIZE,
				}))
			})
			.catch((err) => {
				setProductData((prevState) => ({
					...prevState,
					isLoading: false,
				}))
			})
			.finally(() => setTimeout(() => setIsSkeleton(false), 1000))
	}

	const getMarketplaces = () => {
		categoryRepository
			.getMarketPlaces()
			.then((res) => setMarketplaces(res))
			.catch((err) => {})
	}

	const handleRemoveItem = (selectedItem?: IProductDetails | null) => {
		setConfirmDelete(true)
		if (selectedItem) {
			warehouseRepository
				.deleteStoredProduct([selectedItem.id])
				.then(() => {
					M24Notification.messageSuccess(t('message.success'))
					setSelectItems([])
				})
				.catch((err) => {
					M24Notification.notifyError(t('message.error'), err)
				})
				.finally(() => setConfirmDelete(false))
		} else {
			warehouseRepository
				.deleteStoredProduct(selectedItems)
				.then(() => {
					M24Notification.messageSuccess(t('message.success'))
					setSelectItems([])
				})
				.catch((err) => {
					M24Notification.notifyError(t('message.error'), err)
				})
				.finally(() => setConfirmDelete(false))
		}
	}

	const onClickDelete = (record?: IProductDetails) => {
		confirm({
			icon: null,
			title: <span className={'robotomedium'}>{t('storedProduct.confirm-delete-title')}</span>,
			content: <span className={'robotoregular'}>{t('storedProduct.confirm-delete-content')}</span>,
			cancelText: (
				<span>
					<i className='fa-solid fa-xmark mgl5 mgr10' />
					{t('storedProduct.confirm-cancelText')}
				</span>
			),
			cancelButtonProps: { className: 'btn_cancel' },
			okText: (
				<span>
					<i className='fa-solid fa-trash mgl5 mgr10' />
					{t('storedProduct.confirm-okText')}
				</span>
			),
			okType: 'danger',
			okButtonProps: { className: 'btn-remove-order' },
			onOk() {
				handleRemoveItem(record)
			},
		})
	}

	const handleClickMoveToPendingList = (selectedItem?: IProductDetails | null) => {
		if (selectedItem) {
			setSelectItems([...selectedItems, selectedItem.id])
		}
		setMoveToPendingList(true)
	}

	const handleCancelMoveTOPendingList = () => {
		setMoveToPendingList(false)
		setIsMoving(false)
		setShowErrorPopup(false)
		setErrorContent('')
		setMovingResult(false)
		setSuccessCount(0)
		setFailedCount(0)
		setIsCheckedList([])
	}

	const handleMoveToPendingList = (force?: boolean) => {
		let formData = {
			integrationIds: isCheckedList,
			productIds: selectedItems,
			force: force || false,
		}
		setIsMoving(true)
		setMovingResult(true)
		const movingItem = () =>
			warehouseRepository
				.moveItemsToPendingProductList(formData)
				.then((response) => {
					setIsMoving(false)
					if (response.data.length < 1) {
						setFailedCount(isCheckedList.length * selectedItems.length)
						M24Notification.notifyError(t('message.titleFailed'), t(`pendingProduct.duplicate_products-error`), '', 5)
					} else if (response.data.length < isCheckedList.length * selectedItems.length) {
						setSuccessCount(isCheckedList.length * selectedItems.length)
						M24Notification.messageSuccess(t('message.success'), '', 2)
					} else {
						setSuccessCount(isCheckedList.length * selectedItems.length)
						M24Notification.messageSuccess(t('message.success'), '', 2)
					}
				})
				.catch((err) => {
					setMovingResult(false)
					setIsMoving(false)
					let err_title: string = lodash.get(err.response.data, 'title')
					if (err_title === "price_formula_can't_calculate") {
						setErrorContent(
							`${t(`pendingProduct.${err_title}-error`, {
								integrationName: lodash.forEach(err.response.data.integrationNames),
							})}`
						)
						setShowErrorPopup(true)
					} else if (err_title && err_title === 'Not Found') {
						setFailedCount(isCheckedList.length * selectedItems.length)
						M24Notification.notifyError(t('message.titleFailed'), t(`message.try_again`), '', 3)
					} else {
						setFailedCount(isCheckedList.length * selectedItems.length)
						M24Notification.notifyError(t('message.titleFailed'), t(`pendingProduct.${err_title}-error`), '', 5)
					}
				})
		// .finally(()=>setIsMoving(false));
		setTimeout(movingItem, 2000)
	}

	return (
		<MainLayout
			title={t('warehouse.storedProductsTitle')}
			descriptions={{
				title: `${t('storedProduct.pageTitle')}`,
				descriptions: `${t('storedProduct.descriptions')}`,
				className: 'mg-bt-12 box-shadow-desc box-shadow-desc-only',
				pageCode: STORED_DESCRIPTIONS,
				hasDescription: true,
			}}
			hasExtensionCommon={true}
			breadcrumbs={{ total: productData?.total }}>
			<Row gutter={[0, 12]} className='stored-products-container'>
				<Col xs={24}>
					<FilterList searchParams={searchParams} setSearchParams={setSearchParams} marketplaces={marketplaces} />
				</Col>
				<Col xs={24}>
					
					  <ProductList
						{...productData}
						marketplaces={marketplaces}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						selectedItems={selectedItems}
						setSelectItems={setSelectItems}
						onClickDelete={onClickDelete}
						onClickMoveToPendingList={handleClickMoveToPendingList}
						isLoading={productData.isLoading}
						isSkeleton={isSkeleton}
						showEmptyCart={showEmptyCart}
					/>
				</Col>
			</Row>
			{moveToPendingList && (
				<PostToSale
					visible={moveToPendingList}
					onCancel={handleCancelMoveTOPendingList}
					handleMoveToPendingList={handleMoveToPendingList}
					isMoving={isMoving}
					setIsMoving={setIsMoving}
					showError={showErrorPopup}
					errorContent={errorContent}
					setShowError={setShowErrorPopup}
					movingResult={movingResult}
					successCount={successCount}
					isCheckedList={isCheckedList}
					setIsCheckedList={setIsCheckedList}
					checkedAll={checkedAll}
					setCheckAll={setCheckAll}
					failedCount={failedCount}
					setFailedCount={setFailedCount}
				/>
			)}
		</MainLayout>
	)
}

export default StoredProducts

