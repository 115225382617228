/**
 *
 * @param text
 * @returns {string}
 */
import lodash from 'lodash';
import { formatMoneyBySuffix } from '../core/helpers/string';
import React from 'react';

export const linkOrder = (text: any, config: any) => {
	if (!text) return '';
	let orderRegex = /#[A-Z0-9_]{4,15}/g;
	let orderCode = text.match(orderRegex);
	if (orderCode) {
		orderCode = orderCode[0];
		orderCode = orderCode.replace('#', '');
		let url = '';
		if (text.indexOf('đơn ký gửi') > 0)
			url = lodash.get(config, 'shipment', '');
		else if (text.indexOf('đơn hàng') > 0 || text.indexOf('đơn #') > 0)
			url = lodash.get(config, 'order', '');
		else if (text.indexOf('YCTT') > 0 || text.indexOf('YCCT') > 0)
			url = lodash.get(config, 'peer_payment', '');
		url = url.replace('{code}', orderCode);
		return text.replace(text.indexOf('đơn #') ? `#${orderCode}` : orderCode, function () {
			const linkContent = text.indexOf('đơn #') ? `#${orderCode}` : orderCode
			return `<a target='_blank' class='item-link' href=${url}>${linkContent}</a>`
		});
	}
	return text;
};
/**
 * format đơn giá
 * @param value
 * @param unit đơn vị tiền
 * @returns {*}
 */
export const formatPriceByUnit = (value?: any, unit?: any) => {
	return value;
	// if (value === undefined || value === null) return '---';
	// return `${preCharacter}${currentcy && currentcy.prefix ? currentcy.prefix : ''}${numeral(value).format('0,0.[0000]')}${currentcy && currentcy.suffix ? currentcy.suffix : ''}`;
};

/**
 * Supported search many item
 * @param key
 * @param value
 */
export function handleInputChangeMany(key: string, value: any, filter: any) {
	let temp = lodash.merge({}, filter);
	let realValues = lodash.get(temp, key, []);

	if (realValues.length) {
		realValues = realValues.split(',');
	}
	if (realValues.indexOf(value) >= 0) {
		realValues = lodash.filter(realValues, (n) => n !== value);
	} else {
		if (realValues === '') {
			realValues = [];
		}
		realValues.push(value);
	}
	temp[key] = realValues.join();
	if (realValues.length === 0) {
		delete temp[key];
	}
	return temp;
}
/**
 * Input change
 * @param key
 * @param value
 * @returns {{}}
 */
export function handleInputChange(key: string, value: any, filter: any) {
	let temp = lodash.merge({}, filter);
	temp[key] = value;
	if (value === null || value === '' || value == undefined) {
		delete temp[key];
	}
	return temp;
}
/**
 * Select range of date
 * @param e
 * @param from
 * @param to
 */
export function handleChangeRangeDate(
	e: any,
	from: string,
	to: string,
	filter: any
) {
	let temp = lodash.merge({}, filter);
	if (e && e.length) {
		temp[from] = e[0].startOf('day').toISOString();
		temp[to] = e[1].endOf('day').toISOString();
	} else {
		delete temp[from];
		delete temp[to];
	}
	return temp;
}
/**
 * Select range of date
 * @param e
 * @param from
 * @param to
 */
export function handleChangeDate(key: string, e: any, filter: any) {
	let temp = lodash.merge({}, filter);
	if (e) {
		temp[key] = e.toISOString();
	} else {
		delete temp[key];
	}
	return temp;
}

export const getCleanFormFilter = (
	obj: any,
	keysNoCheck: string[] = [],
	keyAdded: string[] = []
) => {
	let keyOfEmptyString = [];
	if (keysNoCheck) {
		keyOfEmptyString = Object.keys(obj).filter(
			(key) =>
				lodash.isEmpty(obj[key]) &&
				lodash.isNil(obj[key]) &&
				!lodash.includes(keysNoCheck, key)
		);
	} else {
		keyOfEmptyString = Object.keys(obj).filter((key) =>
			lodash.isEmpty(obj[key])
		);
	}
	return lodash.omit(obj, [...keyOfEmptyString, ...keyAdded!]);
};

export const checkArrayToEqual = (arr1: any = [], arr2: any = []) => {
	let hasElement;
	const sameLength = arr1.length === arr2.length;
	for (const item of arr1) {
		if (lodash.includes(arr2, item)) {
			hasElement = true;
		} else {
			hasElement = false;
		}
	}
	return hasElement && sameLength;
};

export const newLineToBr = (str: string) => {
	return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
};
