import lodash from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import MainLayout from '../../components/Layout'
import { categoryRepository } from '../../repositories/CategoryRepository'
import M24Notification from '../../utils/M24Notification'
import { getCleanFormFilter } from '../../utils/StringUtils'
import Filter from './Filter'
import { ConnectionInterface } from '../../Interface/ConnectionInterface'
import './style.scss'
import MarketPalaceInterface from '../../Interface/MarketPalaceInterface'
import { ProviderInterface } from '../../Interface/AccountBalanceInterface'
import PeerPaymentItems from './List'
import { peerPaymentAndShipmentRepository } from '../../repositories/PeerpaymentAndShipmentRepository'
import PeerpaymentInterface from '../../Interface/PeerpaymentInterface'
import PeerpaymentFilterInterface from '../../Interface/PeerpaymentFilterInterface'
import PeerpaymentStatusInterface from '../../Interface/PeerpaymentStatusInterface'
import { cartRepository } from '../../repositories/CartRepository'

const PeerPayments = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { t } = useTranslation()
	const [filter, setFilter] = useState<PeerpaymentFilterInterface | {}>({})
	const [statuses, setStatuses] = useState<PeerpaymentStatusInterface[]>([])
	const [accounts, setAccounts] = useState<ConnectionInterface[]>([])
	const [providers, setProviders] = useState<ProviderInterface[]>([])
	const [peerpayment, setPeerpayment] = useState<PeerpaymentInterface[]>([])
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(Number(searchParams.get('page')))
	const [pageSize, setPagesize] = useState(Number(searchParams.get('size')))
	const [showProgressBar, setShowProgressBar] = useState(true)
	const [marketPalaces, setMarketPalaces] = useState<MarketPalaceInterface[]>([])
	const filterRef = useRef<HTMLDivElement | null>(null)
	const [isSkeleton, setIsSkeleton] = useState(true)
	const [peerPaymnetAndShipmentStatuses, setPeerPaymnetAndShipmentStatuses] = useState([])
	const [curPartner, setCurPartner] = useState<any>()
	const [curAccount, setCurAccount] = useState<any>()
	const [partnerOfShipments, setPartnerOfShipments] = useState([])
	const [loading, setLoading] = useState(false)

	const getPeerpayment = useCallback(async () => {
		setShowProgressBar(true)
		const providerUsername = searchParams.get('providerUsername')
		const peerPaymentUsername = searchParams.get('peerPaymentUsername')
		const peerPaymentProvider = searchParams.get('peerPaymentProvider')
		const provider = searchParams.get('provider')
		const code = searchParams.get('code')
		const peerPaymentCode = searchParams.get('peerPaymentCode')
		const originalReceipt = searchParams.get('originalReceipt')
		const peerPaymentStatus = searchParams.get('peerPaymentStatus')
		const statuses = searchParams.get('statuses')
		const page = searchParams.get('page')
		const size = searchParams.get('size')
		const temp = {
			page,
			size,
			providerUsername,
			provider,
			statuses,
			peerPaymentUsername,
			peerPaymentProvider,
			code,
			peerPaymentCode,
			peerPaymentStatus,
			originalReceipt,
		}
		setFilter((prevState: any) => ({
			...prevState,
			...getCleanFormFilter(temp),
		}))
		try {
			const response = await peerPaymentAndShipmentRepository.getShipmentAndPeerPayment(getCleanFormFilter(temp))
			const currentPage = lodash.get(response, 'headers.x-page-number')
			const total = lodash.get(response, 'headers.x-total-count')
			setPeerpayment(response.data)
			setPage(parseInt(currentPage) + 1)
			setTotal(parseInt(total))
			setPagesize(parseInt(lodash.get(response, 'headers.x-page-size')))
			setShowProgressBar(false)
			setTimeout(() => setIsSkeleton(false), 1000)

		} catch (err: any) {
			M24Notification.messageError(t(err), '', 3)
			setShowProgressBar(false)
			setTimeout(() => setIsSkeleton(false), 1000)
		}
	}, [searchParams, t])

	const getShipmentStatuses = useCallback(async () => {
		const res = await categoryRepository.getShipmentStatuses()
		setStatuses(res)
	}, [])


	const getAccounts = useCallback(async () => {
		try {
			const res = await cartRepository.getPartnerOfShipmentOrOrder('PEER_PAYMENT')
			setAccounts(res)
			const accDefault = res.find((acc: any) => acc.connection.defaultConnection)
		} catch (error) {
		}
	}, [])

	const getPartnersOfShipment = useCallback(async () => {
		try {
			const res = await cartRepository.getPartnerOfShipmentOrOrder(
				'SHIPMENT',
				curAccount?.connection.provider.code
			)
			setPartnerOfShipments(res)
		} catch (error) {
		}
	}, [curAccount?.connection.provider.code])

	const getProviders = useCallback(async () => {
		try {
			const res = await categoryRepository.getProvider()
			setProviders(res)
		} catch (err) {}
	}, [])

	const getPeerPaymentStatuses = useCallback(async () => {
		try {
			const res = await categoryRepository.getPeerPaymentStatuses()
			setPeerPaymnetAndShipmentStatuses(res)
		} catch (error) {
			
		}
	}, [])

	useEffect(() => {
		getPeerpayment()
	}, [getPeerpayment])

	useEffect(() => {
		getShipmentStatuses()
		getAccounts()
		getProviders()
		getPeerPaymentStatuses()
	}, [getPeerpayment, getShipmentStatuses, getAccounts, getProviders, getPeerPaymentStatuses, getPartnersOfShipment])

	useEffect(() => {
		getPartnersOfShipment()
	}, [getPartnersOfShipment])

	const filterChangeHandler = (obj: any) => {
		setFilter((prevState: any) => setFilter({ ...prevState, ...obj }))
	}

	const handleFilter = (params: any) => {
		setFilter({ ...params })
		setSearchParams(
			getCleanFormFilter({
				...params,
			})
		)
	}

	return (
		<MainLayout title={t('menu.peermentsTitle')} showProgressBar={showProgressBar} breadcrumbs={{ total }}>
			<div ref={filterRef}>
				<Filter
					filter={filter}
					statuses={statuses}
					filterChangeHandler={filterChangeHandler}
					accounts={accounts}
					providers={providers}
					handleFilter={handleFilter}
					partnerOfShipments={partnerOfShipments}
					peerPaymentAndShipmentStatuses={peerPaymnetAndShipmentStatuses}
					curPartner={curPartner}
					account={curAccount}
				/>
			</div>
			<PeerPaymentItems
				data={peerpayment}
				getData={getPeerpayment}
				accounts={accounts}
				statuses={statuses}
				loading={showProgressBar}
				filter={filter}
				page={page}
				size={pageSize}
				total={total}
				handleFilter={handleFilter}
				marketPalaces={marketPalaces}
				providers={providers}
				peerPaymentAndShipmentStatuses={peerPaymnetAndShipmentStatuses}
				partnerOfShipments={partnerOfShipments}
			/>
		</MainLayout>
	)
}

export default PeerPayments

