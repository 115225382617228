import { Avatar, Button, Divider, Empty, List, Tooltip } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../components/Empty/EmptyDataFilter'
import M24Pagination from '../../components/Pagination/M24Pagination'
import Services from '../../components/Services'
import TruncateText from '../../components/TruncateText'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../core/helpers/string'
import { ProviderInterface } from '../../Interface/AccountBalanceInterface'
import { ConnectionInterface } from '../../Interface/ConnectionInterface'
import MarketPalaceInterface from '../../Interface/MarketPalaceInterface'
import ShipmentsFilterInterface from '../../Interface/ShipmentFilterInterface'
import ShipmentInterface from '../../Interface/ShipmentInterface'
import ShipmentStatusInterface from '../../Interface/ShipmentStatusInterface'
import EmptyCartImg from '../../resources/images/empty-blue1.png'
import ShipmentImageDefault from '../../resources/images/shipment-ubox-default.png'
import { getCleanFormFilter } from '../../utils/StringUtils'
import SkeletonOrders from '../Orders/SkeletonOrders'
interface ShipmentItemsProps {
	data: ShipmentInterface[]
	accounts: ConnectionInterface[]
	statuses: ShipmentStatusInterface[]
	loading: boolean
	page: number
	size: number
	total: number
	filter: ShipmentsFilterInterface | {}
	marketPalaces: MarketPalaceInterface[]
	providers: ProviderInterface[]
	getData: () => void
	handleFilter: (params: ShipmentsFilterInterface | {}) => void
}

const ShipmentItems: React.FC<ShipmentItemsProps> = (props) => {
	const [searchParams] = useSearchParams()
	const {
		data,
		getData,
		accounts,
		statuses,
		loading,
		filter,
		page,
		size,
		total,
		handleFilter,
		marketPalaces,
		providers,
	} = props

	const { t } = useTranslation()
	const navigate = useNavigate()

	const [loadingState, setLoadingState] = useState(true)
	useEffect(() => {
		setTimeout(() => setLoadingState(false), 1000)
	})

	const pageChangeHandler = (filter: any) => {
		const { page, size } = filter
		handleFilter(
			getCleanFormFilter(
				{
					...filter,
					page: (page - 1).toString(),
					size: size.toString(),
				},
				[],
				['pageSize']
			)
		)
	}

	return (
		<>
			{!loading && !loadingState && (
				<List
					className='shipments-items'
					pagination={false}
					header={
						<div className='flex align-items-center justify-content-between'>
							<div className='dpl-flex align-items-center'>
								<h3 className='robotomedium mg-0 fsz-16 line-h-24 txt-color-black mg-r-4'>
									{t('shipments.list')}
								</h3>
								<span
									className={`${
										total > 0
											? 'bg-color-primary txt-color-white'
											: 'bg-color-gray-badge txt-color-gray4'
									} border-radius20  fsz-12 line-h-20 px-8`}
								>
									{total}
								</span>
							</div>
							<Button
								className='btn-df btn-df-secondary-bg-white fsz-14 line-h-22 robotomedium flex align-items-center flex-nowrap'
								icon={<i className='fa-regular fa-plus mg-r-10'></i>}
								onClick={() => navigate('/shipments/create', { replace: true })}
							>
								{t('shipments.createShipments')}
							</Button>
						</div>
					}
					locale={{
						emptyText:
							!searchParams.get('code') &&
							!searchParams.get('originalId') &&
							!searchParams.get('merchantName') &&
							!searchParams.get('waybill') &&
							!searchParams.get('statuses') &&
							!searchParams.get('createdFrom') &&
							!searchParams.get('createdTo') &&
							!searchParams.get('providerUsername') &&
							!searchParams.get('provider') ? (
								<Empty
									className={`shipments-empty ${loadingState || loading ? 'dpl-none' : ''}`}
									image={EmptyCartImg}
									description={
										<>
											<span className='robotoregular fsz-14 line-h-22 txt-color-gray8'>
												{isEmpty(filter) ? t('shipments.noResult') : t('shipments.empty')}
											</span>
											<Button
												className='btn-df btn-df-secondary-bg-lemon fsz-14 line-h-22 robotomedium'
												icon={<i className='fa-regular fa-plus mg-r-10'></i>}
												onClick={() => navigate('/shipments/create', { replace: true })}
											>
												{t('shipments.createShipments')}
											</Button>
										</>
									}
								/>
							) : (
								<EmptyDataFilter description={t('shipments.filterEmpty')} />
							),
					}}
					dataSource={data}
					renderItem={(item: ShipmentInterface) => {
						const account = accounts.find(
							(acc: ConnectionInterface) =>
								acc.provider.code === item.provider && acc.name === item.providerUsername
						)
						const status = statuses.find((stt: ShipmentStatusInterface) => stt.code === item.status)
						const marketPalace = marketPalaces.find(
							(marketPalace: MarketPalaceInterface) => marketPalace.code === item.marketplace
						)
						const provider = providers.find((prod: ProviderInterface) => prod.code === item.provider)
						let currency
						const currencyJsonStr = provider?.currency
						if (currencyJsonStr) {
							currency = JSON.parse(currencyJsonStr).code
						}
						const variantProperties = item.variantProperties ? item.variantProperties : []
						return (
							<List.Item
								key={item.id}
								onClick={() => navigate(`/shipments/${item.code}`)}
								className='cursor-pointer'
							>
								<List.Item.Meta
									avatar={
										<Avatar
											src={item.image ? item.image : ShipmentImageDefault}
											shape='square'
											size={48}
											className='bd-none-impt'
										/>
									}
									title={
										<div className='flex flex-col justify-content-between'>
											<div className='flex align-items-center'>
												<Link to={`/shipments/${item.code}`} className='mg-r-12'>
													<Paragraph
														className='robotomedium mg-0 txt-color-orange2 fsz-14 line-h-22 order-heading whitespace'
														copyable={{
															text: item.code,
															icon: [
																<Tooltip
																	title={
																		<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																			Copyx`
																		</span>
																	}
																	color='#FBB739'
																>
																	<i className='far fa-copy line-h-14 w-12'></i>
																</Tooltip>,
																<Tooltip
																	title={
																		<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																			Copied
																		</span>
																	}
																	color='#FBB739'
																>
																	<i className='far fa-check fsz-12 line-h-14 w-12'></i>
																</Tooltip>,
															],
															tooltips: [false, false],
														}}
													>
														{`#${item.code}`}
													</Paragraph>
												</Link>
												<Divider type='vertical' className='mg-0 mg-r-12' />
												<div className='flex align-items-center mg-r-12'>
													<i className='fa-light fa-store mg-r-4' />
													<span className='txt-color-black fsz-12 line-h-20'>
														{item.merchantName ? item.merchantName : '---'}
													</span>
												</div>
												<Divider type='vertical' className='mg-0 mg-r-12' />
												<span className='mg-r-12'>
													{item.timestamp ? formatDateTime(item.timestamp) : '---'}{' '}
													<Tooltip
														color='#FBB739'
														title={
															<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																{t('shipments.createAtTooltip')}
															</span>
														}
													>
														<i className='fa-regular fa-circle-info txt-color-gray4 fsz-10 cursor-pointer line-h-20'></i>
													</Tooltip>
												</span>
												<Divider type='vertical' className='mg-0 mg-r-12' />
												<span className='flex align-items-center mg-r-12'>
													<span className='mg-r-3'>{`${
														item.orderedQuantity ? item.orderedQuantity : '---'
													} / ${
														item.receivedQuantity ? item.receivedQuantity : '---'
													}`}</span>
													<Tooltip
														color='#FBB739'
														title={
															<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																{t('shipments.quantifyTooltip')}
															</span>
														}
													>
														<i className='fa-regular fa-circle-info txt-color-gray4 fsz-10 cursor-pointer line-h-20'></i>
													</Tooltip>
												</span>
												<Divider type='vertical' className='mg-0 mg-r-12' />
												<div className={'dpl-flex align-items-center'}>
													<Avatar
														size={12}
														src={account?.provider?.logo}
														shape={'circle'}
														className='bd-none-impt mg-r-4'
													/>
													<span className='txt-color-black'>{account?.provider.code}</span>
													<Divider type={'vertical'} className={'bd-color-black mx-4'} />
													<span className={'robotoregular txt-size-h8'}>{account?.name}</span>
												</div>
											</div>
											<div className='flex'>
												<div className='flex'>
													<span className='txt-color-gray4 fsz-12 line-h-20 mg-r-2 whitespace mg-r-2'>
														<span className='mg-r-1'>
															{`${t('shipments.financeInfo')}`}{' '}
														</span>
														<Tooltip
															color='#FBB739'
															title={
																<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																	{`${t('shipments.provisionalAmount')} / ${t(
																		'shipments.provisionalAmountTooltip'
																	)}`}
																</span>
															}
														>
															<i className='fa-regular fa-circle-info txt-color-gray4 fsz-10 cursor-pointer line-h-20'></i>
														</Tooltip>
														:
													</span>
													<span className='line-h-20 txt-color-black whitespace'>{`${
														item.totalFee
															? formatMoneyByUnit(item.totalFee, currency)
															: '---'
													} / ${
														item.totalValue
															? formatMoneyByUnit(item.totalValue, item.currency)
															: '---'
													}`}</span>
												</div>
												<Divider type={'vertical'} className={'bd-color-black mg-t-4'} />
												<span className='txt-color-gray4 fsz-12 line-h-20 mg-r-2 whitespace'>{`${t(
													'shipments.financeTotalNeedToPaid'
												)}:`}</span>
												<span className='txt-color-red2 mg-r-18'>{`${
													item.totalUnpaid
														? formatMoneyByUnit(item.totalUnpaid)
														: formatMoneyByUnit(0)
												}`}</span>

												{item.services.length > 0 && (
													<div className='mg-r-18 flex'>
														<span className='txt-color-gray fsz-12 line-h-20 mg-r-2 whitespace'>{`${t(
															'shipments.services'
														)}:`}</span>
														<Services data={item.services} />
													</div>
												)}
												<div className='flex'>
													<span className='txt-color-gray4 fsz-12 line-h-20 mg-r-3 whitespace'>
														{t('shipments.refCustomerCodeLabel')}
													</span>
													<TruncateText
														defaultValue={
															item.refShipmentCode ? item.refShipmentCode : '---'
														}
													/>
												</div>
											</div>
										</div>
									}
								/>

								<span
									className='line-h-20 rad-25 txt-color-white px-12 py-2 txt-capitalize'
									style={{
										backgroundColor: `${status?.color ? status.color : '#0082E6'}`,
									}}
								>
									{status?.name}
								</span>
							</List.Item>
						)
					}}
				></List>
			)}
			{(loading || loadingState) && <SkeletonOrders data={data} loading={loadingState || loading} />}
			<div className='flex justify-content-end'>
				{data && data.length > 0 && (
					<M24Pagination
						onPageChange={pageChangeHandler}
						filter={{ ...filter, page, size }}
						pagination={{
							current: page,
							pageSize: size,
							hideOnSinglePage: true,
							total,
						}}
						className='mg-t-24 mg-bt-24'
					/>
				)}
			</div>
		</>
	)
}

export default ShipmentItems

