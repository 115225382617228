import { Empty, Skeleton, SkeletonProps, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

export type SkeletonTableColumnsType = {
	key: string
}

type SkeletonTableProps = SkeletonProps & {
	columns: ColumnsType<SkeletonTableColumnsType>
	rowCount?: number
}

export const SkeletonTable:React.FC<SkeletonTableProps> = ({loading = false,active = false,rowCount=3,columns,children,className,}) => {
	return loading ? (
		<Table
			rowKey='key'
			pagination={false}
			dataSource={[...Array(rowCount)].map((_, index) => ({
				key: `key${index}`,
			}))}
			locale={{emptyText: <Empty className='dpl-none'/>}}
			columns={columns.map((column: any) => {
				return {
					...column,
					render: function renderPlaceholder() {
						return <Skeleton title={false} paragraph={{rows:1, width:[Number(column.width!) - 24], className: 'mg-0'}} active className={`text-${column?.align} ${className?.includes('table-expandable') && !className?.includes('empty') && column.key === 'code' ? 'pd-l-24' : ''}`}/>
					},
				}
			})}
            className={`${className ? className : ''}`}
		/>
	) : (
		<>{children}</>
	)
}
