import React, {useContext, useEffect, useState} from 'react'
import MainLayout from "../../components/Layout";
import {useTranslation} from "react-i18next";
import {Info} from "./Info";
import {Status} from "./Status";
import {claimRepository} from "../../repositories/ClaimRepository";
import {notification, Spin} from "antd";
import {useParams, useSearchParams} from "react-router-dom";
import lodash from "lodash";
import {Notfound} from "../../components/Notfound";
import {ClaimDetailContext} from "../../context/ClaimDetailContext";
import {ClaimInterface} from "../../Interface/ClaimInterface";
import {ClaimModel} from "../../model/ClaimModel";
import {getProviderFromConnections} from "../../utils/util";
import {supplierRepository} from "../../repositories/SupplierRepository";
import {ConnectionInterface} from "../../Interface/ConnectionInterface";
import {ProviderModel} from "../../model/ProviderModel";
import {ClaimStateInterface} from "../../Interface/ClaimStateInterface";
import BoxChat from "../../components/BoxChat";
import {threadRepository} from "../../repositories/ThreadRepository";
import {BoxChatContext} from "../../context/BoxChatContext";


export const ClaimDetail = () => {
    const context = useContext(ClaimDetailContext)
    const {t} = useTranslation()
    const {providerCode, customerCode, orderCode, claimCode} = useParams()
    const [loading, setLoading] = useState(false)
    const [connections, setConnections] = useState<Array<ConnectionInterface>>([])
    const [claim, setClaim] = useState<ClaimInterface>(ClaimModel)
    const [statuses, setStatuses] = useState<ClaimStateInterface[]>([])
    const [is404, setIs404] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const boxChatContext = useContext(BoxChatContext);


    useEffect(() => {
        setLoading(true)
        claimRepository.getClaimDetail(providerCode!, customerCode!, orderCode!, claimCode!)
            .then(response => {
                setClaim(response.data)
            })
            .catch(err => {
                if (lodash.get(err, 'response.status') === 404) {
                    setIs404(true)
                }
                notification.error({
                    message: err.message
                })
            })
            .finally(() => setLoading(false))
    }, [providerCode, customerCode, orderCode, searchParams])

    useEffect(() => {
        supplierRepository.getListAccount().then(response => {
            setConnections(response);
            context.provider = getProviderFromConnections(connections, providerCode!) || ProviderModel
        })
    }, [])

    useEffect(() => {
        claimRepository.getClaimStatuses(providerCode!).then((response) => {
            setStatuses(response);
        })
    }, [])

    useEffect(() => {
        context.claim = {...claim}
        context.provider = getProviderFromConnections(connections, providerCode!) || ProviderModel
        context.customerCode = customerCode!
        context.statuses = statuses
        context.onRatingSuccess = () => {
            setSearchParams({t: (new Date()).getMilliseconds().toString()})
        }
        context.onArchiveSuccess = () => {
            setSearchParams({t: (new Date()).getMilliseconds().toString()})
        }
    }, [claim, statuses,connections,searchParams])


    useEffect(() => {
        showBoxChat();
    }, []);

    if (is404) return <Notfound />

    const showBoxChat = () => {
        // setLoading(true)
        threadRepository.createThread({
            referenceCode: orderCode!,
            type: 'CLAIM_COMMENT',
            claimCode: claimCode!,
        })
            .then((response) => {
                // if (!boxChatContext.visible) {
                //     boxChatContext.toggle()
                // }
                boxChatContext.onFilterThreads({})
                    .then(() => {
                        if (boxChatContext.thread.id !== response.data.id) {
                            boxChatContext.onSelectThread(response.data)
                        }
                    })
            })
            .finally(() => {
                // setLoading(false);
            })
    }
    return (
        <MainLayout title={`${t('tickets.detail_header')} #${claim.code}`}>
            <Spin spinning={loading}>
                <div className={'ticket-detail-container'}>
                    <Info
                        connections={connections}
                    />
                    <Status
                        statuses={statuses}
                    />
                </div>
            </Spin>
            <BoxChat
                referenceCode={claimCode!}
                type={'CLAIM_COMMENT'}
            />
        </MainLayout>
    )
}
