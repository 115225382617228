import { Avatar, Divider, Popover, Row } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import lodash from 'lodash'
import { DownOutlined } from '@ant-design/icons'
import { t } from 'i18next'
interface UserActionPopoverProps {
	visible: boolean
	profile: any
	visibleChangeHandler: (visible: boolean) => void
	logoutHandler: () => void
}
const DropdownUserActions: React.FC<UserActionPopoverProps> = (props) => {
	const navigate = useNavigate()
	const { visible, visibleChangeHandler, logoutHandler, profile } = props
	return (
		<Popover
			visible={visible}
			onVisibleChange={visibleChangeHandler}
			placement='bottomRight'
			arrowPointAtCenter
			overlayClassName='user-actions'
			content={
				<div className='flex flex-col justify-content-center ' style={{width: 200}}>
					<div className='flex align-items-center cursor-pointer user-dropdown-hover px-17 py-13' onClick={() => navigate('/profile')}>
						<i className='fa-solid fa-address-card mg-r-10 txt-color-primary' />
						<span className='fsz-14 line-h-22 txt-color-black'>{t('menu.profile')}</span>
					</div>
					<Divider className='border-divider-horizontal mg-0'/>
					<div className={'dpl-flex align-items-center user-dropdown-hover px-17 py-13'} key={'logout'}>
						<span
							onClick={logoutHandler}
							className='_logout-btn txt-color-black cursor-pointer flex align-items-center'>
							<i className='fas fa-sign-out-alt mg-r-10 txt-color-primary'></i>
							<span className='fsz-14 line-h-22 txt-color-black'>{t('menu.logout')}</span>
						</span>
					</div>
				</div>
			}
			trigger='click'>
			<div style={{ height: 64 }} className={'dpl-flex justify-content-end align-items-center mgr5 '}>
				<p className={'_user-name mgt15 mg-l-20 txt-size-h7 robotoregular txt-color-black mgr10 cursor-pointer'}>
					{lodash.get(profile, 'name', null)}
					<DownOutlined className={'txt-size-h9 txt-color-gray mgl5'} />
				</p>
				<Avatar src={lodash.get(profile, 'avatar', null)} className={'_avatar-1 mgl10'} />
			</div>
		</Popover>
	)
}

export default DropdownUserActions
