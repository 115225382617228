import {ThreadMessageInterface} from "../../Interface/ThreadMessageInterface";
import React, {useContext, useRef, useState} from "react";
import { Row, Col } from 'antd'
import moment from "moment";
import * as linkify from 'linkifyjs';
import {Modal} from "antd";
import FileSaver from "file-saver";
import {BoxChatContext} from "../../context/BoxChatContext";

interface Props {
    message: ThreadMessageInterface
}

interface PreviewImageInterface {
    name?: string
    url?: string
}

interface PreviewVideoInterface {
    name?: string
    url?: string
    type?: string
}

const formatContent = (content: string) => {
    let newContent = content

    if (newContent.indexOf('\n')) {
        newContent = newContent.replaceAll('\n', `<br/>`)
    }

    const findLinks = linkify.find(newContent)
    for (let item of findLinks) {
        if (item.type === 'url') {
            newContent = newContent.replaceAll(item.value, `<a target="_blank" href='${item.href}'>${item.value}</a>`)
        }
    }

    return newContent
}

export const MessageItem = (props: Props) => {
    const {message} = props
    const [isShowPreviewImage, setIsShowPreviewImage] = useState(false)
    const [previewImage, setPreviewImage] = useState<PreviewImageInterface>({})
    const [isShowPreviewVideo, setIsShowPreviewVideo] = useState(false)
    const [previewVideo, setPreviewVideo] = useState<PreviewVideoInterface>({})
    const [isVideoPlay, setIsVideoPlay] = useState(false)
    const videoRef = useRef<any>()
    const boxChatContext = useContext(BoxChatContext);

    const handlePreviewImage = (name: string, url: string) => {
        previewImage.name = name
        previewImage.url = url
        setPreviewImage({...previewImage})
        setIsShowPreviewImage(true)
    }

    const handlePreviewVideo = (name: string, url: string, type: string) => {
        previewVideo.name = name
        previewVideo.url = url
        previewVideo.type = type
        setPreviewVideo({...previewVideo})
        setIsShowPreviewVideo(true)
    }

    const handlePlayVideo = () => {
        if (videoRef.current) {
            videoRef.current.play()
            setIsVideoPlay(true)
        }
    }

    const handleDownload = (url: string, name: string) => {
        FileSaver.saveAs(url, name);
    }

    const renderImageGroup = (sources: any[]) => {

        return (
            sources.length > 0 &&
                <Row justify={ message.author.staff ? "start" : "end" }>
                    <div 
                        className={
                            sources.length === 1
                                ? "chat-message-item__group chat-message-item__group__single"
                                : (
                                    sources.length === 2
                                        ? "chat-message-item__group chat-message-item__group__couple"
                                        : "chat-message-item__group chat-message-item__group__multiple"
                                )
                        }
                    >
                        {
                            sources.map((source, i) => (
                                <div className="chat-message-item__group__item" key={`${source.url}_${i}`}>
                                    {
                                        source.type.startsWith('video')
                                            ? <div onClick={() => handlePreviewVideo(source.name, source.url, source.type)} className={'chat-message-item__message__video cursor-pointer'}>
                                                <video width={'100%'} height={'100%'}>
                                                    <source src={ source.url } type={'video/mp4'} />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <span className={'video-backdrop'} />
                                                <i className="video-icon fa-regular fa-circle-play" />
                                            </div>
                                            : <img 
                                                onClick={() => handlePreviewImage(source.name, source.url)} 
                                                className="cursor-pointer" 
                                                src={ source.url } alt={ source.name } 
                                                width="100%" height="100%"
                                            />
                                    }
                                </div>
                            ))
                        }
                    </div>
                </Row>
        )
    }

    const renderFileGroup = (sources: any[]) => {

        return (
            sources.length > 0 &&
            sources.map((source, i) => (
                <div className="chat-message-item__message" key={`${source}_${i}`}>
                    <div className="chat-message-item__message__attachment-items">
                        <div className="chat-message-item__message__attachment-items__inner">
                            <a
                                className={'chat-message-item__message__file'}
                                href={source.url}
                                download={source.name}
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleDownload(source.url, source.name)
                                }}
                            ><i className="far fa-paperclip"/>{source.name}</a>
                        </div>
                    </div>
                </div>
            ))
        )
    }

    return (
        <div className={`chat-message-item ${message.author.staff || message.author.internal ? 'friend' : 'me'}`}>
            <div className="chat-message-item__time-avatar">
                <span className={'chat-message-item__time-avatar__time'}>{moment(message.timestamp).format("HH:mm DD/MM/YYYY")}</span>
                <span className={'chat-message-item__time-avatar__dot'}>
                    <i className="fa-solid fa-period" />
                </span>
                <img className={'chat-message-item__time-avatar__avatar'} src={message.author.staff ? message.author.avatar : boxChatContext.user.avatar} />
                <span className={'chat-message-item__time-avatar__name'}>{
                    message.author.staff ? message.author.username : boxChatContext.user.name
                }</span>
            </div>


            {/* {Array.isArray(message.attachments) && message.attachments.length > 0 && message.attachments.map(item => (
                <div className="chat-message-item__message">
                    <div className="chat-message-item__message__attachment-items">
                        <div className="chat-message-item__message__attachment-items__inner">
                            <React.Fragment key={item.hash}>
                                {item.type.startsWith('image') && (
                                    <img onClick={() => handlePreviewImage(item.name, item.url)} className={'cursor-pointer chat-message-item__message__image'} src={item.url} alt={item.name} />
                                )}

                                {item.type.startsWith('video') && (
                                    <div onClick={() => handlePreviewVideo(item.name, item.url, item.type)} className={'chat-message-item__message__video cursor-pointer'}>
                                        <video width={'100%'} height={'100%'}>
                                            <source src={item.url} type={'video/mp4'} />
                                            Your browser does not support the video tag.
                                        </video>
                                        <span className={'video-backdrop'} />
                                        <i className="video-icon fa-regular fa-circle-play" />
                                    </div>
                                )}

                                {!item.type.startsWith('image') && !item.type.startsWith('video') && (
                                    <a
                                        className={'chat-message-item__message__file'}
                                        href={item.url}
                                        download={item.name}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            handleDownload(item.url, item.name)
                                        }}
                                    ><i className="far fa-paperclip"/> {item.name}</a>
                                )}
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            ))} */}

            { renderImageGroup(message.attachments.filter(a => a.type.startsWith('video') || a.type.startsWith('image')))}
            { renderFileGroup(message.attachments.filter(a => !a.type.startsWith('video') && !a.type.startsWith('image')))}

            {message.content && (
                <div className="chat-message-item__message">
                    <div className={'chat-message-item__message__text'}>
                        {
                            (/(<([^>]+)>)/i).test(message.content)
                                ? <span>{ message.content }</span>
                                : <span dangerouslySetInnerHTML={{__html: formatContent(message.content)}} />
                        }
                    </div>
                </div>
            )}

            {isShowPreviewImage && (
                <Modal
                    className={'box-chat-modal-preview'}
                    title={previewImage.name}
                    closeIcon={<i className="fa-solid fa-xmark" />}
                    open={true}
                    footer={false}
                    onCancel={() => setIsShowPreviewImage(false)}
                >
                    <img className={'preview-image'} src={previewImage.url} alt={previewImage.name} />
                </Modal>
            )}

            {isShowPreviewVideo && (
                <Modal
                    className={'box-chat-modal-preview'}
                    title={previewVideo.name}
                    closeIcon={<i className="fa-solid fa-xmark" />}
                    open={true}
                    footer={false}
                    onCancel={() => setIsShowPreviewVideo(false)}
                >
                    <div className="preview-video">
                        <video
                            ref={videoRef}
                            width={'100%'}
                            height={'100%'}
                            controls={isVideoPlay}
                            onPlaying={() => setIsVideoPlay(true)}
                            onPause={() => setIsVideoPlay(false)}
                        >
                            <source src={previewVideo.url} type={'video/mp4'} />
                            Your browser does not support the video tag.
                        </video>

                        {!isVideoPlay && (
                            <>
                                <span className={'video-backdrop'} />
                                <i onClick={() => handlePlayVideo()} className="video-icon fa-regular fa-circle-play" />
                            </>
                        )}
                    </div>
                </Modal>
            )}
        </div>
    )
}
