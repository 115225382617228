import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { formatMoneyByUnit } from '../../../../core/helpers/string'

interface ItemProps {
    label: string
	currency: string
	currencyMarketplace?: string
	position?: number | string
	value: number | string
	valueExchanged?: number
    isMdLabel?: boolean
    valueClass?: string
	labelClass?: string
	loading: boolean
}
const Item: React.FC<ItemProps> = (props) => {
	const { currency, currencyMarketplace, position, value, valueExchanged, label, labelClass, valueClass, loading } = props
	return (
			<div className='item flex align-items-center'>
				<span className={`${labelClass ? labelClass : ''} label flex align-items-baseline`}>
						{!loading ? label : <Skeleton width={80} />} <span className='label-notice mg-l-2'>{!loading && position}</span>
				</span>
				<span className={`value-by-provider ${valueClass ? valueClass : ''}`}>
					{!loading ? value || value === 0 ? formatMoneyByUnit(value, currency) : '---' : <Skeleton width={80}/>}
					{valueExchanged && <span className='value-by-marketplace'>
						{!loading ? formatMoneyByUnit(valueExchanged, currencyMarketplace) : <Skeleton width={80}/>}
					</span>}
				</span>
			</div>
	)
}

export default Item