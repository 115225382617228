import React, {useEffect, useState} from "react";
import {ConnectChannelInterface, ConnectShopInterface, ShopCriteria} from "../../../Interface/ConnectShopInterface";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, ConfigProvider, Empty, List} from "antd";
import EmptyBlue from "../../../resources/images/empty-blue1.png";
import {useNavigate} from "react-router-dom";

type MarketIntegrationProps = {
    channel:ConnectChannelInterface;
    integrations:ConnectShopInterface[];
    getChannelIntegrations:(filter:ShopCriteria,channelCode:string)=>void;
    handleClickCheckbox:(e:any)=>void;
    isCheckedList:any[];
    loading:boolean;
}

function MarketIntegrations(props:MarketIntegrationProps) {

    useEffect(() => {
        props.getChannelIntegrations({
            limit:1000,
            offset:0,
        },props.channel.code);
    }, []);

    return(
            <List
                className={'market-integration__item'}
                style={{overflow:'auto',maxHeight:150}}
                dataSource={props.integrations}
                renderItem={(integration:ConnectShopInterface)=> {
                    return(
                        <List.Item>
                            <Checkbox
                                value={integration.id}
                                checked={props.isCheckedList.includes(integration.id)}
                                onClick={props.handleClickCheckbox}
                            >
                                <span className={'mgl10 robotomedium txt-color-black'}>
                                    {integration.name}
                                </span>
                            </Checkbox>
                        </List.Item>


                    )
                }}
                size={'small'}
                loading={props.loading}
            />
    )
}

export default MarketIntegrations;
