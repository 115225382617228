import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Card, Col, Grid, Input, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { ConnectShopInterface } from '../../Interface/ConnectShopInterface'
import lodash, { isArray } from 'lodash'

type SellingProductFilterProps = {
	searchParams: any
	setSearchParams: Dispatch<SetStateAction<any>>
	integrationList: ConnectShopInterface[]
	statuses: Object[]
}

const { useBreakpoint } = Grid

function SellingProductFilter(props: SellingProductFilterProps) {
	const { t } = useTranslation()
	const { lg } = useBreakpoint()

	const [collapse, setCollapse] = useState(false)

	const activeShopList = props.searchParams.get('integrationIds')?.split(',') || []
	const activeStatuses = props.searchParams.get('statuses')?.split(',') || []

	const [productNameFieldValue, setProductNameFieldValue] = useState<string>(props.searchParams.get('name'))
	const [productSkuValue, setProductSkuValue] = useState<string>(props.searchParams.get('productCode'))
	const [productId, setProductIdValue] = useState<string>(props.searchParams.get('itemId'))
	const [showCleanFilter, setShowCleanFilter] = useState(false)

	const onResetFilter = (paramName: string = '') => {
		if (!paramName) {
			props.setSearchParams({})
			setProductNameFieldValue('')
			setProductSkuValue('')
			setProductIdValue('')
			return
		}

		const { ...rest } = Object.fromEntries(props.searchParams)
		delete rest[paramName]
		props.setSearchParams({ ...rest })
	}

	const onSelectShop = (integrationId: string) => {
		switch (integrationId !== '') {
			case true:
				let shopList = [...activeShopList]
				const { ...rest } = Object.fromEntries(props.searchParams)
				if (shopList.includes(integrationId)) {
					shopList = shopList.filter((s) => s !== integrationId)
					if (shopList.length > 0) {
						props.setSearchParams({ ...rest, integrationIds: shopList.join(',') })
					} else {
						delete rest.integrationIds
						props.setSearchParams({ ...rest })
					}
				} else {
					shopList.push(integrationId)
					props.setSearchParams({ ...rest, integrationIds: shopList.join(',') })
				}
				break
			default:
				onResetFilter('integrationIds')
				break
		}
	}

	const handleSelectStatuses = (status: string) => {
		if (!status) {
			onResetFilter('statuses')
			return
		}

		let statusList = [...activeStatuses]
		const { ...rest } = Object.fromEntries(props.searchParams)

		if (statusList.includes(status)) {
			statusList = statusList.filter((s) => s !== status)
			if (statusList.length > 0) {
				props.setSearchParams({ ...rest, statuses: statusList.join(',') })
			} else {
				delete rest.statuses
				props.setSearchParams({ ...rest })
			}
		} else {
			statusList.push(status)
			props.setSearchParams({ ...rest, statuses: statusList.join(',') })
		}
	}

	const handleCollapseFilter = () => {
		setCollapse(!collapse)
	}

	const handleProductIdValueChange = (e: React.ChangeEvent<HTMLInputElement>) => setProductIdValue(e.target.value)
	const handleProductNameFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setProductNameFieldValue(e.target.value)
	const handleProductSkuIdValueChange = (e: React.ChangeEvent<HTMLInputElement>) => setProductSkuValue(e.target.value)

	const findProductByInput = () => {
		let filterTemp = {
			name: productNameFieldValue?.trim() || '',
			productCode: productSkuValue?.trim() || '',
			itemId: productId?.trim() || '',
			integrationIds: activeShopList.join(','),
			statuses: activeStatuses.join(','),
		}
		props.setSearchParams({
			...lodash.omitBy(filterTemp, (value) => !value),
		})
	}

	// const checkUrlSearchParams = () => {
	//     var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
	// }

	const handleSearch = () => findProductByInput()

	useEffect(() => {
		setShowCleanFilter(
			!!productNameFieldValue ||
				!!productSkuValue ||
				!!productId ||
				isArray(activeShopList) ||
				isArray(activeStatuses)
		)
	}, [activeShopList, activeStatuses, productId, productNameFieldValue, productSkuValue])

	return (
		<Card
			title={
				<div className={'dpl-flex flex-row justify-content-between'} onClick={handleCollapseFilter}>
					<span>{t('warehouse.searchProduct')}</span>
					<span>
						{collapse ? <i className='fa-solid fa-angle-down' /> : <i className='fa-solid fa-angle-up' />}
					</span>
				</div>
			}
			className='filter-list-container'>
			{!collapse ? (
				<Row gutter={[0, 16]}>
					<Col xs={24}>
						<Row gutter={[32, 12]} align='middle'>
							<Col>
								<Row gutter={[6, 12]} align='middle'>
									<Col>
										<h4 className='txt-size-h7 robotoregular txt-color-black line-h-22 mgbt0'>
											{t('pendingProduct.shop-filter')}:
										</h4>
									</Col>
									<Col>
										<Button
											className={`status-package mg-bt-0 ${
												activeShopList.length === 0 ? 'active' : ''
											}`}
											onClick={() => onSelectShop('')}>
											{t('tickets.missing_quantity_all')}
										</Button>
									</Col>
									{props.integrationList.map((shop: ConnectShopInterface, i) => (
										<Col key={`shop_${i}`}>
											<Button
												className={`status-package mg-bt-0 ${
													activeShopList.includes(shop.id) ? 'active' : ''
												}`}
												onClick={() => onSelectShop(shop.id)}>
												{shop.name}
											</Button>
										</Col>
									))}
								</Row>
							</Col>
							<Col>
								<Row gutter={[6, 12]} align='middle'>
									<Col>
										<h4 className='txt-size-h7 robotoregular txt-color-black line-h-22 mgbt0'>
											{t('sellingProduct.status-filter')}:
										</h4>
									</Col>
									<Col>
										<Button
											className={`status-package mg-bt-0 ${
												activeStatuses.length === 0 ? 'active' : ''
											}`}
											onClick={() => handleSelectStatuses('')}>
											{t('tickets.missing_quantity_all')}
										</Button>
									</Col>
									{props.statuses.map((status: any, i) => (
										<Col key={`shop_${i}`}>
											<Button
												className={`status-package mg-bt-0 ${
													activeStatuses.includes(status.code) ? 'active' : ''
												}`}
												onClick={() => handleSelectStatuses(status.code)}>
												{status.name}
											</Button>
										</Col>
									))}
								</Row>
							</Col>
							<Col className={'width100pc'}>
								<Row gutter={[12, 0]} align='bottom'>
									<Col span={8}>
										<Row gutter={[4, 0]} align='middle'>
											<Col>
												<h4 className='txt-size-h7 robotoregular txt-color-black line-h-22 mgbt0'>
													{t('orderDetail.productName')}:
												</h4>
											</Col>
											<Col>
												<Input
													type='text'
													className={`txt-size-h8 txt-color-secondary robotoregular border-radius4 line-h-20 ${
														lg ? 'input-fixed-width' : ''
													}`}
													placeholder={`${t('warehouse.fillProductNameField')}...`}
													onChange={(e) => handleProductNameFieldValueChange(e)}
													value={productNameFieldValue}
													onPressEnter={handleSearch}
												/>
											</Col>
										</Row>
									</Col>
									<Col span={8}>
										<Row gutter={[4, 0]} align='middle'>
											<Col>
												<h4 className='txt-size-h7 robotoregular txt-color-black line-h-22 mgbt0'>
													{t('sellingProduct.productSkuCode')}:
												</h4>
											</Col>
											<Col>
												<Input
													type='text'
													className={`txt-size-h8 txt-color-secondary robotoregular border-radius4 line-h-20 ${
														lg ? 'input-fixed-width' : ''
													}`}
													placeholder={`${t('sellingProduct.fillProductSkuField')}`}
													onChange={(e) => handleProductSkuIdValueChange(e)}
													value={productSkuValue}
													onPressEnter={handleSearch}
												/>
											</Col>
										</Row>
									</Col>
									<Col span={8}>
										<Row gutter={[4, 0]} align='bottom'>
											<Col>
												<h4 className='txt-size-h7 robotoregular txt-color-black line-h-22 mgbt0'>
													{t('sellingProduct.productId')}:
												</h4>
											</Col>
											<Col>
												<Input
													type='text'
													className={`txt-size-h8 txt-color-secondary robotoregular border-radius4 line-h-20 ${
														lg ? 'input-fixed-width' : ''
													}`}
													placeholder={`${t('sellingProduct.fillProductIdField')}`}
													onChange={(e) => handleProductIdValueChange(e)}
													value={productId}
													onPressEnter={handleSearch}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col xs={24}>
						<Row justify='end'>
							{showCleanFilter && (
								<Col>
									<Button
										type='text'
										className='btn-filter-reset txt-size-h8 robotoregular txt-color-secondary'
										icon={<i className='fa-solid fa-rotate-left' />}
										onClick={() => onResetFilter()}>
										{t('filter.refresh')}
									</Button>
								</Col>
							)}
							<Col>
								<Button
									className='btn-df btn-df-secondary-bg-lemon px-24'
									onClick={handleSearch}>
									{t('orders.button_search')}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			) : null}
		</Card>
	)
}

export default SellingProductFilter

