import { Row, Col } from 'antd';

interface RowItemInterface {
  label?: string | false;
  children?: any;
  className?: string;
  subLabel?: string;
}

export const RowItem: React.FC<RowItemInterface> = ({
  className = 'mgt10',
  label,
  subLabel,
  children,
}: RowItemInterface): JSX.Element => {
  return (
    <Row gutter={12} className={className}>
      <Col span={3} className="label">
        {label && `${label}:`}
        {subLabel && <br />}
        {subLabel}
      </Col>

      <Col span={21}>{children}</Col>
    </Row>
  );
};
