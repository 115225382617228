import { AxiosResponse } from "axios";
import { API_BASE_URL } from "../core/config";
import { httpConfig } from "../core/config/http";
import { url } from '../core/helpers/string';
import { Repository } from '../core/repositories/Repository';
import { ClaimCommentCriteriaInterface } from "../Interface/ClaimCommentCriteriaInterface";
import { ClaimCreateInterface } from "../Interface/ClaimCreateInterface";

export class ClaimRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.setBaseURL(url(API_BASE_URL, ''));
    }

    getClaimDetail = (providerCode: string, customerCode: string, orderCode: string, claimCode: string) => {
        return this.http.get(`providers/${providerCode}/accounts/${customerCode}/orders/${orderCode}/claims/${claimCode}`)
    }

    archive = (providerCode: string, customerCode: string,  ticketCode: string) => {
        return this.http.put(`providers/${providerCode}/accounts/${customerCode}/claims/${ticketCode}/archive`)
    }

    rating = (providerCode: string, customerCode: string, ticketCode: string, data: {rating: number, comment: string}) => {
        return this.http.put(`providers/${providerCode}/accounts/${customerCode}/claims/${ticketCode}/rating`, data)
    }

    getClaimStatuses = (providerCode: string): Promise<any> => {
        return this.http.get(`/providers/${providerCode}/claim-states`, {
            params:{offset:0, limit:100}
        }).then((response:AxiosResponse) => {
            return response.data;
        });
    }

    getClaims = (providersCode: string, customerCode: string, filter: any):Promise<any> => {
        return this.http.get(`/claims`,
            {params:{...filter, sort: 'createdAt:desc', limit: 1000, provider: providersCode, providerUsername: customerCode}}
        ).then((response:AxiosResponse) => {
            return response;
        }) 
    }

    create = (data: ClaimCreateInterface) => {
        return this.http.post(`/claims`, data)
    }

    getClaimsByOrder = (
        providerCode: string | undefined, 
        accountCode: string | undefined, 
        orderCode: string | undefined, 
        filter: any
    ): Promise<any> => {
        const { page, size: limit, sort, order } = filter
        const offset = limit ? (page - 1) * limit : 0
        const params = { offset, limit, sort: sort || "createdAt", order: order || "DESC" }
        return this.http
            .get(
                `/providers/${providerCode}/accounts/${accountCode}/claims?orderCode=${orderCode}`,
                { params }
            )
            .then((response: AxiosResponse<any>) => {return response});
    }

    getMilestones = (providerCode: string, accountCode: string, orderCode: string, claimCode: string) => {
        return this.http.get(`/providers/${providerCode}/accounts/${accountCode}/orders/${orderCode}/claims/${claimCode}/milestones`)
            .then((response:AxiosResponse) => {
                return response.data
            })
    }
    creatClaimComment=(threadReferenceCode:string|null,data:any):Promise<any> => {
        const formData = new FormData();
        if (Array.isArray(data.attachments)) {
            for (let file of data.attachments) {
                formData.append('attachments', new Blob([file]), file.name)
            }
        }
        if (data.comment) {
            formData.set('comment', new Blob([JSON.stringify(data.comment)], {type: 'application/json'}))
        }
        return this.http.post(`claims/${threadReferenceCode}/comments`,formData
        )
    }

    getClaimComment = (threadReferenceCode:string|null,filter:ClaimCommentCriteriaInterface):Promise<any> => {
        return this.http.get(`claims/${threadReferenceCode}/comments`,{params:{...filter}})
            .then((response:AxiosResponse) => {
            return response;
        })
    }

    public getClaimsV1 = (code: string, provider: string, providerUsername: string) : Promise<any> => {
        return this.http.get(`/claims`, {params: {size: 1000, sort: 'createdAt:desc', provider, providerUsername}}).then((response:AxiosResponse) => {
            return response.data;
        })
    }
}

export const claimRepository: ClaimRepository = new ClaimRepository();
