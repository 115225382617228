import { Image, Layout, Menu, MenuProps } from 'antd'
import lodash, { map } from 'lodash'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'
import { TENANT_KEY } from '../../core/config'
import Logo from '../../resources/images/dau-hop.png'
import { localStorageRead } from '../../utils/LocalStorageUtils'
import { Button } from 'antd'
const { Sider } = Layout

const { ItemGroup, Item } = Menu

interface SidebarProps {
	// logoutHandler: () => void
	// toggleHandler: () => void
	// specificToggle: (val: boolean) => void
	// collapsed: boolean
	activeKey: string
	currentConfigServices: any
}

const Sidebar: React.FC<SidebarProps> = (props) => {
	// const { collapsed, toggleHandler, activeKey, logoutHandler, currentConfigServices, specificToggle } = props
	const { activeKey, currentConfigServices } = props
	const [, setCurrentConfigShowBalance] = useState()
	const ref = useRef<HTMLDivElement | null>(null)

	const navigate = useNavigate()
	const { t } = useTranslation()
	const [menu, setMenu] = useState<any>()

	type MenuItem = Required<MenuProps>['items'][number]

	useEffect(() => {
		let connectItems = {
			children: [
				{
					key: 'connect-supplier',
					icon: <i className='fa-light fa-user fsz-14' />,
					label: (
						<Link to={'/connect-supplier'} className='fsz-14'>
							Tài khoản tạo đơn
						</Link>
					),
				},
			],
		}

		let financeItems = {
			children: [
				{
					key: 'transactions',
					icon: <i className='fa-light fa-money-from-bracket fsz-14' />,
					label: (
						<Link to={'/transactions'} className='fsz-14'>
							Giao dịch
						</Link>
					),
				},
			],
		}

		let deliveryItems = {
			children: [
				{
					key: 'packages',
					icon: <i className='fa-light fa-cart-flatbed-boxes fsz-14' />,
					label: (
						<Link to={'/packages'} className='fsz-14'>
							Kiện hàng
						</Link>
					),
				},
				{
					key: 'request-delivery',
					icon: <i className='fa-light fa-money-from-bracket fsz-14' />,
					label: (
						<Link to={'/delivery'} className='fsz-14'>
							Yêu cầu giao
						</Link>
					),
				},
				{
					key: 'delivery-notes',
					icon: <i className="fa-light fa-clipboard-list-check fsz-14"></i>,
					label: (
						<Link to={'/delivery-notes'} className='fsz-14'>
							Phiếu xuất
						</Link>
					),
				},
			],
		}

		let ordersItems = {
			children: [
				{
					key: 'orders',
					icon: <i className='fa-solid fa-user fsz-14' />,
					label: (
						<Link to={'/orders'} className='fsz-14 '>
							Mua hộ
						</Link>
					),
				},

				{
					key: 'shipments',
					icon: <i className="fa-light fa-memo-circle-info fsz-14"></i>,
					label: (
						<Link to={`/shipments`} className='fsz-14'>
							Đơn ký gửi
						</Link>
					),
				},

				{
					key: 'peerpayments',
					icon: <i className='fa-solid fa-user fsz-14' />,
					label: (
						<Link to={'/peerpayments'} className='fsz-14'>
							Đơn ký gửi & Thanh toán hộ
						</Link>
					),
				},
				{
					key: 'claims',
					icon: <i className='fa-solid fa-user fsz-14' />,
					label: (
						<Link to={'/claims'} className='fsz-14'>
							Khiếu nại
						</Link>
					),
				},
			],
		}

		let gsaItems = {
			children: [
				{
					key: 'stored-products',
					icon: <i className='fa-light fa-bookmark fsz-14' />,
					label: (
						<Link to={'/stored-products'} className=' fsz-14'>
							Sản phẩm đã lưu
						</Link>
					),
				},
				{
					key: 'price-config',
					icon: <i className='fa-light fa-money-check-pen' />,
					label: (
						<Link to={'/price-config'} className='fsz-14'>
							Cấu hình giá bán
						</Link>
					),
				},
				{
					key: 'connect-platform',
					icon: <i className='fa-light fa-bag-shopping dpl-content' />,
					label: <span className='fsz-14'>Sàn thương mại liên kết</span>,
					children: [
						{
							key: 'pending-products/shopee',
							icon: <i className='fa-solid fa-user fsz-14' />,
							label: (
								<Link to={`/pending-products/shopee`} className='fsz-14'>
									Chờ đăng bán
								</Link>
							),
						},
						{
							key: 'selling-products/shopee',
							icon: <i className=' fsz-14' />,
							label: (
								<Link to={`/selling-products/shopee`} className='fsz-14'>
									Đang bán
								</Link>
							),
						},
					],
				},
				{
					key: 'market-connect',
					icon: 	<i className='fa-light fa-house-signal fsz-14' />,
					label: (
						<Link to={`/market-connect`} className='fsz-14'>
							Kết nối shop
						</Link>
					),
				},
			],
		}

		if (!currentConfigServices?.enableGsa) {
			connectItems.children = connectItems.children.filter((item) => item.key !== 'market-connect')
			gsaItems.children = gsaItems.children.filter(
				(item) =>
					item.key !== 'warehouse/stored-products' &&
					item.key !== 'selling-products/shopee' &&
					item.key !== 'pending-products/shopee'
			)
		}

		if (!currentConfigServices?.enablePeerPayment || !currentConfigServices?.enableShipment) {
			ordersItems.children = ordersItems.children.filter((item) => item.key !== 'peerpayments')
		}
		if (!currentConfigServices?.enableOrder) {
			ordersItems.children = ordersItems.children.filter((item) => item.key !== 'orders')
		}
		if (!currentConfigServices?.enableShipment) {
			ordersItems.children = ordersItems.children.filter((item) => item.key !== 'shipments')
		}
		if (!currentConfigServices?.enableShipment && !currentConfigServices?.enableOrder) {
			connectItems.children = connectItems.children.filter((item) => item.key !== 'connect-supplier')
			financeItems.children = financeItems.children.filter((item) => item.key !== 'transactions')
			deliveryItems.children = deliveryItems.children.filter(
				(item) => item.key !== 'packages' && item.key !== 'delivery-notes' && item.key !== 'delivery'
			)
			ordersItems.children = ordersItems.children.filter((item) => item.key !== 'claims')
		}

		let items: MenuItem[] = [
			{
				key: 'order-management',
				icon: <i className="fa-light fa-clipboard-list-check fsz-16"></i>,
				label: <span className='fsz-12 '>Quản lý đơn</span>,
				children: ordersItems.children,
			},
			{
				key: 'finance',
				icon: <i className='fa-light fa-money-bill-wave fsz-16' />,
				label: <span className='ant-submenu-items-label'>Tài chính</span>,
				children: financeItems.children,
			},
			{
				key: 'delivery',
				icon: <i className='fa-regular fa-truck fsz-16' />,
				label: <span className='ant-submenu-items-label'>Giao hàng</span>,
				children: deliveryItems.children,
			},
			{
				key: 'connect',
				icon: <i className='fa-light fa-link fsz-16' />,
				label: <span className='ant-submenu-items-label'>Kết nối</span>,
				children: connectItems.children,
			},

			{
				key: 'seller',
				icon: <i className='fa-light fa-store fsz-16' />,
				label: <span className='ant-submenu-items-label'>Bán hàng</span>,
				children: gsaItems.children,
			},
		]

		const showBalance = lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', '')

		if (
			!currentConfigServices?.enableShipment &&
			!currentConfigServices?.enableOrder &&
			!currentConfigServices?.enableGsa
		) {
			items = items.filter((item: any) => item?.key !== 'connect')
		}
		if (!currentConfigServices?.enableShipment && !currentConfigServices?.enableOrder) {
			items = items.filter(
				(item: any) =>
					item?.key !== 'products' &&
					item?.key !== 'transactions' &&
					item?.key !== 'orders' &&
					item?.key !== 'delivery' &&
					item?.key !== 'seller'
			)
		}

		if (!currentConfigServices?.enableGsa) {
			items = items.filter((item: any) => item?.key !== 'warehouse')
		}
		if (!currentConfigServices?.enableGsa) {
			items = items.filter((item: any) => item?.key !== 'linked-channels')
		}
		items = items.filter((item: any) => item?.children.length > 0)
		setCurrentConfigShowBalance(showBalance)
		const menu = !showBalance ? items.filter((item) => item?.key !== 'transactions') : items
		setMenu(menu)
	}, [
		activeKey,
		currentConfigServices?.enableGeneral,
		currentConfigServices?.enableGsa,
		currentConfigServices?.enableOrder,
		currentConfigServices?.enableShipment,
		currentConfigServices?.enablePeerPayment,
		t,
	])
	return (
		<Sider theme='light' width={80} className='sidebar-left'>
			<Image
				preview={false}
				height={28}
				width={29.75}
				src={Logo}
				alt='ubox-logo'
				className='cursor-pointer ubox-logo'
				onClick={() => navigate('/')}
			/>
			<Menu inlineCollapsed={false} mode='vertical' selectedKeys={[activeKey]} items={menu} />

			<span className=' cursor-pointer position-fix position-left-1 position-bottom-5 '>
				<i className='fa-light fa-moon fsz-18 icon-dark-mode' />
			</span>
		</Sider>
	)
}

export default Sidebar
