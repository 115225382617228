import React, {
  MutableRefObject,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Editor, IAllProps } from '@tinymce/tinymce-react';

import './style.scss';
import { useTranslation } from 'react-i18next';
import Form, { FormInstance } from 'antd/es/form';
import { Input } from 'antd';

interface RichTextEditorProps extends IAllProps {
  placeholder?: string;
  height?: number;
  isRequired?: boolean;
  form?: FormInstance<Record<any, any>>;
  requireMessage?: string;
  formItemName?: string;
}

const RichTextEditor = React.forwardRef(
  (
    {
      placeholder,
      height = 221,
      isRequired = false,
      form,
      formItemName = '',
      requireMessage = '',
      ...props
    }: RichTextEditorProps,
    ref: any
  ): JSX.Element => {
    const editorRef: MutableRefObject<any> = useRef(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      getContent: () => {
        return editorRef.current?.getContent();
      },
    }));

    const onBlur = () => {
      form?.setFieldsValue({
        [formItemName]: editorRef.current?.getContent(),
      });

      if (isRequired) {
        setIsError(!editorRef.current?.getContent());
      }
      setIsFocus(false);
    };

    let className = 'rich-text-container';

    if (isError) {
      className += ' validate-error';
    }

    if (isFocus) {
      className += ' rich-text-container-focused';
    }

    if (isError && isFocus) {
      className += ' error-focus';
    }

    return (
      <div className={className}>
        {formItemName && (
          <Form.Item
            name={formItemName}
            rules={[{ required: isRequired, message: '' }]}
            style={{ height: 0 }}
          >
            <Input />
          </Form.Item>
        )}

        <Editor
          onInit={(_: any, editor: any) => (editorRef.current = editor)}
          init={{
            height,
            menubar: false,
            toolbar:
              'undo redo | blocks fontfamily | formatselect | list |' +
              'bold italic underline | alignleft aligncenter ' +
              'alignright | list | ' +
              'removeformat | help',
            placeholder,
            images_file_types: '',
            toolbar_location: 'bottom',
            line_height_formats: '1',
            content_style: `html {cursor: text} body { font-family: Sans-serif; font-size: 14px; line-height: 1; margin: 11px; } 
              .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before
              { color: #BDBDBD; font-family: "Roboto", sans-serif;} 
              p {margin-top: 11px}`,
          }}
          apiKey="u8lnt7mu7oivi5o1fn5d309dk38eutomyxyuzmtkawvhnjwj"
          onBlur={onBlur}
          onKeyDown={() => setIsError(false)}
          onFocus={() => setIsFocus(true)}
          initialValue={form?.getFieldValue(formItemName)}
          {...props}
        />
        {isError && (
          <div role="alert" className="ant-form-item-explain-error">
            {requireMessage || t('message.field-required')}
          </div>
        )}
      </div>
    );
  }
);

export default RichTextEditor;
