import React from "react";
import {useTranslation} from "react-i18next";
import uboxLogo from "../../../resources/images/ubox-logo.png";

export const WelcomeNotice = () => {
    const {t} = useTranslation()
    return (
        <div className="notification-welcome">
            <div className={'notification-welcome-img'}><img src={uboxLogo} alt={'No items'} /></div>
            <div className={'notification-welcome-txt'}>{t('headerNotification.welcomeMessage')}</div>
        </div>
    )
}
