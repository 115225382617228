import { Avatar, Badge, Button, Card, Col, Image, Row, Table, Tooltip } from 'antd'
import lodash from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DEFAULT_PAGE_SIZE } from '../../core/config'
import { formatNumber } from '../../core/helpers/string'
import { IMarketplace } from '../../Interface/MarketplaceInterface'
import EmptyDataFilter from '../Empty/EmptyDataFilter'
import M24Pagination from '../Pagination/M24Pagination'

type SellingProductListProps = {
	loading: boolean
	total: number | null
	productList: any[]
	selectedItems: string[]
	setSelectItems: Dispatch<SetStateAction<string[]>>
	searchParams: any
	setSearchParams: any
	marketplaces: IMarketplace[]
	statuses: any[]
}

const ActionIcon = (props: { classes: string }) => {
	const [isHovered, setHover] = useState<boolean>(false)

	return (
		<i
			className={`${isHovered ? 'fa-solid' : 'fa-light'} ${props.classes}`}
			onMouseOver={() => setHover(true)}
			onMouseLeave={() => setHover(false)}></i>
	)
}

function SellingProductList(props: SellingProductListProps) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const [productList, setProductList] = useState<any[]>([])
	const [hoveredButtonAtRowKey, setHoverButtonAtRowKey] = useState<null | string>(null)

	useEffect(() => {
		setProductList(
			props.productList.map((item) => ({
				...item,
				startSkuIndex: 0,
				endSkuIndex: 3,
			}))
		)
	}, [props.productList])

	const onChangePage = (filter: any) => {
		props.setSearchParams({
			...Object.fromEntries(props.searchParams),
			page: filter.page,
			pageSize: filter.pageSize,
		})
	}

	const onShowMoreSkus = (id: string, skusLength: number, type: string) => {
		setProductList((prevProductList) => {
			const matchProductIndex = prevProductList.map((product) => product.id).indexOf(id)
			if (matchProductIndex >= 0) {
				const clonedProductList = [...prevProductList]
				if (type === 'show-more') {
					clonedProductList[matchProductIndex].endSkuIndex += skusLength
				} else if (type === 'show-less') {
					clonedProductList[matchProductIndex].endSkuIndex = 3
				}
				return clonedProductList
			}
			return prevProductList
		})
	}

	const onClickRow = (record: any, rowIndex: number | undefined) => {
		if (Number.isInteger(rowIndex) && hoveredButtonAtRowKey !== `${record.id}`) {
			props.setSelectItems((prevSelectedItems: string[]) => {
				const selectedRowIndex = prevSelectedItems.indexOf(`${record.id}`)
				const clonedSelectedItems = [...prevSelectedItems]
				selectedRowIndex >= 0
					? clonedSelectedItems.splice(selectedRowIndex, 1)
					: clonedSelectedItems.push(`${record.id}`)
				return clonedSelectedItems
			})
		}
	}

	const handleClickProductName = (sellerUrl: string) => {
		let w = window
		let windowFeatures = 'left=100,top=100,width=540,height=720,modal=yes,alwaysRaised=yes'
		if (sellerUrl) w.open(sellerUrl, '_blank', windowFeatures)
	}

	const localeCustom = {
		emptyText: (
			<EmptyDataFilter
				description={
					!searchParams.get('name') && !searchParams.get('productCode') && !searchParams.get('itemId')
						? t('sellingProduct.empty-data')
						: t('sellingProduct.filterEmpty')
				}
			/>
		),
	}

	const columns: any = [
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('orderDetail.productName')}</span>
			),
			dataIndex: 'name',
			key: 'name',
			width: '25%',
			render: (_: string, record: any) => {
				let s = lodash.find(props.statuses, (s) => s.code === record.status)
				return (
					<Row gutter={[8, 0]} wrap={false}>
						<Col className={'dpl-flex flex-col align-self-center'}>
							<Image
								src={record.productImages[0]}
								preview={false}
								width={64}
								height={64}
								className='border-radius4 bd-none'
							/>
						</Col>
						<Row gutter={[0, 3]}>
							<Col xs={20} className={'pdl5'}>
								<a
									className='txt-size-h7 robotomedium line-h-22 txt-color-black cursor-pointer'
									onClick={() => handleClickProductName(record.sellerUrl)}>
									{record.name}
								</a>
							</Col>
							<Col xs={24} className={'pdl5'}>
								<span className='shop-name txt-size-h8 robotoregular txt-color-blue6 dpl-il-block px-10 py-1 border-radius4 line-h-20 bd-color-blue6 bd1px'>
									{record.integrationName}
								</span>
								<span className={'txt-color-gray4 robotoregular txt-size-h8 pdl25'}>
									ID: {record.itemId ? record.itemId : '---'}
								</span>
							</Col>
							<Col xs={20} className={'pdl5'}>
								<span>
									<i className='fa-solid fa-heart txt-color-primary pdr5' /> ---
								</span>
								<span>
									<i className='fa-light fa-eye pdr5 pdl10' /> ---
								</span>
								{s && (
									<div
										style={{ backgroundColor: s.color }}
										className='dpl-il-block px-10 py-1 line-h-20 mgl10 product-status border-radius20 txt-color-white width80px txt-center'>
										{s.name}
									</div>
								)}
							</Col>
						</Row>
					</Row>
				)
			},
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>Marketplace</span>,
			dataIndex: 'marketplace',
			key: 'marketplace',
			width: '10%',
			render: (_: string, record: any) => {
				let marketplace = lodash.find(props.marketplaces, (m) => m.code === record.marketplace)
				return (
					<div className='marketplace-row flex align-items-center flex-nowrap'>
						<Avatar
							src={lodash.get(marketplace, 'icon', '')}
							size={16}
							className='mg-r-3 rad-2 bd-none-impt'
							shape='square'
						/>
						<span className='txt-size-h7 robotoregular line-h-22 txt-color-black txt-capitalize '>
							{record.marketplace}
						</span>
					</div>
				)
			},
		},
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('warehouse.skuProductTitle')}</span>
			),
			dataIndex: '',
			width: '10%',
			render: (_: string, record: any) => (
				<span className='product-code txt-size-h7 robotoregular line-h-22 txt-color-black'>
					{record.code ? record.code : '----'}
				</span>
			),
		},
		{
			title: (
				<Row align='middle'>
					<Col xs={11}>
						<span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>
							{t('warehouse.skuProductSplitTitle')}
						</span>
					</Col>
					<Col xs={8} className={'txt-right pdr5 fsz-14 line-h-22 robotomedium txt-color-gray4'}>
						<span>{t('warehouse.salePrice')}</span>
					</Col>
					<Col xs={5} className={'txt-right pdr5 fsz-14 line-h-22 robotomedium txt-color-gray4'}>
						<span>{t('warehouse.stock')}</span>
					</Col>
				</Row>
			),
			dataIndex: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('warehouse.skuProductTitle')}</span>
			),
			width: '40%',
			render: (_: string, record: any, index: number) => (
				<Row gutter={[0, 2]}>
					{record.skus &&
						record.skus.slice(record.startSkuIndex, record.endSkuIndex).map((sku: any, i: number) => (
							<Col xs={24} key={`${sku.oid}`}>
								<Row align='middle' className='px-12 py-8 bg-color-gray-badge'>
									<Col xs={11}>
										<span className='txt-size-h7 line-h-22 txt-color-black roboteregular '>
											{sku.name}
										</span>
									</Col>
									<Col xs={8} className={'txt-right'}>
										<span className='txt-size-h7 line-h-22 txt-color-black robotomedium align-self-center'>
											{formatNumber(sku.salePrice)}
										</span>
									</Col>
									<Col xs={5} className={'txt-right'}>
										<span className='txt-size-h7 line-h-22 txt-color-orange2 robotomedium'>
											{formatNumber(sku.stock)}
										</span>
									</Col>
								</Row>
							</Col>
						))}
					{record.endSkuIndex < record.skus?.length && (
						<Col xs={24}>
							<Row justify='center' className={'mgt5'}>
								<span
									className='btn-show-more txt-size-h8 robotomedium txt-color-blue7'
									onMouseOver={() => setHoverButtonAtRowKey(`${record.id}`)}
									onMouseLeave={() => setHoverButtonAtRowKey(null)}
									onClick={() => {
										onShowMoreSkus(record.id, record.skus.length, 'show-more')
									}}>
									<i className='fa-regular fa-plus mgl5 mgr5' />
									{t('pendingProduct.display-more-sku')}
								</span>
							</Row>
						</Col>
					)}
					{record.endSkuIndex > record.skus?.length && record.skus?.length > 2 && (
						<Col xs={24}>
							<Row justify='center' className={'mgt5'}>
								<span
									className='btn-show-more txt-size-h8 robotomedium txt-color-blue7'
									onMouseOver={() => setHoverButtonAtRowKey(`${record.id}`)}
									onMouseLeave={() => setHoverButtonAtRowKey(null)}
									onClick={() => onShowMoreSkus(record.id, record.skus.length, 'show-less')}>
									<i className='fa-regular fa-hyphen mgl5 mgr5' />
									{t('pendingProduct.display-less-sku')}
								</span>
							</Row>
						</Col>
					)}
				</Row>
			),
		},
		{
			title: (
				<div className={'width100pc txt-center fsz-14 line-h-22 robotomedium txt-color-gray4'}>
					{t('connectMarket.action')}
				</div>
			),
			dataIndex: 'action',
			key: 'action',
			width: '10%',
			render: (_: string, record: any, index: number) => (
				<Row
					gutter={12}
					justify={'center'}
					className='action-row'
					onMouseOver={() => setHoverButtonAtRowKey(`${record.id}`)}
					onMouseLeave={() => setHoverButtonAtRowKey(null)}>
					<Col>
						<Tooltip
							placement='top'
							title={t('address.edit-action')}
							color='#3340B6'
							overlayClassName='action-tooltip'
							overlayInnerStyle={{
								borderRadius: '3px',
							}}>
							<Button
								type='text'
								icon={<ActionIcon classes='fa-sliders txt-size-h8 txt-color-blue6' />}
								onClick={() => navigate(`/selling-products/shopee/${record.id}/edit`)}></Button>
						</Tooltip>
					</Col>
				</Row>
			),
		},
	]

	return (
		<Card
			title={
				<Row align='middle' justify='space-between'>
					<Col>
						<Row align='middle' gutter={[3, 0]}>
							<Col>
								<h3 className='mgbt0'>{t('warehouse.sellingProduct')}</h3>
							</Col>
							<Col>
								<Badge
									overflowCount={9999}
									showZero
									count={props.total || 0}
									className={`${!props.total ? 'badge-zero' : 'badge-not-zero'}`}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			}
			className='product-list-container'>
			<Row gutter={[0, 12]}>
				<Col xs={24}>
					<Table
						className={'pendingProducts-data'}
						rowSelection={{
							selectedRowKeys: props.selectedItems,
							onChange: (selectedRowKeys: any) => {
								props.setSelectItems([...selectedRowKeys])
							},
						}}
						onRow={(record, rowIndex: number | undefined) => {
							return {
								onClick: () => onClickRow(record, rowIndex),
							}
						}}
						loading={props.loading}
						locale={localeCustom}
						columns={columns}
						dataSource={productList.map((item, i) => ({ ...item, key: `${item.id}` }))}
						pagination={false}
					/>
				</Col>
				{productList.map((item, i) => ({ ...item, key: `${item.id}` })).length > 0 && (
					<Col xs={24}>
						<Row justify='end'>
							<M24Pagination
								filter={{
									size: parseInt(props.searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`),
									page: parseInt(props.searchParams.get('page') || '1'),
								}}
								pagination={{
									total: props.total,
									current: parseInt(props.searchParams.get('page') || '1'),
									pageSize: parseInt(props.searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`),
									hideOnSinglePage: true,
								}}
								onPageChange={onChangePage}
							/>
						</Row>
					</Col>
				)}
			</Row>
		</Card>
	)
}

export default SellingProductList
