import React from 'react'
import Skeleton from 'react-loading-skeleton'
interface ItemProps {
	label: any
	content: any
	className?: string
	loading?: boolean
	widthLabel?: number | string
	widthContent?: number | string
	skeletonLabelClass?: string
	skeletonContentClass?: string
	containerClassContent?: string
}
const Item: React.FC<ItemProps> = (props) => {
	const { label, content, className, loading, widthLabel, widthContent, skeletonLabelClass , skeletonContentClass, containerClassContent} = props
	return !loading ? (
		<div className={`robotoregular width100pc dpl-flex align-items-baseline ${className ? className : ''}`}>
			<span className={`line-h-22 h-22 txt-nowrap txt-color-gray4 mg-r-3`}>{label}</span>
			<span className='dpl-flex txt-size-h7 txt-color-black align-items-baseline info-item width100pc'>{content}</span>
		</div>
	) : (
		<div className={`flex align-items-center ${className ? className : ''}`}>
			<Skeleton height={22} width={widthLabel? widthLabel : ''} className={`txt-nowrap txt-color-gray4 mg-r-3 ${skeletonLabelClass ? skeletonLabelClass : ''}`} />
			<Skeleton height={22} width={widthContent ? widthContent : ''} className={`${skeletonContentClass ? skeletonContentClass : ''}`} containerClassName={containerClassContent ? containerClassContent : ''}/>
		</div>
	)
}

export default Item

