import React, {useEffect, useRef, useState} from "react";
import {Avatar, Button, Checkbox, Col, Divider, Input, InputNumber, Row, Spin} from "antd";
import lodash, { set } from "lodash";
import {formatMoneyByUnit} from "../../../core/helpers/string";
import {useTranslation} from "react-i18next";
import ConfirmModal from "../../Modal/ConfirmModal";
import InlineEdit from "../../InlineEdit";

type Props = {
    product: any;
    salePrice: any;
    currency: string;
    isDeleting: boolean;
    handleDeleteSku: (productId: any, skuId: any, callback: () => void) => void;
    handleUpdateQuantitySku: (quantify: number, productId: any, skuId: any, callback: () => void) => void;
    handleUpdateEmployeeNote: (skuId: any, value: string, callback: () => void) => void;
    handleUpdatePersonalNote: (skuId: any, value: string, callback: () => void) => void;
    selectSku:(isSelect: boolean)=>void;
    isSelected: boolean
    handleShowProgressBar: (val: boolean) => void
}

function ProductItem(props: Props) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const {product, salePrice, currency, handleDeleteSku, isDeleting, handleUpdateQuantitySku, handleUpdateEmployeeNote, handleUpdatePersonalNote, selectSku,isSelected, handleShowProgressBar } = props;
    const [quantity, setQuantity] = useState<number>(product.quantity);
    const [price, setPrice] = useState<number>(product.salePrice);
    const [errorQuantity, setErrorQuantity] = useState<string>();
    //@ts-ignore
    const refInputQuantity = useRef<Input>(null);
    const handleSubmit = () => {
        handleDeleteSku(product.productId, product.id, () => {
            setShowConfirm(false);
        });

    }
    useEffect(() => {
        if (product) {
            setQuantity(product.quantity);
            if(!salePrice)
                setPrice(product.salePrice);
            // if (errorQuantity)
                // setErrorQuantity('');
        }
    }, [product]);

    useEffect(() => {
        checkQuantityError(quantity);
    }, [quantity]);

    useEffect(() => {
        if (salePrice) {
            setPrice(salePrice);
        }

    }, [salePrice]);

    const handleCancel = () => {
        setShowConfirm(false);
    }
    const onUpdateQuantifyByBatchSize = (plus: boolean) => {
        let quantity = lodash.cloneDeep(product.quantity);
        if (plus) quantity = quantity + product.batchSize;
        else{
            quantity = quantity - product.batchSize;
            if(quantity<1) quantity=1;
        }
        setLoading(true);
        handleUpdateQuantitySku(quantity, product.productId, product.id, () => {
            setLoading(false);
            handleShowProgressBar(false)
        });
    }
    const onChangeQuantity = (raw: any) => {
        let value = raw.replace(/[^\d]/g, "");
        setQuantity(value);
        checkQuantityError(value);
        handleShowProgressBar(false)
    }

    const checkQuantityError = (value:any) => {
        let temp = parseInt(value);
        if (!value || (product.minQuantity && value < product.minQuantity)) setErrorQuantity(`${t('message.errorMinQuantity', {value: product.minQuantity})}`);
        else if (product.stock && temp > product.stock) setErrorQuantity(`${t('message.errorMaxQuantity', {value: product.stock})}`);
        else if (product.batchSize && temp % product.batchSize !== 0) setErrorQuantity(`${t('message.errorBatchSize', {value: product.batchSize})}`);
        else if (product.limitQuantity && temp > product.limitQuantity) setErrorQuantity(`${t('message.limitQuantity')}`);
        else setErrorQuantity('');
    }

    const onChangeQuantityBlur = (e: any) => {
        let raw = e.target.value;
        raw = raw.replace(/[^\d]/g, "");
        let value = parseInt(raw);
        if (!value) {
            value = 1;
            setQuantity(value);
            setErrorQuantity('');
        }
        // if (errorQuantity) return;
        else {
            setLoading(true);
            handleUpdateQuantitySku(value, product.productId, product.id, () => {
                setLoading(false);
            });
        }

    }
    const updateEmployeeNote=(value: string, callback: ()=>void)=>{
        // setLoading(true);
        handleUpdateEmployeeNote(product.id, value,()=>{
            // setLoading(false);
            handleShowProgressBar(false)
            callback();
        });
    }
    const updatePersonalNote=(value: string, callback: ()=>void)=>{
        // setLoading(true);
        handleUpdatePersonalNote(product.id, value,()=>{
            // setLoading(false);
            handleShowProgressBar(false)
            callback();
        });
    }
    return (
        <div className={'width100'}  onClick={() => {
            selectSku(!isSelected)
        }}>
            <Spin spinning={loading}>
                <Row>
                    <Col span={12}>
                        <Row>
                            <Col span={1} className='mg-r-8'>
                                <Checkbox checked={isSelected} onChange={e=> selectSku(e.target.checked)}/>
                            </Col>
                            <Col span={22}>
                                <Row>
                                    <Col>
                                    <a className={'txt-color-black txt-size-h7 robotomedium '}
                                                target="_blank"
                                                href={lodash.get(product, 'url')} rel="noreferrer">
                                        <Avatar shape={'square'} className='bd-none-impt rad-4'
                                                size={40}
                                                src={lodash.get(product, 'image', '')}/></a>
                                    </Col>
                                    <Col className={'mgl5'} span={21}>
                                        <Row><a className={'txt-color-black txt-size-h7 robotomedium '}
                                                target="_blank"
                                                href={lodash.get(product, 'url')} rel="noreferrer">{product.name}</a></Row>
                                        <Row>{
                                            product.variantProperties.map((x: any, index: number) => {
                                                return <span className={'txt-size-h8 txt-color-secondary'}
                                                             key={x.id}>{`${x.name}: ${x.value}`}
                                                    {index < product.variantProperties.length - 1 &&
                                                    <i style={{fontSize: 5}}
                                                       className="fas fa-circle mgl5 mgr5"></i>}</span>
                                            })
                                        }</Row>

                                    </Col>
                                </Row>
                                <Col className={'mgt8'}>
                                    <Row className={'dpl-flex align-items-center'} align={'middle'}>
                                        <span className={'txt-color-secondary txt-size-h8 mgr5'}>{t('shopping-cart.personalNote')}:</span>
                                        <InlineEdit onSubmit={updatePersonalNote}
                                                    title={t('shopping-cart.personalNote')}
                                                    type={'textarea-modal'}
                                                    defaultValue={lodash.get(product,'personalNote','')}/>
                                    </Row>
                                    <Row className={'dpl-flex align-items-center'}>
                                        <span className={'txt-color-secondary txt-size-h8 mgr5'}>{t('shopping-cart.employeeNote')}:</span>
                                        <InlineEdit onSubmit={updateEmployeeNote}
                                                    title={t('shopping-cart.employeeNote')}
                                                    type={'textarea-modal'}
                                                    defaultValue={lodash.get(product,'employeeNote','')}/>
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={5} className={'align-items-center'}>
                        <Row className={'dpl-flex justify-content-center align-items-center'}>
                            <Button onClick={() => onUpdateQuantifyByBatchSize(false)}
                                    disabled={product.quantity ===1} ghost
                                    icon={<span className={'txt-color-gray2'}><i
                                        className="fas fa-minus"></i></span>} className='bd-none'></Button>
                            <Col span={8}>
                                <Input
                                    ref={refInputQuantity}
                                    style={{textAlign: 'center'}}
                                    className={'txt-color-gray2'}
                                    value={quantity}
                                    onChange={(e) => onChangeQuantity(e.target.value)}
                                    onBlur={(e) => onChangeQuantityBlur(e)}
                                    onKeyDown={e => {
                                        //detect ENTER
                                        if (e.keyCode === 13 && refInputQuantity && refInputQuantity.current) {
                                            refInputQuantity.current.blur();
                                        }
                                    }}
                                />

                            </Col>
                            <Button onClick={() => onUpdateQuantifyByBatchSize(true)}
                                    disabled={(product.quantify +product.batchSize)  > product.stock} ghost
                                    icon={<span className={'txt-color-gray2'}><i
                                        className="fas fa-plus"></i></span>}></Button>
                        </Row>
                        <Row className={'dpl-flex justify-content-center'}>
                            {errorQuantity &&
                            <span className={'txt-color-orange txt-center mgt5'}>{errorQuantity}</span>}
                        </Row>
                    </Col>
                    <Col span={2}>
                        <Row className={'dpl-flex align-items-center justify-content-end'}>
                                            <span
                                                className={'robotoregular txt-size-h7  mgt5 robotomedium'}>{formatMoneyByUnit(price, currency)}</span>
                        </Row>
                    </Col>
                    <Col span={2}>
                        <Row className={'dpl-flex align-items-center justify-content-end'}>
                                             <span
                                                 className={'robotoregular txt-size-h7  txt-color-orange mgt5 robotomedium'}>
                                                 {price != null && quantity ? formatMoneyByUnit(quantity * price, currency) : '---'}
                                             </span>

                        </Row>
                    </Col>
                    <Col span={3}>
                        <Row justify={'end'}>
                            <Button
                                style={{paddingRight:0}}
                                type={'text'} onClick={() => {
                                setShowConfirm(true);
                            }}><i className="fad fa-trash"/></Button>
                        </Row>
                    </Col>
                </Row>
                <Row>

                </Row>
            </Spin>
            {showConfirm && <ConfirmModal
                title={`${t('cart.deleteItem')}`}
                visible={showConfirm}
                loading={isDeleting}
                content={t('shopping-cart.confirmDeleteProduct')}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                classButton='btn-df btn-df-warning-bg-white'
                iconBtn={<i className="mg-r-10 fa-solid fa-trash" />}
            />}
        </div>
    );
}

export default ProductItem;
