export enum PackageStatusEnum {
  PENDING = 'PENDING',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  WAITING = 'WAITING', // treo
}

export enum BillTypeEnum {
  PERSONAL = 'PERSONAL',
  COMPANY = 'COMPANY',
}

export interface InvoiceData {
  code: string;
  invoiceId?: string;
  owner?: string;
}

export enum RequestBillFormNames {
  billType = 'type',
  taxCode = 'taxCode',
  companyName = 'companyName',
  companyAddress = 'companyAddress',
  companyEmail = 'companyEmail',

  fullName = 'fullName',
  address = 'address',
  email = 'email',
}

export enum RequestBillStatus {
  WAITING = 'PENDING',
  SUCCESS = 'SIGNED',
  FAILED = 'FAILED',
}

export interface CreatePaymentInvoiceBody {
  order?: string;
  name: string;
  address: string;
  email: string;
  tax: string;
  owner?: string;
  type: BillTypeEnum;
}

export interface PaymentInvoiceInterface {
  id: string
  tenant: string
  order: string
  name: string
  address: string
  email: string
  tax: string
  owner: string
  refPayment: string
  status: string
  type: string
  invoice: string
  createdAt: string,
  createdBy: string
  modifiedAt: string,
  modifiedBy: string
  version: number
}

export interface PackageOrderInterface {
  id: string;
  tenant: string;
  code: string;
  customer: string;
  vendor: string;
  packageCode: string;
  originalPrice: number;
  currency: string;
  status: string | PackageStatusEnum;
  totalValue: number;
  packageName: string;
  paymentMethod: string;
  billOrder: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  version: number;
  paymentInvoice?: PaymentInvoiceInterface;
}
