import { Avatar, Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import UboxImg from "../../resources/images/gobiz-logo-extension.png"

interface ExtensionCommonProps {
	handleClose: () => void
}

const ExtensionCommon: React.FC<ExtensionCommonProps> = ({handleClose}) => {
	const { t } = useTranslation()
	return  <div className='flex flex-col pd-16 box-shadow-desc'>
			<div className='flex align-items-center mg-bt-8'>
				<Avatar src={UboxImg} shape='square' size={24} className='rad-8 bd-none-impt mg-r-6' />
				<span className='robotomedium txt-color-black fsz-16 line-h-24'>{t('description.extension')}</span>
			</div>
			<span className='robotoregular fsz-14 line-h-20 txt-color-black mg-bt-12' dangerouslySetInnerHTML={{__html: t('description.extensionCommon')}}></span>
			<div className='flex align-items-center justify-content-around'>
				<a
					href='https://gobiz.vn/gobiz-shopping-assistant/'
					target={'_blank'}
					className='item-link robotoregular fsz-12 line-h-20 cursor-pointer' rel="noreferrer">
					{t('description.research')}
				</a>
				<a
					href='https://chrome.google.com/webstore/detail/gobiz-c%C3%B4ng-c%E1%BB%A5-h%E1%BB%97-tr%E1%BB%A3-ng%C6%B0%E1%BB%9D/hbghadnblijiihmmgmchjlbejcgncjef'
					target='blank'>
					<Button className='btn-df btn-df-secondary-bg-lemon' icon={<i className='fa-regular fa-download mg-r-10'></i>}>
						{t('description.installNow')}
					</Button>
				</a>
			</div>
			<i className="fa-solid fa-xmark cursor-pointer" onClick={handleClose}></i>
		</div>
}

export default ExtensionCommon

