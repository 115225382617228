import { Avatar, Button, Card, Col, Divider, Empty, Image, notification, Row, Select } from 'antd'
import lodash, { isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormValues } from '../../../containers/Shipment/Create'
import ShipmentFeesAndForm from '../../../containers/Shipment/Create/ShipmentFeesAndForm'
import { EMPTY_INFO, TENANT_KEY } from '../../../core/config'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import AccountBalanceInterface from '../../../Interface/AccountBalanceInterface'
import DraftShipmentInterface from '../../../Interface/DraftShipmentInterface'
import PartnerAccountInterface from '../../../Interface/PartnerAccountInterface'
import PreviewShipment1688Interface from '../../../Interface/PreviewShipment1688Interface'
import PreviewShipmentAndPeerPaymentInterface, { InfoShipmentAndPeerPayment, PurchasingAccount } from '../../../Interface/PreviewShipmentAndPeerPaymentInterface'
import ServiceGroupedInterface from '../../../Interface/ServiceGroupedInterface'
import ServiceInterface from '../../../Interface/ServiceInterface'
import { cartRepository } from '../../../repositories/CartRepository'
import { shipmentRepository } from '../../../repositories/ShipmentRepository'
import EmptyImage from '../../../resources/images/empty-2.png'
import { localStorageRead } from '../../../utils/LocalStorageUtils'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import M24Notification from '../../../utils/M24Notification'
import InlineEdit from '../../InlineEdit'
import ConfirmModal from '../../Modal/ConfirmModal'
import Address from '../Address/AddressList'
import CreateAddressModal from '../Address/CreateAddressModal'
import Services from '../Services'
import '../Step2/styles.scss'

export interface Step2Props {
	merchant: any
	setMerchant: (merchant: any) => void
	setStep: (value: number) => void
	handleCreateShipmentAndOrder: (body: PreviewShipmentAndPeerPaymentInterface | {}, info: InfoShipmentAndPeerPayment, cb?: () => void) => void
	getPartnersOfShipment: (code: string) => void
	handlePreview1688: (body: PreviewShipmentAndPeerPaymentInterface | {}, info: InfoShipmentAndPeerPayment) => void
	preview1688: any
	partnerPeerPayment: PartnerAccountInterface[]
	handleLoadingPreview: (val: boolean) => void
	loadingPreview: boolean
}

function ShipmentAndOrder(props: Step2Props) {
	const {
		preview1688,
		handlePreview1688,
		merchant,
		handleCreateShipmentAndOrder,
		partnerPeerPayment,
		handleLoadingPreview,
		loadingPreview,
	} = props
	const { t } = useTranslation()

	const [accountDefault, setAccountDefault] = useState<PartnerAccountInterface>()
	const [isFirst, setIsFirst] = useState<boolean>(true)
	const [exchangeRate, setExchangeRate] = useState<any>()
	const [totalPrice, setTotalPrice] = useState<number>()
	const [services, setServices] = useState<ServiceInterface[]>([])
	const [currentServiceSelected, setCurrentServiceSelected] = useState<ServiceInterface[]>([])
	const [serviceSelectedCache, setServiceSelectedCache] = useState<any>({})
	const [serviceGroups, setServiceGroups] = useState<ServiceGroupedInterface[]>([])
	const [favoriteServices, setFavoriteServices] = useState<any>([])
	const [favoriteUpdating, setFavoriteUpdating] = useState(false)
	const [confirmFavorite, setConfirmFavorite] = useState(false)
	const [noticeFavoriteService, setNoticeFavoriteService] = useState<any>()
	const [currency, setCurrency] = useState<string>()
	const [employeeNote, setEmployeeNote] = useState<string>('')
	const [personalNote, setPersonalNote] = useState<string>('')
	const [noticeWarehouse, setNoticeWarehouse] = useState<any>()
	const [openAddressList, setOpenAddressList] = useState<boolean>(false)
	const [openModalAdd, setOpenModalAdd] = useState<boolean>(false)
	const [addressList, setAddressList] = useState<any>([])
	const [selectedAddress, setSelectedAddress] = useState<any>()
	const [deliveryAddress, setDeliveryAddress] = useState<any>(null)
	const [addressSupported, setAddressSupported] = useState<boolean>(false)
	const [currentConfigShowBalance] = useState(lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', ''))
	const [isAllow, setIsAllow] = useState(true)
	const [totalSKU, setTotalSKU] = useState<number>(0)
	const [totalLink, setTotalLink] = useState<number>(0)
	const [isLoading, setIsLoading] = useState(false)

	const [accountUseForBuys, setAccountUseForBuys] = useState<PurchasingAccount[]>([])
	const [accountLinkShipments, setAccountLinkShipments] = useState<AccountBalanceInterface[]>([])
	const [curAccountNeedBuy, setCurAccountNeedBuy] = useState<PurchasingAccount>()
	const [draftShipment, setDraftShipment] = useState<DraftShipmentInterface>()
	const [currentServiceHasError, setCurrentServiceHasError] = useState<any>()
	const [accountLinkShipment, setAccountLinkShipment] = useState<AccountBalanceInterface>()
	const [foreignCash, setForeignCash] = useState(0)
	const [cargoList, setCargoList] = useState([])

	useEffect(() => {
		if (preview1688) {
			const orderPreviewResuslt = preview1688?.orderPreviewResuslt
			if (orderPreviewResuslt) {
				setForeignCash(orderPreviewResuslt[0]?.sumPayment / 100)
				setCargoList(orderPreviewResuslt[0]?.cargoList)
			}
		} else {
			setForeignCash(0)
		}
	}, [preview1688])

	/*********************************************************** SERVICES ***********************************************************/

	const getFavoriteServices = () => {
		cartRepository
			.getFavoriteServices()
			.then((res) => {
				setFavoriteServices(res)
			})
			.catch((err) => {})
	}

	const updateFavoriteService = () => {
		setFavoriteUpdating(true)
		let items: string[] = []
		currentServiceSelected.map((x: any) => items.push(x.code))
		let body: any = {
			services: items,
			provider: lodash.get(accountDefault, 'connection.provider.code', ''),
			providerUsername: lodash.get(accountDefault, 'connection.name', ''),
		}
		cartRepository
			.updateFavoriteServices(body)
			.then((res) => {
				M24Notification.messageSuccess(t('message.saveFavoriteServiceSuccess'))
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
				getFavoriteServices()
			})
			.finally(() => {
				setFavoriteUpdating(false)
				setConfirmFavorite(false)
			})
	}

	const getServices = useCallback(() => {
		shipmentRepository
			.getShipmentServices(
				lodash.get(accountDefault, 'connection.name', ''),
				lodash.get(accountDefault, 'connection.provider.code', '')
			)
			.then((res) => {
				let otherServices = lodash.sortBy(
					res.filter((x: any) => !x.serviceGroup),
					'position'
				)
				let services = lodash.sortBy(
					res.filter((x: any) => x.serviceGroup),
					'position'
				)
				let items = lodash
					.chain(services)
					.groupBy('serviceGroup.code')
					.map((value, key) => {
						let group = value[0].serviceGroup
						return { ...group, services: value }
					})
					.value()
				let temp: any = []
				temp.push({
					code: 'other',
					name: t('shopping-cart.otherService'),
					services: otherServices,
				})
				temp = temp.concat(items)
				setServices(res)
				setServiceGroups(temp)
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
	}, [accountDefault, t])

	useEffect(() => {
		if (accountDefault) {
			let tempService = lodash.get(serviceSelectedCache, accountDefault.connection.id, [])
			if (!tempService || tempService.length === 0) {
				tempService = services.filter((xx: any) => xx.defaultApplied)
				let temp = lodash.cloneDeep(serviceSelectedCache)
				let favorite = favoriteServices.find(
					(x: any) =>
						x.provider === accountDefault.connection.provider.code &&
						x.customer === accountDefault.connection.name
				)
				if (favorite) {
					let isConflict = false
					let items: any = []
					favorite.services.map((x: string) => {
						let temp = services.find((xx: any) => xx.code === x)
						if (!temp) isConflict = true
						else items.push(temp)
					})
					if (!isConflict) {
						setCurrentServiceSelected(items)
						temp[accountDefault.connection.id] = items
						setServiceSelectedCache(temp)
					} else {
						setNoticeFavoriteService(true)
						temp[accountDefault.connection.id] = tempService
						setServiceSelectedCache(temp)
						setCurrentServiceSelected(tempService)
					}
				} else {
					temp[accountDefault.connection.id] = tempService
					setServiceSelectedCache(temp)
					setCurrentServiceSelected(tempService)
				}
			} else {
				setCurrentServiceSelected(tempService)
			}
		}
	}, [services])

	const changeService = (items: any) => {
		if (accountDefault && accountDefault.connection) {
			setCurrentServiceSelected(items)
			let temp = lodash.cloneDeep(serviceSelectedCache)
			temp[accountDefault.connection.id] = items
			setServiceSelectedCache(temp)
		}
	}

	useEffect(() => {
		getAddressList()
		getFavoriteServices()
	}, [])

	/*********************************************************** ACCOUNTS, EXCHANGE RATE, CURRENCY, ADDRESS ***********************************************************/

	const validAddress = useCallback(() => {
		setAddressSupported(false)
		if (!accountDefault || !deliveryAddress) return
		cartRepository
			.checkAddressSupport({
				location: deliveryAddress.location,
				provider: lodash.get(accountDefault, 'connection.provider.code', ''),
			})
			.then((response) => {
				setAddressSupported(true)
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err, {
					value: lodash.get(accountDefault, 'connection.provider.name', ''),
				})
			})
	}, [accountDefault, deliveryAddress, t])

	const getExchangeRate = useCallback(
		(account: any, curAccountNeedBuy: any) => {
			if (currency) {
				cartRepository
					.getExchangeRate(
						account?.connection.provider.code,
						account?.connection?.name,
						curAccountNeedBuy?.account
					)
					.then((res) => {
						setExchangeRate(res)
					})
					.catch((err) => {
						M24ErrorUtils.showError(t, err)
					})
			}
		},
		[currency, t]
	)

	const getAddressList = (isReset?: boolean) => {
		setIsLoading(true)
		cartRepository
			.getAddressList()
			.then((response) => {
				setAddressList(response)
				if (!deliveryAddress || isReset) {
					let temp = response.find((x: any) => x.isDefault)
					setDeliveryAddress(temp)
				} else {
					let temp = response.find((x: any) => x.id === deliveryAddress.id)
					setDeliveryAddress(temp)
				}
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false)
			})
	}

	useEffect(() => {
		setCurrency(lodash.get(merchant, 'products[0].skus[0].currency', ''))
		if (partnerPeerPayment && partnerPeerPayment.length > 0) {
			let accDefault = partnerPeerPayment.find((x: PartnerAccountInterface) => x.connection.defaultConnection)
			if (accDefault) {
				setAccountDefault(accDefault)
			} else {
				setAccountDefault(partnerPeerPayment[0])
			}
		}
	}, [merchant, partnerPeerPayment])

	const getAccountsUseBuy = useCallback(async () => {
		if (accountDefault?.connection?.provider.code && accountDefault?.connection?.name) {
			try {
				const res = await cartRepository.getAccountsForBuy(
					accountDefault.connection.provider.code,
					accountDefault?.connection?.name
				)
				setAccountUseForBuys(res)
				setCurAccountNeedBuy(res[1])
			} catch (error) {}
		}
	}, [accountDefault?.connection?.provider.code, accountDefault?.connection?.name])

	const getPartnerShipment = useCallback(async () => {
		try {
			const res = await cartRepository.getPartnerOfShipmentOrOrder(
				'SHIPMENT',
				accountDefault?.connection.provider.code
			)
			setAccountLinkShipments(res)
			const accLinkShipmentIsDefault = res.find((acc: any) => acc.connection.defaultConnection)
			if (accLinkShipmentIsDefault) {
				setAccountLinkShipment(accLinkShipmentIsDefault)
			} else {
				setAccountLinkShipment(res[0])
			}
		} catch (error) {
			M24ErrorUtils.showError(t, error)
		}
	}, [accountDefault, t])

	useEffect(() => {
		if (accountDefault) {
			getAccountsUseBuy()
			getPartnerShipment()
		}
	}, [accountDefault, getAccountsUseBuy, getPartnerShipment])

	useEffect(() => {
		if (accountDefault && curAccountNeedBuy) {
			getExchangeRate(accountDefault, curAccountNeedBuy)
			getServices()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountDefault, curAccountNeedBuy])

	useEffect(() => {
		validAddress()
	}, [validAddress])

	/*********************************************************** PRODUCTS *************************************************************/

	useEffect(() => {
		if (exchangeRate) {
			let totalPrice: number = 0
			let totalSku = 0
			merchant.products.map((product: any) => {
				let total: number = 0
				let price: any = 0
				product.skus.map((xx: any) => {
					total = total + xx.quantity
					totalSku = totalSku + xx.quantity
				})
				if (product.pricePolicies && product.pricePolicies.length > 0) {
					let pricePolicies = lodash.sortBy(product.pricePolicies, ['minQuantity'])
					if (total < pricePolicies[0].minQuantity) {
						price = pricePolicies[0].salePrice
					} else if (total > pricePolicies[pricePolicies.length - 1].maxQuantity) {
						price = pricePolicies[pricePolicies.length - 1].salePrice
					} else {
						pricePolicies.map((x: any) => {
							if (total >= x.minQuantity && total <= x.maxQuantity) {
								price = x.salePrice
							}
						})
					}
					totalPrice += total * price
				} else {
					product.skus.map((xx: any) => {
						totalPrice += xx.salePrice * xx.quantity
					})
				}
				setTotalPrice(totalPrice)
			})
		}
	}, [exchangeRate])

	useEffect(() => {
		if (merchant && merchant.products && merchant.products.length > 0) {
			let totalSKU = 0
			let totalLink = 0
			let totalPrice: number = 0
			merchant.products.map((product: any) => {
				let total: number = 0
				let price: any = 0
				totalLink = totalLink + product.skus.length
				product.skus.map((sku: any) => {
					totalSKU = totalSKU + sku.quantity
					total = total + sku.quantity
				})
				if (product.pricePolicies && product.pricePolicies.length > 0) {
					let pricePolicies = lodash.sortBy(product.pricePolicies, ['minQuantity'])
					if (total < pricePolicies[0].minQuantity) {
						price = pricePolicies[0].salePrice
					} else if (total > pricePolicies[pricePolicies.length - 1].maxQuantity) {
						price = pricePolicies[pricePolicies.length - 1].salePrice
					} else {
						pricePolicies.map((x: any) => {
							if (total >= x.minQuantity && total <= x.maxQuantity) {
								price = x.salePrice
							}
						})
					}
					totalPrice += total * price
				} else {
					product.skus.map((xx: any) => {
						totalPrice += xx.salePrice * xx.quantity
					})
				}
			})
			setTotalPrice(totalPrice)
			setTotalSKU(totalSKU)
			setTotalLink(totalLink)
		} else {
			setTotalLink(0)
			setTotalSKU(0)
			setTotalPrice(0)
		}
	}, [merchant])

	/*********************************************************** ACTIONS, HANDLER *************************************************************/

	const handleChange = (code: string) => {
		let temp = partnerPeerPayment.find((x: any) => x.connection.id === code)
		if (temp) {
			setAccountDefault(temp)
		}
	}
	const formatAmount = (amount: any, unit?: string) => {
		if (!amount && amount !== 0) return ''
		let color = amount < 0 ? 'txt-color-red' : 'txt-color-green'
		return <span className={color}>{`${formatMoneyByUnit(amount, unit)}`}</span>
	}


	const updateEmployeeNote = (value: string, callback: () => void) => {
		setEmployeeNote(value)
		callback()
	}
	const updatePersonalNote = (value: string, callback: () => void) => {
		setPersonalNote(value)
		callback()
	}

	const handleCloseAddressList = () => {
		setOpenAddressList(false)
	}
	const handleCloseAddressCreate = () => {
		setOpenModalAdd(false)
		setSelectedAddress(null)
		if (addressList && addressList.length > 0) setOpenAddressList(true)
		else setOpenAddressList(false)
	}
	const openAddressCreate = () => {
		setOpenAddressList(false)
		setOpenModalAdd(true)
	}
	const showModalEditAddress = (item: any) => {
		setOpenAddressList(false)
		setSelectedAddress(item)
		setOpenModalAdd(true)
	}

	const onSubmitCreateModal = (body: any) => {
		setIsLoading(true)
		if (selectedAddress) {
			cartRepository
				.updateAddress(selectedAddress.id, body)
				.then((response) => {
					M24Notification.messageSuccess(t('message.success'))
					getAddressList()
					handleCloseAddressCreate()
					setSelectedAddress(null)
					setOpenAddressList(true)
				})
				.catch((error) => {
					M24ErrorUtils.showError(t, error)
				})
				.finally(() => {
					setIsLoading(false)
				})
		} else {
			cartRepository
				.createAddress(body)
				.then((response) => {
					M24Notification.messageSuccess(t('message.success'))
					if (addressList.length > 0) {
						setOpenAddressList(true)
					}
					getAddressList()
					handleCloseAddressCreate()
				})
				.catch((error) => {
					M24ErrorUtils.showError(t, error)
				})
				.finally(() => {
					setIsLoading(false)
				})
		}
	}

	const makeDeliveryAddress = (address: any) => {
		setDeliveryAddress(address)
	}

	useEffect(() => {
		let isAllow
		if (
			!addressSupported ||
			(currentServiceSelected && currentServiceSelected.length === 0) ||
			addressList.length === 0 ||
			!deliveryAddress
		)
			isAllow = false
		else {
			let groups = serviceGroups.filter((x: ServiceGroupedInterface) => x.required)
			if (groups && groups.length > 0) {
				groups.forEach((group: ServiceGroupedInterface) => {
					let temp = currentServiceSelected.find(
						(x: ServiceInterface) => x.serviceGroup && x.serviceGroup.code === group.code
					)
					if (!temp) {
						isAllow = false
					} else {
						const requireGroups = temp.requireGroups
						if (requireGroups && requireGroups.length > 0) {
							requireGroups.forEach((requireGroup: string[]) => {
								const requireServiceSelect = currentServiceSelected.find(
									(curServiceSelected: ServiceInterface) =>
										curServiceSelected.serviceGroup?.code === requireGroup
								)
								isAllow = !!requireServiceSelect
							})
						} else {
							isAllow = true
						}
					}
				})
			} else {
				isAllow = true
			}
		}

		setIsAllow(!!isAllow)
	}, [addressList.length, addressSupported, currentServiceSelected, deliveryAddress, serviceGroups])

	const handleChangeAccountForBuy = (value: string) => {
		const curAcc = accountUseForBuys.find((acc) => acc.id === value)
		setCurAccountNeedBuy(curAcc)
	}

	const createDraftShipments = useCallback(async () => {
		setNoticeWarehouse('')
		const provider = lodash.get(accountDefault, 'connection.provider.code', '')
		const providerUsername = lodash.get(accountDefault, 'connection.name', '')
		const services = lodash.map(currentServiceSelected, 'code')
		const addressId = lodash.get(deliveryAddress, 'id', '')
		setIsLoading(true)
		if (provider && providerUsername && services.length > 0 && addressId) {
			try {
				const res = await shipmentRepository.createDraftShipment({
					provider,
					providerUsername,
					services,
					addressId,
				})

				const notPreferredWareHouse = res?.notices.find(
					(notice: any) => notice.title === 'not_preferred_warehouse'
				)
				setNoticeWarehouse(notPreferredWareHouse ? notPreferredWareHouse.reference.name : '')
				setDraftShipment(res)
				setIsLoading(false)
			} catch (err) {
				setIsLoading(false)
				if (lodash.get(err, 'response.data.title') === 'shipment_config_disabled') {
					notification.error({ message: t(`message.${lodash.get(err, 'response.data.title')}`), duration: 6 })
				}
			}
		}
	}, [accountDefault, currentServiceSelected, deliveryAddress, t])

	const handleCurServiceHasError = (obj: any) => {
		setCurrentServiceHasError(obj)
	}

	const preview1688Handler = useCallback(
		async (only1688: boolean, formValues?: FormValues) => {
			let refCustomerCode, refShipmentCode, expectedPackages
			if (formValues) {
				refCustomerCode = formValues.refCustomerCode
				refShipmentCode = formValues.refShipmentCode
				expectedPackages = formValues.expectedPackages
			}

			if (accountDefault && deliveryAddress && curAccountNeedBuy) {
				let body: PreviewShipmentAndPeerPaymentInterface | {} = {}
				let curSku: any = []
				const provider = accountLinkShipment?.connection.provider.code
				const providerUsername = accountLinkShipment?.connection.name
				const addressId = deliveryAddress.id
				let image = ''
				merchant.products.forEach((product: any) => {
					if (product.skus && product.skus.length > 0) {
						product.skus.map((sku: any) => {
							let item: any = { ...sku }
							item.skuId = sku.id
							item.orderedQuantity = sku.quantity
							item.actualPrice = item.salePrice
							item.productUrl = sku.url
							item.productImage = sku.image
							item.merchantName = merchant.name
							item.merchantContact = ''
							const skuPreviewFrom1688: any = cargoList.find(
								(item: any) =>
									item.offerId.toString() === sku.offerId &&
									item.skuId.toString() === sku.code &&
									item.specId.toString() === sku.specId
							)
							item.unitPrice = skuPreviewFrom1688?.finalUnitPrice ?? 0
							item.salePrice = skuPreviewFrom1688?.finalUnitPrice ?? 0
							curSku.push(item)
							image = sku.image
							return item
						})
					}
				})
				body = {
					...body,
					image,
					products: curSku,
					services: lodash.map(currentServiceSelected, 'code'),
					merchantUrl: '',
					merchantName: merchant?.merchant?.name ?? '',
					refShipmentCode,
					refCustomerCode,
					providerUsername,
					provider,
					addressId,
					expectedPackages,
					purchaseAccount: curAccountNeedBuy?.account ?? '',
					marketplace: '1688',
				}
				const info:InfoShipmentAndPeerPayment = {
					exchangeRate,
					deliveryAddress,
					purchaseAccount: curAccountNeedBuy?.account ?? '',
					accountLinkShipment: accountLinkShipment ?? undefined,
					draftShipment,
					skus: curSku,
					currency,
					refShipmentCode,
					refCustomerCode,
					expectedPackages,
					employeeNote,
					providerUsername,
					provider,
					accountDefault
				}
				if (!only1688) {
					handleLoadingPreview(true)
					handleCreateShipmentAndOrder(body, info, () => handleLoadingPreview(false))
				}
				if (only1688) {
					handlePreview1688(body, info)
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			accountDefault,
			accountLinkShipment,
			curAccountNeedBuy,
			currency,
			currentServiceSelected,
			deliveryAddress,
			draftShipment,
			employeeNote,
			exchangeRate,
			merchant?.name,
			merchant?.products,
			cargoList,
		]
	)

	useEffect(() => {
		if (curAccountNeedBuy && !isEmpty(currentServiceSelected)) {
			preview1688Handler(true)
		}
	}, [curAccountNeedBuy, currentServiceSelected])

	const handleChangeAccountLinkShipment = (value: string) => {
		const curAcc = accountLinkShipments.find((acc) => acc.connection.id === value)
		setAccountLinkShipment(curAcc)
		createDraftShipments()

	}

	useEffect(() => {
		createDraftShipments()
	}, [createDraftShipments])

	return (
		<Card bodyStyle={{ padding: 0 }} className='box-step-service'>
			<div  style={{ background: '#EDF3F7' }} className='flex flex-nowrap'>
				<div style={{flexBasis: '70%'}}  className='box-step-service__left shipment-and-pay mg-r-12'>
					<Row className='bg-color-white pd-16 rad-6 box-shadow mg-bt-12'>
						<div className='flex align-items-center mg-bt-12 justify-content-end width100pc'>
							<Select
								className='mg-r-8'
								placeholder={t('shopping-cart.selectAccount')}
								value={accountDefault?.connection?.id}
								onChange={handleChange}>
								{partnerPeerPayment &&
									partnerPeerPayment.length > 0 &&
									partnerPeerPayment.map((x: any) => {
										let currencyItem = JSON.parse(x.connection.provider.currency)
										return (
											<Select.Option key={x.connection.id} value={x.connection.id}>
												<Row className={'dpl-flex align-items-center'}>
													<Avatar
														size={20}
														src={lodash.get(x, 'connection.provider.logo', '')}
														shape={'circle'}></Avatar>
													<span className={'mgl5 robotoregular txt-size-h7 txt-color-black'}>
														{lodash.get(x, 'connection.domain', '')}
													</span>
													<Divider type={'vertical'} className={'bd-color-black'} />
													<span className={'robotoregular txt-size-h7 txt-color-black'}>
														{lodash.get(x, 'connection.name', '')}
													</span>
													{currentConfigShowBalance && (
														<span className={'mgl10 robotoregular txt-size-h7'}>
															Số dư:{' '}
															<span>
																{formatAmount(
																	lodash.get(x, 'balance', ''),
																	lodash.get(currencyItem, 'code', '')
																)}
															</span>
														</span>
													)}
												</Row>
											</Select.Option>
										)
									})}
							</Select>

							<Select
								style={{ width: 200 }}
								placeholder={t('shopping-cart.chooseAccountForBuy')}
								value={curAccountNeedBuy ? curAccountNeedBuy.id : accountUseForBuys[0]?.id}
								onChange={handleChangeAccountForBuy}>
								{accountUseForBuys.map((acc) => (
									<Select.Option key={acc.id} value={acc.id}>
										{acc.account}
									</Select.Option>
								))}
							</Select>
						</div>
						<div className='info flex flex-col width100pc'>
							<div className='flex align-items-center'>
								<span className='fsz-14 line-h-22 txt-color-gray4 mg-r-135'>
									{`${t('shopping-cart.amountOfForeign')}:`}{' '}
									<span className='txt-color-black robotomedium'>
										{foreignCash ? formatMoneyByUnit(foreignCash, currency) : EMPTY_INFO}
									</span>
								</span>
								<span className='fsz-14 line-h-22 txt-color-gray4 mg-r-135'>
									{`${t('shopping-cart.exchangeRateNew')}: `}
									<span className='txt-color-black robotomedium'>
										{exchangeRate?.rate && exchangeRate?.exchange
											? `${formatMoneyByUnit(1, currency)} = ${formatMoneyByUnit(
													exchangeRate?.rate,
													exchangeRate?.exchange
											  )}`
											: EMPTY_INFO}
									</span>
								</span>
								<span className='fsz-14 line-h-22 txt-color-gray4'>
									{`${t('shopping-cart.orderValues')}: `}{' '}
									<span className='txt-color-black robotomedium'>
										{exchangeRate &&
										(exchangeRate?.rate || exchangeRate?.rate === 0) &&
										exchangeRate?.exchange &&
										foreignCash
											? formatMoneyByUnit(
													foreignCash * exchangeRate?.rate,
													exchangeRate?.exchange
											  )
											: EMPTY_INFO}
									</span>
								</span>
							</div>
						</div>
					</Row>
					<div className='bg-color-white pd-16 rad-6 box-shadow'>
						<Row className={'dpl-flex align-items-center justify-content-between mg-bt-12 '}>
							<Col span={11}>
								<Avatar
									className={'rad-4 bd-none-impt mg-r-5'}
									shape={'square'}
									size={20}
									src={lodash.get(merchant, 'merchant.icon')}></Avatar>
								<span className={'txt-size-h8 txt-color-secondary'}>{t('shopping-cart.seller')}:</span>
								&nbsp;
								<span className={'txt-size-h8 txt-color-black'}>
									{lodash.get(merchant, 'merchant.name', '')}
								</span>
							</Col>
							<Col span={13} className='flex justify-content-end'>
								<Select
									className='mg-r-8'
									placeholder={t('shopping-cart.chooseAccountForBuy')}
									value={accountLinkShipment ? accountLinkShipment.connection.id : undefined}
									onChange={handleChangeAccountLinkShipment}>
									{accountLinkShipments.map((acc) => (
										<Select.Option key={acc?.connection.id} value={acc?.connection.id}>
											<Row className={'dpl-flex align-items-center'}>
												<Avatar
													size={20}
													className='bd-none-impt'
													src={lodash.get(acc, 'connection.provider.logo', '')}
													shape={'circle'}></Avatar>
												<span className={'mgl5 robotoregular txt-size-h7 txt-color-black'}>
													{lodash.get(acc, 'connection.domain', '')}
												</span>
												<Divider type={'vertical'} className={'bd-color-black'} />
												<span className={'robotoregular txt-size-h7 txt-color-black'}>
													{lodash.get(acc, 'connection.name', '')}
												</span>
											</Row>
										</Select.Option>
									))}
								</Select>
							</Col>
						</Row>
						<div className={'label-row mg-bt-12'}>
							<span className={'txt-size-h5 robotomedium txt-color-black line-h-24 whitespace'}>
								{t('shopping-cart.orderService')}
							</span>
							<div className='line-d-h-1' />
						</div>
						<div className="mg-bt-12">
						<Services
							setCheckedList={changeService}
							checkedList={currentServiceSelected}
							services={services}
							serviceGroups={serviceGroups}
							handleCurServiceHasError={handleCurServiceHasError}
							setIsAllow={(val: boolean) => setIsAllow(val)}
						/>
						</div>

						<Row className={'label-row mg-bt-12'}>
							<span className={'txt-size-h5 robotomedium txt-color-black'}>
								{t('shopping-cart.orderAddress')}
							</span>
							<div className='line-d-h-1' />
							{addressList && addressList.length > 0 && (
								<Button
									type={'text'}
									className={'flr txt-size-h7 robotoregular px-0 txt-color-blue6'}
									onClick={() => {
										setOpenAddressList(true)
									}}
									icon={<i className='fad fa-cog mg-r-10' />}>
									{t('shopping-cart.editAddress')}
								</Button>
							)}
						</Row>
						{addressList && addressList.length <= 0 ? (
							<Empty
								description={
									<span>
										{t('address.empty-address-content-1')}
										<a onClick={() => setOpenModalAdd(true)} className='mg-l-2'>
											{t('address.empty-address-content-2')}
										</a>
									</span>
								}
								image={<Image preview={false} className={'icon-empty'} src={EmptyImage} />}
							/>
						) : (
							<Col className={'_delivery-address mg-bt-12 pd-l-0'}>
								<Row style={{ height: 22, marginBottom: '8px' }} align={'middle'}>
									<span>
										<i className='fas fa-user txt-size-h8 txt-color-gray2' />
									</span>
									<span className={'txt-size-h7 robotomedium mgl10'}>
										{lodash.get(deliveryAddress, 'receiver', '---')}
									</span>
								</Row>
								<Row style={{ height: 22, marginBottom: '8px' }} align={'middle'}>
									<span className={'dpl-block'}>
										<i className='fas fa-home-lg-alt txt-size-h8 txt-color-gray2' />
									</span>
									<span className={'txt-size-h7 robotoregular mgl10'}>
										{lodash.get(deliveryAddress, 'address1', '')}{' '}
										{lodash.get(deliveryAddress, 'display', '---')}
									</span>
								</Row>
								<Row style={{ height: 22, marginBottom: '8px' }} align={'middle'}>
									<span className={'dpl-block'}>
										<i className='fas fa-phone txt-size-h8 txt-color-gray2' />
									</span>
									<span className={'txt-size-h7 robotomedium mgl10'}>
										{lodash.get(deliveryAddress, 'phoneNumber', '---')}
									</span>
								</Row>
								{deliveryAddress && deliveryAddress.isDefault ? (
									<Row style={{ height: 22, marginBottom: '8px' }} align={'middle'}>
										<span>
											<i className='fas fa-cog txt-size-h8 txt-color-gray2' />
										</span>
										<span className={'txt-size-h7 robotoregular mgl10'}>
											{t('address.status-isDefault')}
										</span>
									</Row>
								) : null}
								{noticeWarehouse && isFirst && (
									<Row className={'notice-warehouse mg-bt-24'}>
										<Col span={23}>
											<Row>
												<Col>
													<span className={'txt-color-primary'}>
														<i className='fas fa-exclamation-circle'></i>
													</span>
												</Col>
												<Col span={22}>
													<span
														className='mgl12 txt-size-h7 txt-color-black dpl-block whitespace-pre-wrap break-word'
														dangerouslySetInnerHTML={{
															__html: t('cart.not_preferred_warehouse', {
																code: noticeWarehouse,
															}),
														}}></span>
												</Col>
											</Row>
										</Col>
										<Col className={'dpl-flex justify-content-end'} span={1}>
											<span
												onClick={() => {
													setNoticeWarehouse(null)
													setIsFirst(false)
												}}>
												<i className='cursor-pointer fas fa-times'></i>
											</span>
										</Col>
									</Row>
								)}
								{/*{noticeWarehouse&&<Alert className={'mgt12'} message={<span className={'txt-size-h7 txt-color-black'}>{t('cart.not_preferred_warehouse',{code: noticeWarehouse})}</span>} type="warning" showIcon closable />}*/}
							</Col>
						)}
						<div className={'label-row mg-bt-12'}>
							<span className={'txt-size-h5 robotomedium txt-color-black'}>
								{t('shopping-cart.orderNote')}
							</span>

							<div className='line-d-h-1' />
						</div>
						<Row className={'dpl-flex align-items-center mg-bt-4'}>
							<span className={'txt-color-secondary robotoregular fsz-14 line-h-22 mgr5'}>
								{t('shopping-cart.personalNote')}:
							</span>
							<InlineEdit
								onSubmit={updatePersonalNote}
								title={t('shopping-cart.personalNote')}
								type={'textarea-modal'}
								defaultValue={personalNote}
								className={'_note-custom'}></InlineEdit>
						</Row>
						<Row className={'dpl-flex align-items-center'}>
							<span className={'txt-color-secondary txt-capitalize robotoregular fsz-14 line-h-22 mgr5'}>
								{/* {t('shopping-cart.employeeNote')}: */}
								{t('shipment-pay.noteForOrder')}:
							</span>
							<InlineEdit
								onSubmit={updateEmployeeNote}
								title={t('shopping-cart.employeeNote')}
								type={'textarea-modal'}
								defaultValue={employeeNote}></InlineEdit>
						</Row>
					</div>
				</div>
				<div style={{flexBasis: '30%'}} className={'freeContainer h-fit-content rad-6'}>
					<ShipmentFeesAndForm
						calculating={isLoading}
						draftShipment={draftShipment}
						submitHandler={preview1688Handler}
						loading={loadingPreview}
						allowCreate={isAllow}
						currency={currency}
						currentServiceHasError={currentServiceHasError}
						checkedList={currentServiceSelected}
						services={services}
						currentStep={1}
						preview1688={preview1688}
						isShipmentAndPeerPayment
						feePeerPayment={null}
						purchasingAccount={curAccountNeedBuy}
					/>
				</div>
			</div>
			{openAddressList && (
				<Address
					isVisible={openAddressList}
					onCancel={handleCloseAddressList}
					openAddressCreate={openAddressCreate}
					addressList={addressList}
					updateAddress={showModalEditAddress}
					getAddressList={getAddressList}
					makeDeliveryAddress={makeDeliveryAddress}
					deliveryAddress={deliveryAddress}
				/>
			)}
			{openModalAdd && (
				<CreateAddressModal
					isVisible={openModalAdd}
					onCancel={handleCloseAddressCreate}
					onSubmit={onSubmitCreateModal}
					datasource={selectedAddress}
					isDefault={addressList.length === 0}
				/>
			)}
			{confirmFavorite && (
				<ConfirmModal
					loading={favoriteUpdating}
					onCancel={() => setConfirmFavorite(false)}
					onSubmit={updateFavoriteService}
					visible={confirmFavorite}
					content={t('message.cofirmSaveFavoriteService')}
				/>
			)}
		</Card>
	)
}

export default ShipmentAndOrder