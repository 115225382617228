import {VendorServiceInterface} from "../Interface/VendorServiceInterface";


export const VendorServiceModel:VendorServiceInterface = {
    avatar: '',
    code: "",
    createdAt: "",
    createdBy: "",
    description: "",
    id: "",
    modifiedAt: "",
    modifiedBy: "",
    name: "",
    status: "",
    tenant: "",
    version: 0
}
