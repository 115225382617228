import { AutoComplete, Col, Input, Row } from 'antd'
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connectMarketContext } from '../../../context/ConnectMarketContext'
import { supplierRepository } from '../../../repositories/SupplierRepository'
import { localStorageRead } from '../../../utils/LocalStorageUtils'


type BenefitSharingProps = {
	setProviderAccount: Dispatch<SetStateAction<string>>
	setProvider: Dispatch<SetStateAction<string>>
	provider: string
	providerAccount: string
	setSelectedValue: Dispatch<SetStateAction<any>>
	selectedVal: string
}


export default function BenefitSharing(props: BenefitSharingProps) {
	const { t } = useTranslation()

	return (
		<div className={'dlp-flex benefit-sharing-container'}>
			<div className={'dpl-bl bg-color-gray5 txt-size-h5 robotomedium pdt5 pdbt5 pdl12 border-radius4'}>
				{t('purchasePopup.benefit-sharing-title')}
			</div>
			<div className={'mgt15 mgbt15 dpl-fl flex-row'}>
				<span className={'height100pc'}>
					<i className='fa-solid fa-pipe mgr5 txt-size-h7' style={{ color: '#339DFF' }} />
				</span>
				<span>
					<i className='fa-solid fa-circle-exclamation mgr5 txt-size-h7' style={{ color: '#339DFF' }} />
					<span className={'txt-size-h7 robotoregular txt-color-black'}>{t('purchasePopup.benefit-sharing-tip')}</span>
				</span>
			</div>
			<div className={'width100pc'}>
				<InputArea
					setProviderAccount={props.setProviderAccount}
					setProvider={props.setProvider}
					provider={props.provider}
					providerAccount={props.providerAccount}
					selectedVal={props.selectedVal}
					setSelectedValue={props.setSelectedValue}
				/>
			</div>
		</div>
	)
}

type InputAreaProps = {
	setProviderAccount: Dispatch<SetStateAction<string>>
	setProvider: Dispatch<SetStateAction<string>>
	provider: string
	providerAccount: string
	setSelectedValue: Dispatch<SetStateAction<any>>
	selectedVal: string
}

const InputArea = (props: InputAreaProps) => {
	const { t } = useTranslation()
	const [, setConnections] = React.useState<any>([])
	const [disableInput, setDisableInput] = React.useState<boolean>(false)
	const [, setDisableSelect] = React.useState<boolean>(false)
	const [, setLoading] = React.useState<boolean>(false)
	const [providerList, setProviderList] = useState<any>([])
	const [result, setResult] = useState<any>([])

	useEffect(() => {
		const providerListFromExt = localStorageRead('ACC_GSA_NEW')
		setProviderList([...providerListFromExt])
		setResult([...providerListFromExt])
	}, [])
	const { w } = useContext(connectMarketContext)

	useEffect(() => {
		getListConnection()
	}, [])

	const getListConnection = () => {
		setLoading(true)
		supplierRepository
			.getListAccount()
			.then((response) => {
				setConnections(response)
			})
			.catch((err) => console.log(err.response))
			.finally(() => {
				setTimeout(() => setLoading(false), 1000)
			})
	}

	useEffect(() => {
		checkDisableInput()
		checkDisableSelect()
	}, [props.selectedVal, props.provider, props.providerAccount])

	const checkDisableInput = () => {
		if (props.selectedVal && props.selectedVal.length >= 1) {
			setDisableInput(true)
		} else {
			setDisableInput(false)
		}
	}
	const checkDisableSelect = () => {
		if (props.provider || props.providerAccount) {
			setDisableSelect(true)
		} else {
			setDisableSelect(false)
		}
	}

	const onSelectProvider = (value: any) => {
		props.setSelectedValue(value)
	}

	const handleOpenConnectSupplierWindow = () => {
		let checkConnect: any
		let windowObjectReferrer: any
		const openNewWindow = (url: any) => {
			let windowFeatures = 'left=100,top=100,width=840,height=720,modal=yes,alwaysRaised=yes'
			windowObjectReferrer = w.open(url, '_blank', windowFeatures)
		}
		openNewWindow('/#/connect-supplier')
		checkConnect = setInterval(() => {
			if (!windowObjectReferrer || !windowObjectReferrer.closed) return
			clearInterval(checkConnect)
			getListConnection()
		}, 900)
	}
	const [alert, setAlert] = React.useState<ReactNode>(undefined)

	useEffect(() => {
		if (providerList.length === 0) {
			setAlert(
				<div className={'pdt10'}>
					<i className='fa-regular fa-triangle-exclamation txt-color-primary mgr10' />
					<span className={' robotoregular txt-size-h8'}>{t('purchasePopup.alertEmptyWebOrdersAccounts')}</span>
				</div>
			)
		} else {
			setAlert(null)
		}
	}, [providerList.length, t])

	const handleAlertInput = useCallback(() => {
		if (!props.provider && !props.providerAccount) {
			setAlert(null)
			return
		}
		if (!props.provider || !props.providerAccount) {
			setAlert(
				<div className={'pdt10'}>
					<i className='fa-regular fa-triangle-exclamation txt-color-primary mgr10' />
					<span className={' robotoregular txt-size-h8'}>{t('purchasePopup.input-provider-alert')}!</span>
				</div>
			)
			if (!props.provider && !props.providerAccount) setAlert(null)
		} else setAlert(null)
	}, [props.provider, props.providerAccount, t])


	const handleSearchProvider = (value: string) => {
		const accountFiltered = providerList.filter((provider: any) => provider.domain.includes(value))
		setResult(accountFiltered)
	}

	return (
		<Row className={'benefit-sharing-inputs'}>
			<Col span={24} className={'pdr20'}>
				<Row align='middle' gutter={12}>
					<Col span={12}>
						<Row align='middle'>
							<Col span={10}>
								<span className={'width100pc robotoregular txt-size-h7 whitespace'}>
									{t('purchasePopup.provider-input-title')} <span className='txt-color-red'>*</span>
								</span>
							</Col>
							<Col span={14}>
								<AutoComplete
									value={props.provider || undefined}
									onChange={(value) => {
										const acc = providerList.find((acc: any) => acc.domain === value)
										if (acc) {
											const username = acc.type !== 'ubox' ? acc.username : acc.createdBy
											props.setProviderAccount(username)
										}
										props.setProvider(value)
									}}
									className={'width100pc provider-select'}
									placeholder={t('purchasePopup.provider-input-placeholder')}
									onSearch={handleSearchProvider}
									>
									{result.map(({domain, id } : any) => (
										<AutoComplete.Option key={id} value={domain}>
											{`${domain}`}
										</AutoComplete.Option>
									))}
								</AutoComplete>
							</Col>
						</Row>
					</Col>
					<Col span={12}>
						<Row align='middle'>
							<Col span={7}>
								<span className={'width100pc robotoregular txt-size-h7'}>
									{t('purchasePopup.account-input-title')} <span className='txt-color-red'>*</span>
								</span>
							</Col>
							<Col span={17}>
								<Input
									value={props.providerAccount}
									onChange={(event) => props.setProviderAccount(event.target.value)}
									placeholder={`${t('purchasePopup.provider-account-input-placeholder')}`}
									disabled={disableInput}
									onBlur={handleAlertInput}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				{alert}
			</Col>
			{/* <Col span={12}>
                <div className={'width100pc height100 align-items-center dpl-flex flex-row'}>
                    <span className={'mgr15'}>{t('purchasePopup.or-action')}</span>
                    <Spin
                        className={'width100pc'}
                        size={'default'}
                        spinning={loading}
                    >
                        {connections && connections.length >=1 ?
                            <Select
                                className={'width100pc'}
                                placeholder={t('purchasePopup.select-provider-placeholder')}
                                disabled={disableSelect}
                                onChange={onSelectProvider}
                                allowClear={true}
                                value={props.selectedVal || undefined}
                            >
                                {connections.map((account: any) => {
                                    let providerCode = lodash.get(account,'provider.code','');
                                    let accountName = lodash.get(account,'name','');
                                    let accountVal = providerCode.concat(',').concat(accountName)
                                    return <Select.Option
                                        key={account.id}
                                        value={accountVal}
                                        className={'width100pc'}>
                                        <div className={'dpl-flex align-items-center'}>
                                            <Avatar size={12} src={lodash.get(account, 'provider.logo', '')}
                                                    shape={'circle'}/>
                                            <span
                                                className={'mgl5 robotoregular txt-size-h7 txt-capitalize'}>{lodash.get(account, 'provider.code', '')}</span>
                                            <Divider type={"vertical"} className={'bd-color-black'}/>
                                            <span
                                                className={'robotoregular txt-size-h7'}>{lodash.get(account, 'name', '')}</span>
                                        </div>
                                    </Select.Option>
                                })}
                            </Select>
                            :
                            <div className={'width100pc'}>
                                <Button
                                    className={'connect-supplier-btn'}
                                    onClick={handleOpenConnectSupplierWindow}
                                >
                                    <i className="fa-regular fa-link mgr5 txt-color-white"/>
                                    {t('supplier.btConnect')}
                                </Button>
                            </div>
                        }
                    </Spin>
                </div>
            </Col> */}
		</Row>
	)
}

