import { PlusOutlined } from '@ant-design/icons';
import { Form, FormInstance, Input } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { AxiosResponse } from 'axios';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ParagraphWithCopy from '../../components/ParagraphWithCopyButton';
import RichTextEditor from '../../components/RichTextEditor';
import UploadCommon from '../../components/UploadCommon';
import { EditStoredProductFormNames } from '../../Interface/StoredProductInterface';
import { uploadRepository } from '../../repositories/UploadRepository';
import M24Notification from '../../utils/M24Notification';
import { RowItem } from './RowItem';

interface CommonInfoSectionProps {
  form: FormInstance<Record<EditStoredProductFormNames, any>>;
  productImages: UploadFile<unknown>[];
  setProductImages: (images: UploadFile<unknown>[]) => void;
  setIsDisableSubmitButton: (value: boolean) => void;
}

export interface RichTextFields {
  description: string;
  detail: string;
}

const CommonInfoSection = forwardRef(
  (
    {
      form,
      productImages = [],
      setProductImages,
      setIsDisableSubmitButton,
    }: CommonInfoSectionProps,
    ref: any
  ) => {
    const { t } = useTranslation();

    const longDescriptionRef: any = useRef();
    const shortDescriptionRef: any = useRef();

    const handleSetProductImages = (images: UploadFile<unknown>[]): void => {
      setProductImages(images);
      setIsDisableSubmitButton(false);
    };

    const uploadFile = ({ file }: { file: File }) => {
      uploadRepository
        .create(file)
        .then((res: AxiosResponse<string>): void => {
          handleSetProductImages([
            ...productImages,
            {
              uid: String(productImages.length),
              name: file.name,
              url: res.data,
              status: 'done',
            },
          ]);
        })
        .catch(() => {
          M24Notification.notifyError(
            t('message.titleFailed'),
            t(`message.try_again`),
            '',
            2
          );
        });

      return false;
    };

    useImperativeHandle(ref, () => ({
      getCommonInfoValues: (): RichTextFields => {
        return {
          description: shortDescriptionRef.current?.getContent(),
          detail: longDescriptionRef.current?.getContent(),
        };
      },
    }));

    return (
      <section id="common-info-section">
        <h2 className="section-header">
          <span>{t('productDetail.general-info-box')}</span>
        </h2>

        <RowItem label={`${t('productDetail.item-image')}`}>
          <UploadCommon
            listType="picture-card"
            fileList={productImages}
            isDrag={true}
            accept="image/*"
            setFileList={handleSetProductImages}
            customRequest={(file: any) => uploadFile(file)}
          >
            <PlusOutlined className="plus-icon" />
          </UploadCommon>
        </RowItem>

        <RowItem label={`${t('productDetail.item-name')}`} className="mgt2">
          <Form.Item name="name">
            <Input
              showCount
              maxLength={300}
              className="m24-input font-medium"
              placeholder={`${t('productDetail.item-name-placeholder')}`}
            />
          </Form.Item>
        </RowItem>

        <RowItem label={`${t('productDetail.item-link')}`}>
          <div className="profuct-link">
            <ParagraphWithCopy text={form.getFieldValue('link')}>
              <a
                href={form.getFieldValue('link')}
                rel="noreferrer"
                target="_blank"
              >
                <span> {form.getFieldValue('link')}</span>
              </a>
            </ParagraphWithCopy>
          </div>
        </RowItem>

        <RowItem label={`${t('productDetail.item-long-description')}`}>
          <RichTextEditor
            ref={longDescriptionRef}
            placeholder={`${t('productDetail.fill-description')}`}
            initialValue={form.getFieldValue('longDescription')}
            onEditorChange={() => setIsDisableSubmitButton(false)}
          />
        </RowItem>

        <RowItem label={`${t('productDetail.item-short-description')}`}>
          <RichTextEditor
            ref={shortDescriptionRef}
            placeholder={`${t('productDetail.fill-description')}`}
            initialValue={form.getFieldValue('shortDescription')}
            height={180}
            onEditorChange={() => setIsDisableSubmitButton(false)}
          />
        </RowItem>
      </section>
    );
  }
);

export { CommonInfoSection };
