import FeeBox from './FeeBox'
import Heading from './Heading'

interface FinanceProps{
    orderDetail: any;
    expand: boolean
    loading: boolean
}
const Finance = (props: FinanceProps) => {
    const {orderDetail, expand, loading} = props;
  return (
    <div className={`dpl-flex flex-col flex-basis-half robotoregular ${!expand ? 'orderDetail-collapse__item h-fit-content' : 'pd-bt-36'}`}>
        {<Heading className={`${expand ? 'mg-bt-8' : ''}`} loading={loading}/>}
        {expand && <FeeBox orderDetail={orderDetail} loading={loading}/>}
    </div>
  )
}

export default Finance