import {CurrencyInterface, ServicePackageInterface, UserServiceInterface} from "../Interface/UserServiceInterface";
import {VendorServiceModel} from "./VendorServiceModel";


export const CurrencyModel: CurrencyInterface = {
    code: "",
    createdAt: "",
    createdBy: "",
    description: "",
    id: "",
    modifiedAt: "",
    modifiedBy: "",
    name: "",
    precision: 0,
    prefix: "",
    suffix: "",
    version: 0
}

export const ServicePackageModel:ServicePackageInterface = {
    code: "",
    createdAt: "",
    createdBy: "",
    currency: CurrencyModel,
    description:"",
    expirationTime: 0,
    id: "",
    modifiedAt: "",
    modifiedBy: "",
    name: "",
    price: 0,
    status: "",
    tenant: "",
    vendor: "",
    version: 0
}

export const UserServiceModel:UserServiceInterface ={
    createdAt: "",
    createdBy: "",
    customer: "",
    expireDate: "",
    id: "",
    modifiedAt: "",
    modifiedBy: "",
    package: ServicePackageModel,
    status: "",
    tenant: "",
    vendor: VendorServiceModel,
    version: 0
}
