import { message } from 'antd';
import { Repository } from '../core/repositories/Repository';
import { url } from '../core/helpers/string';
import { httpConfig } from '../core/config/http';
import { API_BASE_URL } from '../core/config';
import { AxiosResponse } from 'axios';
import { BodyEditProductForSellInterface } from '../Interface/ProductInterface';

export class ProductWarehouseRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.setBaseURL(url(API_BASE_URL, ''));
  }

  public getStoredProducts(filter: any) {
    return this.http.get(`/personal-products`, {
      params: { ...filter },
    });
  }
  public deleteStoredProduct(productsId: string[]) {
    return this.http.delete(`personal-products/${productsId}`);
  }

  public moveItemsToPendingProductList(body: any) {
    return this.http
      .post(`draw-products`, body)
      .then((response: AxiosResponse) => response);
  }

  public getPendingProducts(filter: any) {
    return this.http
      .get(`draw-products`, { params: { ...filter, sort: 'timestamp:DESC' } })
      .then((response: AxiosResponse) => response);
  }
  public deletePendingProduct(productIds: string[]) {
    return this.http.delete(`draw-products/${productIds}`);
  }
  public getSellingProducts(filter: any, controller?: AbortSignal) {
    return this.http
      .get(`listing-products`, {
        params: { ...filter, sort: 'timestamp:DESC' },
        signal: controller,
      })
      .then((response: AxiosResponse) => response);
  }
  public getSellingProductStatus(channel: string) {
    return this.http
      .get(`listing-product-statuses`, {
        params: { offset: 0, limit: 1000, channel },
      })
      .then((response: AxiosResponse) => response.data);
  }

  public getSellingProductDetails(productId: string) {
    return this.http.get(`listing-products/${productId}`);
  }

  public editSellingProductDetails(
    productId: string,
    body: BodyEditProductForSellInterface
  ) {
    return this.http.patch(`listing-products/${productId}`, body);
  }

  public getPendingProductDetails(productId: string) {
    return this.http.get(`/draw-products/${productId}`);
  }

  public editPendingProductDetails(
    productId: string,
    body: BodyEditProductForSellInterface
  ) {
    return this.http.patch(`/draw-products/${productId}`, body);
  }

  public upProductToShopeeMarketplace = (drawProductIds: string[]): Promise<any> => {
		return this.http
			.post(
				`/listing-products`,
				{ drawProductIds }
			)
			.then((response: AxiosResponse) => {
				return response.data
			})
	}
   
    public postingProductForSale = (body:any) => {
        return this.http.post(`listing-products`,body)
            .then((response:AxiosResponse) => response.data)
    }

    public upShopee (drawProductIds: string) {
      return this.http.post('/listing-products', {drawProductIds})
    }

}

export const warehouseRepository: ProductWarehouseRepository =
  new ProductWarehouseRepository();
