import { Row, Col, Form, InputNumber } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InputNumberWithBeforeAddon } from '../../components/base/InputNumberWithBeforeAddon';
import { onPressOnlyNumberAndAllowDecimals } from './helpers';
import { RowItem } from './RowItem';

const TransportSection: FC<any> = ({ form }) => {
  const { t } = useTranslation();

  const length = Form.useWatch('length', form);
  const width = Form.useWatch('width', form);
  const height = Form.useWatch('height', form);

  const lengthInvalid: boolean = useMemo((): boolean => {
    return (width !== null || height !== null) && length === null;
  }, [length, width, height]);

  const widthInvalid: boolean = useMemo((): boolean => {
    return (length !== null || height !== null) && width === null;
  }, [length, width, height]);

  const heightInvalid: boolean = useMemo((): boolean => {
    return (width !== null || length !== null) && height === null;
  }, [length, width, height]);

  return (
    <section id="transport-section">
      <h2 className="section-header">
        <span>{t('productDetail.delivering-detail-box')}</span>
      </h2>

      <RowItem label={`${t('productDetail.item-weight')}`}>
        <Form.Item name="weight">
          <InputNumberWithBeforeAddon
            placeholder={`${t('productDetail.weight')}`}
            className="font-medium w-100-percent"
            addonAfter={t('productDetail.kg')}
            min={0}
            max={1000000}
            onKeyPress={onPressOnlyNumberAndAllowDecimals}
          />
        </Form.Item>
      </RowItem>

      <RowItem
        label={`${t('productDetail.item-sizes')}`}
        className="size-inputs mgt10"
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="length"
              rules={[
                {
                  required: width || height,
                  message: `${t('productDetail.invalid-length')}`,
                },
              ]}
              validateStatus={lengthInvalid ? 'error' : 'success'}
              help={lengthInvalid ? t('productDetail.invalid-length') : ''}
            >
              <InputNumber
                placeholder={`${t('productDetail.item-length')}`}
                className="font-medium w-100-percent"
                min={0}
                onKeyPress={onPressOnlyNumberAndAllowDecimals}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="width"
              rules={[
                {
                  required: length || height,
                  message: `${t('productDetail.invalid-width')}`,
                },
              ]}
              validateStatus={widthInvalid ? 'error' : 'success'}
              help={widthInvalid ? t('productDetail.invalid-width') : ''}
            >
              <InputNumber
                placeholder={`${t('productDetail.item-wide')}`}
                className="m24-input font-medium w-100-percent"
                min={0}
                onKeyPress={onPressOnlyNumberAndAllowDecimals}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="height"
              rules={[
                {
                  required: length || width,
                  message: `${t('productDetail.invalid-height')}`,
                },
              ]}
              validateStatus={heightInvalid ? 'error' : 'success'}
              help={heightInvalid ? t('productDetail.invalid-height') : ''}
            >
              <InputNumber
                placeholder={`${t('productDetail.item-height')}`}
                className="m24-input font-medium w-100-percent"
                min={0}
                onKeyPress={onPressOnlyNumberAndAllowDecimals}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="size-unit">{t('productDetail.cm-unit')}</div>
      </RowItem>
    </section>
  );
};

export { TransportSection };
