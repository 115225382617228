import { Badge, Col, Layout, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import M24Breadcrumb from './Breadcrumb'
import PopoverDropdownUserActions from './DropdownUserActions'
import PopoverNotices from './PopoverNotices'
const { Header } = Layout
interface NavigationProps {
	title: string
	isShowNotice: boolean
	totalUnreadNotice: number
	profile: any
	visibleDropdownUserActions: boolean
	breadcrumbs?: any
	totalCart?: number
	getTotalUnreadNotice: () => void
	logoutHandler: () => void
	onVisibleChangeNoticesHandler: (visible: boolean) => void
	visibleDropdownUserActionsChangeHandler: (visible: boolean) => void
}
const Navigation: React.FC<NavigationProps> = (props) => {

	const {
		isShowNotice,
		totalUnreadNotice,
		logoutHandler,
		profile,
		breadcrumbs,
		onVisibleChangeNoticesHandler,
		getTotalUnreadNotice,
		visibleDropdownUserActions,
		visibleDropdownUserActionsChangeHandler,
		totalCart
	} = props

	return (
		<Header className='header'>
			<div
				className={`dpl-flex  align-items-center ${
					breadcrumbs?.enable !== 'NO_BREAD_CRUMB' ? 'justify-content-between' : 'justify-content-end'
				}`}>
				{breadcrumbs?.enable !== 'NO_BREAD_CRUMB' && <M24Breadcrumb breadcrumbs={breadcrumbs} />}
				<div
					id={'header-right-content'}
					className={'header-right-content dpl-flex justify-content-between align-items-center'}>
					<div className='flex align-items-center mg-r-12'>
							<Link to='/shopping-cart' className='robotomedium fsz-14 line-h-16 txt-color-black mg-r-5 link-hover pd-8'>
								<Badge count={totalCart} overflowCount={99} offset={[totalCart! > 99 ? 18 : 10,1]}>
									<i className='fas fa-shopping-cart txt-color-blue6' />
								</Badge>
							</Link>
					</div>
					<PopoverNotices
						totalUnreadNotice={totalUnreadNotice}
						getTotalUnreadNotice={getTotalUnreadNotice}
						isShowNotice={isShowNotice}
						onVisibleChangeHandler={onVisibleChangeNoticesHandler}
					/>
					<PopoverDropdownUserActions
						visible={visibleDropdownUserActions}
						profile={profile}
						logoutHandler={logoutHandler}
						visibleChangeHandler={visibleDropdownUserActionsChangeHandler}
					/>
				</div>
			</div>
		</Header>
	)
}

export default Navigation

