import {Spin, Timeline} from "antd";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from 'moment';
import {ClaimDetailContext} from "../../context/ClaimDetailContext";
import {claimRepository} from "../../repositories/ClaimRepository";
import {ClaimMilestoneInterface} from "../../Interface/ClaimMilestoneInterface";
import {getClaimStatusByCode} from "../../utils/util";
import {useParams} from "react-router-dom";
import {ClaimStateInterface} from "../../Interface/ClaimStateInterface";


type StatusProps ={
    statuses:ClaimStateInterface[];
}

export const Status = (props:StatusProps) => {
    // const {claim, provider, customerCode, statuses} = useContext(ClaimDetailContext)
    const {claim} = useContext(ClaimDetailContext)
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [milestones, setMilestones] = useState<ClaimMilestoneInterface[]>([])

    const {providerCode,customerCode,orderCode,claimCode} = useParams();

    useEffect(() => {
        if (providerCode && claimCode) {
            setLoading(true)
            claimRepository.getMilestones(providerCode, customerCode!, orderCode!, claimCode)
                .then(items => setMilestones(items))
                .finally(() => setLoading(false))
        }
    }, [providerCode,claimCode,orderCode,customerCode])

    const milestonesParsed = useMemo(()=> {
        let timestampMax: any = '';
        milestones.forEach((item) => {
            if( item.state === 'PROCESSING') {
                timestampMax = item.timestamp
            }
        })

        const result = milestones.filter((item) => {
            if (item.state !== 'PROCESSING' || item.timestamp === timestampMax) {
                return true
            }
            return false
        })
        return result
    },[milestones])

    const milestonesSorted = milestonesParsed.sort((a,b)=> (moment(a.timestamp) > moment(b.timestamp)) ? 1 : -1)

    return (
        <div className={'ticket-status-box h-fit-content box-shadow'}>
            <Spin spinning={loading}>
                <div className={'mgbt15 robotomedium txt-color-black fsz-16'}>{t('tickets.ticket-status')}</div>
                <Timeline className={`status-length-${milestonesParsed.length}`}>
                    {milestonesSorted.length && milestonesSorted.map((item,index) => {
                        const statusItem = getClaimStatusByCode(props.statuses, item.state)
                        if (statusItem.name) {
                            return (
                                <Timeline.Item key={item.state+index} color={statusItem.color} dot={<i className="fa-regular fa-circle-dot"/>}>
                                    <div
                                        className={'txt-capitalize robotomedium txt-color-black'}>{statusItem.name}</div>
                                    <div
                                        className={'ticket-status-time'}>{item.timestamp ? moment(item.timestamp).format("HH:mm DD/MM/YYYY") : '--'}</div>
                                    <div
                                        className={'ticket-status-time'}>({`${Math.floor(moment.duration(moment().diff(moment(item.timestamp), 'seconds'), 'seconds').asDays())} ngày`})
                                    </div>
                                </Timeline.Item>
                            )
                        }
                    })}

                    {!milestones.length && (
                        <Timeline.Item color={claim.publicState.color} dot={<i className="fa-regular fa-circle-dot" />}>
                            <div className={'txt-capitalize robotomedium txt-color-black'}>{claim.publicState.name}</div>
                            <div className={'ticket-status-time'}>{claim.createdAt ? moment(claim.createdAt).format("HH:mm DD/MM/YYYY") : '--'}</div>
                            <div className={'ticket-status-time'}>({moment.duration(moment().diff(moment(claim.createdAt), 'seconds'), 'seconds').humanize()})</div>
                        </Timeline.Item>
                    )}
                </Timeline>
            </Spin>
        </div>
    )
}
