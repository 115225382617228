import React from "react";
import {Button, Col, List, Radio, Row} from "antd";
import {useTranslation} from "react-i18next";

type PaymentMethodProps = {
    handleSelectedPayment: (code: string) => void;
    handleCreatDraft: () => void;
    paymentSelected: string;
    handlePrevStep: () => void;
    isCreateDraft:boolean;
}

function PaymentMethods(props: PaymentMethodProps) {
    const {t} = useTranslation();
    const PAYMENTS = [
        {code: 'BANK_TRANSFER', name: 'profile.purchase-bankTransfer', disabled: false},
        {code: 'ONEPAY', name: 'profile.purchase-onepay', disabled: true},
    ];

    return (
        <div className={'package-list'}>
                <div className={'payment-list'}>
                        <List
                            dataSource={PAYMENTS}
                            renderItem={(pm: any) => {
                                    return (
                                        <div id={'payment-item'}>
                                            <List.Item
                                                key={pm.code}
                                                className={`payment-list-item `}
                                                onClick={() => {
                                                    if(!pm.disabled) {
                                                        props.handleSelectedPayment(pm.code);
                                                    }
                                                } }
                                            >
                                                <Row className={`width100pc`}>
                                                    <Col span={1} className={'justify-content-center pdl0 pdr5'}>
                                                        <Radio
                                                            className={''}
                                                            key={pm.code}
                                                            checked={pm.code === props.paymentSelected}
                                                            disabled={pm.disabled}
                                                            onChange={() => {
                                                                props.handleSelectedPayment!(pm.code)
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col span={23} flex={'auto'}
                                                         className={`payment-list-item-content ${pm.code === props.paymentSelected ? 'payment-list-item-content-selected' : ''} ${pm.disabled ? 'payment-list-item-content__disabled' : ''}`}
                                                    >
                                                        <span className={``}>{t(pm.name)}</span>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        </div>
                                    )
                            }}
                        />
                </div>
                <div className={'package-list-footer'}>
                    <Button className={'cancel mgr10'}
                            onClick={props.handlePrevStep}
                    >
                        <i className="fa-solid fa-arrow-left-long mgl5 mgr10"/>
                        {t('profile.prev-button')}
                    </Button>
                    <Button className={'purchase txt-capitalize'}
                            onClick={props.handleCreatDraft}
                            loading={props.isCreateDraft}
                    >
                        <i className="fa-solid fa-arrow-right-long mgl5 mgr10"/>
                        {t('profile.next-button')}
                    </Button>
                </div>

        </div>
    )
}

export default PaymentMethods;
