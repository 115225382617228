import { Badge, Tabs } from 'antd'
import lodash from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tabItems } from '../../../core/config'
import { NoticeCategoryInterface } from '../../../Interface/NoticeCategoryInterface'
import { NoticeFilterInterface } from '../../../Interface/NoticeFilterInterface'
import { NoticeItemInterface } from '../../../Interface/NoticeItemInterface'
import { noticeRepository } from '../../../repositories/NoticeRepository'
import './style.scss'
import TabContent from './TabContent'
import { WelcomeNotice } from './WelcomeNotice'

const { TabPane } = Tabs
interface Props {
	totalUnread: number
	refetchUnread: () => void
}

const TabNotification = (props: Props) => {
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)
	const [notices, setNotices] = useState<NoticeItemInterface[]>([])
	const [currentTab, setCurrentTab] = useState('ORDER')
	const [currentButton, setCurrentButton] = useState('all')

	const getNotices = (criteria: NoticeFilterInterface) => {
		setLoading(true)
		noticeRepository
			.getNotices({
				sort: 'publishDate:desc',
				offset: 0,
				limit: 200,
				...criteria,
			})
			.then((response) => {
				setNotices(response.data)
			})
			.catch(() => setNotices([]))
			.finally(() => setLoading(false))
	}

	const getUnreadNotices = (criteria: NoticeFilterInterface) => {
		setLoading(true)
		noticeRepository
			.getUnreadNotices({
				offset: 0,
				limit: 200,
				...criteria,
			})
			.then((response) => {
				setNotices(response.data)
			})
			.catch(() => setNotices([]))
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		getNotices({
			groupCode: 'ORDER',
		})
		// firebaseTokenRegister();
	}, [])

	const handleChangeTab = (key: string) => {
		setCurrentTab(key)
		getNotices({
			groupCode: key,
		})
	}

	const handleFilter = (type: string) => {
		setCurrentButton(type)
		if (type === 'all') {
			getNotices({
				groupCode: currentTab,
			})
		} else if (type === 'unread') {
			getUnreadNotices({
				groupCode: currentTab,
			})
		}
	}

	const handleMarkReadAll = () => {
		setLoading(true)
		noticeRepository
			.markAsReadAll()
			.then(() => {
				handleFilter(currentButton)
				props.refetchUnread()
			})
			.finally(() => setLoading(false))
	}

	const handleMarkReadOne = (notice: NoticeItemInterface) => {
		setLoading(true)
		noticeRepository
			.markAsRead(notice.id, {
				groupCode: currentTab,
			})
			.then(() => {
				handleFilter(currentButton)
			})
			.finally(() => setLoading(false))
	}

	const [categories, setCategories] = useState<NoticeCategoryInterface[]>([])

	const getCategories = () => {
		noticeRepository
			.getCategories()
			.then((response) => {
				let items = lodash
					.filter(response.data, (item: NoticeCategoryInterface) => item.code !== 'PROFILE')
					.map((item: NoticeCategoryInterface) => {
						const tabItem = tabItems.find((it) => it.key === item.code)
						item.name = tabItem ? tabItem.name : item.name
						item.sort = tabItem ? tabItem.sort : 1
						return item
					})
				items = lodash.sortBy(items, 'sort')
				Promise.all(
					items.map(async (item: any, index: number) => {
						try {
							const res = await noticeRepository.getUnreadCountNotices(item.code)
							item['unreadCount'] = res.data
							return item
						} catch (err: any) {}
					})
				)
					.then((values) => {
						setCategories([...items])
					})
					.catch((err: any) => {})
			})
			.catch(() => setCategories([]))
	}

	useEffect(() => {
		getCategories()
	}, [])

	return (
		<>
			<div className='notification-top'>
				<span className='top-title'>
					<span>{t('notification.title')}</span>
					<span className='notice-top-badge'>{props.totalUnread}</span>
				</span>
				<span onClick={() => handleMarkReadAll()} className='mark-as-read-all'>
					{t('notification.markAsRead')}
				</span>
			</div>
			{categories.length > 0 && (
				<Tabs defaultActiveKey='ORDER' onChange={handleChangeTab} className='notifications-tabs'>
					{categories.map((item) => (
						<TabPane
							tab={
								<div className='flex align-items-center'>
									<span className='mg-r-4 txt-capitalize'>{t(item.name)}</span>
									<Badge
										count={item.unreadCount}
										style={{
											backgroundColor: item.unreadCount && currentTab === item.code ? '#3340B6' : '#F4F6F8',
											color: item.unreadCount && currentTab === item.code ? 'white' : '#707070',
											fontWeight: item.unreadCount ? 500 : 400,
											borderRadius: 20,
											paddingLeft: 11,
											paddingRight: 11
										}}
									/>
								</div>
							}
							key={item.code}>
							<TabContent
								currentButton={currentButton}
								loading={loading}
								notices={notices}
								onMarkReadOne={handleMarkReadOne}
								onMarkReadAll={handleMarkReadAll}
								onFilter={handleFilter}
							/>
						</TabPane>
					))}
				</Tabs>
			)}

			{!categories.length && (
				<div className='notifications-tabs'>
					<WelcomeNotice />
				</div>
			)}
		</>
	)
}

export default TabNotification

