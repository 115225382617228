//ORDER_COMMENT
//chatBox.threadTypeOrder
import moment from "moment";
import React, {useContext} from "react";
import {ThreadItemInterface} from "../../Interface/ThreadItemInterface";
import {useTranslation} from "react-i18next";
import {BoxChatContext} from "../../context/BoxChatContext";
import lodash from "lodash";
import {ThreadMessageInterface} from "../../Interface/ThreadMessageInterface";
import {Avatar} from "antd";

interface Props {
    thread: ThreadItemInterface
}

const getThreadType = (t: any,thread:any) => {
    if (thread.type === "ORDER_COMMENT") {
        return (
            <span>{t('chatBox.threadTypeOrder')} #{thread.referenceCode}</span>
        );
    }
    else if (thread.type === 'CLAIM_COMMENT') {
        return (
            <span>{t('chatBox.threadTypeClaim')}#{thread.referenceCode}</span>
        );
    }
    return 'Unknown'
}

export const ThreadItem = (props: Props) => {
    const {thread} = props
    const {t} = useTranslation()
    const boxChatContext = useContext(BoxChatContext)

    const handleSelectThread = () => boxChatContext.onSelectThread(thread)

    const renderContent = (message: ThreadMessageInterface) => {
        if (message.content) {
            return thread.lastMessage.customer
                ? t('chatBox.lastMessageMe') + ': ' + message.content
                : t('chatBox.lastMessageStaff') + ': ' + message.content
        }

        if (message.attachments.length > 0) {
            return thread.lastMessage.customer
                ? t('chatBox.lastMessageMeAttachment').replace('%s', message.attachments.length.toString())
                : t('chatBox.lastMessageStaffAttachment', message.attachments.length.toString())
        }
    }

    return (
        <div className={`box-chat-thread-item ${thread.id === boxChatContext.thread.id ? 'selected' : ''} ${thread.isRead ? 'read' : ''}`} onClick={() => handleSelectThread()}>
            <div className={'dpl-flex'}>
                <div style={{display:'grid'}} className={'width100pc mgl10'}>
                    <div className="thread-order mgl5 dpl-flex justify-space-between">
                        <span>{getThreadType(t,thread)}</span>
                        <span>{!thread.isRead && <i className="thread-dot fa-solid fa-circle-small txt-size-h7" />}</span>
                    </div>
                    {lodash.get(thread, 'lastMessage.timestamp') && (
                        <div style={{display:'grid',flexDirection:"column"}} className={'mgl5'}>
                            <div className="thread-message">
                                {renderContent(thread.lastMessage)}
                            </div>
                            <div className="thread-time">{moment(thread.lastMessage.timestamp).format("HH:mm DD/MM/YYYY")}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
