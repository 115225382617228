import { Repository } from '../core/repositories/Repository';
import { url } from '../core/helpers/string';
import { httpConfig } from '../core/config/http';
import { API_BASE_URL } from '../core/config';
import { RcFile } from 'antd/es/upload';
import axios, { AxiosResponse } from 'axios';

export class UploadRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.setBaseURL(url(API_BASE_URL, ''));
  }

  create = (file: File) => {
    const formData = new FormData();
    formData.set('file', file);
    return this.http.post(`/personal-products-upload`, formData);
  };

//   public uploadImage = (file: RcFile) : Promise<any> => {
   
//     return axios.post(`http://localhost:5000/api/upload`, formData, {headers: {
//       'Content-Type': 'multipart/form-data',
//     },}).then((response:AxiosResponse) => {
//         return response.data;
//     })
// }
}

export const uploadRepository: UploadRepository = new UploadRepository();
