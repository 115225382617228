import lodash from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import MainLayout from '../../components/Layout'
import ShipmentsFilterInterface from '../../Interface/ShipmentFilterInterface'
import ShipmentInterface from '../../Interface/ShipmentInterface'
import ShipmentStatusInterface from '../../Interface/ShipmentStatusInterface'
import { categoryRepository } from '../../repositories/CategoryRepository'
import { shipmentRepository } from '../../repositories/ShipmentRepository'
import { supplierRepository } from '../../repositories/SupplierRepository'
import M24ErrorUtils from '../../utils/M24ErrorUtils'
import M24Notification from '../../utils/M24Notification'
import { getCleanFormFilter } from '../../utils/StringUtils'
import Filter from './Filter'
import ShipmentItems from './List'
import { ConnectionInterface } from '../../Interface/ConnectionInterface'
import './shipments.style.scss'
import MarketPalaceInterface from '../../Interface/MarketPalaceInterface'
import { Button, Empty } from 'antd'
import { ProviderInterface } from '../../Interface/AccountBalanceInterface'

const Shipments = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { code } = useParams()
	const [filter, setFilter] = useState<ShipmentsFilterInterface | {}>({})
	const [statuses, setStatuses] = useState<ShipmentStatusInterface[]>([])
	const [accounts, setAccounts] = useState<ConnectionInterface[]>([])
	const [providers, setProviders] = useState<ProviderInterface[]>([])
	const [shipments, setShipments] = useState<ShipmentInterface[]>([])

	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(Number(searchParams.get('page')))
	const [pageSize, setPagesize] = useState(Number(searchParams.get('size')))
	const [showProgressBar, setShowProgressBar] = useState(true)
	const [marketPalaces, setMarketPalaces] = useState<MarketPalaceInterface[]>([])
	const filterRef = useRef<HTMLDivElement | null>(null)
	const [isSkeleton, setIsSkeleton] = useState(true)

  
    
	const getShipments = useCallback(async () => {
		setShowProgressBar(true)
		const providerUsername = searchParams.get('providerUsername')
		const providerOrderCode = searchParams.get('providerOrderCode')
		const provider = searchParams.get('provider')
		const code = searchParams.get('code')
		const originalReceipt = searchParams.get('originalReceipt')
		const merchantName = searchParams.get('merchantName')
		const timestampFrom = searchParams.get('createdFrom')
		const timestampTo = searchParams.get('createdTo')
		const providerShipmentCode = searchParams.get('providerShipmentCode')
		const statuses = searchParams.get('statuses')
		const waybill = searchParams.get('waybill')
		const page = searchParams.get('page')
		const size = searchParams.get('size')
        
		const temp = {
			page,
			size,
			providerUsername,
			providerOrderCode,  
			provider,
			code,
			statuses,
			originalReceipt,
			timestampFrom,
			timestampTo,
			merchantName,
			providerShipmentCode,
			waybill,
		}
		setFilter((prevState: any) => ({
			...prevState,
			...getCleanFormFilter(temp),
		}))
		try {
			const response = await shipmentRepository.getShipments(getCleanFormFilter(temp))
			const currentPage = lodash.get(response, 'headers.x-page-number')
			const total = lodash.get(response, 'headers.x-total-count')
			setShipments(response.data)
			setPage(parseInt(currentPage) + 1)
			setTotal(parseInt(total))
			setPagesize(parseInt(lodash.get(response, 'headers.x-page-size')))
			setShowProgressBar(false)
			setTimeout(() => setIsSkeleton(false), 1000)
		} catch (err: any) {
			M24Notification.messageError(t(err), '', 3)
			setShowProgressBar(false)
			setTimeout(() => setIsSkeleton(false), 1000)
		}
	}, [searchParams, t])

	const getShipmentStatuses = useCallback(async () => {
		const res = await categoryRepository.getShipmentStatuses()
		setStatuses(res)
	}, [])

	const getAccounts = useCallback(async () => {
		try {
			const res = await supplierRepository.getListAccount()
			const accounts = res.filter(({ status }: any) => status === 'CONNECTED')

			setAccounts(accounts)
		} catch (err) {
			M24ErrorUtils.showError(t, err)
		}
	}, [])

	const getProviders = useCallback(async () => {
		try {
			const res = await categoryRepository.getProvider()
			setProviders(res)
		} catch (err) {}
	}, [])

	const getMarketplaces = useCallback(async () => {
		try {
			const res = await categoryRepository.getMarketPlaces()
			setMarketPalaces(res)
		} catch (err) {}
	}, [])

	useEffect(() => {
		getShipments()
		getShipmentStatuses()
		getAccounts()
		getProviders()
		getMarketplaces()
	}, [getShipments, getShipmentStatuses, getAccounts, getProviders, getMarketplaces])

	const filterChangeHandler = (obj: any) => {
		setFilter((prevState: any) => setFilter({ ...prevState, ...obj }))
	}

	const handleFilter = (params: any) => {
		setFilter({ ...params })
		setSearchParams(
			getCleanFormFilter({
				...params,
			})
		)
	}

	return (
		<MainLayout title={t('menu.shipmentsTitle')} showProgressBar={showProgressBar} breadcrumbs={{ total }}>
			<div ref={filterRef}>
				<Filter
					filter={filter}
					statuses={statuses}
					filterChangeHandler={filterChangeHandler}
					accounts={accounts}
					providers={providers}
					handleFilter={handleFilter}
				/>
			</div>
			<ShipmentItems
				data={shipments}
				getData={getShipments}
				accounts={accounts}
				statuses={statuses}
				loading={showProgressBar}
				filter={filter}
				page={page}
				size={pageSize}
				total={total}
				handleFilter={handleFilter}
				marketPalaces={marketPalaces}
				providers={providers}
			/>
		</MainLayout>
	)
}

export default Shipments

