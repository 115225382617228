import MainLayout from '../../components/Layout';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EditProductForSell } from '../../components/EditProductForSell';

const SellingProductEditing = () => {
  const { t } = useTranslation();
  const params: any = useParams();

  return (
    <MainLayout title={t('productDetail.product-details')}>
      <EditProductForSell
        productId={params.productId}
        productStatus="SELLING"
      />
    </MainLayout>
  );
};

export default SellingProductEditing;
