import { Avatar, Button, Image, Popover } from 'antd'
import React from 'react'
import ShopNhatLucBatDoubleImg from '../../resources/images/1688.png'
import TaoBaoImg from '../../resources/images/taobao.png'
import TMallImg from '../../resources/images/tmall.png'
import EmptyCartImg from '../../resources/images/empty-blue1.png'
import { useTranslation } from 'react-i18next'

interface EmptyCartProps {
	className?: string
	minHeight?: number
	emptyText: string
}

const EmptyCart: React.FC<EmptyCartProps> = ({ className, minHeight, emptyText }) => {
	const { t } = useTranslation()

	const contentShop = () => {
		return (
			<div className='content-shop flex flex-col'>
				<a href={'https://www.1688.com/'} target='_blank' className='flex align-items-center pd-8' rel='noreferrer'>
					<Image width={24} height={18} src={ShopNhatLucBatDoubleImg} preview={false} className='mg-r-4' />
					<span className='robotoregular fsz-14 line-h-22 text-reg txt-color-black'>1688.com</span>
				</a>
				<a href={'https://world.taobao.com/'} target='_blank' className='flex align-items-center pd-8' rel='noreferrer'>
					<Image width={24} height={18} src={TaoBaoImg} preview={false} className='mg-r-4' />
					<span className='robotoregular txt-color-black fsz-14 line-h-22'>taobao.com</span>
				</a>
				<a href={'https://www.tmall.com/'} target='_blank' className='flex align-items-center pd-8' rel='noreferrer'>
					<Image width={24} height={18} src={TMallImg} preview={false} className='mg-r-4' />
					<span className='robotoregular fsz-14 line-h-22 txt-color-black'>tmall.com</span>
				</a>
			</div>
		)
	}

	return (
		<div
			className={`${className ? className : ''} flex flex-col justify-content-center align-items-center flex-grow-1 align-items-center`}
			style={{ minHeight: minHeight ? `calc(100vh - ${minHeight}px)` : '' }}>
			<Avatar src={EmptyCartImg} alt='cart-empty' className='mg-bt-8 bd-none-impt' size={33} shape='square' />
			<span className='robotoregular fsz-12 line-h-20 mg-bt-12'>{emptyText}</span>
			<Popover placement='rightTop' title={null} content={contentShop} trigger='click' arrowPointAtCenter>
				<Button
					className='btn-df btn-df-secondary-bg-lemon fsz-14 line-h-22'
					icon={<i className='fa-regular fa-cart-arrow-down mg-r-10' />}
					style={{ paddingLeft: '15px', paddingRight: '15px' }}>
					{t('shoppingCart.orderNow')}
				</Button>
			</Popover>
		</div>
	)
}

export default EmptyCart

