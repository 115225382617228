import React, {useRef} from "react";
import {Avatar, Button, Col, ConfigProvider, Empty, List, Row, Spin} from "antd";
import {UserServiceInterface} from "../../../Interface/UserServiceInterface";
import {useTranslation} from "react-i18next";
import GobizLogo from "../../../resources/images/gobiz-128.png";
import lodash from "lodash";
import moment from "moment";
import UboxLogo3 from '../../../resources/images/UboxLogo3.png'
import Paragraph from "antd/es/typography/Paragraph";

type UserServicesProps = {
    userServices: UserServiceInterface[];
    isLoading: boolean;
    handlePurchase: (vendor: any) => void;
}

function UserServices(props: UserServicesProps) {
    const {t} = useTranslation();
    const {
        userServices, isLoading,
        handlePurchase
    } = props;
    const divRef = useRef<any>();

    return (
        <ConfigProvider renderEmpty={() => <Empty
            image={<Avatar src={GobizLogo} size={100} shape='square' className="gobiz-gray"/>}
            description={<span className={'txt-size-h7 robotoregular txt-color-black'}>{t('profile.empty-service-list')}</span>}/>}>
            {/*<Spin spinning={isLoading}>*/}
                <List
                    className={'vendor-list'}
                    dataSource={userServices}
                    renderItem={(item: UserServiceInterface) => {
                        let isExpired = lodash.get(item, 'expireDate') < moment().format("YYYY-MM-DD");
                        return (
                            <List.Item className={`vendor-item ${isExpired ? 'vendor-item-expired' : ''}`}>
                                <Row className={'width100pc'}>
                                    <Col span={2} flex={'auto'} className={'align-items-center'}>
                                        <Avatar src={lodash.get(item, 'vendor.avatar') || UboxLogo3}
                                                size={96}
                                                shape={'square'}
                                                className={'bd-none-impt'}
                                                style={{width: "auto", height: "auto"}}
                                        />
                                    </Col>
                                    <Col span={18} flex={'auto'} className={'pdl25 pdr10'}>
                                        <div style={{textAlign: 'justify'}}>
                                            <div className={'width100pc txt-size-h1 robotomedium txt-color-black'}>
                                                <span className={'width100pc dpl-flex align-items-center'}>
                                                    <span className={'txt-size-h1 robotomedium'}
                                                        style={{color: '#3340B6'}}>{lodash.get(item, 'package.name', '')}
                                                    </span>
                                                    {lodash.get(item, 'expireDate') > moment().format("YYYY-MM-DD") ?
                                                        <span className={'txt-size-h7 txt-color-black robotoregular mgl10 dpl-flex'}>
                                                            <i className="fa-solid fa-period mgr5"/>
                                                            <span>{t('profile.package-expiration-date')}: {moment(lodash.get(item, 'expireDate')).format('DD/MM/YYYY')}</span>
                                                    </span>
                                                        : <span
                                                            className={'txt-size-h7 robotomedium txt-color-red mgl10'}>
                                                        <i className="fa-solid fa-triangle-exclamation"/>&nbsp;{t('profile.package-expired')}
                                                    </span>
                                                    }
                                        </span>
                                            </div>
                                            <span
                                                className={'width100pc robotomedium txt-size-h7'}>{t('profile.service-package-vendor')}:&nbsp;
                                                {lodash.get(item, 'vendor.name', '')}</span>
                                            <div className={'item-description'}>
                                                <Paragraph ellipsis={{
                                                    rows:3,
                                                    expandable:false
                                                }}>
                                                    <div
                                                        ref={divRef}
                                                        id={'p-description'}
                                                        className={'width100pc robotoregular txt-size-h7'}
                                                        dangerouslySetInnerHTML={{__html:lodash.get(item,'package.description','')}}
                                                        // style={ellipsis ? {maxHeight:107,overflowY:'hidden'} : {height:'auto'}}
                                                    />
                                                </Paragraph>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={4} flex={'auto'} className={'align-items-center'}>
                                        <Button className={'purchase-btn'}
                                                onClick={() => {
                                                    handlePurchase(item.vendor)
                                                }}
                                        >
                                            {lodash.get(item, 'expireDate') > moment().format("YYYY-MM-DD") ?
                                                t('profile.service-package-upgrade')
                                                : t('profile.service-package-recharge')
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </List.Item>
                        )
                    }}
                />
            {/*</Spin>*/}
        </ConfigProvider>
    );

}

export default UserServices;
