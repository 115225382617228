import React, { useContext, useEffect, useState } from 'react'
import { ConfigProvider } from 'antd'

import Dashboard from './containers/Dashboard'
import ConnectSupplier from './containers/ConnectSupplier'
import Transaction from './containers/Transaction'
import ShoppingCart from './containers/ShoppingCart'
import Orders from './containers/Orders'
import OrderDetail from './containers/OrderDetail'
import Packages from './containers/Packages'

import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import { LANGUAGE_STORAGE_KEY, TENANT_KEY } from './core/config'
import numeral from 'numeral'
import Success from './containers/Delivery/Create/Success'
import Claims from './containers/Claim'
import ClaimCreate from './containers/ClaimCreate'
import { ClaimDetail } from './containers/ClaimDetail'
import moment from 'moment'
import 'moment/locale/vi'
import InventoryVoucher from './containers/Delivery/InventoryVoucher'
import RequestDelivery from './containers/Delivery/Request'
import CreateRequestDelivery from './containers/Delivery/Create'
import { Login } from './containers/Login'
import { Authentication } from './containers/Authentication'
import { AuthenticateRoute } from './components/AuthenticateRoute'
import { tenantRepository } from './repositories/TenantRepository'
import { localStorageRead, localStorageRemoveItem, localStorageSave } from './utils/LocalStorageUtils'
import { AppContext } from './context/AppContext'
import PageNotFound from './components/ErrorPage/404'
import Profile from './containers/Profile'
import StoredProducts from './containers/StoredProducts'
import MarketConnect from './containers/ConnectMarket'
import ConnectMarketRedirectPage from './components/ConnectMarket/ConnectMarketRedirectPage'
import PriceConfig from './containers/PriceConfig'
import ProductForSell from './containers/ProductForSell'
import StoredProductEditing from './containers/StoredProductEditing'
import PendingProduct from './containers/PendingProduct'
import SellingProduct from './containers/SellingProduct'
import SellingProductEditing from './containers/SellingProductEditing'
import CreateShipment from './containers/Shipment/Create'
import Shipments from './containers/Shipment'
import Shipment from './containers/Shipment/Detail'
import { TenantUpdateConfigCommand } from './Interface/TenantUpdateConfigCommand'
import { isEmpty } from 'lodash'
import MainLayout from './components/Layout'
import PeerPayments from './containers/PeerPayment'

switch (localStorage.getItem(LANGUAGE_STORAGE_KEY)) {
	case 'vi':
	default:
		moment.locale('vi', {
			relativeTime: {
				s: '%d giây',
				m: '%d phút',
				h: '%d giờ',
				d: '%d ngày',
				w: '%d tuần',
				M: '%d tháng',
				y: '%d năm',
			},
		})

		numeral.register('locale', 'vi', {
			delimiters: {
				thousands: '.',
				decimal: ',',
			},
			abbreviations: {
				thousand: 'k',
				million: 'm',
				billion: 'b',
				trillion: 't',
			},
			ordinal: function (number) {
				return ''
			},
			currency: {
				symbol: '₫',
			},
		})
		numeral.locale('vi')
		break
}

function App() {
	const [currentConfigServices, setCurrentConfigServices] = useState<TenantUpdateConfigCommand>()
	const [totalCart, setTotalCart] = useState(0)
	
	useEffect(() => {
		const fetchCurrent = async () => {
			const response = await tenantRepository.getCurrent()
			if (localStorageRead(TENANT_KEY)) {
				localStorageRemoveItem(TENANT_KEY)
			}
			localStorageSave(TENANT_KEY, response)
			const { generalConfig, peerPaymentConfig, shipmentConfig, gsaConfig, orderConfig } = response?.config

			setCurrentConfigServices((prevState: any) => ({
				...prevState,
				enableShipment: shipmentConfig?.enable,
				enableOrder: orderConfig?.enable,
				enableGeneral: generalConfig?.enable,
				enablePeerPayment: peerPaymentConfig?.enable,
				enableGsa: gsaConfig?.enable,
			}))
		}
		fetchCurrent()
	}, [])

	return (
		<div id={'app-main'} className={''}>
			<ConfigProvider>
				<BrowserRouter>
					<Routes>
						<Route path={'/authentication'} element={<Authentication />} />
					</Routes>
				</BrowserRouter>
				<HashRouter>
					<Routes>
						{!isEmpty(currentConfigServices) && <Route path='*' element={<PageNotFound />} />}
						<Route path={'/login'} element={<Login />} />
						<Route
							path={'/'}
							element={
								<AuthenticateRoute>
									<Dashboard />
								</AuthenticateRoute>
							}
						/>

						{currentConfigServices?.enableOrder && (
							<>
								<Route
									path={'/orders/:code'}
									element={
										<AuthenticateRoute>
											<OrderDetail />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/orders'}
									element={
										<AuthenticateRoute>
											<Orders />
										</AuthenticateRoute>
									}
								/>
							</>
						)}
						{(currentConfigServices?.enableShipment || currentConfigServices?.enableOrder) && (
							<>
								<Route
									path={'/connect-supplier'}
									element={
										<AuthenticateRoute>
											<ConnectSupplier />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/shopping-cart'}
									element={
										<AuthenticateRoute>
											<ShoppingCart />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/transactions'}
									element={
										<AuthenticateRoute>
											<Transaction />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/packages'}
									element={
										<AuthenticateRoute>
											<Packages />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/delivery'}
									element={
										<AuthenticateRoute>
											<RequestDelivery />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/delivery/create'}
									element={
										<AuthenticateRoute>
											<CreateRequestDelivery />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/delivery/create-success'}
									element={
										<AuthenticateRoute>
											<Success />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/claims'}
									element={
										<AuthenticateRoute>
											<Claims />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/claims/create'}
									element={
										<AuthenticateRoute>
											<ClaimCreate />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/claims/:providerCode/:customerCode/:orderCode/:claimCode/:back'}
									element={
										<AuthenticateRoute>
											<ClaimDetail />
										</AuthenticateRoute>
									}
								/>
								<Route
									path='/delivery-notes'
									element={
										<AuthenticateRoute>
											<InventoryVoucher />
										</AuthenticateRoute>
									}
								/>
							</>
						)}

						{currentConfigServices?.enableGsa && (
							<>
								<Route
									path='/stored-products'
									element={
										<AuthenticateRoute>
											<StoredProducts />
										</AuthenticateRoute>
									}
								/>
								<Route
									path='/warehouse/stored-product/:productId/edit'
									element={
										<AuthenticateRoute>
											<StoredProductEditing />
										</AuthenticateRoute>
									}
								/>
								<Route
									path='/price-config'
									element={
										<AuthenticateRoute>
											<PriceConfig />
										</AuthenticateRoute>
									}
								/>
								<Route
									path='/market-connect'
									element={
										<AuthenticateRoute>
											<MarketConnect />
										</AuthenticateRoute>
									}
								/>
								<Route path={'/connect-market'} element={<ConnectMarketRedirectPage />} />
								<Route
									path={'/pending-products/shopee'}
									element={
										<AuthenticateRoute>
											<PendingProduct channel={'shopee'} />
										</AuthenticateRoute>
									}
								/>
								<Route
									path='/pending-products/shopee/:productId/edit'
									element={
										<AuthenticateRoute>
											<ProductForSell />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/selling-products/shopee'}
									element={
										<AuthenticateRoute>
											<SellingProduct channel={'shopee'} />
										</AuthenticateRoute>
									}
								/>
								<Route
									path={'/selling-products/shopee/:productId/edit'}
									element={
										<AuthenticateRoute>
											<SellingProductEditing />
										</AuthenticateRoute>
									}
								/>
							</>
						)}

						{currentConfigServices?.enableShipment && (
							<>
								<Route
									path='/shipments/create'
									element={
										<AuthenticateRoute>
											<CreateShipment />
										</AuthenticateRoute>
									}
								/>
								<Route
									path='/shipments'
									element={
										<AuthenticateRoute>
											<Shipments />
										</AuthenticateRoute>
									}
								/>
								<Route
									path='/shipments/:code'
									element={
										<AuthenticateRoute>
											<Shipment />
										</AuthenticateRoute>
									}
								/>
							</>
						)}
						{currentConfigServices?.enableShipment && currentConfigServices?.enablePeerPayment && (
							<>
								<Route
									path='/peerpayments/create'
									// element={
									//     <AuthenticateRoute>
									//         <CreateShipment />
									//     </AuthenticateRoute>
									// }
								/>
								<Route
									path='/peerpayments'
									element={
										<AuthenticateRoute>
											<PeerPayments />
										</AuthenticateRoute>
									}
                                    
								/>
								<Route
									path='/peerpayment/:code'
									// element={
									//     <AuthenticateRoute>
									//         <Shipment />
									//     </AuthenticateRoute>
									// }
								/>
							</>
						)}
						<Route
							path='/profile'
							element={
								<AuthenticateRoute>
									<Profile />
								</AuthenticateRoute>
							}
						/>
					</Routes>
				</HashRouter>
			</ConfigProvider>
		</div>
	)
}

export default App

