import { PlusOutlined } from '@ant-design/icons';
import { Cascader, Form, FormInstance, Input } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { AxiosResponse } from 'axios';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import RichTextEditor from '../../components/RichTextEditor';
import UploadCommon from '../../components/UploadCommon';
import { EditProductForSellFormNames } from '../../Interface/ProductInterface';
import { connectMarketRepository } from '../../repositories/ConnectMarketRepository';
import { uploadRepository } from '../../repositories/UploadRepository';
import M24Notification from '../../utils/M24Notification';
import { searchTree } from './helpers';
import { RowItem } from './RowItem';

interface CommonInfoSectionProps {
  form: FormInstance<Record<EditProductForSellFormNames, any>>;
  productImages: UploadFile<unknown>[];
  intergration: any;
  categoryId?: number;
  productVideo: string;
  setProductVideo: (url: string) => void;
  setProductImages: (images: UploadFile<unknown>[]) => void;
  setIsDisableSubmitButton: (value: boolean) => void;
  setCategoryId: (id: number | undefined) => void;
}

export interface CommonInfoData {
  detail: string;
}

const CommonInfoSection = forwardRef(
  (
    {
      form,
      productImages = [],
      intergration,
      productVideo,
      setProductVideo,
      setProductImages,
      setIsDisableSubmitButton,
      categoryId,
      setCategoryId,
    }: CommonInfoSectionProps,
    ref: any
  ) => {
    const { t } = useTranslation();
    const [categories, setCategories] = useState<any[]>([]);
    // const [isUploadingVideo, setIsUploadingVideo] = useState(false);
    // const [thumbnailVideo, setThumbnailVideo] = useState<string>('');

    useEffect(() => {
      if (!intergration) {
        return;
      }

      connectMarketRepository
        .getCategories(intergration)
        .then((res: AxiosResponse<any>) => {
          setCategories(res.data);

          var result = searchTree(categoryId, res.data);
          form.setFieldsValue({
            category: result,
          });
          // setCategorySelected(result);
        })
        .catch(() => {
          M24Notification.notifyError(
            t('message.titleFailed'),
            t(`productForSell.get-category-error`),
            '',
            5
          );
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intergration]);

    const longDescriptionRef: any = useRef();

    const handleSetProductImages = (images: UploadFile<unknown>[]): void => {
      setProductImages(images);
      setIsDisableSubmitButton(false);
    };

    const uploadFile = ({ file }: { file: File }) => {
      uploadRepository
        .create(file)
        .then((res: AxiosResponse<string>): void => {
          handleSetProductImages([
            ...productImages,
            {
              uid: String(productImages.length),
              name: file.name,
              url: res.data,
              status: 'done',
            },
          ]);
        })
        .catch(() => {
          M24Notification.notifyError(
            t('message.titleFailed'),
            t(`message.try_again`),
            '',
            4
          );
        });

      return false;
    };

    // const uploadVideoProduct = ({ file }: { file: File }) => {
    //   setIsUploadingVideo(true);

    //   generateVideoThumbnail(file).then((thumbnail: unknown): any => {
    //     setThumbnailVideo(String(thumbnail));
    //   });

    //   uploadRepository
    //     .create(file)
    //     .then(async (res: AxiosResponse<string>): Promise<any> => {
    //       setProductVideo(res.data);
    //     })
    //     .catch(() => {
    //       M24Notification.notifyError(
    //         t('message.titleFailed'),
    //         t(`message.try_again`),
    //         '',
    //         2
    //       );
    //     })
    //     .finally(() => {
    //       setIsUploadingVideo(false);
    //     });

    //   return false;
    // };

    const onChangeCategory = (values: any[]) => {
      if (!values) {
        setCategoryId(undefined);
      } else {
        const category: number | undefined = values[values.length - 1];
        setCategoryId(category);
      }

      form.resetFields(['attributes']);
    };

    // useEffect(() => {
    //   if (productVideo) {
    //     generateVideoThumbnailFromUrl(productVideo).then(
    //       (thumbnail: unknown): any => {
    //         console.log('thumbnail', thumbnail);
    //         setThumbnailVideo(String(thumbnail));
    //       }
    //     );
    //   } else {
    //     setThumbnailVideo('');
    //   }
    // }, [productVideo]);

    useImperativeHandle(ref, () => ({
      getCommonInfoValues: (): CommonInfoData => {
        return {
          detail: longDescriptionRef.current?.getContent(),
        };
      },
    }));

    return (
      <section id="common-info-section">
        <h2 className="section-header">
          <span>{t('productForSell.general-info-box')}</span>
        </h2>

        <RowItem label={`${t('productForSell.shop-name')}`} isRequire={true}>
          <Form.Item name="integrationName">
            <Input className="m24-input font-medium" disabled />
          </Form.Item>
        </RowItem>

        <RowItem label={`${t('productForSell.item-name')}`} isRequire={true}>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: `${t('productForSell.name-validate')}` },
              { min: 3, message: `${t('productForSell.name-validate-length')}` },
              { max: 100, message: `${t('productForSell.name-validate-length')}` },
            ]}
          >
            <Input
              showCount
              minLength={3}
              maxLength={100}
              className="m24-input font-medium"
              placeholder={`${t('productForSell.item-name-placeholder')}`}
            />
          </Form.Item>
        </RowItem>

        <RowItem label={`${t('productForSell.select-category')}`} isRequire={true}>
          <Form.Item
            name="category"
            rules={[
              {
                required: true,
                message: `${t('productForSell.category-validate')}`,
              },
            ]}
          >
            <Cascader
              options={categories}
              className="m24-select txt-size-h7"
              onChange={onChangeCategory}
              fieldNames={{
                label: 'categoryName',
                value: 'categoryId',
                children: 'childrens',
              }}
              dropdownClassName="dropdown-popup-custom"
              showSearch
              placeholder={`${t('productForSell.select-category')}`}
            />
          </Form.Item>
        </RowItem>

        <RowItem label={`${t('productForSell.product-sku')}`}>
          <Form.Item name="code">
            <Input
              maxLength={300}
              className="m24-input"
              placeholder={`${t('productForSell.product-sku-placeholder')}`}
            />
          </Form.Item>
        </RowItem>

        <RowItem label={`${t('productForSell.item-image')}`} isRequire={true}>
          <Form.Item name="productImageMock" className="input-hidden">
            <Input />
          </Form.Item>
          <UploadCommon
            listType="picture-card"
            fileList={productImages}
            isDrag={true}
            accept="image/*"
            setFileList={handleSetProductImages}
            customRequest={(file: any) => uploadFile(file)}
            maxCount={9}
            maxSize={2}
            require={true}
            requireMessage={`${t('productForSell.product-image-validate')}`}
            fileConfig={{ isSquare: true, minResolution: 500 }}
          >
            {productImages.length < 9 && (
              <PlusOutlined className="plus-icon" style={{ fontSize: 22 }} />
            )}
          </UploadCommon>
        </RowItem>

        {/* <RowItem label={t('productForSell.product-video')}>
          <div className="upload-video">
            <div className="upload-input">
              <UploadCommon
                listType="picture-card"
                fileList={
                  isUploadingVideo
                    ? [
                        {
                          uid: '1234456',
                          name: 'video-product',
                          url: thumbnailVideo,
                          status: 'uploading',
                        },
                      ]
                    : thumbnailVideo
                    ? [
                        {
                          uid: '1234456',
                          name: 'video-product',
                          url: thumbnailVideo,
                          status: 'done',
                        },
                      ]
                    : []
                }
                accept="video/mp4"
                customRequest={(file: any) => uploadVideoProduct(file)}
                mediaType="video"
                onRemove={() => {
                  setProductVideo('');
                  setThumbnailVideo('');
                }}
                videoUrlForPreview={productVideo}
                titleModalPreview={t('productForSell.title-preview-video')}
                maxSize={30}
              >
                {!productVideo && !isUploadingVideo && (
                  <PlusOutlined
                    className="plus-icon"
                    style={{ fontSize: 22 }}
                  />
                )}
              </UploadCommon>
            </div>
            <div>
              <span className="txt-color-secondary line-height20">
                1. {t('productForSell.upload-video-des-1')}
              </span>
              <br />
              <span className="txt-color-secondary line-height20">
                2. {t('productForSell.upload-video-des-2')}
              </span>
              <br />
              <span className="txt-color-secondary line-height20">
                3. {t('productForSell.upload-video-des-3')}
              </span>
              <br />
              <span className="txt-color-secondary line-height20">
                4. {t('productForSell.upload-video-des-4')}
              </span>
              <br />
            </div>
          </div>
        </RowItem> */}

        <RowItem
          label={`${t('productForSell.item-long-description')}`}
          isRequire={true}
        >
          <RichTextEditor
            ref={longDescriptionRef}
            placeholder={`${t('productForSell.fill-description')}`}
            onEditorChange={() => {
              setIsDisableSubmitButton(false);
            }}
            isRequired={true}
            form={form}
            formItemName="detail"
            requireMessage={`${t('productForSell.description-validate')}`}
          />
        </RowItem>
      </section>
    );
  }
);

export { CommonInfoSection };
