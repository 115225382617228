import { Row } from 'antd'
import lodash from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import Finance from './Finance'
import Heading from './Heading'
import Information from './Information'
import TabLayout from './TabLayout'
import TrackOrder from './TrackOrder'
export interface OrderInfoProps {
	orderDetail?: any
	statuses: any
	showProgressBar: any
	finalcials: any
	reOrder: () => void
	isReOrdering: boolean
	cancelOrder: (callback: () => void) => void
	isCancelOrdering: boolean
	confirmProductChange: (code: string, id: string, confirm: boolean, successInfo: string) => void
	loadingNotConfirm: { [id: string]: boolean } | undefined
	loadingConfirm: { [id: string]: boolean } | undefined
	claim: any
	onClaimPageChange: (filter: any) => void
	providerCode: string | undefined
	accountCode: string | undefined
	handleConfirm: (val: boolean) => void
	showBoxChat: () => void
	loadingCreateThread: boolean
	handleTrackOrder: () => void
	orderHistory: any
	loadingTransaction: boolean
}

function OrderInfo(props: OrderInfoProps) {
	const {
		orderDetail,
		statuses,
		showProgressBar,
		finalcials,
		reOrder,
		isReOrdering,
		cancelOrder,
		isCancelOrdering,
		confirmProductChange,
		loadingConfirm,
		loadingNotConfirm,
		loadingCreateThread,
		handleTrackOrder,
		orderHistory,
		loadingTransaction
	} = props
	const { handleConfirm, claim, onClaimPageChange, providerCode, accountCode } = props
	const { t } = useTranslation()
	const orderItems = lodash.get(props.orderDetail, 'orderItems', [])
	const exchangeRate = lodash.get(props.orderDetail, 'exchangeRate', [])
	const currencyProvider = lodash.get(props, 'provider.currency', '')
	const [expand, setExpand] = useState(true);
	
	return (
		<>
			<Heading
				statuses={statuses}
				isCancelOrdering={isCancelOrdering}
				cancelOrder={cancelOrder}
				isReOrdering={isReOrdering}
				reOrder={reOrder}
				orderDetail={orderDetail}
				showBoxChat={props.showBoxChat}
				loadingCreateThread={loadingCreateThread}
				loading={showProgressBar}
			/>
			<Row className='mg-t-12'>
				<div className='dpl-flex flex-col flex-basis-80pc flex-wrap justify-content-start mg-r-12'>
					<div className={`dpl-flex justify-content-between position-re white-box ${!expand ? 'h-fit-content align-items-center py-0' : ''}`}>
						<Information orderDetail={orderDetail} expand={expand} loading={showProgressBar}/>
						<Finance orderDetail={orderDetail} expand={expand} loading={showProgressBar} />
						<div className={`${expand ? 'orderDetail-expand' :'orderDetail-collapse'} ${showProgressBar ? 'flex align-items-center' : ''}`} onClick={() => setExpand(!expand)}>
							{!showProgressBar ? <i className={`mg-r-6 fa-solid fa-angle-${expand ? 'up' : 'down'}`}></i> : <Skeleton width={14} height={14} className='mg-r-6'/>}
							{!showProgressBar ? t(`orderDetail.${expand ? 'collapse' : 'noCollapse'}`) :<Skeleton width={50} height={20}/>}
						</div>
					</div>
					<div>
						<TabLayout
							loadingConfirm={loadingConfirm}
							loadingNotConfirm={loadingNotConfirm}
							confirmProductChange={confirmProductChange}
							finalcials={finalcials}
							orderDetail={orderDetail}
							items={orderItems}
							exchangeRate={exchangeRate}
							currency={currencyProvider}
							loading={showProgressBar}
							claim={claim}
							onClaimPageChange={onClaimPageChange}
							providerCode={providerCode}
							accountCode={accountCode}
							handleConfirm={handleConfirm}
							loadingTransaction={loadingTransaction}
						/>
					</div>
				</div>
				<div className='flex-basis-20pc pd-t-12 px-12 bg-color-white box-order track-order h-fit-content'>
					<h5 className='robotomedium txt-size-h5 mg-0 line-h-24 mg-bt-8 txt-color-black mg-bt-8'>
						{!showProgressBar ? t('orderDetail.history') : <Skeleton width={150} height={22} />}
					</h5>
					<TrackOrder
						orderHistory={orderHistory}
						statuses={statuses}
						orderDetail={orderDetail}
						handleTrackOrder={handleTrackOrder}
						loading={showProgressBar}
					/>
				</div>
			</Row>
		</>
	)
}
export default OrderInfo
