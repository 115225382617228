import { Col, Form, Input, Row, Select, Spin } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	DetailFieldInterface,
	DetailFieldValueInterface,
	EditProductForSellFormNames,
	InputTypeEnum,
} from '../../Interface/ProductInterface'
import { RowItem } from './RowItem'

interface DetailSectionInterface {
	form: FormInstance<Record<EditProductForSellFormNames, any>>
	detailFields: DetailFieldInterface[]
	isGetAttribute: boolean
}

const DetailSection: FC<DetailSectionInterface> = ({
	form,
	detailFields,
	isGetAttribute = false,
}: DetailSectionInterface) => {
	const { t } = useTranslation()
	const [isShowAllAttribute, setIsShowAllAttribute] = useState<boolean>(false)
	const renderInput = ({ inputType, values }: DetailFieldInterface) => {
		if (inputType === InputTypeEnum.DROP_DOWN || inputType === InputTypeEnum.COMBO_BOX) {
			const options = values?.map((value: DetailFieldValueInterface) => ({
				label: value.translate_value,
				value: value.original_value,
			}))

			return (
				<Select
					options={options}
					className='m24-select'
					allowClear
					placeholder={t('productForSell.please-select')}
					dropdownClassName='dropdown-popup-custom'
					// mode={inputType === InputTypeEnum.COMBO_BOX ? 'multiple' : undefined}
				/>
			)
		}

		if (inputType === InputTypeEnum.TEXT_FILED) {
			return <Input placeholder={`${t('productForSell.please-enter')}`} />
		}
	}

	return (
		<section id='details-info'>
			<h2 className='section-header'>
				<span>{t('productForSell.detail-info')}</span>
			</h2>

			<div>
				<Row>
					{isGetAttribute ? (
						<RowItem className='mgt10 w-100-percent'>
							<Spin />
						</RowItem>
					) : (
						detailFields.map((field: DetailFieldInterface, index: number): ReactNode => {
							let className: string = isShowAllAttribute && index > 5 ? 'dpl-none' : ''
							if (index % 2 > 0) {
								className += ' pd-l-10'
							} else {
								className += ' pd-r-10'
							}

							return (
								<Col span={12} key={`field_${index}`} className={className}>
									<RowItem
										label={field.attributeName}
										isRequire={field.isMandatory}
										labelSpan={6}
										contentSpan={18}>
										<Form.Item
											name={['attributes', String(field.attributeId)]}
											rules={[
												{
													required: field.isMandatory,
													message: `${t('productForSell.attributes-validate', {
														name: field.attributeName,
													})}`,
												},
											]}>
											{renderInput(field)}
										</Form.Item>
									</RowItem>
								</Col>
							)
						})
					)}
					{/* <span onClick={() => setIsShowAllAttribute(!isShowAllAttribute)}>
            {isShowAllAttribute
              ? t('productForSell.show-all-attribute')
              : t('productForSell.show-less-attribute')}
          </span> */}
				</Row>
			</div>
		</section>
	)
}

export { DetailSection }

