import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const DragableUploadListItem = ({ file = {}, originNode, ...props }: any) => {
  return (
    <Draggable draggableId={file.uid} index={Number(file.uid)}>
      {(provided: any) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {originNode}
        </div>
      )}
    </Draggable>
  );
};

export { DragableUploadListItem };
