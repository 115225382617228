import React from 'react'
import { useTranslation } from 'react-i18next'
import InlineEdit from '../../../components/InlineEdit'
interface NoteProps {
	submitHandler: (value: string, callback: () => any) => void
	note: string
	label: string
	title: string
}
const Note: React.FC<NoteProps> = (props) => {
	const { t } = useTranslation()
	const { submitHandler, note, label, title } = props
	return (
		<div className='box-services__note-item mg-bt-6'>
			<span className='label'>{label}:</span>
			<InlineEdit
				onSubmit={submitHandler}
				title={title}
				type={'textarea-modal'}
				defaultValue={note}
				className={'_note-custom'}></InlineEdit>
		</div>
	)
}

export default Note