import { Button, Image } from 'antd'
import { t } from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { NO_BREAD_CRUMB } from '../../core/config'
import NotFoundImg from '../../resources/images/404.png'
import MainLayout from '../Layout'
const PageNotFound = () => {
	const navigate = useNavigate()
	return (
		<MainLayout title={t('page.notFound')} breadcrumbs={{ enable: NO_BREAD_CRUMB }}>
			<div className='dpl-flex flex-col align-items-center justify-content-center height100'>
				<Image src={NotFoundImg} preview={false} className='mg-bt-6'/>
				<h3 className='robotomedium fsz-24 line-h-28 txt-color-black mg-0 mg-bt-6'>{t('page.notFound')}</h3>
				<span className='mg-bt-20 robotoregular fsz-14 line-h-22 txt-color-gray2 mg-bt-6'>{t('page.notFoundMess')}</span>
				<Button
					className='btn-df btn-df-primary txt-color-black robotoregular fsz-14 line-h-22 border-radius4 py-5 px-12'
					onClick={() => navigate('/', { replace: true })}
					icon={<i className='far fa-home mg-r-6' />}>
					{t('page.backToHomePage')}
				</Button>
			</div>
		</MainLayout>
	)
}

export default PageNotFound
