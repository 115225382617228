import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Card, Col, Image, Modal, notification, Row, Steps } from 'antd'
import lodash from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MainLayout from '../../components/Layout'
import ShipmentAndOrder from '../../components/ShoppingCart/ShipmentAndPeerPayment/ShipmentAndOrderStep2'
import ShipmentAndOrderStep3 from '../../components/ShoppingCart/ShipmentAndPeerPayment/ShipmentAndOrderStep3'
import ShopList from '../../components/ShoppingCart/ShopList'
import Step2 from '../../components/ShoppingCart/Step2'
import Step3 from '../../components/ShoppingCart/Step3'
import { SHOPPING_CART_DESCRIPTIONS } from '../../core/config'
import useWindowDimensions from '../../hooks'
import AccountBalanceInterface from '../../Interface/AccountBalanceInterface'
import PartnerAccountInterface from '../../Interface/PartnerAccountInterface'
import { InfoShipmentAndPeerPayment } from '../../Interface/PreviewShipmentAndPeerPaymentInterface'
import ShipmentAndPayOrderInterface from '../../Interface/ShipmentAndPayInterface'
import { cartRepository } from '../../repositories/CartRepository'
import { supplierRepository } from '../../repositories/SupplierRepository'
import SuccessImg from '../../resources/images/THANH-CONG.gif'
import M24ErrorUtils from '../../utils/M24ErrorUtils'
import M24Notification from '../../utils/M24Notification'
import SkeletonShoppingCart from './SkeletonShoppingCart'
import './styles.scss'



const { Step } = Steps

function ShoppingCart() {
	const [showProgressBar, setShowProgressBar] = useState<boolean>(false)
	const [isDeletingSKU, setDeletingSKU] = useState<boolean>(false)
	const [isDeletingMerchant, setDeletingMerchant] = useState<boolean>(false)
	const { t } = useTranslation()
	const [currentStep, setCurrentStep] = useState<number>(0)
	const [cartItem, setCartItem] = useState<any>([])
	const [filter, setFilter] = useState<any>({})
	const [pagination, setPagination] = useState<any>({})
	const { width } = useWindowDimensions()
	const [accountBalance, setAccountBalance] = useState<any>([])
	const [merchantSelected, setMerchantSelected] = useState<any>({
		merchant: {},
		products: [],
	})
	const [loadingState, setLoadingState] = useState(true)
	const [isShipmentAndOrders, setIsShipmentAndOrders] = useState(false)
	const [partnerPeerPayment, setPartnerPeerPayment] = useState<PartnerAccountInterface[]>([])
	const [preview1688, setPreview1688] = useState<any>({})
	const [info, setInfo] = useState<InfoShipmentAndPeerPayment>()
	const [loadingPreview, setLoadingPreview] = useState(false);


	const [previewShipment, setPreviewShipment] = useState<any>()
	const navigate = useNavigate()
	const [openModalSuccess, setOpenModalSuccess] = useState(false)
	const [feePeerPayment, setFeePeerPayment] = useState<any>();
	const [loadingCreateShipmentAndPeerPayment, setLoadingCreateShipmentAndPeerPayment] = useState(false);
	
	
	useEffect(() => {
		getData(filter)
		getAccountBalance()
		setTimeout(() => setLoadingState(false), 1000)
	}, [])


	const getData = (filter?: any) => {
		setShowProgressBar(true)
		cartRepository
			.getProductCart(filter)
			.then((res) => {
				let currentPage = lodash.get(res, 'headers.x-page-number')
				let total = lodash.get(res, 'headers.x-total-count')
				setCartItem(res.data)
				setPagination({
					current: parseInt(currentPage) + 1,
					hideOnSinglePage: true,
					total: parseInt(total),
					pageSize: parseInt(lodash.get(res, 'headers.x-page-size')),
				})
			})
			.catch((err) => {})
			.finally(() => {
				setShowProgressBar(false)
			})
	}

	const getAccountBalance = useCallback(async () => {
		try {
			const res = await supplierRepository.getAccountBalance()
			const accountBalanceFiltered = res.filter((acc: AccountBalanceInterface) => {
				return acc.connection.provider.orderServices[0].enable
			})
			setAccountBalance(accountBalanceFiltered)
		} catch (err) {}
	}, [])
	const onPageChange = (filter: any) => {
		setFilter(filter)
		let temp = lodash.cloneDeep(filter)
		if (filter && filter.page && filter.page > 0) {
			temp.page = temp.page - 1
		}
		window.scroll(0, 0)
		getData(temp)
	}

	const handleClickOrderButton = () => {
		setIsShipmentAndOrders(false)
		setCurrentStep(1)
	}

	const handleCreateShipmentAndOrder = () => {
		setIsShipmentAndOrders(true)
		setCurrentStep(1)
	}

	const deleteSku = (merchantId: any, productId: any, skuId: any, callback: () => void) => {
		setDeletingSKU(true)
		cartRepository
			.deleteSku(skuId)
			.then((res) => {
				M24Notification.messageSuccess(t('message.deleteSKUSuccess'))
				if (callback) callback()
				let temp = lodash.cloneDeep(cartItem)
				let isLastSKU = false
				temp.map((x: any) => {
					if (x.id === merchantId) {
						let product = x.products.find((xx: any) => xx.id == productId)
						if (product && product.skus.length === 1) {
							isLastSKU = true
						}
					}
				})
				if (isLastSKU) getData({ ...filter, page: 0 })
				else getData(filter)
				if (merchantSelected && merchantSelected.merchant.id) {
					let temp = lodash.cloneDeep(merchantSelected)
					if (temp.merchant.id === merchantId) {
						temp.products.map((x: any) => {
							if (x.id === productId) {
								x.skus = x.skus.filter((sku: any) => sku.id !== skuId)
							}
						})
						setMerchantSelected(temp)
					}
				}
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => setDeletingSKU(false))
	}
	const deleteMerchant = (merchantId: any, callback: () => void) => {
		setDeletingMerchant(true)
		cartRepository
			.deleteMerchant(merchantId)
			.then((res) => {
				M24Notification.messageSuccess(t('message.deleteMerchantSuccess'))
				getData({ ...filter, page: 0 })
				if (merchantSelected && merchantSelected.merchant.id) {
					let temp = lodash.cloneDeep(merchantSelected)
					if (temp.merchant.id === merchantId) {
						setMerchantSelected({
							merchant: {},
							products: [],
						})
					}
				}
				if (callback) callback()
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => setDeletingMerchant(false))
	}
	const handleUpdateQuantitySku = (
		merchantId: any,
		quantity: number,
		productId: any,
		skuId: any,
		callback: () => void
	) => {
		cartRepository
			.updateQuantitySku(skuId, quantity)
			.then((res) => {
				M24Notification.messageSuccess(t('message.updateQuantitySuccess'))
				getData({ ...filter, page: filter.page > 0 ? filter.page - 1 : 0 })
				if (merchantSelected && merchantSelected.merchant.id) {
					let temp = lodash.cloneDeep(merchantSelected)
					if (temp.merchant.id === merchantId) {
						temp.products.map((x: any) => {
							if (x.id === productId) {
								x.skus.map((sku: any) => {
									if (sku.id === skuId) {
										sku.quantity = quantity
									}
								})
							}
						})
						setMerchantSelected(temp)
					}
				}

				if (callback) callback()
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => setDeletingMerchant(false))
	}

	const handleUpdateEmployeeNote = (skuId: any, value: string, callback: () => void) => {
		cartRepository
			.updateSku(skuId, { employeeNote: value })
			.then((res) => {
				getData({ ...filter, page: filter.page > 0 ? filter.page - 1 : 0 })
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => {
				if (callback) callback()
			})
	}
	const handleUpdatePersonalNote = (skuId: any, value: string, callback: () => void) => {
		cartRepository
			.updateSku(skuId, { personalNote: value })
			.then((res) => {
				getData({ ...filter, page: filter.page > 0 ? filter.page - 1 : 0 })
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => {
				if (callback) callback()
			})
	}

	const selectMerchant = (merchant: any, isSelect: boolean) => {
		console.log('isSelect', isSelect)
		if (!isSelect)
			setMerchantSelected({
				merchant: {},
				products: [],
			})
		else {
			let item = cartItem.find((x: any) => x.id === merchant.id)
			if (item && item.products && item.products.length > 0) {
				// let skusId: string[]=[];
				// item.products.map((x: any)=>{
				//         x.skus.map((xx:any)=> {
				//             skusId.push(xx);
				//         });
				// })
				setMerchantSelected({
					merchant,
					products: item.products,
				})
			}
		}
	}
	const selectSku = (merchant: any, product: any, sku: any, isSelect: boolean) => {
		console.log('merchant', merchant)
		console.log('product', product)
		console.log('sku', sku)
		console.log('isSelect in shopping cart', isSelect)
		if (merchant.id !== merchantSelected.merchant.id) {
			setMerchantSelected({
				merchant,
				products: [
					{
						id: product.id,
						pricePolicies: product.pricePolicies,
						skus: [sku],
					},
				],
			})
		} else {
			let temp = lodash.cloneDeep(merchantSelected)
			if (!isSelect) {
				temp.products.map((x: any) => {
					if (x.id === product.id) {
						let skus = x.skus.filter((xx: any) => xx.id !== sku.id)
						x.skus = skus
					}
				})
				let products = temp.products.filter((x: any) => x.skus.length > 0)
				if (products && products.length > 0) {
					temp.products = products
					setMerchantSelected(temp)
				} else {
					setMerchantSelected({
						merchant: {},
						products: [],
					})
				}
			} else {
				let isExist = temp.products.find((x: any) => x.id === product.id)
				if (!isExist) {
					temp.products.push({
						id: product.id,
						pricePolicies: product.pricePolicies,
						skus: [sku],
					})
				} else {
					temp.products.map((x: any) => {
						if (x.id === product.id) {
							x.skus.push(sku)
						}
					})
				}
				setMerchantSelected(temp)
			}
		}
	}

	const [orderSuccessView, setOrderSuccessView] = useState<any>({})
	const handleCreateOrder = (body: any, callback: any) => {
		// setIsLoading(true);
		cartRepository
			.createOrderOneShop(body)
			.then((response) => {
				setOrderSuccessView(response.data)
				setStep(2)
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => {
				if (callback) callback()
			})
	}

	const handlePreview1688 = useCallback(async (body: any, info: any) => {
		try {
			const res = await cartRepository.previewShipment1688(body)
			setInfo(info)
			setPreview1688(res)
		} catch (error) {
			setPreview1688(null)
			notification.error({message: t('shipment-pay.errorFromMarketPalace'), duration: 6})
		}
	}, [t])

	const handleCreateShipmentAndOrderFromStep2 = useCallback(async (body: any, info: any, cb: any) => {
		setInfo(info)
		try {
			const resShipment = await cartRepository.createShipmentNot1688(body)
			setPreviewShipment(resShipment)
			setCurrentStep(2)
		} catch (error) {
			// setPreview1688(null)
			M24ErrorUtils.showError(t, error)
		}finally {
			cb()
		}
	}, [t])

	const initSteps = () => {
		if (currentStep === 0) {
			return (
				<ShopList
					isDeletingMerchant={isDeletingMerchant}
					isDeletingSKU={isDeletingSKU}
					handleDeleteSku={deleteSku}
					handleDeleteMerchant={deleteMerchant}
					handleUpdateQuantitySku={handleUpdateQuantitySku}
					onPageChange={onPageChange}
					pagination={pagination}
					filter={filter}
					handleClickOrderBtn={handleClickOrderButton}
					cartItem={cartItem}
					getCartData={getData}
					handleUpdateEmployeeNote={handleUpdateEmployeeNote}
					handleUpdatePersonalNote={handleUpdatePersonalNote}
					selectMerchant={selectMerchant}
					selectSku={selectSku}
					merchantSelected={merchantSelected}
					handleShowProgressBar={(val: boolean) => setShowProgressBar(val)}
					onCreateShipmentAndOrder={handleCreateShipmentAndOrder}
				/>
			)
		}
		if (currentStep === 1 && !isShipmentAndOrders) {
			return (
				<Step2
					setStep={setStep}
					setMerchant={setMerchantSelected}
					merchant={merchantSelected}
					accountBalance={accountBalance}
					handleCreateOrder={handleCreateOrder}
				/>
			)
		}
		if (currentStep === 1 && isShipmentAndOrders) {
			return (
				<ShipmentAndOrder
					setStep={setStep}
					setMerchant={setMerchantSelected}
					merchant={merchantSelected}
					partnerPeerPayment={partnerPeerPayment}
					handleCreateShipmentAndOrder={handleCreateShipmentAndOrderFromStep2}
					getPartnersOfShipment={getPartnersOfShipment}
					handlePreview1688={(body: any, info: any) => handlePreview1688(body, info)}
					preview1688={preview1688}
					handleLoadingPreview={(val: boolean) => setLoadingPreview(val)}
					loadingPreview={loadingPreview}
				/>
			)
		}
		if (currentStep === 2 && !isShipmentAndOrders) {
			return <Step3 orderSuccessView={orderSuccessView} />
		}
		if (currentStep === 2 && isShipmentAndOrders) {
			return (
				<ShipmentAndOrderStep3
					merchant={merchantSelected}
					preview1688={preview1688}
					previewShipment={previewShipment}
					info={info}
					feePeerPayment={feePeerPayment}
					handleCreateShipmentAndPayOrder={handleCreateShipmentAndPayOrderFormStep3}
					handleLoadingCreateShipmentAndOrder={(val: boolean) => setLoadingCreateShipmentAndPeerPayment(false)}
					loading={loadingCreateShipmentAndPeerPayment}
				/>
			)
		}
	}
	const setStep = (value: number) => {
		setCurrentStep(value)
		if (value === 0) {
			setMerchantSelected({
				merchant: {},
				products: [],
			})
			// notification.destroy();
		}
	}

	const getPartnersOfPeerPayment = useCallback(async () => {
		try {
			const res = await cartRepository.getPartnerOfShipmentOrOrder('PEER_PAYMENT')
			setPartnerPeerPayment(res)
		} catch (error) {
		}
	}, [])

	const getPartnersOfShipment = useCallback(async (providerCode: string) => {
		try {
			const res = await cartRepository.getPartnerOfShipmentOrOrder('SHIPMENT')
			setPartnerPeerPayment(res.data)
		} catch (error) {
			M24ErrorUtils.showError(t, error)
		}
	}, [t])

	useEffect(() => {
		getPartnersOfPeerPayment()
	}, [getPartnersOfPeerPayment])

	const handleCreateShipmentAndPayOrderFormStep3 = async (body: ShipmentAndPayOrderInterface, cb: () => void) => {
		setLoadingCreateShipmentAndPeerPayment(true)
		try {
			await cartRepository.createShipmentAndPayOrder(body)
			setOpenModalSuccess(true)
		} catch (error) {
			M24ErrorUtils.showError(t, error)
		}
		finally{
			cb()
		}
	}

	const handleOk = () => {
		setOpenModalSuccess(true)
	}

	const handleCancel = () => {
		setOpenModalSuccess(false)
		setCurrentStep(0)
		getData()
	}

	const getPeerPaymentFee = useCallback(async () => {
		const body = {
			peerPaymentProvider: info?.provider ?? '',
			peerPaymentUsername: info?.providerUsername??'',
			amount: preview1688?.orderPreviewResuslt && preview1688?.orderPreviewResuslt[0].sumPayment ? preview1688?.orderPreviewResuslt[0].sumPayment/100 : 0,
			exchangeRate: info?.exchangeRate ?info?.exchangeRate.rate :   0,
			paymentMethodCode: 'alipay',
		}

		try {
			const res = await cartRepository.getPeerPaymentFee(body)
			setFeePeerPayment(res)
		} catch (error) {
			M24ErrorUtils.showError(t, error)
		}
	 }, [info?.exchangeRate, info?.provider, info?.providerUsername, preview1688?.orderPreviewResuslt, t])


	 useEffect(() => {
		if(info &&  preview1688?.orderPreviewResuslt && info?.exchangeRate) {
			getPeerPaymentFee()
		}
	 }, [getPeerPaymentFee, info, preview1688?.orderPreviewResuslt])



	return (
		<MainLayout
			title={t('menu.shopping-cart')}
			showProgressBar={showProgressBar}
			descriptions={
				currentStep === 0
					? {
							title: `${t('shoppingCart.pageTitle')}`,
							descriptions: `${t('shoppingCart.descriptions')}`,
							className: 'box-shadow-descriptions',
							pageCode: SHOPPING_CART_DESCRIPTIONS,
							hasDescription: true,
					  }
					: null
			}
			hasExtensionCommon={currentStep === 0 ? true : false}
			breadcrumbs={{ currentStep: currentStep + 1 }}>
			<Card className={'steps-header'} bodyStyle={{ width: width - 85 }}>
				<Row className={'dpl-flex justify-content-between'} align='middle'>
					<Col lg={10} sm={20}>
						<Steps current={currentStep} className='shopping-cart-steps' size='small'>
							<Step
								className={currentStep === 1 ? 'cursor-pointer' : ''}
								onClick={currentStep === 1 ? () => setStep(currentStep - 1) : undefined}
								title={
									<span className='robotoregular fsz-14 line-h-22 txt-capitalize'>
										{t('shopping-cart.products')}
									</span>
								}
							/>
							<Step
								title={
									<span className={`robotoregular fsz-14 line-h-22`}>
										{t('shopping-cart.services')}
									</span>
								}
							/>
							<Step
								title={
									<span className='robotoregular fsz-14 line-h-22'>
										{t('shopping-cart.payments')}
									</span>
								}
							/>
						</Steps>
					</Col>
					<Col>
						{currentStep > 0 && currentStep < 2 && (
							<Button
								onClick={() => {
									setStep(currentStep - 1)
								}}
								icon={<ArrowLeftOutlined />}
								className='btn-df btn-df-tertiary-bg-white fsz-14 line-h-22 px-15'>
								{t('shopping-cart.backStep')}
							</Button>
						)}
					</Col>
				</Row>
				{currentStep === 0 && (
					<Row align={'middle'} className={'pdt15 mgt10 border-top-1x line'}>
						<Col span={12}>
							<span className={'txt-color-black txt-center txt-size-h7 robotomedium pd-l-32'}>
								{t('shopping-cart.products')}
							</span>
						</Col>
						<Col span={5} className='txt-center'>
							<span className={'txt-color-black txt-center txt-size-h7 robotomedium'}>
								{t('shopping-cart.quantity')}
							</span>
						</Col>
						<Col span={2} className={'txt-right txt-size-h7 robotomedium'}>
							<span className={'txt-color-black txt-center txt-size-h7 robotomedium'}>
								{t('shopping-cart.unitPrice')}
							</span>
						</Col>
						<Col span={2} className='txt-right'>
							<span className={'txt-color-black txt-center txt-size-h7 robotomedium'}>
								{t('shopping-cart.totalPrice')}
							</span>
						</Col>
						<Col span={3} className='txt-right'>
							<span className={'txt-color-black txt-center txt-size-h7 robotomedium'}>
								{t('shopping-cart.action')}
							</span>
						</Col>
					</Row>
				)}
			</Card>
			{currentStep === 0 ? (
				<div style={{ paddingTop: 114 }}>
					{showProgressBar || loadingState ? (
						<SkeletonShoppingCart loading={loadingState} data={cartItem} />
					) : (
						initSteps()
					)}
				</div>
			) : (
				<div style={{ paddingTop: 74 }}>{initSteps()}</div>
			)}

			<Modal title={null} open={openModalSuccess} onOk={handleOk} onCancel={handleCancel} footer={null} centered closable={false} className='success-peer-payment-shipment'>
				<div className="flex flex-col align-items-center">
				<Image preview={false} src={SuccessImg} width={200} height={200}/>
				<span className='robotomedium fsz-16 line-h-24 txt-color-blue5 mg-bt-8'>{t('shipment-pay.createSuccess')}</span>
				<span className='robotoregular fsz-14 line-h-22 txt-color-black mg-bt-16'>
					{t('shipment-pay.tksForUseMyService')}
				</span>
				<Button
					className=' mg-bt-8 btn-df btn-df-secondary-bg-lemon robotomedium flex align-items-center flex-nowrap justify-content-center fsz-14 line-h-22'
					onClick={() => window.location.reload()}
					icon={<i className='fa-solid fa-right mg-r-10 txt-color-white'></i>} style={{width: 180}}>
					{t('shipment-pay.nextUseShipmentAndPeerPayment')}
				</Button>
				<Button
					className='btn-df btn-df-secondary-bg-white robotomedium flex align-items-center flex-nowrap justify-content-center fsz-14 line-h-22'
					onClick={() => {
						navigate('/peerpayments')
					}}
					icon={<i className='fa-solid fa-right mg-r-10 txt-color-blue6'></i>} style={{width: 180}}>
					{t('shipment-pay.gotoPeerPaymentList')}
				</Button>
				</div>
			</Modal>
		</MainLayout>
	)
}

export default ShoppingCart