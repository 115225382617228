import React from "react";
import {VendorServiceInterface} from "../../../Interface/VendorServiceInterface";
import {ServicePackageInterface} from "../../../Interface/UserServiceInterface";
import {useTranslation} from "react-i18next";
import {Button,Image} from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import GOBIZ_SUCCESS from '../../../resources/images/Gobiz-Success.png'
import GOBIZ_SUCCESS_GIF from '../../../resources/images/THANH-CONG.gif'

type SuccessPopupProps = {
    selectedVendor: VendorServiceInterface;
    selectedPackage: ServicePackageInterface;
    handleCancel: () => void;
}

function SuccessPopup(props: SuccessPopupProps) {
    const {t} = useTranslation();
    return (
        <div className={'package-list'}>
            <div className={'dpl-flex flex-row align-items-center justify-content-center'}>
                {/* <div className={'pld30 justify-content-center'}>
                    <Image
                        style={{width:256,height:'auto'}}
                        src={GOBIZ_SUCCESS_GIF}
                        alt={''}
                        preview={false}
                    />
                </div> */}
                <div className={''}>
                    <Paragraph className={'txt-size-h7 txt-color-black'}>
                        <span><i className="fa-solid fa-circle-small fa-2xs mgr5"/></span>
                        <span className={'robotomedium '}>{t('profile.vendor-packages-purchase', {
                            vendor: props.selectedVendor.name,
                            package: props.selectedPackage.name
                        })}</span>
                        <span className={'robotoregular mgl5'}>{t('profile.purchase-success-message')}</span>
                    </Paragraph>
                    <Paragraph className={'txt-size-h7 txt-color-black position-re'}>
                        <span><i className="fa-solid fa-circle-small fa-2xs mgr5"/></span>
                        <span className={'robotoregular'}>{t('profile.purchase-success-notice')}</span>
                    </Paragraph>
                    <div className={'mgt10 dpl-fl flex-row'}>
                <span className={''}>
                    <i className="fa-solid fa-pipe mgr5 txt-size-h7" style={{color: '#339DFF'}}/>
                </span>
                        <span>
                     <i className="fa-solid fa-circle-exclamation mgr5 txt-size-h7"
                        style={{color: '#339DFF'}}/>
                     <span className={'txt-size-h7 robotoregular txt-color-black'}>
                         {t('profile.purchase-success-cs-content')}
                     </span>
                 </span>
                    </div>
                </div>


            </div>
            <div className={'package-list-footer'}>
                <Button className={'purchase txt-capitalize'}
                        onClick={props.handleCancel}
                >
                    <i className="fa-solid fa-check mgl5 mgr10"/>{t('purchasePopup.payment-popup-confirm')}
                </Button>
            </div>
        </div>
    )
}

export default SuccessPopup;
