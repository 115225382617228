import {Button, Col, Divider, List, Modal, Row, Skeleton} from "antd";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {orderRepository} from "../../repositories/OrderRepository";
import {parseOrderLog} from "../../utils/FormatLog";
import {useTranslation} from "react-i18next";
import {formatDateTime} from "../../core/helpers/date-time";
import lodash from "lodash";

interface LogModalProps{
    onCancel:()=>void;
    visible: boolean;
    orderCurrency: any;
}
function LogModal(props: LogModalProps){
    const {onCancel, visible, orderCurrency} = props;
    const [dataSource, setDataSource] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [initLoading, setInitLoading] = useState<boolean>(true);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [filter, setFilter] = useState<any>({});
    const [metadata, setMetadata] = useState<any>();
    let {code} = useParams();
    const {t} = useTranslation();
    useEffect(()=>{
        getData(0);
    },[]);
    useEffect(()=>{
        if(metadata&&metadata.page + 1 >= metadata.pageCount)
            setHasMore(false);
    },[metadata]);
    const getData=(page:number)=>{
        if(page>0)
            setLoading(true);
        orderRepository.getLogs(code, {page}).then(res=>{
            let items = parseOrderLog(t, res.data, orderCurrency);
            setMetadata({
                pageCount: parseInt(lodash.get(res, 'headers.x-page-count')),
                page:parseInt(lodash.get(res, 'headers.x-page-number')),
                size: parseInt(lodash.get(res, 'headers.x-page-size')),
                total: parseInt(lodash.get(res, 'headers.x-total-count')),
            })
            setDataSource(page===0?items:dataSource.concat(items));
        }).catch(err=>{

        }).finally(()=>{
            if(page===0) setInitLoading(false);
            else setLoading(false);
        });
    }
    const loadMoreData=()=>{
        if (loading) return;
        getData(metadata.page + 1)
    }
    const loadMore =
        !initLoading&&hasMore ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button loading={loading} onClick={loadMoreData}>{t('button.loadmore')}</Button>
            </div>
        ) : null;
    return <Modal
        open={visible}
        title={<span className='robotomedium line-h-22 fsz-14 txt-color-primary pd-0'>{t('log_order.title',{code: code})}</span>}
        onCancel={onCancel}
        closeIcon={<i className="far fa-times w-9 h-16 fsz-14 line-h-16 txt-color-black"></i>}
        footer={false}
        width={750}
        className='modal-without-header-padding log-order'
        bodyStyle={{padding:0}}
        centered
    >
        <div
            style={{
                maxHeight: 400,
                overflow: 'auto',
                paddingBottom:20
            }}
        >
            <List
                loadMore={loadMore}
                dataSource={dataSource}
                loading={initLoading}
                renderItem={(item: any, index: number) => (
                    <List.Item key={index} style={{paddingTop:8, paddingBottom: 6}}>
                        <div>
                            <span className={'txt-size-h8'}>
                                <span className={'txt-color-gray2'}>{t('log_order.timestamp')}</span>
                                <span className={'txt-color-black'}>{formatDateTime(item.timestamp)}</span>
                                <span>
                                            <span
                                                className={'pdl12 txt-color-gray2'}>{item.role === 'STAFF' ? `${t('log_order.staff')}` : `${t('log_order.customer')}`}</span>
                                            <span
                                                className={'txt-color-black robotomedium pdl5'}>{item.fullname}</span>
                                        </span>
                            </span>
                            <Row className='txt-size-h7 txt-color-black dpl-block whitespace-pre-wrap break-word'  dangerouslySetInnerHTML={{__html: `${t(`log_order.${item.property}`, item)}`}}>
                            </Row>
                        </div>
                    </List.Item>
                )}
            />
        </div>
    </Modal>
}
export default LogModal;
